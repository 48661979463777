
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskRecordVoiceDialog" class="task__dialog">
  <div class="vp-task-recording-voice-dialog task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'RECORD_VOICE_MSG' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body task__dialog-body--pad">
    <div *ngIf="isRecording" class="recording-time">
      <span> {{ recordedTime }}</span>
    </div>
    <div *ngIf="!isRecording" class="tap-to-start">
      <span> {{'TAP_TO_START' | translate}} </span>
    </div>
    <div *ngIf="isRecording" class="voice-recording">
      <mdl-icon class="stopVoiceRecBtn" (click)="abortRecording()">delete</mdl-icon>
      <button (click)="sendRecording()" id="recordvoicestopbtn" class="task-record-stop-btn mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
        <i class="material-icons">stop</i>
      </button>
      <mdl-icon *ngIf="!isPauseRecording" class="pauseVoiceRecBtn" (click)="pauseRecording()">pause</mdl-icon>
      <mdl-icon *ngIf="isPauseRecording" class="resumeVoiceRecBtn" (click)="resumeRecording()">fiber_manual_record</mdl-icon>
    </div>
    <div *ngIf="!isRecording" class="voice-recording start-voice-recording">
      <button  id="recordvoicemicbtn" (click)="startRecording()" class="task-record-mic-btn mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
        <i class="material-icons">mic</i>
      </button>
    </div>
  </div>
</div>
