
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createSelector } from "@ngrx/store";
import { Task } from "../../models/task";
import { taskAdapter, _getIsLoading, _getIsLoaded, _getIsNextPageLoading, _getIsNextPageLoaded, _getCurrentOffset, _getIsMoreTasks, _getSortBy, _getSelectedFilterOption, _getIsSearchMode, _getProjectsList, _getPriorityList, _getMemberList, _getAuthUser, _getSearchStatisticsInfo, _getTaskStatisticsInfo,  _getSelectedFilterType, _getTagList, _getSelectedTasksIds, _getSelectAll, _getIsDetailView, _getTagStatisticsInfo, _getIsMultiSelectView, _getIsEditTaskView, _getIsOpenTasksChecked, _getReminderTasks, _getFolderList, _getListStatisticsInfo, _getMyTeamUsers, _getSaveSearches, _getLocationList, _getLocationStatisticsInfo, _getIsShowMyTaskChecked, _getAssignmentTasks, _getIsTaskDetailHighlight, _getIsCloseTasksChecked, _getSettings } from "../reducers/tasks.reducers";
import { TasksRootState, getTasksRootState } from "../reducers/index";
import { RootState } from "../../../reducers/index";


export const getTaskState = createSelector(
  getTasksRootState,
  state => state.task
);

export const {
  selectIds: getTaskIds,
  selectEntities: getTaskEntities,
  selectAll: getAllTask,
  selectTotal: getTotalTask, // returns total count of task
} = taskAdapter.getSelectors(getTaskState);

export const getTasks = getAllTask;

export const getTasksIsLoaded = createSelector(
  getTaskState,
  _getIsLoaded
);

export const getTasksIsLoading = createSelector(
  getTaskState,
  _getIsLoading
);

export const getIsTasksNextPageLoading = createSelector(
  getTaskState,
  _getIsNextPageLoading
);

export const getIsTasksNextPageLoaded = createSelector(
  getTaskState,
  _getIsNextPageLoaded
);

export const getTasksCurrentPageOffset = createSelector(
  getTaskState,
  _getCurrentOffset
);

export const getIsTasksIsMoreTasks = createSelector(
  getTaskState,
  _getIsMoreTasks
);

export const getSortBy = createSelector(
  getTaskState,
  _getSortBy
);

export const getSelectedFilterOption = createSelector(
  getTaskState,
  _getSelectedFilterOption
);

export const getSelectedFilterType = createSelector(
  getTaskState,
  _getSelectedFilterType
);

export const getIsSearchMode = createSelector(
  getTaskState,
  _getIsSearchMode
);

export const getSearchStatisticsInfo = createSelector(
  getTaskState,
  _getSearchStatisticsInfo
);

export const getTagStatisticsInfo = createSelector(
  getTaskState,
  _getTagStatisticsInfo
);

export const getListStatisticsInfo = createSelector(
  getTaskState,
  _getListStatisticsInfo
);

export const getLocationStatisticsInfo = createSelector(
  getTaskState,
  _getLocationStatisticsInfo
);

export const getTaskStatisticsInfo = createSelector(
  getTaskState,
  _getTaskStatisticsInfo
);

export const getProjectsList = createSelector(
  getTaskState,
  _getProjectsList
);

export const getTagList = createSelector(
  getTaskState,
  _getTagList
);

export const getFolderList = createSelector(
  getTaskState,
  _getFolderList
);

export const getLocationList = createSelector(
  getTaskState,
  _getLocationList
);

export const getPriorityList = createSelector(
  getTaskState,
  _getPriorityList
);

export const getMemberList = createSelector(
  getTaskState,
  _getMemberList
);

export const getAuthUser = createSelector(
  getTaskState,
  _getAuthUser
);

export const getSelectedTasksIds = createSelector(
  getTaskState,
  _getSelectedTasksIds
);

export const getSelectAll = createSelector(
  getTaskState,
  _getSelectAll
);

export const getIsDetailView = createSelector(
  getTaskState,
  _getIsDetailView
);

export const getIsMultiSelectView = createSelector(
  getTaskState,
  _getIsMultiSelectView
);

export const getIsEditTaskView = createSelector(
  getTaskState,
  _getIsEditTaskView
);

export const getIsOpenTasksChecked = createSelector(
  getTaskState,
  _getIsOpenTasksChecked
);

export const getIsCloseTasksChecked = createSelector(
  getTaskState,
  _getIsCloseTasksChecked
);

export const getReminderTasks = createSelector(
  getTaskState,
  _getReminderTasks
);

export const getAssignmentTasks = createSelector(
  getTaskState,
  _getAssignmentTasks
);

export const getMyTeamUsers = createSelector(
  getTaskState,
  _getMyTeamUsers
);

export const getSaveSearches = createSelector(
  getTaskState,
  _getSaveSearches
);

export const getIsShowMyTaskChecked = createSelector(
  getTaskState,
  _getIsShowMyTaskChecked
);

export const getIsTaskDetailHighlight = createSelector(
  getTaskState,
  _getIsTaskDetailHighlight
);

export const getSettings = createSelector(
  getTaskState,
  _getSettings
);

export const getTasksById = (state: RootState, id: number) => {
  return getTaskEntities(state)[id];
};
