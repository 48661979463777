
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy, HostListener, AfterViewInit } from "@angular/core";
import { MdlDialogReference, MdlSnackbarService, MdlDialogService } from "@angular-mdl/core";
import { TaskRepository } from "../../../repository/task.repository";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { Store } from "@ngrx/store";
import { TasksRootState } from "../../../store";
import { TaskAccountDeactivateConfirmationComponent } from "../task-account-deactivate-confirmation/task-account-deactivate-confirmation.component";
import { FeedbackType, getFeedbackType } from "../../../models/feedback-type";
import { VNCStandardObj } from "../../../models/vnc-standard-obj";
import { TranslateService } from "@ngx-translate/core";
import { takeWhile, take } from "rxjs/operators";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";


@Component({
  selector: "vp-task-account-deactivate-feedback",
  templateUrl: "./task-account-deactivate-feedback.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAccountDeactivateFeedbackComponent implements OnDestroy, AfterViewInit {
  isAlive: boolean = true;
  isOnline: boolean = false;
  selectedFeedbackType: FeedbackType;
  FeedbackType = FeedbackType;
  mdlFixDone = false;
  otherReason: string = "";
  feedbackReason: string = "";

  constructor(
    private changerDetectorRef: ChangeDetectorRef,
    private tasksRepo: TaskRepository,
    private dialogRef: MatDialogRef<TaskAccountDeactivateFeedbackComponent>,
    private broadcaster: Broadcaster,
    private store: Store<TasksRootState>,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private mdlSnackbarService: MdlSnackbarService
  ) {
    this.selectedFeedbackType = FeedbackType.NO_MORE;
    this.changerDetectorRef.markForCheck();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskAccountDeactivateFeedback").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  ngAfterViewInit(): void {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changerDetectorRef.detectChanges();
      } , 100);
    }
  }

  @HostListener("document:keydown.esc", ["$event"])
  onESC() {
    this.close(false);
  }

  close(value?) {
    this.dialogRef.close(value);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  onFeedbackSubmit() {
    if ( this.selectedFeedbackType === FeedbackType.OTHER) {
      this.feedbackReason = this.otherReason;
    } else {
      this.translate.get(getFeedbackType(this.selectedFeedbackType).translation_key).pipe(take(1)).subscribe( res => {
        this.feedbackReason = res;
      });
    }
    localStorage.setItem("deactivate_feedback", this.feedbackReason);
    const sDialog = this.matDialog.open(TaskAccountDeactivateConfirmationComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-deactivate-confirmation-dialog",
    });
    sDialog.afterClosed().pipe(take(1)).subscribe($event => {
      if ($event) {
        this.close($event);
      }
    });
  }

  getFeedbackType(reason: FeedbackType): VNCStandardObj {
    return getFeedbackType(reason);
  }
}
