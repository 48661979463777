
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ElementRef, ViewChild, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit } from "@angular/core";
import { Task, AuthUser, AddFieldsType, BulkUpdateArgs, List } from "../../models";
import { TaskService } from "../../task.service";
import { MdlSnackbarService, MdlTextFieldComponent, MdlDialogService } from "@angular-mdl/core";
import { TranslateService } from "@ngx-translate/core";
import { DateFilterType, SuccessType, ErrorType } from "../../shared/task-enum";
import { TaskUtils } from "../../shared/task-utils";
import { TasksConstants } from "../../shared/task-constacts";
import { User } from "../../models/user";
import { Priority } from "../../models/priority";
import { Project } from "../../models/project";
import { Tag } from "../../models/tag";
import { Location } from "../../models/location";
import { Store } from "@ngrx/store";
import { TasksRootState, getProjectsList, getPriorityList, getMemberList, getSelectedFilterOption, SetIsLoading, getSelectedFilterType, SetDetailView, SetEditTaskView, SetSelectAll, ResetSelectedTaskId, UpdateTaskSuccess, SetTaskDetailHighlight } from "../../store/index";
import { TaskRepository } from "../../repository/task.repository";
import { FilesStorageService } from "../../services/files-storage.service";
import { SuccessService } from "../../../common/providers/success-service";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { ConfigService } from "../../../common/providers/config.service";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { TaskToolbarComponent } from "../task-toolbar/task-toolbar.component";
import { Subject, Observable } from "rxjs";
import { TaskAddAttachmentDialogComponent } from "../task-add-attachment-dialog/task-add-attachment-dialog.component";
import { FileUpload } from "../../models/file-upload";
import { TaskAddReminderDialogComponent } from "../task-add-reminder-dialog/task-add-reminder-dialog.component";
import { getAuthUser, getIsEditTaskView, getFolderList, getLocationList, getIsTaskDetailHighlight, getTasksById } from "../../store/selectors/tasks.selectors";
import { ErrorService } from "../../../common/providers/error-service";
import { TaskAddFieldDialogComponent } from "../task-add-field-dialog/task-add-field-dialog.component";
import { environment } from "../../../../environments/environment";
import { UntypedFormControl } from "@angular/forms";
import { TaskDatePickerComponent } from "../task-date-picker/task-date-picker.component";
import { BulkUpdateIssueType } from "../../shared/task-enum";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { dialogType } from "../../models/dialog-type";
import { CommonUtil, MediaType } from "../../../common/utils/common.utils";
import { TaskAttachment } from "../../models/task-attachment";
import { MediaPreviewDialogComponent, MEDIA_ATTACHMENT, MEDIA_TYPE, MEDIA_CONTENT } from "../media-preview/media-preview.component";
import { ActivatedRoute, Router } from "@angular/router";
import { MdlPopoverComponent } from "@angular-mdl/popover";
import { getOnlineStatus } from "../../../reducers";
import sort from "fast-sort";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { TimelogHistoryComponent } from "../timelog-history/timelog-history-dialog.component";
import { LogTimeComponent } from "../log-time/log-time-dialog.component";
import { TaskWatchersComponent } from "../task-watchers-dialog/task-watchers-dialog.component";
import { LocaleDatePipe } from "../pipes/locale-date.pipe";
import { LocaleService } from "../../../common/providers/locale.service";
import { takeWhile, finalize, debounceTime, startWith, map, take, takeUntil } from "rxjs/operators";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { DescriptionDiffDialogComponent } from "../description-diff/description-diff.component";
import { taskAdapter } from "../../store/reducers/tasks.reducers";
import { Comment } from "@angular/compiler";
import { ResponsiveService } from "src/app/common/providers/responsive.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-details",
  templateUrl: "task-details.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDetailsComponent implements OnInit, OnDestroy {
  task: Task;
  isEditMode: boolean = false;
  taskCpy: Task;
  @Output() onUpdate = new EventEmitter();
  @ViewChild("editTaskSubject", { static: false }) editTaskSubject: ElementRef;
  @ViewChild("commentInput", { static: false }) commentInput: ElementRef;
  @ViewChild("tagInput", { static: false }) tagInput: ElementRef;
  @ViewChild("startDateInput", { static: false }) startDateInput: ElementRef;
  @ViewChild("dueDateInput", { static: false }) dueDateInput: ElementRef;
  public bulkUpdateIssueType = BulkUpdateIssueType;
  debouncer: Subject<any> = new Subject();
  commentText: string;
  tagText: string;
  subject: string = "";
  applying: boolean = false;
  required_statuses = [];
  repeatItems: any[] = [];
  startDateItems: any[] = [];
  dueDateItems: any[] = [];
  commentLines = 1;
  memberList: User[] = [];
  priorityList: Priority[] = [];
  projectList: Project[] = [];
  folderList: List[] = [];
  locationList: Location[] = [];
  isAlive = true;
  msgs: any;
  searchTagsList: any[] = [];
  @ViewChild("taskToolbar", { static: false }) taskToolbar: TaskToolbarComponent;
  fileUploads: FileUpload[] = [];
  authUser: AuthUser;
  reminderDateTime: {
    isDueDate: boolean;
    dateTime: Date;
  };
  propertyListExpanded: boolean = true;
  effortsAndProgress: boolean = true;
  commentListExpanded: boolean = true;
  isDescEditMode: boolean = false;
  selectedDevice: any;
  assigneeCtrl: UntypedFormControl = new UntypedFormControl();
  filteredUsers: Observable<User[]>;
  projectCtrl: UntypedFormControl = new UntypedFormControl();
  private _onDestroy = new Subject<void>();
  filteredProjects: Observable<Project[]>;
  listCtrl: UntypedFormControl = new UntypedFormControl();
  filteredLists: Observable<List[]>;
  locationCtrl: UntypedFormControl = new UntypedFormControl();
  filteredLocations: Observable<Location[]>;
  selectedPriorityOption: any;
  selectedStatusOption: any;
  selectedDuedateOption: any;
  selectedStartdateOption: any;
  selectedRepeatOption: any;
  selectedDoneOption: any;
  rangeStart = new Date();
  private datePipe: LocaleDatePipe = new LocaleDatePipe(this.localeService);
  repeats = new UntypedFormControl();
  dueDatefc = new UntypedFormControl();
  startDatefc = new UntypedFormControl();
  isShowMore: boolean = false;
  dialogType = dialogType;
  isToolbarOpen: boolean = false;
  CommonUtil = CommonUtil;
  private isAlive$ = new Subject();
  isLongPressed = false;
  MediaType = MediaType;
  isMobileDevice = CommonUtil.isOnMobileDevice();
  showMoreTags: boolean = false;
  isFixedHeader: boolean = false;
  isAddTagCheck: boolean = false;
  @ViewChild("taskDetailHeaderPopover", { static: false }) contextPopover: MdlPopoverComponent;
  isOnline: boolean = false;
  estimatedHours: any;
  estimatedMinutes: any;
  spentHours: any;
  spentMinutes: any;
  done_percentages: any[] = TaskUtils.getDoneRatio();
  showExternalUrl: boolean = false;
  externalURLForm: UntypedFormGroup;
  selectedWatcherList: User[] = [];
  descLines: number = 0;
  descExpanded: boolean = false;
  htmlDescription: any;
  @ViewChild("assigneeTrigger", { read: MatAutocompleteTrigger, static: false}) assigneeTrigger: MatAutocompleteTrigger;
  @ViewChild("projectTrigger", { read: MatAutocompleteTrigger, static: false}) projectTrigger: MatAutocompleteTrigger;
  @ViewChild("listTrigger", { read: MatAutocompleteTrigger, static: false}) listTrigger: MatAutocompleteTrigger;
  @ViewChild("locationTrigger", { read: MatAutocompleteTrigger, static: false}) locationTrigger: MatAutocompleteTrigger;
  userMentionList: any [] = [];
  taskHighlightFields: Task;
  isHighLighTaskDetails: boolean = false;
  screen: string = this.responsiveService.getScreen();
  descriptionHTML: any = "";

  constructor(
    private taskService: TaskService,
    private mdlSnackbarService: MdlSnackbarService,
    private translate: TranslateService,
    private store: Store<TasksRootState>,
    private taskRepo: TaskRepository,
    private successService: SuccessService,
    private broadcaster: Broadcaster,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef,
    private errorService: ErrorService,
    private dialogService: MdlDialogService,
    private router: Router,
    private activated: ActivatedRoute,
    private filesStorageService: FilesStorageService,
    private fb: UntypedFormBuilder,
    private localeService: LocaleService,
    private responsiveService: ResponsiveService,
    private configService: ConfigService,
    private matDialog: MatDialog
  ) {
    this.setupStore();

    this.activated.params.pipe(takeWhile(() => this.isAlive)).subscribe(params => {
      console.log("[task-details] activated.params", params);

      this.activated.queryParams.subscribe(queryParams => {
        console.log("[task-details] activated.queryParams", queryParams);

        if (params.id) {

          // if opened from notification and this is a task update notification
          let taskUpdatedOn;
          if (queryParams.openedFromNotification) {
            taskUpdatedOn = new Date(queryParams.taskUpdatedOn); // "2019-04-03T13:11:26.789Z
          }

          this.getTaskDetails(params.id, taskUpdatedOn);
        }

        if (queryParams.show_desc_diff) {
          this.configService.hideKeyboard();
          setTimeout(() => {
            this.showDiscriptionDiff();
          }, 2000);
        }
      });
    });

    this.messageTranslatorService.translatedMessagesd$.subscribe(res => {
      this.msgs = res;
      this.startDateItems = null;
      this.dueDateItems = null;
      this.startDateItems = this.getDateFilterItems(DateFilterType.StartDate);
      this.dueDateItems = this.getDateFilterItems(DateFilterType.DueDate);
      this.repeatItems = this.taskRepo.getRepeatItems();
      setTimeout(() => {
        this.required_statuses = this.taskRepo.getStatusItems();
      }, 2500);
      this.changerDetectorRef.markForCheck();
    });

    this.rangeStart.setDate(this.rangeStart.getDate() - 1);
    this.handleUpdateTaskSuccessMessages();
    this.handleUpdateTaskErrorMessages();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        if (!this.isEditMode) {
          this.backFromDetail();
        } else {
          this.backToDetail();
        }
      });

    this.broadcaster.on<any>("hideTaskDetails").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        if (!this.isEditMode) {
          this.backFromDetail();
        } else {
          this.backToDetail();
        }
      });
  }

  getTaskDetails(id: number, taskUpdatedOn: Date = null) {
    console.log("[task-details][getTaskDetails]", id, taskUpdatedOn);

    this.store.dispatch(new SetIsLoading(true));

    this.taskRepo.getTaskDetails(id, taskUpdatedOn).pipe(finalize( () => {
      this.store.dispatch(new SetIsLoading(false));
    })).subscribe(res => {
        if (res) {
          if (res && res.project) {
            this.taskRepo.getMemberList(res.project.id);
          }
          this.task = res;
          if (this.task.comments) {
            if (this.isHighLighTaskDetails) {
              this.generateHighlightFields(this.task);
            }
            this.task.comments = this.task.comments.filter(comment => comment.notes);
          }
          this.poppulateTaskDetails();
          this.store.dispatch(new SetDetailView(true));
        } else {
          this.backFromDetail();
        }
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        this.backFromDetail();
      });
  }

  generateHighlightFields(task) {
    if (task.comments && task.comments.length > 0) {
      let journal = task.comments.filter( comment => comment.created_on >= task.updated_on);
      if (journal && journal.length > 0) {
        this.taskHighlightFields = new Task({});
        journal = journal[0];
        if (journal.notes !== "") {
          this.taskHighlightFields.comments = [journal];
        }

        if (journal.details && journal.details.length > 0) {
          journal.details.forEach( detail => {
            if (detail.property === "attr") {
              switch (detail.name) {
                case "subject":
                  this.taskHighlightFields.subject = detail.new_value;
                  break;
                case "description":
                  this.taskHighlightFields.description = detail.new_value;
                  break;
                case "priority_id":
                  this.taskHighlightFields.priority = { id: parseInt(detail.new_value), name: ""};
                  break;
                case "assigned_to_id":
                  this.taskHighlightFields.assigned_to = { id: parseInt(detail.new_value), name: ""};
                  break;
                case "project_id":
                  this.taskHighlightFields.project = { id: parseInt(detail.new_value), name: ""};
                  break;
                case "start_date":
                  this.taskHighlightFields.start_date = detail.new_value ? new Date(detail.new_value) : null;
                  break;
                case "due_date":
                  this.taskHighlightFields.due_date = detail.new_value ? new Date(detail.new_value) : null;
                  break;
                case "status_id":
                  this.taskHighlightFields.status = { id: parseInt(detail.new_value), name: ""};
                  break;
                case "external_url":
                  this.taskHighlightFields.external_url = detail.new_value;
                  break;
                case "done_ratio":
                  this.taskHighlightFields.done_ratio = detail.new_value;
                  break;
                case "remind_on":
                  this.taskHighlightFields.remind_on = detail.new_value ? new Date(detail.new_value) : null;
                  break;
                case "estimated_hours":
                  this.taskHighlightFields.estimated_hours = detail.new_value;
                  break;
              }
            } else if (detail.property === "attachment") {
              if (!this.taskHighlightFields.attachments) {
                this.taskHighlightFields.attachments = [];
              }
              this.taskHighlightFields.attachments.push(new TaskAttachment( { id: parseInt(detail.name), filename: detail.new_value}));
            } else if (detail.property === "watcher") {
              if (!this.taskHighlightFields.watchers) {
                this.taskHighlightFields.watchers = [];
              }
              this.taskHighlightFields.watchers.push(new User( { id: parseInt(detail.new_value)}));
            }
          });
        }
        this.changerDetectorRef.markForCheck();
      }
    }
  }

  poppulateTaskDetails() {
    this.setDescription();
    this.setCommentBox();
    this.tagText = "";
    this.searchTagsList = [];

    this.taskRepo.poppulateAssigneeAvatar([this.task]).subscribe((success) => {
      if (success) {
        if (this.task.comments && this.task.comments.length > 0) {
          this.taskRepo.poppulateCommentersAvatar(this.task).subscribe((success) => {
            if (success) {
              this.broadcaster.broadcast("sortCommentList");
              this.changerDetectorRef.markForCheck();
            }
          });
        } else {
          this.changerDetectorRef.markForCheck();
        }
        if (this.task.watchers && this.task.watchers.length > 0) {
          // TODO: to populate only current user comments avatar (not all)
          this.taskRepo.poppulateWatchersAvatar(this.task).subscribe((success) => {
            if (success) {
              this.changerDetectorRef.markForCheck();
            }
          });
        }
      }
    });

    this.updateComments();

    this.externalURLForm = this.fb.group({
      "external_url": ["", Validators.compose([])]
    });
    this.showExternalUrl = false;

    this.changerDetectorRef.markForCheck();
  }

  updateComments() {
    setTimeout(() => {
      if (this.task.comments && this.task.comments.length > 0 ) {
        this.task.comments.forEach( comment => {
          comment.notes = comment.notes.replace(/<br>/g, "\n").replace(/<[^>]*>/g, "");
          comment.notes = comment.notes.replace(/(?:\r\n|\r|\n)/g, "<br>");
          comment.notes = this.taskRepo.renderMentionUsers(comment.notes, this.memberList);
          comment.notes = CommonUtil.linkify(comment.notes);
        });
        this.changerDetectorRef.markForCheck();
      }
    }, 500);
  }

  setDescription() {
    let description = "";
    this.htmlDescription = "";
    if (this.task && this.task.description) {
      this.descExpanded = false;
      description = this.task.description;
      let strArray = description.split("\n");
      this.descLines = 0;
      for (let i = 0; i < strArray.length; i++) {
        strArray[i] = strArray[i].trim().replace(/\}\s/g, "\}");
        if (this.isMobileDevice) {
          this.descLines += Math.ceil(strArray[i].length / 40);
        } else {
          this.descLines += Math.ceil(strArray[i].length / 55);
        }
      }
      description = strArray.join("\n");
      description = CommonUtil.escapeColonURLs(description);
      description = textile(description);
      description.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      description = CommonUtil.linkify(description);
      this.htmlDescription = description;
    }
    if (description) {
      this.descriptionHTML = description;
      this.changerDetectorRef.markForCheck();
    }
  }

  setCommentBox() {
    if (this.commentText && this.commentText.length > 0) {
      this.commentText = "";
      this.resetTextArea();
      let element = document.querySelectorAll(".comment-input")[0];
      let bodyEle = document.querySelectorAll(".detail-body")[0];
      bodyEle.classList.add("task-detail-line-" + this.commentLines);
      element.classList.add("comment-line-" + this.commentLines);
    }
  }

  setHeader(event) {
    if (event && event.target && this.isMobileDevice && !this.isEditMode) {
      let header: any = document.querySelector(".header");
      let body: any = document.querySelector(".detail-body");

      console.log("[task-details] setHeader", event.target.scrollTop, event.target.offsetHeight, event.target.scrollHeight, header.offsetHeight);

      // show header
      if (Math.ceil(event.target.scrollTop) > (header.offsetHeight - 50) ) {
        if (Math.ceil(event.target.scrollTop) + event.target.offsetHeight <= event.target.scrollHeight) {
          let top = event.target.scrollTop;
          this.isFixedHeader = true;
          header.classList.add("fixed-header");
          body.style.paddingTop = header.offsetHeight + "px";
          event.target.scrollTop = top;
          this.changerDetectorRef.markForCheck();
        }

      // hide header
      } else {
        this.isFixedHeader = false;
        header.classList.remove("fixed-header");
        body.style.paddingTop = "0px";
        if (this.contextPopover.isVisible){
          this.contextPopover.hide();
        }
        this.changerDetectorRef.markForCheck();
      }
    }
  }

  resetHeader() {
    this.isFixedHeader = false;
    let header: any = document.querySelector(".header");
    let body: any = document.querySelector(".detail-body");
    header.classList.remove("fixed-header");
    body.style.paddingTop = "0px";
    if (this.contextPopover.isVisible){
      this.contextPopover.hide();
    }
  }

  closeOverlay() {
    if (this.isEditMode) {
      if (this.assigneeTrigger && this.assigneeTrigger.panelOpen) {
        this.assigneeTrigger.closePanel();
        this.changerDetectorRef.markForCheck();
      }
      if (this.projectTrigger && this.projectTrigger.panelOpen) {
        this.projectTrigger.closePanel();
        this.changerDetectorRef.markForCheck();
      }
      if (this.listTrigger && this.listTrigger.panelOpen) {
        this.listTrigger.closePanel();
        this.changerDetectorRef.markForCheck();
      }
      if (this.locationTrigger && this.locationTrigger.panelOpen) {
        this.locationTrigger.closePanel();
        this.changerDetectorRef.markForCheck();
      }
    }
  }

  showDiscriptionDiff() {
    let journal_id = localStorage.getItem("desc_journal_id");
    let detail_id = localStorage.getItem("desc_detail_id");
    if (journal_id && detail_id) {
      const sDialog = this.matDialog.open(DescriptionDiffDialogComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "vp-save-search-dialog",
        data: { journal_id: journal_id, detail_id: detail_id }
      });
      sDialog.afterClosed().pipe(take(1)).subscribe($event => {
        this.removeShowDescriptionDIff();
        this.router.navigate(["./"], { relativeTo: this.activated, queryParams: {} });
      });
    }
  }

  private removeShowDescriptionDIff() {
    localStorage.removeItem("desc_journal_id");
    localStorage.removeItem("desc_detail_id");
  }

  public getDateFilterItems(type: DateFilterType): any {
    let msg_no_date;
    switch (type) {
      case DateFilterType.StartDate:
        msg_no_date = this.msgs.NO_STARTDATE;
        break;
      case DateFilterType.DueDate:
        msg_no_date = this.msgs.NO_DUEDATE;
        break;
    }
    return [
      {
        date: TaskUtils.getDateAfterOffset(0),
        name: this.msgs.TODAY_DUE
      },
      {
        date: TaskUtils.getDateAfterOffset(1),
        name: this.msgs.TOMORROW_DUE
      },
      {
        date: TaskUtils.getDateAfterOffset(2),
        name: this.msgs[TaskUtils.getWeekDay(TaskUtils.getDateAfterOffset(2).getDay())]
      },
      {
        date: TaskUtils.getDateAfterOffset(3),
        name: this.msgs[TaskUtils.getWeekDay(TaskUtils.getDateAfterOffset(3).getDay())]
      },
      {
        date: null,
        name: msg_no_date
      }
    ];
  }

  ngOnInit() {
    this.debouncer.pipe(debounceTime(500)).subscribe(value => {
      if ( !this.tagText || ( this.tagText && this.tagText.trim() === "")) {
        this.searchTagsList = [];
        this.changerDetectorRef.markForCheck();
        return false;
      }

      const searchtext = this.tagText.substr(this.tagText.lastIndexOf(",") + 1);
      if (this.isOnline || CommonUtil.isSQLSupported()) {
        this.taskRepo.getSearchTags(searchtext).subscribe(tags => {
          console.log("[task-details] getSearchTags", tags);
          this.searchTagsList = tags;
          this.changerDetectorRef.markForCheck();
        });
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    });
    this.required_statuses = this.taskRepo.getStatusItems();
    this.changerDetectorRef.markForCheck();
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsTaskDetailHighlight).pipe(takeWhile(() => this.isAlive)).subscribe((isHighLighTaskDetails) => {
      this.isHighLighTaskDetails = isHighLighTaskDetails;
      if (!this.isHighLighTaskDetails) {
        this.taskHighlightFields = null;
        this.changerDetectorRef.markForCheck();
      }
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe(projects => {
      if (projects) {
        this.projectList = projects;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getPriorityList).pipe(takeWhile(() => this.isAlive)).subscribe(priorities => {
      if (priorities) {
        this.priorityList = priorities;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getFolderList).pipe(takeWhile(() => this.isAlive)).subscribe(lists => {
      if (lists) {
        this.folderList = lists;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getLocationList).pipe(takeWhile(() => this.isAlive)).subscribe(locations => {
      if (locations) {
        this.locationList = locations;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getIsEditTaskView).pipe(takeWhile(() => this.isAlive)).subscribe(isEditMode => {
      this.isEditMode = isEditMode;
      if (this.task && !this.isEditMode) {
        this.setDescription();
      }
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("profileAvatarUpdated").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.updateUserAvatarForTask();
      });

    this.broadcaster.on<any>("statusUpdated").pipe(takeWhile(() => this.isAlive))
      .subscribe(status => {
        this.task.status = status;
        if (this.task.status.id === TaskUtils.statusCompletedId()) {
          this.backFromDetail();
        }
        this.changerDetectorRef.markForCheck();
      });

    this.broadcaster.on<any>("replaceTaskId").pipe(takeWhile(() => this.isAlive))
      .subscribe(data => {
        const oldTaskId = data["old_id"];
        const newTaskId = data["new_id"];

        console.log("[task-details] on replaceTaskId", oldTaskId, newTaskId);

        if (this.task.id === oldTaskId) {
          this.task.id = newTaskId;
          if (this.taskCpy) {
            this.taskCpy.id = newTaskId;
          }
          console.log("[task-details] on replaceTaskId: updated task id");
        }
      });

    this.store.select(getMemberList).pipe(takeWhile(() => this.isAlive)).subscribe(members => {
      if (members && members.length > 0) {
        this.memberList = members;
        this.userMentionList = [];
        this.memberList.forEach( member => {
          this.userMentionList.push({ "label": member.name, "value": member.username});
        });
        if (this.isEditMode) {
          this.generateAssigneeList();
        }
        this.changerDetectorRef.markForCheck();
      }
    });

    this.broadcaster.on<any>("sortCommentList").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      if (this.task.comments) {
        if (this.task.comments.length > 0) {
          sort(this.task.comments).desc( comment => comment.created_on);
        }
      }
    });

    this.responsiveService.screen$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(screen => {
        this.screen = screen;
        this.isMobileDevice = CommonUtil.isOnMobileDevice();
        this.changerDetectorRef.markForCheck();
    });

    this.filteredProjects = this.projectCtrl.valueChanges
      .pipe(startWith<string | Project>(""),
            map(value => typeof value === "string" ? value : value.name),
            map(name => name ? this._filterProjects(name) : this.projectList.slice()));

    this.filteredLists = this.listCtrl.valueChanges
      .pipe(startWith<string | List>(""),
            map(value => typeof value === "string" ? value : value.name),
            map(name => name ? this._filterLists(name) : this.folderList.slice()));

    this.filteredLocations = this.locationCtrl.valueChanges
      .pipe(startWith<string | Location>(""),
            map(value => typeof value === "string" ? value : value.name),
            map(name => name ? this._filterLocations(name) : this.locationList.slice()));

    this.broadcaster.on<any>("updateTaskDetail").pipe(takeWhile(() => this.isAlive)).subscribe(taskId => {
      if (taskId && taskId === this.task.id) {
        this.store.select(state => getTasksById(state, taskId)).pipe(take(1)).subscribe( newTask => {
          this.task = newTask;
          this.changerDetectorRef.markForCheck();
        });
      }
    });
  }

  displayFn(data?: any): string | undefined {
    return data ? data.name : undefined;
  }

  private _filterProjects(name: string): Project[] {
    const filterValue = name.toLowerCase();

    return this.projectList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterUsers(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.memberList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0 || option.mail.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterLists(name: string): List[] {
    const filterValue = name.toLowerCase();

    return this.folderList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterLocations(name: string): Location[] {
    const filterValue = name.toLowerCase();

    return this.locationList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  generateAssigneeList() {
    this.filteredUsers = this.assigneeCtrl.valueChanges
      .pipe(startWith<string | User>(""),
            map(value => typeof value === "string" ? value : value.name),
            map(name => name ? this._filterUsers(name) : this.memberList.slice()));

    if (this.taskCpy.assigned_to) {
      let user = this.memberList.find( user => user.id === this.taskCpy.assigned_to.id);
      if (user) {
        this.assigneeCtrl.setValue(user);
      } else {
        user = this.memberList.find( user => user.id === this.authUser.id);
        (user) ? this.assigneeCtrl.setValue(user) : this.assigneeCtrl.setValue("");
      }
    } else {
      this.assigneeCtrl.setValue("");
    }
    this.changerDetectorRef.markForCheck();
  }

  generateProjectList() {
    let project = this.projectList.find( project => project.id === this.taskCpy.project.id);
    this.projectCtrl.setValue(project);
    this.changerDetectorRef.markForCheck();
  }

  generateFolderList() {
    if (this.taskCpy.list) {
      let list = this.folderList.find( list => list.id === this.taskCpy.list.id);
      this.listCtrl.setValue(list);
    } else {
      this.listCtrl.setValue("");
    }
    this.changerDetectorRef.markForCheck();
  }

  generateLocationList() {
    if (this.taskCpy.location) {
      let location = this.locationList.find( location => location.id === this.taskCpy.location.id);
      this.locationCtrl.setValue(location);
    } else {
      this.locationCtrl.setValue("");
    }
    this.changerDetectorRef.markForCheck();
  }

  handleUpdateTaskSuccessMessages() {
    this.successService.only(SuccessType.TaskUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        success.messages
      );
      this.applying = false;
      this.fileUploads = [];
      let isRemoved = false;
      let routingOption;
      let routingType;
      if (!this.taskCpy.assigned_to) {
        this.taskCpy.userAvatar = null;
      }


      this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
      this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);

      if (( this.authUser && this.authUser.id !== this.taskCpy.author.id ) && ( this.taskCpy.assigned_to && this.authUser.id !== this.taskCpy.assigned_to.id) && (routingOption !== TasksConstants.ROUTE_TASKS_I_WATCH)) {
        isRemoved = true;
      } else if (this.taskCpy.status.id === TaskUtils.statusCompletedId()) {
        isRemoved = true;
      } else {
        if (routingType === TasksConstants.ROUTE_TYPE_STATUS) {
          switch (routingOption) {
            case TasksConstants.ROUTE_COMPLETED:
              if (this.taskCpy.status.id !== TaskUtils.statusCompletedId()) {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_SEARCH:
              const searchParams = this.taskRepo.getSearchQuery();
              if (searchParams["v[subject][]"] && this.taskCpy.subject.toLowerCase().indexOf(searchParams["v[subject][]"][0].toLowerCase()) === -1) {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_OPEN:
              if (this.taskCpy.status.id === TaskUtils.statusCompletedId()) {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_NEW:
              if (this.taskCpy.status.id !== TaskUtils.statusNewId()) {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_ASSIGNEDTOME:
              if (this.task.assigned_to.id !== this.taskCpy.assigned_to.id) {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_TODAY_DUE:
              if (this.taskCpy.due_date) {
                let taskDate = this.taskCpy.due_date;
                taskDate.setHours(0, 0, 0, 0);
                if (taskDate.getTime() !== TaskUtils.getCurrentDate().getTime()) {
                  isRemoved = true;
                }
              } else {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_TOMORROW_DUE:
              if (this.taskCpy.due_date) {
                let taskDate = this.taskCpy.due_date;
                taskDate.setHours(0, 0, 0, 0);
                if (taskDate.getTime() !== TaskUtils.getTomorrowDate().getTime()) {
                  isRemoved = true;
                }
              } else {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_THIS_WEEK_DUE:
              if (this.taskCpy.due_date) {
                let taskDate = this.taskCpy.due_date;
                taskDate.setHours(0, 0, 0, 0);
                let weekDate = TaskUtils.getstartAndEndOfWeek();
                if (taskDate.getTime() >= weekDate[0].getTime() && taskDate.getTime() <= weekDate[1].getTime()) {
                  isRemoved = false;
                } else {
                  isRemoved = true;
                }
              } else {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_TASKS_I_WATCH:
              if (this.taskCpy.watchers) {
                let watcher = this.taskCpy.watchers.find( watcher => watcher.id === this.authUser.id );
                if (!watcher) {
                  isRemoved = true;
                }
              } else {
                isRemoved = true;
              }
              break;
            case TasksConstants.ROUTE_MY_OVERDUE_TASKS:
              if (this.taskCpy.due_date) {
                let taskDate = this.taskCpy.due_date;
                taskDate.setHours(0, 0, 0, 0);
                if ((taskDate.getTime() >= TaskUtils.getCurrentDate().getTime()) && (this.taskCpy.status.id === TaskUtils.statusCompletedId())) {
                  isRemoved = true;
                }
              } else {
                isRemoved = true;
              }
              break;
            default:
              isRemoved = false;
              break;
          }

        } else if (routingType === TasksConstants.ROUTE_TYPE_TAG) {
            if (this.taskCpy.tags) {
              let tag = this.taskCpy.tags.find( tag => tag.name === routingOption);
              if (!tag) {
                isRemoved = true;
              }
            } else {
              isRemoved = true;
            }
        } else if (routingType === TasksConstants.ROUTE_TYPE_LIST) {
            if (this.taskCpy.list) {
              if ( this.taskCpy.list.name !== routingOption ) {
                isRemoved = true;
              }
            } else {
              isRemoved = true;
            }
        } else if (routingType === TasksConstants.ROUTE_TYPE_LOCATION) {
          if (this.taskCpy.location) {
            if ( this.taskCpy.location.name !== routingOption ) {
              isRemoved = true;
            }
          } else {
            isRemoved = true;
          }
        }
      }

      this.store.dispatch(new SetEditTaskView(false));

      this.taskRepo.getTasksStats();
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      });

      if (isRemoved) {
        if (routingType === TasksConstants.ROUTE_TYPE_TAG) {
          this.taskRepo.getTagsListWithCounters();
        }
        if (routingType === TasksConstants.ROUTE_TYPE_LIST) {
          this.taskRepo.getFolderListWithCounters();
        }
        if (routingType === TasksConstants.ROUTE_TYPE_LOCATION) {
          this.taskRepo.getLocationsWithCounters();
        }
        this.backFromDetail();
      } else {
        this.task = this.taskCpy;
        this.taskCpy = null;
        this.getTaskDetails(this.task.id);
        setTimeout(() => {
          this.changerDetectorRef.markForCheck();
        });

      }
    });
  }

  handleUpdateTaskErrorMessages() {
    this.errorService.only(ErrorType.TaskUpdateFail).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.applying = false;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      });

      if ( error.messages && error.messages.includes(TasksConstants.FOUND_UPDATE_TASK_CONFLICT)) {
        this.backToDetail();
        this.taskRepo.getTaskDetails(this.task.id, new Date()).subscribe(res => {
            if (res) {
              this.task = res;
              this.task.isChecked = true;
              this.store.dispatch(new UpdateTaskSuccess({ task: { id: this.task.id, changes: this.task } }));
              this.poppulateTaskDetails();
              this.store.dispatch(new SetDetailView(true));
            } else {
              this.backFromDetail();
            }
          }, err => {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
            this.backFromDetail();
          });
      }

      if ( error.messages && error.messages.includes(this.msgs.TASK_HAS_BEEN_DELETED)) {
        this.backFromDetail();
      }
    });
  }

  setAssigneeValue(data: any) {
    console.log("TaskDetailsComponent setAssigneeValue", data, this.authUser.team_user, this.authUser.can_invite_users);

    if (data) {
      if (typeof data === "object" ) {
        this.taskCpy.assigned_to = {
          id: data.id,
          name: data.name
        };
        this.taskCpy.invitee_email = null;
      } else if (this.authUser.team_user === "false") {
        if (this.taskCpy.author.id === this.authUser.id ) {
          let mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (data.match(mailformat)) {
            if (this.authUser.can_invite_users === "true") {
              this.taskCpy.invitee_email = data;
              this.taskCpy.assigned_to = null;
              this.taskCpy.userAvatar = null;
            } else {
              this.mdlSnackbarService.showToast(
                this.msgs.PERMISSION_DECLINED
              );
              return false;
            }
          } else {
            this.mdlSnackbarService.showToast(
              this.msgs.ENTER_VALID_EMAIL_ADDRESS
            );
            return false;
          }
        } else {
          this.mdlSnackbarService.showToast(
            this.msgs.ONLY_AUTHOR_CAN_INVITE
          );
          return false;
        }
      }
    } else {
      this.taskCpy.assigned_to = null;
    }
    return true;
  }

  setProjectValue(data: any) {
    if (data) {
      if (typeof data === "object" ) {
        this.taskCpy.project = {
          id: data.id,
          name: data.name
        };
      }
    }
  }

  setListValue(data: any) {
    if (data) {
      if (typeof data === "object" ) {
        this.taskCpy.list = {
          id: data.id,
          name: data.name
        };
      }
    } else {
      this.taskCpy.list = null;
    }
  }

  setLocationValue(data: any) {
    if (data) {
      if (typeof data === "object" ) {
        this.taskCpy.location = {
          id: data.id,
          name: data.name
        };
      }
    } else {
      this.taskCpy.location = null;
    }
  }

  onProjectSelect(event: any) {
    if (!event) return;
    this.taskRepo.getMemberList(event.option.value.id);
  }

  onStatusSelect(status) {
    if ((this.task.status.id === TaskUtils.statusCompletedId() || this.task.status.id === TaskUtils.statusInProgressId()) && status.value === TaskUtils.statusNewId()) {
      this.mdlSnackbarService.showToast(
        this.msgs.STATUS_SELECTION_ERROR
      );
      setTimeout(() => {
        this.selectedStatusOption = this.taskCpy.status.id;
        this.changerDetectorRef.markForCheck();
      }, 100);
      return false;
    }
    if (status) {
      this.taskCpy.status = this.required_statuses.filter(statusItem => statusItem.id === status.value)[0];
    }
  }

  onPrioritySelect(event) {
    this.priorityList.forEach(priority => {
      if (priority.id === event.value) {
        this.taskCpy.priority = priority;
      }
    });
    this.setPriorityLabelColor();
  }

  setPriorityLabelColor() {
    let priorityColor = (<HTMLElement>document.querySelector(".mat-select-value-text"));
    priorityColor.style.color = this.getPriorityColor(this.taskCpy.priority);
  }

  onRepeatsSelect(repeat) {
    if (repeat) {
      this.taskCpy.repeat = repeat.value;
    }
  }


  onDoneSelect(ratio) {
    if (ratio) {
      this.taskCpy.done_ratio = ratio.value;
    }
  }

  removeTag(tag) {
    this.taskCpy.tags = this.taskCpy.tags.filter(item => item !== tag);
  }

  addTag(event) {
    this.tagText = this.tagInput.nativeElement.value;
    this.searchTagsList = [];

    if (this.tagText.trim().length >= 2) {
      let separatedTagsValues = this.tagText.split(",");
      let isDuplicate = false;
      let failInlength = false;
      let failInSpace = false;
      let failedTags = [];

      separatedTagsValues.forEach(tagName => {
        // validate length
        if (tagName.length < 2 || tagName.length > 30) {
          failedTags.push(tagName);
          failInlength = true;
        } else if (tagName.trim().indexOf(" ") >= 0 ) {
          failedTags.push(tagName);
          failInSpace = true;
        } else {
          let isTagAlreadyExist = false;
          this.taskCpy.tags.forEach(t => {
            if (t.name.toLowerCase() === tagName.trim().toLowerCase()) {
              failedTags.push(tagName.trim());
              isDuplicate = true;
              isTagAlreadyExist = true;
            }
          });

          if (!isTagAlreadyExist) {
            this.taskCpy.tags.push({"id": Tag.tempId(), "name": tagName.trim()});
          }
        }
      });

      // validate
      if (failInlength) {
        this.mdlSnackbarService.showToast(
          this.msgs.TAG_MIN_LENGTH
        );
        this.tagInput.nativeElement.value = failedTags.toString();
        this.tagText = this.tagInput.nativeElement.value;
        this.changerDetectorRef.markForCheck();
      } else if (failInSpace) {
        this.mdlSnackbarService.showToast(
          this.msgs.TAG_SPACE_NOT_ALLOWED
        );
        this.tagInput.nativeElement.value = failedTags.toString();
        this.changerDetectorRef.markForCheck();
      } else if (isDuplicate) {
        this.mdlSnackbarService.showToast(
          this.msgs.DUPLICATE_TAG_NAME
        );
        this.tagInput.nativeElement.value = failedTags.toString();
        this.changerDetectorRef.markForCheck();
      } else {
        this.tagText = "";
        this.tagInput.nativeElement.value = "";
        this.changerDetectorRef.markForCheck();
      }
    }
  }

  displayValue(selectedTagName) {
    console.log("[TaskDetailsComponent][displayValue] tagName & taskCpy", selectedTagName, this.taskCpy.tags);

    if (selectedTagName && (this.searchTagsList.length >= 1)) {
      let isDuplicate = false;

      this.taskCpy.tags.forEach(t => {
        if (t.name.toLowerCase() === selectedTagName.toLowerCase()) {
          this.mdlSnackbarService.showToast(
            this.msgs.DUPLICATE_TAG_NAME
          );
          isDuplicate = true;
          return false;
        }
      });

      if (!isDuplicate) {
        for (let sTag of this.searchTagsList) {
          if (sTag.name.toLowerCase()  === selectedTagName.trim().toLowerCase()) {
            // reuse existing tag id
            this.taskCpy.tags.push({ "id": sTag.id, "name": selectedTagName.trim() });
            break;
          }
        }
      }
      return this.tagText.substr(0, this.tagText.lastIndexOf(",") + 1);
    }
    return selectedTagName;
  }

  onEdit(task) {
    console.log("TaskDetailsComponent onEdit", task);

    if (this.isOnline || CommonUtil.isSQLSupported()) {
      this.taskCpy = new Task(this.task);
      this.subject = this.taskCpy.subject;
      this.taskCpy.description = this.htmlDescription;
      this.store.dispatch(new SetEditTaskView(true));
      this.isDescEditMode = false;
      this.taskRepo.getMemberList(this.taskCpy.project.id);
      if ( this.authUser && this.authUser.team_user === "false") {
        this.taskRepo.syncAuthUser();
      }
      this.selectedPriorityOption = this.taskCpy.priority.id;
      this.selectedStatusOption = this.taskCpy.status.id;
      this.selectedRepeatOption = this.taskCpy.repeat ? this.taskCpy.repeat : "n";
      this.selectedDuedateOption = this.datePipe.transform(this.taskCpy.due_date, "dd MMM. y");
      this.selectedDoneOption = this.taskCpy.done_ratio ? this.taskCpy.done_ratio : 0 ;

      this.selectedWatcherList = [];
      this.fileUploads = [];
      this.resetHeader();

      setTimeout(() => {
        if (this.editTaskSubject !== null) {
          this.editTaskSubject.nativeElement.focus();
          this.setPriorityLabelColor();
        }
      }, 100);
      this.generateProjectList();
      this.generateFolderList();
      this.generateLocationList();
      this.estimatedHours = this.getHours(this.taskCpy.estimated_hours);
      this.estimatedMinutes = this.getMinutes(this.taskCpy.estimated_hours);
      this.spentHours = this.getHours(this.taskCpy.spent_hours);
      this.spentMinutes = this.getMinutes(this.taskCpy.spent_hours);

      this.externalURLForm = this.fb.group({
        "external_url": [this.taskCpy.external_url ? this.taskCpy.external_url : "", Validators.compose([])],
      });

    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
    this.resetTextHighlight();
  }

  applyChanges() {
    if (this.isOnline || CommonUtil.isSQLSupported()) {

      // validate project
      if (!this.task.project) {
        this.taskRepo.errorProjectLinkTaskRequired();
        return false;
      }

      // validate start date / due date
      if (this.taskCpy.due_date && this.taskCpy.start_date) {
        this.taskCpy.due_date.setHours(0, 0, 0, 0);
        this.taskCpy.start_date.setHours(0, 0, 0, 0);
        if (this.taskCpy.due_date < this.taskCpy.start_date) {
          this.taskRepo.errorDueDateInvalid();
          return false;
        }
      }

      // validate assignee
      if (!this.setAssigneeValue(this.assigneeCtrl.value)) {
        return false;
      }

      if ( this.taskCpy.status.id === TaskUtils.statusCompletedId() && this.task.status.id === TaskUtils.statusCompletedId()) {
        if (this.taskCpy.done_ratio !== 100) {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.DONE_EQUAL_ERROR });
          return false;
        }
      } else if ( this.taskCpy.status.id === TaskUtils.statusCompletedId() && this.task.status.id !== TaskUtils.statusCompletedId()) {
        this.taskCpy.done_ratio = 100;
      }

      if (!this.isMobileDevice) {
        this.taskCpy.isChecked = true;
      }

      this.taskCpy.subject = this.subject;
      if (this.taskCpy.subject && this.taskCpy.subject.length > 255) {
        this.taskRepo.subjectTooLong();
        return false;
      }

      this.setProjectValue(this.projectCtrl.value);
      this.setListValue(this.listCtrl.value);
      this.setLocationValue(this.locationCtrl.value);

      if ((this.estimatedHours > 8) || (this.estimatedHours >= 8 && this.estimatedMinutes > 0)) {
        this.taskRepo.errorEstimateIsInvalid();
        return false;
      }
      if (this.estimatedMinutes > 60) {
        this.taskRepo.errorEstimateMinuteIsInvalid();
        return false;
      }

      this.setEstimationTime();
      this.setSpentTime();

      this.setExternalURL();
      this.taskCpy.description = this.taskToolbar.getContentInTextile();

      let watcherMembers: User[] = [];
      if (this.taskCpy.watchers && this.taskCpy.watchers.length > 0) {
        this.taskCpy.watchers.forEach( member => {
          watcherMembers.push(member);
        });
      }
      if (this.selectedWatcherList && this.selectedWatcherList.length > 0) {
        this.selectedWatcherList.forEach( member => {
          watcherMembers.push(member);
        });
      }
      this.applying = true;
      this.taskRepo.updateTaskDetail(this.task, this.taskCpy, this.fileUploads, watcherMembers);

      this.changerDetectorRef.markForCheck();
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  setEstimationTime() {
    this.estimatedHours = this.estimatedHours !== "" ? this.estimatedHours : "00";
    this.estimatedMinutes = this.estimatedMinutes !== "" ? this.estimatedMinutes : "00";
    this.taskCpy.estimated_hours = this.estimatedHours + ":" + this.estimatedMinutes;
  }

  setSpentTime() {
    this.spentHours = this.spentHours !== "" ? this.spentHours : "00";
    this.spentMinutes = this.spentMinutes !== "" ? this.spentMinutes : "00";
    this.taskCpy.spent_hours = this.spentHours + ":" + this.spentMinutes;
  }

  setExternalURL() {
    if (this.externalURLForm.value) {
      this.taskCpy.external_url = this.externalURLForm.value.external_url;
    } else {
      this.taskCpy.external_url = "";
    }
  }

  changeValue(event) {
    this.resizeTextBox();
  }

  inputHandler(event) {
    this.resizeTextBox();
  }

  resizeTextBox() {
    this.resetTextArea();
    let input = this.commentInput.nativeElement;
    let commentLines = Math.floor(input.scrollHeight / 18);
    if (commentLines > 1) {
      if (commentLines > 4) {
        this.commentLines = 4;
      } else {
        this.commentLines = commentLines;
      }
    } else {
      this.commentLines = 1;
    }
    let element = document.querySelectorAll(".comment-input")[0];
    let bodyEle = document.querySelectorAll(".detail-body")[0];
    bodyEle.classList.add("task-detail-line-" + this.commentLines);
    element.classList.add("comment-line-" + this.commentLines);
    this.changerDetectorRef.markForCheck();
  }

  resetTextArea() {
    let element = document.querySelectorAll(".comment-input")[0];
    let bodyEle = document.querySelectorAll(".detail-body")[0];

    for (let i of Array.from(Array(this.commentLines).keys())) {
      element.classList.remove("comment-line-" + (i + 1));
      bodyEle.classList.remove("task-detail-line-" + (i + 1));
    }
    this.commentLines = 1;
    this.changerDetectorRef.markForCheck();
  }

  getPriorityColor(item) {
    return TaskUtils.getPriorityColor(item);
  }

  getTextHightLightPropertyName(item) {
    return TaskUtils.getTextHightLightPropertyName(item);
  }

  getEditIconColor(item) {
    return TaskUtils.getEditIconColor(item);
  }

  getStatusColor(item) {
    return TaskUtils.getStatusColor(item);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.onUpdate.unsubscribe();
    this.debouncer.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  backFromDetail() {
    this.store.dispatch(new SetDetailView(false));
    this.store.dispatch(new SetSelectAll(false));
    this.store.dispatch(new ResetSelectedTaskId());
    this.resetTextHighlight();
    this.router.navigate(["../../"], { relativeTo: this.activated });
  }

  backToDetail() {
    this.store.dispatch(new SetEditTaskView(false));
    this.showExternalUrl = false;
    this.changerDetectorRef.markForCheck();
  }

  searchTags(event) {
    this.debouncer.next(true);
  }

  openAttachDialog() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskAddAttachmentDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaddattachmentdialog"
    });
    dlg.afterClosed().pipe(take(1)).subscribe(uploads => {
      if (!!uploads) {
        for (let i = 0; i < uploads.length; i++) {
          this.fileUploads.push(uploads[i]);
        }
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  // action fron view
  removeAttachments(item, isAttached: boolean) {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + item.filename + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        if (isAttached) {
          this.taskCpy.attachments.splice(this.taskCpy.attachments.indexOf(item), 1);
        } else {
          this.fileUploads.splice(this.fileUploads.indexOf(item), 1);
        }
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  openReminderDialog() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    if (this.taskCpy.remind_on === null) {
      this.reminderDateTime = {
        isDueDate: true,
        dateTime: this.taskCpy.due_date ? this.taskCpy.due_date : null
      };
    } else {
      let currDate = new Date();
      currDate.setHours(0, 0, 0, 0);
      let dueDate = new Date(
        this.taskCpy.remind_on.getFullYear(),
        this.taskCpy.remind_on.getMonth(),
        this.taskCpy.remind_on.getDate(),
      );
      dueDate.setHours(0, 0, 0, 0);
      let isDueDate = false;
      if (currDate.getTime() === dueDate.getTime()) {
        isDueDate = true;
      } else {
        isDueDate = false;
      }
      this.reminderDateTime = {
        isDueDate: isDueDate,
        dateTime: this.taskCpy.remind_on
      };
    }

    const dlg = this.matDialog.open(TaskAddReminderDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaddreminderdialog",
      data: { reminderDateTime: this.reminderDateTime }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(reminderDateTime => {
      if (!!reminderDateTime) {
        let currDate = new Date();
        if (reminderDateTime.isDueDate) {
          if (!this.taskCpy.due_date) {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.NO_DUEDATE_SET });
          } else {
            let reminderDate: Date;
            if (!reminderDateTime.dateTime) {
              reminderDate = this.taskCpy.due_date;
            } else {
              reminderDate = new Date(
                this.taskCpy.due_date.getFullYear(),
                this.taskCpy.due_date.getMonth(),
                this.taskCpy.due_date.getDate(),
                reminderDateTime.dateTime.getHours(),
                reminderDateTime.dateTime.getMinutes()
              );
            }
            if (reminderDate.getTime() < currDate.getTime()) {
              this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PASSED_CURRENTTIME });
            } else {
              this.taskCpy.remind_on = reminderDate;
            }
          }
        } else {
          if (!this.taskCpy.due_date) {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.NO_DUEDATE_SET });
          } else {
            let reminderD = new Date(reminderDateTime.dateTime.toString());
            reminderD.setHours(0, 0, 0, 0);
            let remindertaskD = new Date(this.taskCpy.due_date.toString());
            remindertaskD.setHours(0, 0, 0, 0);
            if (reminderDateTime.dateTime.getTime() < currDate.getTime()) {
              this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PASSED_CURRENTTIME });
            } else if (reminderD > remindertaskD) {
              this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PASSED_DUEDATE });
            } else {
              this.taskCpy.remind_on = reminderDateTime.dateTime;
            }
          }
        }
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  openAddFieldDialog() {
    const dlg = this.matDialog.open(TaskAddFieldDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaddfielddialog",
      data: { task: this.taskCpy }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(value => {
      if (!!value) {
        switch (value.type) {
          case AddFieldsType.reminder:
            if (value.data) {
              this.addReminderFromDialog(value.data);
            }
            break;
          case AddFieldsType.attachment:
            if (value.data) {
              this.addAttachedFromDialog(value.data);
            }
            break;
          case AddFieldsType.url:
            this.addExternalURL();
            break;
          case AddFieldsType.watchers:
            if (value.data) {
              this.addWatcherFromDialog(value.data);
            }
            break;
        }
      }
    });
  }

  addReminderFromDialog(reminderDateTime) {
    if (reminderDateTime) {
      let currDate = new Date();
      if (reminderDateTime.isDueDate) {
        if (!this.taskCpy.due_date) {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.NO_DUEDATE_SET });
        } else {
          let reminderDate: Date;
          if (!reminderDateTime.dateTime) {
            reminderDate = this.taskCpy.due_date;
          } else {
            reminderDate = new Date(
              this.taskCpy.due_date.getFullYear(),
              this.taskCpy.due_date.getMonth(),
              this.taskCpy.due_date.getDate(),
              reminderDateTime.dateTime.getHours(),
              reminderDateTime.dateTime.getMinutes()
            );
          }
          if (reminderDate.getTime() < currDate.getTime()) {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PASSED_CURRENTTIME });
          } else {
            this.taskCpy.remind_on = reminderDate;
          }
        }
      } else {
        if (!this.taskCpy.due_date) {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.NO_DUEDATE_SET });
        } else {
          let reminderD = new Date(reminderDateTime.dateTime.toString());
          reminderD.setHours(0, 0, 0, 0);
          let remindertaskD = new Date(this.taskCpy.due_date.toString());
          remindertaskD.setHours(0, 0, 0, 0);
          if (reminderDateTime.dateTime.getTime() < currDate.getTime()) {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PASSED_CURRENTTIME });
          } else if (reminderD > remindertaskD) {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PASSED_DUEDATE });
          } else {
            this.taskCpy.remind_on = reminderDateTime.dateTime;
          }
        }
      }
      this.changerDetectorRef.markForCheck();
    }
  }

  addAttachedFromDialog(uploads) {
    if (uploads) {
      for (let i = 0; i < uploads.length; i++) {
        this.fileUploads.push(uploads[i]);
      }
      this.changerDetectorRef.markForCheck();
    }
  }

  addWatcherFromDialog(selectedMembers) {
    if (selectedMembers) {
      for (let i = 0; i < selectedMembers.length; i++) {
        let member = this.taskCpy.watchers.find( f => f.id === selectedMembers[i].id );
        let alreadyMember = this.selectedWatcherList.find ( f => f.id === selectedMembers[i].id );
        if (!member && !alreadyMember) {
          this.selectedWatcherList.push(selectedMembers[i]);
        }
      }
      this.changerDetectorRef.markForCheck();
    }
  }

  addExternalURL() {
    this.showExternalUrl = true;
    this.changerDetectorRef.markForCheck();
  }

  removeReminder() {
    this.taskCpy.remind_on = null;
    this.changerDetectorRef.markForCheck();
  }

  editComment(comment, index) {
    comment.isEditMode = true;
    this.resetTextHighlight();
    this.changerDetectorRef.markForCheck();
    setTimeout(() => {
      let commentInput: any = document.querySelector("#edit-comment-" + index);
      if (commentInput) {
        commentInput.value = CommonUtil.strip_html_tags(comment.notes.replace(/<br>/g, "\n"));
        this.applySizeEditComment(commentInput, index);
        commentInput.focus();
      }
      this.changerDetectorRef.markForCheck();
    });
    setTimeout(() => {
      let commentComponents: any = document.querySelectorAll(".comment_detail");
      commentComponents[index].scrollIntoView(false);
    }, 500);
  }

  applySizeEditComment(commentInput, index) {
    this.resizeEditCommentBox(commentInput, index);
  }

  resizeEditCommentBox(commentInput, index) {
    this.resetEditCommentBox(commentInput, index);
    let commentLines = Math.floor(commentInput.scrollHeight / 14);
    if (commentLines > 1) {
      if (commentLines > 4) {
        this.commentLines = 4;
      } else {
        this.commentLines = commentLines;
      }
    } else {
      this.commentLines = 1;
    }
    commentInput.classList.add("comment-line-" + this.commentLines);
    this.changerDetectorRef.markForCheck();
  }

  resetEditCommentBox(commentInput, index) {
    for (let i of Array.from(Array(this.commentLines).keys())) {
      commentInput.classList.remove("comment-line-" + (i + 1));
    }
    this.commentLines = 1;
    this.changerDetectorRef.markForCheck();
  }

  resetComment(comment) {
    comment.isEditMode = false;
    this.changerDetectorRef.markForCheck();
  }

  addComment() {
    if (!this.commentText || this.commentText.trim().length === 0) {
      this.taskRepo.errorCommentRequired();
      this.changerDetectorRef.markForCheck();
    } else {
      if (this.isOnline || CommonUtil.isSQLSupported()) {
        this.taskRepo.addComment(this.task, this.commentText.toString().replace(/(?:\r\n|\r|\n)/g, "<br>")).subscribe(resSuccess => {
          if (resSuccess) {
            // TODO: to populate only current commenter avatar (not all)
            this.taskRepo.poppulateCommentersAvatar(this.task).subscribe((success) => {
              if (success) {
                this.broadcaster.broadcast("sortCommentList");
                this.changerDetectorRef.markForCheck();
              }
            });
          }
        });
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    this.commentText = "";
    this.resetTextArea();

    let element = document.querySelectorAll(".comment-input")[0];
    let bodyEle = document.querySelectorAll(".detail-body")[0];
    bodyEle.classList.add("task-detail-line-" + this.commentLines);
    element.classList.add("comment-line-" + this.commentLines);
  }

  updateComment(comment, value) {
    if (!value || value.trim().length === 0) {
      this.taskRepo.errorCommentRequired();
      this.changerDetectorRef.markForCheck();
    } else {
      if (this.isOnline || CommonUtil.isSQLSupported()) {
        this.taskRepo.editComment(this.task, comment, value.replace(/(?:\r\n|\r|\n)/g, "<br>")).subscribe(resSuccess => {
          this.changerDetectorRef.markForCheck();
        });
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
  }

  private updateUserAvatarForTask() {
    if (this.task && this.authUser) {
      if (this.task.assigned_to) {
        if (this.authUser.id === this.task.assigned_to.id) {
          this.task.userAvatar = this.authUser.userAvatar;
        }
      }
      if (this.task.comments && this.task.comments.length > 0) {
        // TODO: to populate only current user comments avatar (not all)
        this.task.comments.forEach(comment => {
          if (comment.user && comment.user.id === this.authUser.id) {
            comment.userAvatar = this.authUser.userAvatar;
          }
        });
      }
      if (this.task.watchers && this.task.watchers.length > 0) {
        // TODO: to populate only current user comments avatar (not all)
        this.task.watchers.forEach(watcher => {
          if (watcher && watcher.id === this.authUser.id) {
            watcher.avatar = this.authUser.userAvatar;
          }
        });
      }
      const changes = { id: this.task.id, changes: this.task };
      this.store.dispatch(new UpdateTaskSuccess({ task: changes }));
      this.changerDetectorRef.markForCheck();
    }
  }

  downloadFile(attachment) {
    const isImage = CommonUtil.getMediaType(attachment.filename) === this.MediaType.VIDEOS;
    const isVideo = CommonUtil.getMediaType(attachment.filename) === this.MediaType.IMAGE;

    if (CommonUtil.isOnIOS() && !(isImage || isVideo)) {
      this.mdlSnackbarService.showToast(
        this.messageTranslatorService.getMessage(TasksConstants.DOWNLOAD_NOT_IMAGEVIDEO_ATTACHMENT_NOT_PERMITTED_IOS)
      );
      return;
    }

    if (this.isOnline) {
      this.taskRepo.downloadFile(attachment);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  editDateClick(setDateToField: string) {
    const dlg = this.matDialog.open(TaskDatePickerComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-datepicker-dialog",
      data: { header: "", rangeStart: this.rangeStart}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.editDateSelect(res, setDateToField);
      }
    });
  }

  editDateSelect(event, setDateToField) {
    let item = { date: event, name: "dateSelect" };
    if (setDateToField === "startDateSet") {
      this.startDateInput.nativeElement.value = this.datePipe.transform(event, "dd MMM. y");
      this.taskCpy.start_date = event;
    } else {
      this.dueDateInput.nativeElement.value = this.datePipe.transform(event, "dd MMM. y");
      this.taskCpy.due_date = event;
    }
    this.changerDetectorRef.markForCheck();
  }

  startDateSelectItem(selectedItem: any): void {
    this.startDateInput.nativeElement.value = (selectedItem.date !== null) ? this.datePipe.transform(selectedItem.date, "dd MMM. y") : selectedItem.name;
    this.taskCpy.start_date = selectedItem.date;
  }

  dueDateSelectItem(selectedItem: any): void {
    this.dueDateInput.nativeElement.value = (selectedItem.date !== null) ? this.datePipe.transform(selectedItem.date, "dd MMM. y") : selectedItem.name;
    this.taskCpy.due_date = selectedItem.date;
  }

  openAttachment(item: TaskAttachment) {
    switch (CommonUtil.getMediaType(item.filename)) {
      case this.MediaType.IMAGE:
        if (this.isSvg(item.filename)) {
          this.translate.get(["UNSUPPORTED_FILE"])
          .pipe(takeUntil(this.isAlive$))
          .subscribe(res => {
            this.mdlSnackbarService.showToast(res.UNSUPPORTED_FILE);
          });
          return;
        }
        this.previewAttachment(item, this.MediaType.IMAGE);
        break;
      case this.MediaType.VIDEOS:
        this.previewAttachment(item, this.MediaType.VIDEOS);
        break;
      case this.MediaType.VOICE_MESSAGE:
        this.previewAttachment(item, this.MediaType.VOICE_MESSAGE);
        break;
      default:
        if (!environment.isCordova) {
          this.downloadFile(item);
          return;
        }
        this.translate.get(["UNSUPPORTED_FILE"])
          .pipe(takeUntil(this.isAlive$))
          .subscribe(res => {
            this.mdlSnackbarService.showToast(res.UNSUPPORTED_FILE);
        });
        break;
    }
    this.changerDetectorRef.markForCheck();
  }

  isSvg(itemName: string) {
    return itemName.toLowerCase().lastIndexOf(".svg") !== -1;
  }

  previewAttachment(attachmentItem: TaskAttachment, mediaType: string) {
    if (environment.isCordova) {
      // check if an image is already in cache
      this.filesStorageService.filePathOnDiscIfExists(attachmentItem.filename).subscribe((filePath) => {
        if (filePath) {
          // read blob
          this.filesStorageService.readBlobFromDisc(attachmentItem.filename).subscribe((blob) => {
            this.openPreviewDialog(attachmentItem, blob, mediaType);
          });
        } else {
          if (this.isOnline) {
            // download from server
            this.downloadAttachmentForPreviewAndPreview(attachmentItem, mediaType);
          } else {
            this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
          }
        }
      });
    } else {
      if (this.isOnline) {
        // download from server
        this.downloadAttachmentForPreviewAndPreview(attachmentItem, mediaType);
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
  }

  private downloadAttachmentForPreviewAndPreview(attachmentItem: TaskAttachment, mediaType: string){
    this.store.dispatch(new SetIsLoading(true));

    this.taskRepo.getAttachmentContent(attachmentItem).subscribe(blob => {
      this.store.dispatch(new SetIsLoading(false));

      // save into disc cache for offline access
      if (environment.isCordova) {
        this.filesStorageService.saveBlobToDisc(blob, attachmentItem.filename).subscribe((localFileUrl)  => {
          console.log("TaskDetailsComponent, downloadAttachmentForPreviewAndPreview, saved to disk cache");
        }, err => {
          console.log("TaskDetailsComponent, downloadAttachmentForPreviewAndPreview, save to disk cache ERROR", err);
        });
      }

      this.openPreviewDialog(attachmentItem, blob, mediaType);
    }, (err) => {
      console.log("TaskDetailsComponent, previewAttachment error: ", err);

      this.store.dispatch(new SetIsLoading(false));

      this.translate.get(["UNSUPPORTED_FILE"])
          .pipe(takeUntil(this.isAlive$))
          .subscribe(res => {
            this.mdlSnackbarService.showToast(res.UNSUPPORTED_FILE);
      });
    });
  }

  private openPreviewDialog(attachmentItem: TaskAttachment, blob: Blob, mediaType: string) {
    let dialogStyles = {};

    if (!this.CommonUtil.isOnMobileDevice()) {
      dialogStyles = {
        "width": "auto",
        "height": "900px",
        "max-height": "900px",
        "max-width": "900px"
      };
    }

    let pDialog = this.dialogService.showCustomDialog({
      component: MediaPreviewDialogComponent,
      providers: [
        {
          provide: MEDIA_ATTACHMENT, useValue: attachmentItem
        },
        {
          provide: MEDIA_TYPE, useValue: mediaType
        },
        {
          provide: MEDIA_CONTENT, useValue: blob
        }
      ],
      isModal: true,
      styles: dialogStyles,
      animate: false,
      clickOutsideToClose: false
    });
    pDialog.subscribe((dialog) => {
      dialog.onHide().subscribe( (value: any) => {
        if (value) {
          this.deleteAttachment(attachmentItem);
        }
      });
    });
  }

  private deleteAttachment(item: TaskAttachment) {
    if (this.isOnline) {
      this.taskService.deleteAttachment(item).subscribe( res => {
        this.task.attachments.splice(this.task.attachments.indexOf(item), 1);
        this.changerDetectorRef.markForCheck();
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  toolbarIsOpen($event) {
    this.isToolbarOpen = $event;
    this.changerDetectorRef.markForCheck();
  }

  onDeleteTask(): void {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_TASK_MESSAGE), header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      this.onConfirmTaskDeleteDialogClose(res);
    });
  }

  onConfirmTaskDeleteDialogClose($event: boolean): void {
    if ($event) {
      if (this.isOnline || CommonUtil.isSQLSupported()) {
        this.taskRepo.removeTask(this.task.id);
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
  }

  onCompleteTask() {
    let updateArgs: BulkUpdateArgs = { id: TaskUtils.statusCompletedId(), name: TaskUtils.statusCompletedName() };
    if (this.isOnline || CommonUtil.isSQLSupported()) {
      this.completeTask(this.task, updateArgs);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }


  private completeTask(task: Task, args: BulkUpdateArgs){
    this.taskRepo.completeTask(task, args);
  }

  onDuplicateTask(): void {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.OK, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DUPLICATE_TASK), header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      this.onConfirmTaskDeleteDialogClose(res);
    });
  }

  onConfirmDuplicateDialogClose($event: boolean): void {
    if ($event) {
      if (this.isOnline || CommonUtil.isSQLSupported() ) {
        this.taskRepo.duplicateTasks([this.task]);
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
  }

  convertDecimalToTimeFormat(hours: string) {
    return TaskUtils.convertDecimalToTimeFormat(hours);
  }

  setAddCommentFocus() {
    setTimeout(() => {
      let addCommentComponents: any = document.querySelector(".add-comment-wrapper");
      if (addCommentComponents) {
         addCommentComponents.scrollIntoView(false);
      }
    }, 500);
  }

  getHours(hours: string) {
    if (!hours) {
      return "00";
    }
    let time = this.convertDecimalToTimeFormat(hours);
    return TaskUtils.getHours(time);
  }

  getMinutes(hours: string) {
    if (!hours) {
      return "00";
    }
    let time = this.convertDecimalToTimeFormat(hours);
    return TaskUtils.getMinutes(time);
  }

  validateNumber(event, ele) {
    return TaskUtils.validateNumberOf2Digit(event, ele);
  }

  linkify(inputText) {
    return CommonUtil.linkify(inputText);
  }

  openTimelogHistoryDialog() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TimelogHistoryComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-timelog-history-dialog",
      data: { issue_id: this.task.id, created_on: this.task.created_on }
    });
    dlg.afterClosed().pipe(take(1)).subscribe($event => {
      if ($event) {
        if ($event.spent_time && $event.spent_time !== "0") {
          if (this.isOnline) {
            if (this.task.spent_hours && $event.spent_time !== parseFloat(this.task.spent_hours).toString()) {
              this.getTaskDetails(this.task.id, new Date());
            }
          } else {
            this.task.spent_hours = $event.spent_time;
            if (this.task.status && this.task.status.id === TaskUtils.statusNewId()) {
              this.task.status = {
                id: TaskUtils.statusInProgressId(),
                name: TaskUtils.statusInProgressName()
              };
            }
            if (this.task.spent_hours && this.task.estimated_hours) {
              this.task.done_ratio = TaskUtils.getCompletedRatio((parseFloat(this.task.spent_hours) * 100 ) / parseFloat(this.task.estimated_hours));
            }
            this.changerDetectorRef.markForCheck();
            this.taskRepo.updateTaskInStoreAndDB(this.task);
            setTimeout(() => {
              if (!this.task.start_date || ( this.task.start_date && this.task.start_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime())) {
                this.task.start_date = null;
              }
              if (!this.task.due_date || ( this.task.due_date && this.task.due_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime())) {
                this.task.due_date = null;
              }
              this.changerDetectorRef.markForCheck();
            }, 200);
          }
        }
      }
    });
  }

  openLogTimeDialog() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(LogTimeComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-log-time-dialog",
      data: { issue_id: this.task.id, created_on: this.task.created_on }
    });
    dlg.afterClosed().pipe(take(1)).subscribe($event => {
      if ($event) {
        if ($event.timeEntry) {
          if (this.isOnline) {
            this.getTaskDetails(this.task.id, new Date());
            this.backToDetail();
          } else {
            let spent_hours = parseFloat(this.taskCpy.spent_hours ? this.taskCpy.spent_hours : "0.0");
            spent_hours += parseFloat($event.timeEntry.hours);
            this.taskCpy.spent_hours = spent_hours.toString();
            this.task.spent_hours = this.taskCpy.spent_hours;
            this.spentHours = this.getHours(this.taskCpy.spent_hours);
            this.spentMinutes = this.getMinutes(this.taskCpy.spent_hours);
            if (this.taskCpy.status && this.taskCpy.status.id === TaskUtils.statusNewId()) {
              this.taskCpy.status = {
                id: TaskUtils.statusInProgressId(),
                name: TaskUtils.statusInProgressName()
              };
              this.task.status = this.taskCpy.status;
              this.changerDetectorRef.markForCheck();
            }
            if (this.taskCpy.spent_hours && this.taskCpy.estimated_hours) {
              this.taskCpy.done_ratio = TaskUtils.getCompletedRatio((parseFloat(this.taskCpy.spent_hours) * 100 ) / parseFloat(this.taskCpy.estimated_hours));
              this.task.done_ratio = this.taskCpy.done_ratio;
              this.selectedDoneOption = this.task.done_ratio;
              this.changerDetectorRef.markForCheck();
            }
            this.taskRepo.updateTaskInStoreAndDB(this.taskCpy);
            setTimeout(() => {
              if (!this.taskCpy.start_date || ( this.taskCpy.start_date && this.taskCpy.start_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime())) {
                this.taskCpy.start_date = null;
              }
              if (!this.taskCpy.due_date || ( this.taskCpy.due_date && this.taskCpy.due_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime())) {
                this.taskCpy.due_date = null;
              }
              this.changerDetectorRef.markForCheck();
            }, 200);
          }
        }
      }
    });
  }

  openWatcherDialog() {
    const dlg = this.matDialog.open(TaskWatchersComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-watchers-dialog"
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        for (let i = 0; i < res.length; i++) {
          let member = this.taskCpy.watchers.find( f => f.id === res[i].id );
          let alreadyMember = this.selectedWatcherList.find ( f => f.id === res[i].id );
          if (!member && !alreadyMember) {
            this.selectedWatcherList.push(res[i]);
          }
        }
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  // action fron view
  removeWatchers(item, isWatched: boolean) {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_WATCHER) + " \"" + item.name + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        if (isWatched) {
          this.taskCpy.watchers.splice(this.taskCpy.watchers.indexOf(item), 1);
        } else {
          this.selectedWatcherList.splice(this.selectedWatcherList.indexOf(item), 1);
        }
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  getDateWithFormat(date, format) {
    return this.datePipe.transform(date, format);
  }

  onMentionSelect(item: any): string {
    console.log("[TaskDetailViewComponent][onMentionSelect]", item);
    setTimeout(() => {
      /*if (this.messageInput) {
        this.messageInput.nativeElement.focus();
      }*/
    }, 1000);
    if (item) {
      return `@${item.value} `;
    }
    return "";
  }

  checkAttachmentHighLight(attachment) {
    if ( attachment && this.taskHighlightFields) {
      if ( this.taskHighlightFields.attachments && this.taskHighlightFields.attachments.length > 0) {
        let value = this.taskHighlightFields.attachments.find( value => value.id === attachment.id);
        if (value) {
          return true;
        }
      }
    }
    return false;
  }

  checkCommentHighLight(comment) {
    if ( comment && this.taskHighlightFields) {
      if ( this.taskHighlightFields.comments && this.taskHighlightFields.comments.length > 0) {
        let value = this.taskHighlightFields.comments.find( value => value.id === comment.id);
        if (value) {
          return true;
        }
      }
    }
    return false;
  }

  checkWatcherHighLight(watcher) {
    if ( watcher && this.taskHighlightFields) {
      if ( this.taskHighlightFields.watchers && this.taskHighlightFields.watchers.length > 0) {
        let value = this.taskHighlightFields.watchers.find( value => value.id === watcher.id);
        if (value) {
          return true;
        }
      }
    }
    return false;
  }

  resetTextHighlight() {
    this.store.dispatch(new SetTaskDetailHighlight(false));
    this.taskHighlightFields = null;
    this.changerDetectorRef.markForCheck();
  }
}
