
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType, getFolderList, getLocationList } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlSnackbarService } from "@angular-mdl/core";
import { List, dialogType } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { CreateListDialogComponent } from "../create-list-dialog/create-list-dialog.component";
import { CommonUtil } from "../../../common/utils/common.utils";
import { FolderOptionListItemComponent } from "./folder-option-list-item.component";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { MessageTranslatorService } from "../../services";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { Router } from "@angular/router";
import { Location } from "../../models/location";
import { CreateLocationDialogComponent } from "../create-location-dialog/create-location-dialog.component";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-location-option-list",
  template: `
  <div class="task__dialog" id="locationDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'LOCATIONS' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body folder-option-list_dialog">
  <mdl-textfield id="locationSearchField" class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'SEARCH_LOCATION' | translate"  floating-label></mdl-textfield>
    <mdl-list id="locationItems">
      <mdl-list-item class="disable-select" vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openContextMenu(location)" *ngFor="let location of locationList | vpTaskProjectSearch : searchText; let i = index;"  [class.selected]="i == selectedIndex"  (click)="$event.stopPropagation();navigateRoute(location.name)">
        <mdl-icon class="disable-select">place</mdl-icon>
        <span class="list-item disable-select">{{ location.name }}</span>
        <div class="rounded-rectangle">
            <span class="disable-select">{{ location.tasks_count }}</span>
        </div>
      </mdl-list-item>
    </mdl-list>
  </div>
  <button id="newLocationButton" (click)="openAddLocationDialog()" class="add_list-button brand-button-color mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
    <i class="material-icons disable-select">add_location</i>
  </button>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LocationOptionListComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  locationList: Location[] = [];
  isLongPressed: boolean =  false;
  isLoading: boolean = false;
  dialogType = dialogType;
  searchText: string = "";
  isOnline: boolean = false;
  selectedFilterType: string;
  currDelSelectedLocation: Location;

  constructor(
    private router: Router,
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private messageTranslatorService: MessageTranslatorService,
    private mdlSnackbarService: MdlSnackbarService,
    private successService: SuccessService,
    private errorService: ErrorService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<LocationOptionListComponent>
  ) {
    this.setupStore();
    this.handleDeleteLocationSuccessMessages();
    this.handleLocationErrorMessages();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.broadcaster.on<any>("hideLocationOptionListDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });

    this.store
    .select(getLocationList)
    .pipe(takeWhile(() => this.isAlive))
    .subscribe(locations => {
      if (locations) {
        this.locationList = locations;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getSelectedFilterType).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      this.selectedFilterType = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleLocationErrorMessages() {
    this.errorService.only(ErrorType.LocationError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleDeleteLocationSuccessMessages() {
    this.successService.only(SuccessType.LocationDeleted).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
          success.messages
        );
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();

      this.taskRepo.getLocationsWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LOCATION) {
        if (this.isSelectedFilter(this.currDelSelectedLocation.name)) {
          this.router.navigate(["/task"]);
        }
      }
    });
  }

  navigateRoute(title: any) {
    if (CommonUtil.isOnIOS() && this.isLongPressed) {
      this.isLongPressed = false;
      return;
    }
    this.hide(title);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(title?) {
    this.dialogRef.close({ type: this.taskConstants.ROUTE_TYPE_LOCATION, title: title});
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  openAddLocationDialog() {
    const dlg = this.matDialog.open(CreateLocationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-location-dialog",
      data: { isCreate: true, selectedLocation: null }
    });
  }

  openContextMenu(location) {
    this.isLongPressed = true;
    const pDialog = this.matDialog.open(FolderOptionListItemComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-folder-option-list-item-dialog",
    });
    pDialog.afterClosed().pipe(take(1)).subscribe( value => {
      if (value) {
        if (value.type === TasksConstants.RENAME) {
          this.renameLocation(location);
        } else if (value.type === TasksConstants.DELETE) {
          this.deleteLocation(location);
        }
      }
    });
  }

  renameLocation(location) {
    const dlg = this.matDialog.open(CreateLocationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-location-dialog",
      data: { isCreate: false, selectedLocation: location }
    });
  }

  deleteLocation(location) {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + location.name + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.isLoading = true;
        this.currDelSelectedLocation = location;
        this.taskRepo.deleteLocation(location);
        this.changerDetectorRef.markForCheck();
      }
    });
  }
}
