
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-select-apps-dialog task__dialog" id="taskSelectAppsDialog">
    <div class="task__dialog-header">
      <div class="mobile-back-button">
          <button mat-button (click)="close()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
      </div>
      <div class="header_lbl disable-select">
          {{'SEARCH_IN_APPS' | translate}}
      </div>
      <div class="desktop-close-button">
          <button mat-button (click)="close()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
      </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <mdl-list [class.changed]="changed">
          <mdl-list-item *ngFor="let app of apps">
            <mdl-list-item-primary-content>
              <img class="vnc-icon" [src]="getStandardObj(app).image_key"/>
              <h5>{{ getStandardObj(app).title }}</h5>
            </mdl-list-item-primary-content>
            <mdl-list-item-secondary-action>
              <mdl-checkbox [value]="isAppSelected(app)" (click)="appSelected(app)"></mdl-checkbox>
            </mdl-list-item-secondary-action>
          </mdl-list-item>
        </mdl-list>
      <div *ngIf="changed" class="task__dialog-footer">
        <span *ngIf="selectedApps.length === 0">{{'SELECT_AT_LEAST_ONE_APP' | translate}}</span>
        <span *ngIf="selectedApps.length > 0">{{'SAVE_CHANGES' | translate}}</span>
        <button mdl-ripple mdl-button mdl-button-type="fab" (click)="saveApps()"
                [disabled]="selectedApps.length === 0">
          <mdl-icon>done</mdl-icon>
        </button>
      </div>
    </div>
</div>
