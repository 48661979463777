
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnChanges, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { TaskService } from "../../task.service";
import { TasksRootState, getSelectedFilterOption, getSortBy } from "../../store/index";
import { Store } from "@ngrx/store";
import { TaskUtils } from "../../shared/task-utils";
import { TasksConstants } from "../../shared/task-constacts";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "../../../common/providers/config.service";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { Router } from "@angular/router";
import { ResponsiveService } from "../../../common/providers/responsive.service";
import { TaskStatistics, Tag, List, Location } from "../../models";
import { getSelectedFilterType, getTaskStatisticsInfo, getTagList, getFolderList, getLocationList, getIsOpenTasksChecked, getIsShowMyTaskChecked, getIsCloseTasksChecked } from "../../store/selectors/tasks.selectors";
import { MatMenuTrigger } from "@angular/material/menu";
import { CommonUtil } from "../../../common/utils/common.utils";
import { getOnlineStatus } from "../../../reducers";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-vnctask-filter",
  templateUrl: "task-filter.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFilterComponent implements OnDestroy {
  @Input() statusList: any[] = [];
  @Output() onSelect = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onOpenCheckClick = new EventEmitter();
  @Output() onCloseCheckClick = new EventEmitter();
  @Output() onShowMyTaskClick = new EventEmitter();
  selectedFilterOption: string;
  taskStatistics: TaskStatistics[] = [];
  tagList: Tag[] = [];
  folderList: List[] = [];
  locationList: Location[] = [];
  total_count: number = 0;
  selectedFilterKey: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  sortingOption: any = [];
  selectedSortValue: any;
  selectedFilterType: string;
  isLangEnglish: boolean = true;
  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  @ViewChild("statusMenu", { static: true }) statusMenu = MatMenuTrigger;
  @ViewChild("tagMenu", { static: true }) tagMenu = MatMenuTrigger;
  @ViewChild("listMenu", { static: true }) listMenu = MatMenuTrigger;
  @ViewChild("locationMenu", { static: true }) locationMenu = MatMenuTrigger;
  options: any;
  screen: string = this.responsiveService.getScreen();
  isOnline: boolean = false;
  openTask: boolean = false;
  closeTask: boolean = false;
  showMyTask: boolean = false;

  constructor(
    private service: TaskService,
    private store: Store<TasksRootState>,
    private responsiveService: ResponsiveService,
    private router: Router,
    private translate: TranslateService,
    private configService: ConfigService,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef,
    private broadcaster: Broadcaster
  ) {
    this.messageTranslatorService.translatedMessagesd$.subscribe( res => {
      this.options = res;
      this.setSortOption();
      this.isLangEnglish = this.configService.language === "en" ? true : false;
      this.changerDetectorRef.markForCheck();
    });
    this.setupStore();
  }

  setupStore() {

    // tasks filter: by status or by tags
    this.store.select(getSelectedFilterType).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      console.log("TaskFilterComponent. getSelectedFilterType: ", value);
      this.selectedFilterType = value;
      this.changerDetectorRef.markForCheck();
    });

    // tasks sorting
    this.store.select(getSortBy).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      console.log("TaskFilterComponent. getSortBy: ", value);
      this.selectedSortValue = value;
      this.changerDetectorRef.markForCheck();
    });

    this.responsiveService.screen$.pipe(takeWhile(() => this.isAlive)).subscribe(screen => {
      this.screen = screen;
      this.changerDetectorRef.markForCheck();
    });

    // tasks filter (all, open, this week..)
    this.store
      .select(getSelectedFilterOption).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
        console.log("TaskFilterComponent. getSelectedFilterOption: ", value);
        this.selectedFilterOption = value;
        if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_STATUS) {
          this.selectedFilterKey = TaskUtils.getRouteFilterKey(this.selectedFilterOption);
          this.total_count = this.getTotalCountForStatus(this.selectedFilterOption);
        } else if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_TAG) {
          this.selectedFilterKey = this.selectedFilterOption;
          this.total_count = this.getTotalCountForTag(this.selectedFilterOption);
        } else if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_LIST) {
          this.selectedFilterKey = this.selectedFilterOption;
          this.total_count = this.getTotalCountForList(this.selectedFilterOption);
        } else {
          this.selectedFilterKey = this.selectedFilterOption;
          this.total_count = this.getTotalCountForLocation(this.selectedFilterOption);
        }
        this.changerDetectorRef.markForCheck();
      });

    this.store.select(getTaskStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
      if (info) {
        this.taskStatistics = info;
        if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_STATUS) {
          this.total_count = this.getTotalCountForStatus(this.selectedFilterOption);
        }
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getTagList).pipe(takeWhile(() => this.isAlive)).subscribe(tags => {
      if (tags) {
        this.tagList = tags;
        if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_TAG) {
          this.total_count = this.getTotalCountForTag(this.selectedFilterOption);
        }
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getFolderList).pipe(takeWhile(() => this.isAlive)).subscribe(lists => {
      if (lists) {
        this.folderList = lists;
        if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_LIST) {
          this.total_count = this.getTotalCountForList(this.selectedFilterOption);
        }
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getLocationList).pipe(takeWhile(() => this.isAlive)).subscribe(locations => {
      if (locations) {
        this.locationList = locations;
        if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_LOCATION) {
          this.total_count = this.getTotalCountForLocation(this.selectedFilterOption);
        }
        this.changerDetectorRef.markForCheck();
      }
    });

    // online status
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsOpenTasksChecked).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      console.log("TaskFilterComponent. getIsOpenTasksChecked: ", value);
      this.openTask = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsShowMyTaskChecked).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      console.log("TaskFilterComponent. getIsShowMyTaskChecked: ", value);
      this.showMyTask = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsCloseTasksChecked).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      console.log("TaskFilterComponent. getIsCloseTasksChecked: ", value);
      this.closeTask = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  getTotalCountForStatus(routeName) {
    if (this.taskStatistics) {
      let taskStatistic: TaskStatistics = this.taskStatistics.find( statisticInfo =>
        statisticInfo.type === routeName );
      return taskStatistic ? taskStatistic.info.total_count : 0;
    }
    return 0;
  }

  getTotalCountForTag(routeName) {
    if (this.tagList) {
      let tag = this.tagList.find( tag => tag.name === routeName);
      return tag ?  tag.count : 0;
    }
    return 0;
  }

  getTotalCountForList(routeName) {
    if (this.folderList) {
      let list = this.folderList.find( list => list.name === routeName);
      return list ?  list.tasks_count : 0;
    }
    return 0;
  }

  getTotalCountForLocation(routeName) {
    if (this.locationList) {
      let location = this.locationList.find( location => location.name === routeName);
      return location ?  location.tasks_count : 0;
    }
    return 0;
  }

  setSortOption() {
    this.sortingOption = [];
    this.sortingOption.push({ value: TasksConstants.DUE_DATE_SORTING_KEY, viewValue: this.options.DUE_DATE });
    this.sortingOption.push({ value: TasksConstants.PRIORITY_SORTING_KEY, viewValue: this.options.PRIORITY });
    this.sortingOption.push({ value: TasksConstants.TASK_NAME_SORTING_KEY, viewValue: this.options.TASK_NAME });
    this.sortingOption.push({ value: TasksConstants.START_DATE_SORTING_KEY, viewValue: this.options.START_DATE });
    this.sortingOption.push({ value: TasksConstants.STATUS_SORTING_KEY, viewValue: this.options.STATUS });
    this.sortingOption.push({ value: TasksConstants.CREATED_SORTING_KEY, viewValue: this.options.CREATED });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.onSelect.unsubscribe();
  }

  navigateRoute(title) {
    console.log("[task-filter.component] navigateRoute: " + title);
    if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_STATUS) {
      this.router.navigate(["/task", title]);
    } else if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_TAG) {
      this.router.navigate(["/task/tags", title]);
    } else if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_LIST) {
      this.router.navigate(["/task/list", title]);
    } else {
      this.router.navigate(["/task/location", title]);
    }
  }

  setSortBy(value) {
    this.selectedSortValue = value;
    this.changerDetectorRef.markForCheck();

    this.onSelect.emit(this.selectedSortValue);
  }

  onSelectSortItem() {
    this.onSelect.emit(this.selectedSortValue);
  }

  onOpenTaskCheck(event) {
    this.onOpenCheckClick.emit(event);
  }

  onShowMyTaskCheck(event) {
    this.onShowMyTaskClick.emit(event);
  }

  onCloseTaskCheck(event) {
    this.onCloseCheckClick.emit(event);
  }

  setOpenTaskBy(event) {
    this.openTask = !event;
    this.changerDetectorRef.markForCheck();

    this.onOpenCheckClick.emit(this.openTask);
  }

  setCloseTaskBy(event) {
    this.closeTask = !event;
    this.changerDetectorRef.markForCheck();

    this.onCloseCheckClick.emit(this.closeTask);
  }

  setMyTaskBy(event) {
    this.showMyTask = !event;
    this.changerDetectorRef.markForCheck();

    this.onShowMyTaskClick.emit(this.showMyTask);
  }

  onReloadItem() {
    this.onClick.emit();
  }

  getMenuTrigger() {
    if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_TAG) {
      return this.tagMenu;
    } else if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_STATUS) {
      return this.statusMenu;
    } else if ( this.selectedFilterType === this.taskConstants.ROUTE_TYPE_LIST) {
      return this.listMenu;
    } else {
      return this.locationMenu;
    }
  }
}
