
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id = "mobileSearchPanel" class = "vp-mobile-search task-search-header">
  <button id = "mbSearchCloseBtn" class="search-back-button" mdl-button (click)="close()">
    <mdl-icon class="disable-select">chevron_left</mdl-icon>
  </button>
  <input id = "mbSearchInput" #input type="text" class= "input-search" placeholder="{{ 'SEARCH' | translate}}" [(ngModel)]="query" (keydown.enter)="onSearch();"
  />
  <button id = "mbSearchClearBtn" class="icon-button" mdl-button (click)="clearText();">
    <mdl-icon class="disable-select">clear</mdl-icon>
  </button>
  <button id = "mbSearchFilterBtn" class="icon-button" mdl-button (click)="showAdvancedTaskFilters()">
    <mdl-icon class="disable-select">tunes</mdl-icon>
  </button>
</div>
