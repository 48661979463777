
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="task-detail-info">
  <div class="multi-select-task-header">
    <div class="header-info-div">
      <div class="align-item-center">
        <mdl-icon class="material-icons icons">assignment_turned_in</mdl-icon>
      </div>
      <div id="task-detail-info-selected-count" class="align-item-center">
        <span class="sub-header-text">{{ selectedTasks.length }}  {{ 'SELECTED_TASKS' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="multi-select-body">
    <div class="detail-info-table padding-15">
        <div *ngFor="let task of selectedTasks; let i = index" class="table-row">
          <ng-container *ngIf="i <= 7">
            <div class="multiple-select-tasks">{{ task.subject }}</div>
          </ng-container>
          <ng-container *ngIf="i == 8 && !viewAll">
            <div class="multiple-select-tasks">
                <span>...</span>
                <span class="label-view-all" (click)="viewAll = true;">{{ 'VIEW_ALL' | translate }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="i > 7 && viewAll">
            <div class="multiple-select-tasks">{{ task.subject }}</div>
          </ng-container>
        </div>
    </div>
  </div>
</div>
