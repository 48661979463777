
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy
} from "@angular/core";
import { MdlDialogReference } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile } from "rxjs/operators";


@Component({
  selector: "vp-under-development",
  templateUrl: "./under-development.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderDevelopmentDialogComponent implements AfterViewInit, OnDestroy {
  private viewInitialized = false;
  isSending: boolean;
  param = {};
  private isAlive$ = new Subject<boolean>();
  isAlive = true;

  constructor(private broadcaster: Broadcaster,
              private changeDetectionRef: ChangeDetectorRef,
              private dialog: MdlDialogReference) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(res => {
        this.dialog.hide();
      });
    this.broadcaster.on<any>("closeDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(res => {
        this.dialog.hide();
      });
  }

  ngAfterViewInit() {
    if (!this.viewInitialized) {
      this.viewInitialized = true;

      this.onPushBugHack();
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.isAlive = false;

    if (CommonUtil.isOnMobileDevice() && document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  private onPushBugHack() {
    setTimeout(() => {
      try {
        this.changeDetectionRef.detectChanges();
      } catch (e) {
        console.log("[vp-under-development] onPushBugHack()", e);
      }
    }, 100);
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.dialog.hide();
  }

}
