
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference } from "@angular-mdl/core";
import { Tag } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-tag-option-list",
  template: `
  <div class="task__dialog" id="tagsDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'TAGS' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body tag-option-list_dialog">
  <mdl-textfield class="filter-search-textfield" id="tagsSearchField" [(ngModel)]="searchText"  [label]="'FIND_THE_TAG' | translate"  floating-label></mdl-textfield>
    <mdl-list id="tagsList">
      <mdl-list-item *ngFor="let tag of tagList | vpTaskProjectSearch : searchText; let i = index;"  [class.selected]="i == selectedIndex"  (click)="navigateRoute(tag.name)">
        <mdl-icon>local_offer</mdl-icon>
        <span class="list-item">{{ tag.name }}</span>
        <div class="rounded-rectangle">
            <span>{{ tag.count }}</span>
        </div>
      </mdl-list-item>
    </mdl-list>
  </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TagOptionListComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  tagList: Tag[] = [];
  isOnline: boolean = false;
  searchText: string = "";

  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private dialogRef: MatDialogRef<TagOptionListComponent>
  ) {

    this.setupStore();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideTagOptionListDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });

    this.store
      .select(getTagList)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(tags => {
        if (tags) {
          this.tagList = tags;
          this.changerDetectorRef.markForCheck();
        }
      });
  }

  navigateRoute(title: any) {
    this.hide(title);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(title?) {
    console.log("TagOptionListComponent, hide: ", title);
    this.dialogRef.close({ type: this.taskConstants.ROUTE_TYPE_TAG, title: title});
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
