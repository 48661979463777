
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, EventEmitter, Output, AfterViewInit, OnDestroy } from "@angular/core";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { AppType , getAllAppTypes , getAppType } from "../../../models/app-type";
import { VNCStandardObj } from "../../../models/vnc-standard-obj";
import { SearchRepository } from "../../../repository/search.repository";
import { takeWhile, take } from "rxjs/operators";

@Component({
  selector: "vp-select-apps-dialog",
  templateUrl: "select-apps-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectAppsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  apps: AppType[] = getAllAppTypes();
  selectedApps: AppType[] = getAllAppTypes();
  changed = false;
  mdlFixDone = false;
  @Output() closeDialog = new EventEmitter<boolean>();
  isAlive = true;

  constructor(
    private searchRepo: SearchRepository,
    private broadcaster: Broadcaster,
    private changeDetectionRef: ChangeDetectorRef) {
      this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
        .subscribe(presence => {
          this.close();
        });
      this.broadcaster.on<any>("hideTaskSelectAppsDialog").pipe(takeWhile(() => this.isAlive))
        .subscribe(presence => {
          this.close();
        });
  }

  ngOnInit() {
    this.searchRepo.selectSavedApps().pipe(take(1)).subscribe((selectedApps) => {
      this.selectedApps = selectedApps;
      this.changeDetectionRef.markForCheck();
    });
  }

  close() {
    this.closeDialog.emit(true);
  }

  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      } , 100);
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }

  getStandardObj(app: AppType): VNCStandardObj {
    return getAppType(app);
  }

  appSelected(app: AppType) {
    const exists = this.selectedApps.indexOf(app) !== -1;

    if (!exists) {
      this.selectedApps.push(app);
    } else {
      this.selectedApps = this.selectedApps.filter(a => a !== app);
    }
    this.changed = true;
  }

  isAppSelected(app: AppType) {
    return this.selectedApps.indexOf(app) !== -1;
  }

  saveApps() {
    this.searchRepo.saveAppList(this.selectedApps);
    this.close();
  }
}
