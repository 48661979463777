
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="task-header-menubar-div" class="task-header-menubar" [class.notask]="totalCount === 0" [class.task-header-menubar-device]="selectedTasksIds.length > 0">
    <div class="icons__holder">
        <div id="task-header-menubar-close" class="clear_icon_device" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.ClearAll })">
            <mdl-icon>close</mdl-icon>
        </div>
        <span class="filtersortmenu">
            <mdl-checkbox id="task-header-menubar-selectall" [(ngModel)]="selectAll" (click)="menuItemClick.emit({ event:selectAll, type: bulkUpdateIssueType.MarkAll })"></mdl-checkbox>
        </span>
    </div>
    <div id="task-header-menubar-selectedcount" class="header-menubar-selected-count disable-select" *ngIf="selectedTasksIds.length > 0">
        {{selectedTasksIds.length}} {{ 'TASK_SELECTED' | translate}}
    </div>
    <div class="icons__holder" *ngIf="selectedTasksIds.length > 0">
        <div id="task-header-menubar-complete" class="icons__holder_icon" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Complete })">
            <mdl-icon [mdl-tooltip-large]="completeTooltip" mdl-tooltip-position="top">check_circle</mdl-icon>
            <mdl-tooltip #completeTooltip="mdlTooltip">
              {{ 'MARK_AS_COMPLETED' | translate }}
            </mdl-tooltip>
        </div>
        <div id="task-header-menubar-clear" class="icons__holder_icon clear_icon_desktop" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.ClearAll })">
            <mdl-icon [mdl-tooltip-large]="clearTooltip" mdl-tooltip-position="top">close</mdl-icon>
            <mdl-tooltip #clearTooltip="mdlTooltip">
              {{ 'CLEAR_SELECTION' | translate }}
            </mdl-tooltip>
        </div>
        <div *ngIf="screen === 'desktop'" id="task-header-menubar-duplicate" class="icons__holder_icon" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Duplicate })">
            <mdl-icon [mdl-tooltip-large]="duplicateTooltip" mdl-tooltip-position="top">content_copy</mdl-icon>
            <mdl-tooltip #duplicateTooltip="mdlTooltip">
              {{ 'DUPLICATE_TASK' | translate }}
            </mdl-tooltip>
        </div>
        <div id="task-header-menubar-delete" class="icons__holder_icon" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Delete })">
            <mdl-icon [mdl-tooltip-large]="deleteTooltip" mdl-tooltip-position="top">delete</mdl-icon>
            <mdl-tooltip #deleteTooltip="mdlTooltip">
              {{ 'DELETE_TASKS' | translate }}
            </mdl-tooltip>
        </div>
        <div id="task-header-menubar-more" class="icons__holder_icon" #bottomRightButton (click)="taskContextPopover.toggle($event,bottomRightButton)">
            <mdl-icon>more_vert</mdl-icon>
        </div>
    </div>
    <mdl-popover id="task-header-menubar-popover" #taskContextPopover mdl-popover-position="bottom-right" [hide-on-click]="true">
      <div class="task-context-menu-popover">
          <mdl-list>
              <mdl-list-item *ngIf="screen !== 'desktop'" id="task-header-menubar-option-duplicate" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Duplicate })">
                  <mdl-icon>content_copy</mdl-icon> {{'DUPLICATE_TASK'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-priority" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Priority })">
                  <mdl-icon>assignment_late</mdl-icon> {{'CHANGE_PRIORITY'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-repeat" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Recurring })">
                  <mdl-icon>autorenew</mdl-icon> {{'CHANGE_REPEAT_PATTERN'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-startdate" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.StartDate })">
                  <mdl-icon>today</mdl-icon>{{'CHANGE_START_DATE'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-duedate" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.DueDate })">
                  <mdl-icon>event</mdl-icon>{{'CHANGE_DUE_DATE'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-assigned" *ngIf="isChangeUserOptionVisible || (authUser ? (authUser.team_user === 'false'): true)" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.AssignedTo })">
                  <mdl-icon>perm_identity</mdl-icon> {{'CHANGE_ASSIGNED_USER'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-project" *ngIf="projectList.length > 1" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Project })">
                  <mdl-icon>work</mdl-icon>{{'CHANGE_ASSIGNED_PROJECT'| translate}} </mdl-list-item>
              <mdl-list-item id="task-header-menubar-list" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.List })">
                  <mdl-icon>assignment</mdl-icon>{{'CHANGE_LISTS'| translate}} </mdl-list-item>
              <mdl-list-item id="task-header-menubar-tag" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Tags })">
                  <mdl-icon>local_offer</mdl-icon> {{'CHANGE_TAGS'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-location" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Location })">
                   <mdl-icon>place</mdl-icon> {{'CHANGE_LOCATION'| translate}}</mdl-list-item>
              <mdl-list-item id="task-header-menubar-location" *ngIf="isChangeUserOptionVisible" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Watchers })">
                    <mdl-icon>visibility</mdl-icon> {{'ADD_WATCHERS'| translate}}</mdl-list-item>
          </mdl-list>
      </div>
  </mdl-popover>
</div>
