
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Component, OnDestroy, ElementRef, ViewChild, Input } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { TasksRootState, getMyTeamUsers, getAuthUser } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference, MdlDialogService, MdlSnackbarService } from "@angular-mdl/core";
import { TeamUser, AuthUser } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { Subject } from "rxjs";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../../task/shared/task-enum";
import { CommonUtil } from "../../../common/utils/common.utils";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { takeWhile, debounceTime } from "rxjs/operators";

@Component({
  selector: "vp-archive-users",
  template: `
  <div class="task__dialog vp-archive-users-dialog">
  <div *ngIf="!isSearchMode" class="task__dialog-header brand-color mdl-shadow--2dp">
    <div class="task__dialog-mobile-back-button">
      <button (click)="hide()" class="brand-color mdl-button">
        <mdl-icon>chevron_left</mdl-icon>
      </button>
    </div>
    <div class="task__dialog-desktop-close-button">
      <button (click)="hide()" class="brand-color mdl-button">
        <mdl-icon class="material-icons">clear</mdl-icon>
      </button>
    </div>
    <div class="task__dialog-mobile-save-button button-icon">
      <button class="brand-color mdl-button" (click)="openSearch()">
        <mdl-icon>search</mdl-icon>
      </button>
    </div>
    <h4>{{ 'ARCHIVED_USERS' | translate}}</h4>
  </div>
  <div *ngIf="isSearchMode" class = "vp-mobile-search task-search-header">
    <button id = "mbSearchCloseBtn" class="search-back-button" mdl-button (click)="isSearchMode=false;">
      <mdl-icon class="disable-select">chevron_left</mdl-icon>
    </button>
    <input id = "mbSearchInput" #input type="text" class= "input-search" placeholder="{{ 'SEARCH' | translate}}" [(ngModel)]="query" (keyup)="onSearch();"/>
    <button id = "mbSearchClearBtn" class="icon-button" mdl-button (click)="clearText();">
      <mdl-icon class="disable-select">clear</mdl-icon>
    </button>
  </div>
  <div class="task__dialog-body">
    <div class="option-list" [class.searchmode]="isSearchMode">
    <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'SEARCH_USERS' | translate"  floating-label></mdl-textfield>
      <mdl-list>
        <mdl-list-item
          *ngFor="let teamUser of searchTeamUserList  | vpTaskUserSearch : searchText;">
          <div  class="task-icon-avtar disable-select" *ngIf ="!teamUser.avatar">{{ (teamUser) ? teamUser.firstLastNameCharacter : ('NO_NAME' | translate).charAt(0) }}</div>
          <div class="task-comment-avtar disable-select" *ngIf = "teamUser.avatar">
              <img img-cache [src] = "teamUser.avatar"/>
          </div>
          <span class="username-place disable-select">{{ (teamUser) ? teamUser.name : ('NO_NAME' | translate) }}</span>
          <mdl-icon class="disable-select" (click)="unArchiveUser(teamUser)">unarchive</mdl-icon>
        </mdl-list-item>
      </mdl-list>
    </div>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ArchiveUsersComponent implements OnDestroy {
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  isOnline: boolean = false;
  searchText: string = "";
  isSearchMode: boolean = false;
  teamUserList: TeamUser[] = [];
  searchTeamUserList: TeamUser[] = [];
  query: string = "";
  debouncer: Subject<any> = new Subject();
  isLoading: boolean = false;
  selectedTeamUserCount: number;
  @ViewChild("input", { static: false }) input: ElementRef;
  authUser: AuthUser;
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private dialog: MdlDialogReference,
    private dialogService: MdlDialogService,
    private mdlSnackbarService: MdlSnackbarService,
    private successService: SuccessService,
    private errorService: ErrorService,
    private messageTranslatorService: MessageTranslatorService
  ) {
    this.setupStore();
    this.handleUnArchiveUserErrorMessages();
    this.handleUnArchiveUserSuccessMessages();
    this.handleUserManagementErrorMessages();
    this.handleUserManagementSuccessMessages();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.broadcaster.on<any>("hideArchiveUsersDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.focus();
    this.changerDetectorRef.markForCheck();

    if (!CommonUtil.isOnMobileDevice()) {
      if (this.isOnline) {
        this.isLoading = true;
      }
      this.taskRepo.syncMyTeamUsers();
      this.taskRepo.syncAuthUser();
    }
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getMyTeamUsers).pipe(takeWhile(() => this.isAlive)).subscribe((teamUsers) => {
      teamUsers = teamUsers.filter( teamUsers => teamUsers.archived === true);
      this.teamUserList = teamUsers;
      this.searchTeamUserList = teamUsers;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.debouncer.pipe(debounceTime(1000)).subscribe( value => {
      this.searchTeamUser();
    });
  }

  focus() {
    setTimeout(() => {
      if (this.input && this.input.nativeElement) {
        this.input.nativeElement.focus();
        this.changerDetectorRef.markForCheck();
      }
    }, 1000);
  }

  handleUserManagementErrorMessages() {
    this.errorService.only(ErrorType.TeamUserListError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleUserManagementSuccessMessages() {
    this.successService.only(SuccessType.TeamUserListSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleUnArchiveUserErrorMessages() {
    this.errorService.only(ErrorType.UnArchiveUserError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleUnArchiveUserSuccessMessages() {
    this.successService.only(SuccessType.UnArchiveUserSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        this.selectedTeamUserCount + " " + success.messages
      );
      this.isLoading = false;
      this.taskRepo.syncMyTeamUsers();
      this.taskRepo.syncAuthUser();
      this.changerDetectorRef.markForCheck();
    });
  }

  openSearch() {
    this.isSearchMode = true;
    this.focus();
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialog.hide();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.debouncer.unsubscribe();
    this.changerDetectorRef.markForCheck();
  }

  clearText() {
    this.query = "";
    this.changerDetectorRef.markForCheck();
  }

  onSearch() {
    this.debouncer.next(true);
  }

  searchTeamUser() {
    if (this.query) {
      this.searchTeamUserList = this.teamUserList.filter(user => user.name.toLowerCase().indexOf(this.query.toLocaleLowerCase()) === 0 || user.mail.toLowerCase().indexOf(this.query) === 0);
      this.changerDetectorRef.markForCheck();
    } else {
      this.searchTeamUserList = this.teamUserList;
      this.changerDetectorRef.markForCheck();
    }
  }

  unArchiveUser(teamUser) {
    if (this.isOnline) {
      if (teamUser && this.authUser.can_invite_users === "true") {
        let userIds: number[] = [];
        this.selectedTeamUserCount = 1;
        userIds.push(teamUser.id);
        this.isLoading = true;
        this.taskRepo.unArchiveUsers(userIds);
      } else {
        this.mdlSnackbarService.showToast(
          this.messageTranslatorService.getMessage(TasksConstants.REACHED_LIMIT_FOR_ACTIVE_USER)
        );
      }
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }
}
