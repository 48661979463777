
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, AfterViewInit, HostListener, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { FileUpload } from "../../models";
import { environment } from "../../../../environments/environment";
import { AudioService, MessageTranslatorService } from "../../services";
import { TaskRecordVoiceMsgDialogComponent } from "../task-record-voice-msg-dialog/task-record-voice-msg-dialog.component";
import { Subject } from "rxjs";
import { TasksConstants } from "../../shared/task-constacts";
import { ErrorService } from "../../../common/providers/error-service";
import { ErrorType } from "../../shared/task-enum";
import { takeWhile, takeUntil } from "rxjs/operators";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-task-attachment-option-dialog",
  templateUrl: "task-attachment-option-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskAttachmentOptionDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  isAlive = true;
  writeExternalPermission = localStorage.getItem("write_external_permission");

  constructor(private broadcaster: Broadcaster,
    private audioService: AudioService,
    private dialogService: MdlDialogService,
    private changerDetectorRef: ChangeDetectorRef,
    private messageTranslatorService: MessageTranslatorService,
    private errorService: ErrorService,
    private dialogRef: MatDialogRef<TaskAttachmentOptionDialogComponent>,
    private matDialog: MatDialog
  ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideTaskAttachmentOptionDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  fileUploadListener($event) {
    let file: File = $event.target.files[0];
    let myReader: FileReader = new FileReader();
    let self = this;
    if ( file.size > TasksConstants.FILE_UPLOAD_LIMIT) {
      this.broadcaster.broadcast("OnFileLimitExcededError");
      this.changerDetectorRef.markForCheck();
    } else {
      myReader.onloadend = (loadEvent: any) => {
        let upload: FileUpload = {
          filename: file.name,
          content_type: file.type,
          content: myReader.result
        };
        this.broadcaster.broadcast("OnAddAttachItem", {data: upload });
        this.changerDetectorRef.markForCheck();
      };
      myReader.readAsArrayBuffer(file);
    }
    this.hide();
  }

  capturePhoto() {
    if (environment.isCordova) {
      this.checkCameraPermission();
      // capture callback
      let captureSuccess = (mediaFiles) => {
        this.mediaFilesCallBackSuccess(mediaFiles);
      };
      // capture error callback
      let captureError = (error) => {
        console.log(error);
      };
      // start image capture
      navigator.device.capture.captureImage(captureSuccess, captureError, {});
      this.hide();
    } else {
      document.getElementById("custom-photo-input").click();
    }
  }

  makeVideo() {
    if (environment.isCordova) {
      this.checkCameraPermission();
      // capture callback
      let captureSuccess = (mediaFiles) => {
        this.mediaFilesCallBackSuccess(mediaFiles);
      };
      // capture error callback
      let captureError = (error) => {
        console.log(error);
      };

      // start video capture
      navigator.device.capture.captureVideo(captureSuccess, captureError, { duration: 300 });
      this.hide();
    } else {
      document.getElementById("custom-video-input").click();
    }

  }

  mediaFilesCallBackSuccess(mediaFiles) {
    let i, path, len, mediaFile;
    let self = this;
    for (i = 0, len = mediaFiles.length; i < len; i += 1) {
        window.resolveLocalFileSystemURL(mediaFiles[i].localURL , (fileEntry: any) => {
          fileEntry.file((file) => {
            if ( file.size > TasksConstants.FILE_UPLOAD_LIMIT) {
              this.broadcaster.broadcast("OnFileLimitExcededError");
              this.changerDetectorRef.markForCheck();
            } else {
              let myReader: FileReader = new FileReader();
              myReader.onloadend = (loadEvent: any) => {
              let upload: FileUpload = {
                  filename: file.name,
                  content_type: file.type,
                  content: myReader.result
              };
              this.broadcaster.broadcast("OnAddAttachItem", {data: upload });
              this.changerDetectorRef.markForCheck();
              };
              myReader.readAsArrayBuffer(file);
            }
          }, (e) => {
            console.error(e);
          });
        }, (e) => {
          console.error(e);
        });
    }
  }

  checkCameraPermission() {
    if (environment.isCordova) {
      if (device.platform === "Android") {
        let permissions = cordova.plugins.permissions;
        let checkVideoPermissionCallback = function (status) {
          if (!status.hasPermission) {
            let errorCallback = function () {
              console.log("[task-attachment.component]  Camera permission is not turned on");
            };
            permissions.requestPermission(
              permissions.CAMERA,
              function (s) {
                if (!s.hasPermission) {
                  errorCallback();
                }
              },
              errorCallback);
          }
        };

        let checkWriteExternalStoragePermissionCallback = function (status) {
          if (!status.hasPermission) {
            let errorCallback = function () {
              console.log("[task-attachment.component] write external storage permission is not turned on");
            };
            permissions.requestPermission(
              permissions.WRITE_EXTERNAL_STORAGE,
              function (s) {
                if (!s.hasPermission) {
                  errorCallback();
                }
              },
              errorCallback);
          }
        };
        permissions.checkPermission(permissions.CAMERA, checkVideoPermissionCallback, null);
        permissions.checkPermission(permissions.WRITE_EXTERNAL_STORAGE, checkWriteExternalStoragePermissionCallback, null);
      }
    }
  }

  openRecordVoiceDialog() {
    if (typeof device !== "undefined" && device.platform && device.platform.toLowerCase() === "ios") {
      this.audioService.startRecording();
      this.audioService.getRecordedAudio().pipe(takeUntil(this.isAlive$)).subscribe(res => {
        console.log("[Audio] Recorded Audio", res);
        let upload: FileUpload = {
          content: res.content,
          filename: res.title,
          content_type: res.type
        };
        this.broadcaster.broadcast("OnAddAttachItem", { data: upload });
        this.changerDetectorRef.markForCheck();
        this.hide();
      });
      this.audioService.isRecordingFailed().pipe(takeUntil(this.isAlive$)).subscribe(res => {
        console.log("[Audio] Audio Recording Failed" , res);
        this.hide();
      });
    } else {
      const dlg = this.matDialog.open(TaskRecordVoiceMsgDialogComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "vnctaskrecordvoicemsgdialog"
      });
      this.hide();
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
