
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfigService } from "./common/providers/config.service";
import { Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule, AuthGuard } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./common/providers/auth.service";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { environment } from "../environments/environment";
import { SharedModule } from "./shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreModule } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { reducers, reset } from "./reducers";
import { CommonUtil } from "./common/utils/common.utils";
import { AvailableRoutes } from "./common/providers/route.guards";
import { FormsModule } from "@angular/forms";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { SuccessService } from "./common/providers/success-service";
import { ErrorService } from "./common/providers/error-service";
import { LocalStorageService } from "./task/services/local-storage.service";
import { DatabaseService } from "./task/services/database.service";
import { FilesStorageService } from "./task/services/files-storage.service";
import { ResponsiveService } from "./common/providers/responsive.service";
import { TaskService } from "./task/task.service";
import { MessageTranslatorService, HeaderService } from "./task/services";
import { MdlModule } from "@angular-mdl/core";
import { ChangeServerDialogComponent } from "./task/components/change-server-dialog/change-server-dialog.component";
import { RegistrationFormComponent } from "./task/components/registration/registration-form/registration-form.component";
import { RegistrationActivationComponent } from "./task/components/registration/registration-activation/registration-activation.component";
import { RegistrationLinkExpiredComponent } from "./task/components/registration/registration-link-expired/registration-link-expired.component";
import { ForgotPasswordFormComponent } from "./task/components/forgot-password/forgot-password-form/forgot-password-form.component";
import { ForgotPasswordInfoComponent } from "./task/components/forgot-password/forgot-password-info/forgot-password-info.component";
import { ResetPasswordFormComponent } from "./task/components/forgot-password/reset-password-form/reset-password-form.component";
import { TaskRepository } from "./task/repository/task.repository";
import { TaskNotificationsService } from "./task/components";
import { InstallAppDialogComponent } from "./shared/components/install-app/install-app.component";
import { LocaleService } from "./common/providers/locale.service";
import { ElectronService } from "./task/services/electron.service";
import { VNCActionWheelMenuService } from "vnc-library";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        HttpModule,
        SharedModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule.forRoot(),
        StoreModule.forRoot(reducers, { metaReducers: [reset], runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            } }),
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    ],
    declarations: [
        AppComponent,
        ChangeServerDialogComponent,
        RegistrationFormComponent,
        RegistrationActivationComponent,
        RegistrationLinkExpiredComponent,
        ForgotPasswordFormComponent,
        ForgotPasswordInfoComponent,
        ResetPasswordFormComponent,
        InstallAppDialogComponent
    ],
    providers: [
        AuthService,
        ConfigService,
        AuthGuard,
        AvailableRoutes,
        Broadcaster,
        ErrorService,
        SuccessService,
        LocalStorageService,
        DatabaseService,
        FilesStorageService,
        ResponsiveService,
        TaskService,
        MessageTranslatorService,
        TaskRepository,
        TaskNotificationsService,
        HeaderService,
        LocaleService,
        ElectronService,
        VNCActionWheelMenuService
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, CommonUtil.getBaseUrl() + "/assets/i18n/", ".json");
}
