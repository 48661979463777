
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { TaskHelpAndFaqDialogComponent } from "../task-help-faq-dialog/task-help-faq-dialog.component";
import { TaskAboutusDialogComponent } from "../task-aboutus-dialog/task-aboutus-dialog.component";
import { TaskServiceDeskDialogComponent } from "../task-service-desk-dialog/task-service-desk-dialog.component";
import { TaskLegalNoticeDialogComponent } from "../task-legal-notice-dialog/task-legal-notice-dialog.component";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef,MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-task-settings-dialog",
  templateUrl: "task-settings-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskSettingsDialogComponent implements OnDestroy{
  isAlive = true;
  constructor(
    private broadcaster: Broadcaster,
    private dialogRef: MatDialogRef<TaskSettingsDialogComponent>,
    private dialogService: MdlDialogService,
    private matDialog: MatDialog ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.broadcaster.on<any>("hideTaskSettingsDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }


  openAboutDialog(): void {
    const aDialog = this.matDialog.open(TaskAboutusDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaboutdialog",
    });
  }

  openHelpFaqDialog(): void {
    const aDialog = this.matDialog.open(TaskHelpAndFaqDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskhelpfaqdialog",
    });
  }

  openServiceDeskDialog(): void {
    const aDialog = this.matDialog.open(TaskServiceDeskDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskservicedialog",
    });
  }

  openLegalNoticeDialog(): void {
    const aDialog = this.matDialog.open(TaskLegalNoticeDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctasksettingsdialog",
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
