
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Inject } from "@angular/core";
import { MdlDialogReference } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: "vp-task-datepicker",
  templateUrl: "task-date-picker.component.html"
})
export class TaskDatePickerComponent implements OnDestroy {
  dateTime: any;
  header: any;
  rangeStart: any;
  constructor(
    private dialogRef: MatDialogRef<TaskDatePickerComponent>,
    private changerDetectorRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.header = this.data.header;
    this.rangeStart = this.data.rangeStart;
    this.broadcaster.on<any>("closeAllMdlDialogs").subscribe(presence => this.dialogRef.close());
  }

  ngOnDestroy(): void {
  }

  @HostListener("document:keydown.esc")
  hide(date?): void {
    this.dialogRef.close(date);
  }

  setDate() {
    if (this.dateTime) {
      this.hide(this.dateTime);
    }
  }
}
