
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="assign_tag_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <mdl-icon>local_offer</mdl-icon>
  <p>{{ 'TAGS' | translate }}</p>
</div>

<div id="assign_tags_option_list" class="option-list add-tags-dialog" *ngIf="active">
  <div class="tag-header">
    <span>{{ 'TAGS' | translate }}:</span>
    <div class="icons-div">
      <mdl-icon class="icon-close" (click)="backToMain()">clear</mdl-icon>
      <mdl-icon class="icon-check" (click)="setTags()">check_circle</mdl-icon>
    </div>
  </div>
  <div class="tags-list-view">
    <div class="add-tag-div">
        <textarea class="filter-search-textfield add-tag-input ng-dirty" [(ngModel)]="tagText" placeholder="{{ 'ADD_TAG' | translate }}"
            (keydown.enter)="addTag($event);false" (keydown.space)="false" id="task-edit-add-tag-textfield" rows="1" minlength="2" (input)="searchTags($event)" #tagInput [matAutocomplete]="tag">
        </textarea>
        <mdl-icon *ngIf="(tagText ? tagText.length >= 2 : false)" class="add-tag-check" (click)="addTag($event)">add_circle_outline</mdl-icon>
    </div>
    <span class="tag-msg-span">{{ 'ADD_TAG_MSG' | translate }}</span>
    <mat-autocomplete class="tag-list" #tag="matAutocomplete" [displayWith]="displayValue.bind(this)">
        <mat-option *ngFor="let tag of searchTagsList" [value]="tag.name">
            <span>{{ (tag) ? tag.name : ('NO_NAME' | translate) }}</span>
        </mat-option>
    </mat-autocomplete>
  </div>
  <div class="tags-list-view">
    <span *ngFor="let tag of tagsList" class="tag-list-item">
      <span class="edit-input-tag-name">{{tag.name}}</span>
      <i class="material-icons" (click)="removeTag(tag)">close</i>
    </span>
  </div>
</div>
