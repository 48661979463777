
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="registration" [style.backgroundImage]="backgroundImage">
    <div class="registration-activation">
        <div class="title">{{ 'ACTIVATION' | translate }}</div>
        <div class="message-body">
            <div class="activation-message">
                {{ 'ACTIVATION_MESSAGE' | translate }}
            </div>
            <div class="activation-note">
                {{ 'ACTIVATION_NOTE' | translate }}
            </div>
        </div>
        <div class="login-button">
            <button (click)="returnToLoginPage()">{{ 'RETURN_TO_LOGIN_PAGE' | translate }}</button>
        </div>
        <div class="resend-link">
            <span class="disable-select" (click)="resendActivationLink()">{{ 'RESEND_ACTIVATION_LINK' | translate }}</span>
        </div>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
