
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy ,
  ChangeDetectorRef ,
  Component ,
  HostListener ,
  Inject ,
  InjectionToken ,
  OnDestroy ,
  OnInit,
  ElementRef
} from "@angular/core";
import { MdlDialogReference, MdlDialogService, MdlButtonComponent, MdlSnackbarService } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Store } from "@ngrx/store";
import { DomSanitizer , SafeUrl } from "@angular/platform-browser";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { CommonUtil, MediaType } from "../../../common/utils/common.utils";
import { TaskAttachment } from "../../models/task-attachment";
import { isNullOrUndefined } from "util";
import { UnderDevelopmentDialogComponent } from "../under-development/under-development.component";
import { TaskRepository } from "../../repository/task.repository";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { TasksConstants } from "../../shared/task-constacts";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { dialogType } from "../../models/dialog-type";
import { ConfigService } from "../../../common/providers/config.service";
import { FilesStorageService } from "../../services/files-storage.service";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
export const MEDIA_ATTACHMENT = new InjectionToken<TaskAttachment>("attachment");
export const MEDIA_TYPE = new InjectionToken<string>("mediaType");
export const MEDIA_CONTENT = new InjectionToken<TaskAttachment>("content");

@Component({
  templateUrl: "./media-preview.html" ,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPreviewDialogComponent implements OnInit , OnDestroy {
  url: any;
  MediaType = MediaType;
  attachmentContent: Blob;
  attachment: TaskAttachment;
  selectedMediaType: string;
  dialogType = dialogType;
  private isAlive$ = new Subject<boolean>();
  CommonUtil = CommonUtil;
  isAlive = true;
  isLoading = false;
  placeholder = "url(assets/background-checker.png)";
  constructor(private broadcaster: Broadcaster,
              private sanitizer: DomSanitizer,
              private el: ElementRef,
              private dialog: MdlDialogReference,
              private dialogService: MdlDialogService,
              private changeDetectionRef: ChangeDetectorRef,
              private taskRepo: TaskRepository,
              private messageTranslatorService: MessageTranslatorService,
              private mdlSnackbarService: MdlSnackbarService,
              private configService: ConfigService,
              private fileStorageService: FilesStorageService,
              @Inject(MEDIA_ATTACHMENT) public selectedAttachment: TaskAttachment,
              @Inject(MEDIA_TYPE) public selectedType: string,
              @Inject(MEDIA_CONTENT) public content: Blob,
              private matDialog: MatDialog) {
    this.attachment = selectedAttachment;
    this.selectedMediaType = selectedType;
    this.attachmentContent = content;
    this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(content));
    this.changeDetectionRef.markForCheck();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideMediaPreviewDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  @HostListener("document:keydown.esc")
  close(value?): void {
    this.dialog.hide(value);
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (CommonUtil.isOnMobileDevice() && document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  ngOnInit() {
    console.log("[MediaPreviewDialogComponent]", this.el);
    const checkDialog = setInterval(() => {
      if (document.querySelector(".mdl-dialog.open") !== null) {
        if (CommonUtil.isOnMobileDevice()) {
          this.el.nativeElement.parentElement.style.backgroundColor = "black";
          this.el.nativeElement.parentElement.style.marginTop = "0";
        } else {
          this.el.nativeElement.parentElement.style.backgroundColor = "transparent";
          this.el.nativeElement.parentElement.style.marginTop = "-450px";
          this.el.nativeElement.parentElement.style.transform = "none";
          this.el.nativeElement.parentElement.style.top = "50%";
          this.el.nativeElement.parentElement.style.boxShadow = "none";
        }
        clearInterval(checkDialog);
      }
    });
  }

  shareAttachment() {
    if (environment.isCordova) {
      this.sharePhoto();
    } else {
      this.underDevelopment();
    }
  }

  isDeletable() {
    return this.attachment.deletable;
  }

  deleteAttachment() {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + this.attachment.filename + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.close(true);
      }
    });
  }

  downloadAttachment() {
    const isImage = CommonUtil.getMediaType(this.attachment.filename) === this.MediaType.VIDEOS;
    const isVideo = CommonUtil.getMediaType(this.attachment.filename) === this.MediaType.IMAGE;

    if (CommonUtil.isOnIOS() && !(isImage || isVideo)) {
      this.mdlSnackbarService.showToast(
        this.messageTranslatorService.getMessage(TasksConstants.DOWNLOAD_NOT_IMAGEVIDEO_ATTACHMENT_NOT_PERMITTED_IOS)
      );
      return;
    }

    this.taskRepo.downloadFileFromBlob(this.content, this.attachment.filename);
  }

  underDevelopment() {
    // delete and share
    let dialogStyles: any = {
      "width": "320px"
    };
    if (CommonUtil.isOnMobileDevice()) {
      dialogStyles = {
        "width": "70%",
        "min-width": "70%",
        "max-width": "70%",
        "height": "auto"
      };
    }
    this.dialogService.showCustomDialog({
      component: UnderDevelopmentDialogComponent ,
      providers: [],
      isModal: true ,
      styles: dialogStyles ,
      animate: false ,
      clickOutsideToClose: false
    });
  }

  get isOnMobile() {
    return CommonUtil.isOnMobileDevice();
  }


  getFileName(): string {
    if (!isNullOrUndefined(this.attachment)) {
      return this.attachment.filename;
    }
  }

  getTimeAgo(): string {
    return CommonUtil.getTimeAgoWithLocale(this.attachment.created_on, this.configService.language);
  }

  getSenderName() {
    return this.attachment.author.name;
  }

  showDropdown($event: Event, menu: any, button: MdlButtonComponent, otherMenuClass: string): void {
    // If we use type MdlMenuComponent then we cannot access other properties not defined
    // So we use any here
    if (document.querySelector(`.${otherMenuClass} .show-menu`)) {
      document.querySelector(`.${otherMenuClass} .show-menu`).classList.remove("show-menu");
    }
    if (!menu.container.classList.contains("show-menu")) {
      menu.show($event, button);
      menu.container.classList.add("show-menu");
    } else {
      menu.hide();
      menu.container.classList.remove("show-menu");
    }
    this.changeDetectionRef.markForCheck();
  }

  sharePhoto(): void {

    if (environment.isCordova) {
      this.isLoading = true;
    }

    this.fileStorageService.saveBlobToDisc(this.content, this.attachment.filename).subscribe( localFileUrl => {
      console.log("[sharePhoto]", localFileUrl);
      let options = {
        message: "Share file",
        files: [localFileUrl],
        chooserTitle: "Pick an app",
      };

      const onSuccess = (result) => {
        console.log("Shareed? " + result.completed, result.app);
        this.isLoading = false;
        this.changeDetectionRef.detectChanges();
      };

      const onError = (msg) => {
        console.log("Sharing failed with message: " + msg);
        this.isLoading = false;
        this.changeDetectionRef.detectChanges();
      };

      window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    }, err => {
      this.isLoading = false;
      this.changeDetectionRef.detectChanges();
      console.log("[sharePhoto] unable to get local file url", err);
    });
  }
}
