
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { TaskOptionComponent, TaskAddTagsOptionComponent, TaskAddWatchersOptionComponent } from "../task-options";
import { Subject } from "rxjs";
import { CommonUtil } from "../../../../common/utils/common.utils";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "vp-vnctask-compose-input",
  templateUrl: "task-compose-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskComposeInputComponent implements OnInit, OnDestroy {
  @Output() onActiveComponentChange = new EventEmitter();
  @Output() onValueChange = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Input() optionComponents: TaskOptionComponent[];
  @Output() onRemoveTag = new EventEmitter();
  @Output() onRemoveWatcher = new EventEmitter();
  @ViewChild("composeNewTask", { static: false }) composeInput: ElementRef;
  @Input() isVisible: boolean = false;
  subjectLines = 1;
  activeComponent: TaskOptionComponent;
  _window = window;
  _document = document;
  isOnMobileDevice = CommonUtil.isOnMobileDevice();
  triggerKey: string = "";
  lastValue: string = "";
  keyCodes = {
    backspace: 8,
    arrowUp: 40,
    arrowDown: 38,
    enter: 13,
    arrowBack: 37,
    arrowNext: 39,
    delete: 46
  };
  _onKeyUp;
  _navigateOptionsOnKeyDown;
  _changeFocusOnKeyDown;
  _onKeyPress;


  // a flag to determine if the enter was pressed
  // to select an option or to create a task
  selectFlag: boolean = false;
  debouncer: Subject<any> = new Subject();
  constructor(private elementRef: ElementRef, private changerDetectorRef: ChangeDetectorRef) {
    this._onKeyUp = this.onKeyUp.bind(this);
    this._navigateOptionsOnKeyDown = this.navigateOptionsOnKeyDown.bind(this);
    this._changeFocusOnKeyDown = this.changeFocusOnKeyDown.bind(this);
    this._onKeyPress = this.eventOnKeyPress.bind(this);
  }

  ngOnInit() {
    this.attachHandlers();
    setTimeout(() => {
      this.composeInput.nativeElement.focus();
    }, 0);

    this.debouncer.pipe(debounceTime(1000)).subscribe( value => {
      this.onAdd.emit();
    });
  }

  // add handler to change focus between contenteditable divs to all
  // but only add the handler to trigger the key events to the last one
  attachHandlers() {
    const inputs = this.elementRef.nativeElement.querySelectorAll("[contenteditable]");
    [].forEach.call(inputs, (input) => {
      input.removeEventListener("keydown", this._navigateOptionsOnKeyDown);
      input.removeEventListener("keyup", this._onKeyUp);
      input.removeEventListener("keydown", this._changeFocusOnKeyDown);
      input.removeEventListener("keypress", this._onKeyPress);
      input.addEventListener("keypress", this._onKeyPress);
      input.addEventListener("keydown", this._changeFocusOnKeyDown);
      input.addEventListener("keyup", this._onKeyUp);
    });
    let input = inputs[inputs.length - 1];
    input.removeEventListener("keypress", this._onKeyPress);
    input.addEventListener("keydown", this._navigateOptionsOnKeyDown);
    // input.focus();
  }

  // if the options are being displayed after the trigger key is set
  // navigate through them when navigation keys are pressed inside the input div.
  // pass the events to the relevant component only!
  navigateOptionsOnKeyDown($event) {
    if ($event.keyCode === this.keyCodes.enter)
      $event.preventDefault();

    if (this.activeComponent) {
      switch ($event.keyCode) {
        case this.keyCodes.enter:
          this.activeComponent.setValue();
          let value = this.activeComponent.getValue();
          if (value) {
            this.insertTag(value, $event.target);
            this.selectFlag = true;
          }
          else this.activeComponent.validate();
          break;
        case this.keyCodes.arrowDown:
          $event.preventDefault();
          this.activeComponent.navigate("down");
          break;
        case this.keyCodes.arrowUp:
          $event.preventDefault();
          this.activeComponent.navigate("up");
          break;
      }
    }
  }

  eventOnKeyPress($event) {
    if ($event.keyCode === this.keyCodes.enter) {
      $event.preventDefault();
      if (this.selectFlag) {
        this.selectFlag = false;
        return;
      }
      if (!this.selectFlag) return this.onAddTask();
    }
  }

  changeFocusOnKeyDown($event) {
    let pos = this.getCursorPos($event.target);

    // if the cursor is at the start and back arrow is pressed,
    // go to the previous contentedtiable div skipping any tag in between
    if (pos.atStart && $event.keyCode === this.keyCodes.arrowBack) {
      return this.placeCaretAtEnd(this.getPreviousInput($event.target));
    }

    // if the cursor is at the start AND backspace is pressed,
    // remove the input div itself and move back to the previous one.
    // If there's a tag in the way, remove that as well!
    if (pos.atStart && $event.keyCode === this.keyCodes.backspace) {
      let input = this.getPreviousInput($event.target);
      let tag = this.getTagBehind($event.target);
      if (tag) {
        let component = this.optionComponents.find(component => component.id === tag.id);
        let container = this.getNextInput(tag);
        if ( component instanceof TaskAddTagsOptionComponent) {
          this.onRemoveTag.emit(tag.getAttribute("tag-name"));
        }
        if ( component instanceof TaskAddWatchersOptionComponent) {
          this.onRemoveWatcher.emit(tag.getAttribute("tag-name"));
        }
        this.onValueChange.emit({ id: component.id, value: null });
        tag.remove();
        if (container) {
          component.clearValue();
          container.remove();
        }
      }
      this.attachHandlers();
      return;
    }

    // if the cursor is at the end, the next arrow is pressed,
    // move to the next input div skipping any tag in the way
    if (pos.atEnd && $event.keyCode === this.keyCodes.arrowNext) {
      let input = this.getNextInput($event.target);
      if (input) input.focus();
      return;
    }
    // this.changerDetectorRef.markForCheck();
  }

  changeValue(event) {
    this.resizeTextBox();
  }

  inputHandler(event) {
    this.resizeTextBox();
  }

  resizeTextBox() {
    this.resetTextArea();
    let input = this.composeInput.nativeElement;
    let subjectLines = Math.floor(input.scrollHeight / 18);
    if (subjectLines > 1) {
      if (subjectLines > 3) {
        this.subjectLines = 3;
      } else {
        this.subjectLines = subjectLines;
      }
    } else {
      this.subjectLines = 1;
    }
    let element = document.querySelectorAll("#new_task_input")[0];
    element.classList.add("subject-line-" + this.subjectLines);
    this.changerDetectorRef.markForCheck();
  }

  resizeOptionComponent() {
    let composeDiv: any = document.querySelectorAll("#compose-div")[0];
    let height;
    let optionDiv: any = document.querySelectorAll("#options-container")[0];
    if (this.isOnMobileDevice) {
      if (this.activeComponent) {
        height = composeDiv.offsetHeight + 15;
        optionDiv.style.height = "calc(100% - " + height + "px )";
        optionDiv.style.maxHeight = "calc(100% - " + height + "px )";
        optionDiv.style.display = "block";
      } else {
        height = composeDiv.offsetHeight + 59;
        optionDiv.style.height = "calc(100% - " + height + "px )";
        optionDiv.style.maxHeight = "350px";
        optionDiv.style.display = "flex";
      }
    } else {
      let newHeight;
      if (this.activeComponent) {
        height = composeDiv.offsetHeight + 43;
        newHeight = 450 - height;
        optionDiv.style.height =  newHeight + "px";
        optionDiv.style.maxHeight =  newHeight + "px";
        optionDiv.style.display = "block";
      } else {
        height = composeDiv.offsetHeight + 87;
        newHeight = 450 - height;
        optionDiv.style.height =  newHeight + "px";
        optionDiv.style.maxHeight = "350px";
        optionDiv.style.display = "flex";
      }
    }
    this.changerDetectorRef.markForCheck();
  }

  resetTextArea() {
    let element = document.querySelectorAll("#new_task_input")[0];
    let buttonEle;
    for (let i of Array.from(Array(this.subjectLines).keys())) {
      if ( i !== 0) {
        element.classList.remove("subject-line-" + (i + 1));
      }
    }
    this.subjectLines = 1;
    this.changerDetectorRef.markForCheck();
  }

  onNewTaskInputChange() {
    this.onValueChange.emit({ id: "vp-vnctask-compose-input" });
  }

  pasteValue() {
    setTimeout( () => {
      this.onValueChange.emit({ id: "vp-vnctask-compose-input" });
    }, 200);
  }

  onKeyUp($event) {
    if ($event.keyCode === this.keyCodes.enter) {
      $event.preventDefault();
      if (this.selectFlag) {
        this.selectFlag = false;
        return;
      }
      if (!this.selectFlag) {
        let ele = $event.target;
        ele.blur();
        return this.onAddTask();
      }
    }
    const elem = $event.target;
    const text = elem.textContent;

    this.lastValue = text;
    // Exit if backspace is pressed and triggerKey is erased
    if (this.activeComponent && elem.textContent.indexOf(this.activeComponent.triggerKey) < 0) {
      this.clearTriggerKey();
      return;
    }

    let component = this.hasEnteredTriggeredKey(text);
    // if trigger key is set, and new trigger key is not pressed
    // emit proceeding text
    if (this.activeComponent) {
      if (!component || (component && this.activeComponent.triggerKey === component.triggerKey)) {
        if (this.activeComponent.isValid()) {
          this.activeComponent.filter(text.split(this.activeComponent.triggerKey).pop());
          return;
        }
      }
    }

    // if user has entered the trigger key at the start or end of the input,
    // set the triggerKey and emit the proceeding text
    if (component && component.isValid() && !component.getValue()) {
      if (this.activeComponent) this.activeComponent.hide();
      this.activeComponent = component;
      this.onActiveComponentChange.emit(this.activeComponent);
      this.activeComponent.show();
      this.activeComponent.filter(text.split(component.triggerKey).pop());
    }

  }

  hasEnteredTriggeredKey(text: string): TaskOptionComponent {
    // prefer any trigger key at the end first!
    let component = this.optionComponents.find(component => {
      return text.endsWith(` ${component.triggerKey}`);
    });
    if (!component) {
      component = this.optionComponents.find(component => {
        return text.startsWith(component.triggerKey);
      });
    }
    return component;
  }

  // check if the cursor is at the start or the end of the input div
  getCursorPos(el) {
    let atStart = false, atEnd = false;
    let selRange, testRange;
    let sel = this._window.getSelection();
    if (sel.rangeCount) {
      selRange = sel.getRangeAt(0);
      testRange = selRange.cloneRange();

      testRange.selectNodeContents(el);
      testRange.setEnd(selRange.startContainer, selRange.startOffset);
      atStart = (testRange.toString() === "");

      testRange.selectNodeContents(el);
      testRange.setStart(selRange.endContainer, selRange.endOffset);
      atEnd = (testRange.toString() === "");
    }
    return { atStart: atStart, atEnd: atEnd };
  }

  getPreviousInput(el) {
    let sibling = el.previousSibling;
    while (sibling) {
      if (sibling.hasAttribute && sibling.hasAttribute("contenteditable"))
        return sibling;
      sibling = sibling.previousSibling;
    }
  }

  getNextInput(el) {
    let sibling = el.nextSibling;
    while (sibling) {
      if (sibling.hasAttribute && sibling.hasAttribute("contenteditable"))
        return sibling;
      sibling = sibling.nextSibling;
    }
  }

  getTagBehind(el) {
    if (el.previousSibling && el.previousSibling.tagName === "SPAN") {
      return el.previousSibling;
    }
    return null;
  }

  placeCaretAtEnd(el) {
    if (!el) return;
    el.focus();
    let range = this._document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    let sel = this._window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  insertTag(value: any, input?: any) {
    let componentId = this.activeComponent.id;
    let triggerKey = this.activeComponent.triggerKey;
    this.onValueChange.emit({ id: componentId, value: value });
    this.clearTriggerKey(input);

    let span = this._document.createElement("span");
    span.className = "input-tag";
    span.id = componentId;
    if (value.displayName) {
      span.innerHTML = `${triggerKey}${value.displayName}`;
      span.setAttribute("tag-name", value.displayName);
    } else {
      span.innerHTML = `${triggerKey}${value.name}`;
      span.setAttribute("tag-name", value.name);
    }
    let icon = this._document.createElement("i");
    icon.className = "material-icons";
    icon.innerHTML = "cancel";
    icon.addEventListener("click", ($event) => {
      let tag;
      if ($event.target) {
        let target: any = $event.target;
        if (target.parentElement) {
          tag = target.parentElement;
        }
      }
      if (tag) {
        let component = this.optionComponents.find(component => component.id === tag.id);
        let container = this.getNextInput(tag);
        if ( component instanceof TaskAddTagsOptionComponent) {
          this.onRemoveTag.emit(tag.getAttribute("tag-name"));
        }
        if ( component instanceof TaskAddWatchersOptionComponent) {
          this.onRemoveWatcher.emit(tag.getAttribute("tag-name"));
        }
        this.onValueChange.emit({ id: component.id, value: null });
        tag.remove();
        if (container) {
          component.clearValue();
          if (container.innerHTML === "") {
            container.remove();
          }
        }
      }
      this.lastValue = "";
      this.attachHandlers();
      if (!this.activeComponent) {
        this.onValueChange.emit({ id: "vp-vnctask-compose-input" });
      }
    });
    span.appendChild(icon);

    let div = this._document.createElement("div");
    div.contentEditable = "true";

    let container = this.elementRef.nativeElement.querySelector(".container");
    container.appendChild(span);
    container.appendChild(div);

    div.focus();

    this.attachHandlers();
  }

  setActiveComponent(component: TaskOptionComponent) {
    let input = this.getLastInput();
    if (this.activeComponent) this.activeComponent.hide();
    this.activeComponent = component;
    this.onActiveComponentChange.emit(this.activeComponent);
    this.activeComponent.show();
    input.innerHTML = input.innerHTML.replace(/<br>\\*/g, "") + component.triggerKey;
    this.placeCaretAtEnd(input);
    this.onKeyUp({ target: this.getLastInput(), keyCode: 0 });
  }

  getActiveComponent() {
    return this.activeComponent;
  }

  clearTriggerKey(input?: any) {
    if (this.activeComponent) {
      if (!input) input = this.getLastInput();
      const index = input.innerHTML.indexOf(this.activeComponent.triggerKey);
      if (index !== -1) {
        input.innerHTML = input.innerHTML.substring(0, index);
      }
      this.activeComponent.hide();
      this.activeComponent = null;
      this.onActiveComponentChange.emit(null);
    }
  }

  getLastInput() {
    return this.elementRef.nativeElement.querySelector("[contenteditable]:last-child");
  }

  getText() {
    let input = this.composeInput.nativeElement;
    return input.value;
  }

  onAddTask() {
    this.debouncer.next(true);
  }

  ngOnDestroy(): void {
    this.onActiveComponentChange.unsubscribe();
    this.onValueChange.unsubscribe();
    this.onAdd.unsubscribe();
    this.debouncer.unsubscribe();
  }

}
