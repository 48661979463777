
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, ElementRef, AfterViewInit, HostListener } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { MdlDialogReference, MdlSnackbarService, MdlTextFieldComponent } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { TasksRootState } from "../../store/reducers";
import { Store } from "@ngrx/store";
import { getOnlineStatus } from "../../../reducers";
import { TaskRepository } from "../../repository/task.repository";
import { SearchRepository } from "../../repository/search.repository";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { TasksConstants } from "../../shared/task-constacts";
import { takeUntil, takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-save-query-dialog",
    templateUrl: "save-query-dialog.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveQueryDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    form: UntypedFormGroup;
    name = new UntypedFormControl(null, [Validators.required]);
    private isAlive$ = new Subject<boolean>();
    isAlive: boolean = true;
    isLoading: boolean = false;
    isOnline: boolean = false;
    @ViewChild("queryName", { static: false }) queryName: ElementRef;
    constructor(
      private broadcaster: Broadcaster,
      private errorService: ErrorService,
      private successService: SuccessService,
      private mdlSnackbarService: MdlSnackbarService,
      private store: Store<TasksRootState>,
      private tasksRepo: TaskRepository,
      private searchRepo: SearchRepository,
      private changerDetectorRef: ChangeDetectorRef,
      private fb: UntypedFormBuilder,
      private messageTranslatorService: MessageTranslatorService,
      private dialogRef: MatDialogRef<SaveQueryDialogComponent>,
  ) {
      this.handleSaveQuerySuccessMessages();
      this.handleSaveQueryErrorMessages();
      this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.hide();
        });
      this.broadcaster.on<any>("hideSaveQueryDialog").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.hide();
        });

      this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
        this.isOnline = isOnline;
        this.changerDetectorRef.markForCheck();
      });
    }

    ngOnInit() {
      this.form = this.fb.group({
        "name": this.name
      });
    }

    ngAfterViewInit(): void {
      if (this.queryName) {
        setTimeout( () => {
          this.queryName.nativeElement.focus();
        }, 200);
      }
    }

    handleSaveQueryErrorMessages() {
      this.errorService.only(ErrorType.saveSearchesError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
        this.mdlSnackbarService.showToast(
          error.messages
        );
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
      });
    }

    handleSaveQuerySuccessMessages() {
      this.successService.only(SuccessType.saveSearchesSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
        this.mdlSnackbarService.showToast(
            success.messages
          );
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
        this.tasksRepo.getSaveSearches();
        this.cancel(true);
      });
    }

    ngOnDestroy(): void {
      this.isAlive$.next(false);
      this.isAlive$.unsubscribe();
    }

    @HostListener("document:keydown.esc", ["$event"])
    hide() {
      this.dialogRef.close();
    }

    cancel(value?) {
      this.dialogRef.close(value);
    }

    save() {
      if (this.isOnline) {
        if (this.name.value.length > 255 ) {
          this.mdlSnackbarService.showToast(
            this.messageTranslatorService.getMessage(TasksConstants.SAVE_FILTER_IS_TOO_LONG)
          );
          return;
        }
        this.isLoading = true;
        this.searchRepo.saveSearches(this.name.value);
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
}
