
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskFieldsDialog" class="task__dialog">
    <div class="vp-task-add-fields-dialog task__dialog-header">
      <div class="mobile-back-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'ADD_FIELD' | translate}}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
      <mdl-list class="action-list">
        <mdl-list-item id="add-field-reminder" (click)="redirectTo(addFieldsType.reminder)">
            <mdl-list-item-primary-content>
                <mdl-icon>notifications_active</mdl-icon>
                <span>{{'REMINDER' | translate}}</span>
            </mdl-list-item-primary-content>
        </mdl-list-item>
        <mdl-list-item id="add-field-attachment" (click)="redirectTo(addFieldsType.attachment)">
            <mdl-list-item-primary-content>
              <mdl-icon>attach_file</mdl-icon>
              <span>{{'ATTACHMENT' | translate}}</span>
            </mdl-list-item-primary-content>
        </mdl-list-item>
        <mdl-list-item id="add-field-url"  (click)="redirectTo(addFieldsType.url)">
            <mdl-list-item-primary-content>
              <mdl-icon>insert_link</mdl-icon>
              <span>{{'URL' | translate}}</span>
            </mdl-list-item-primary-content>
        </mdl-list-item>
        <!-- <mdl-list-item id="add-field-subtask" (click)="redirectTo(addFieldsType.subtask)">
          <mdl-list-item-primary-content>
            <mdl-icon>event_note</mdl-icon>
            <span>{{'SUBTASK' | translate}}</span>
          </mdl-list-item-primary-content>
        </mdl-list-item> -->
        <mdl-list-item id="add-field-watchers" (click)="redirectTo(addFieldsType.watchers)">
          <mdl-list-item-primary-content>
            <mdl-icon>visibility</mdl-icon>
            <span>{{'WATCHERS' | translate}}</span>
          </mdl-list-item-primary-content>
        </mdl-list-item>
      </mdl-list>
    </div>
</div>

