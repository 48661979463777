
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


export class TasksConstants {
  static TASK_API_URL = "/api/task";
  static ROUTE_TYPE_STATUS = "status";
  static ROUTE_TYPE_TAG = "tags";
  static ROUTE_TYPE_LIST = "list";
  static ROUTE_TYPE_LOCATION = "location";
  static ROUTE_ALL_TASK = "all";
  static ROUTE_OPEN = "open";
  static ROUTE_NEW = "new";
  static ROUTE_COMPLETED = "completed";
  static ROUTE_ASSIGNEDTOME = "assigned";
  static ROUTE_CREATEDBYME = "created";
  static ROUTE_TODAY_DUE = "today";
  static ROUTE_TOMORROW_DUE = "tomorrow";
  static ROUTE_THIS_WEEK_DUE = "week";
  static ROUTE_TASKS_I_WATCH = "watch";
  static ROUTE_MY_OVERDUE_TASKS = "overdue";
  static ROUTE_SEARCH = "search";
  static DETAIL_VIEW = "detail";
  static ALL_TASK  = "ALL_TASK";
  static OPEN = "OPEN";
  static NEW_TASK = "NEW";
  static COMPLETED = "COMPLETED";
  static ASSIGNEDTOME = "ASSIGNEDTOME";
  static CREATEDBYME = "CREATEDBYME";
  static TODAY_DUE = "TODAY_DUE";
  static TOMORROW_DUE = "TOMORROW_DUE";
  static THIS_WEEK_DUE = "THIS_WEEK_DUE";
  static TASKS_I_WATCH = "TASKS_I_WATCH";
  static MY_OVERDUE_TASKS = "MY_OVERDUE_TASKS";
  static LOW = "LOW";
  static NORMAL = "NORMAL";
  static HIGH = "HIGH";
  static URGENT = "URGENT";
  static IMMEDIATE = "IMMEDIATE";
  static NEW = "New";
  static IN_PROGRESS = "In Progress";
  static CLOSED = "Closed";
  static PRIORITY = "PRIORITY";
  static DUE_DATE = "DUE_DATE";
  static TASK_NAME = "TASK_NAME";
  static START_DATE = "START_DATE";
  static STATUS = "STATUS";
  static CREATED = "CREATED";
  static DUE_DATE_SORTING_KEY = "due_date";
  static PRIORITY_SORTING_KEY = "priority";
  static TASK_NAME_SORTING_KEY = "subject";
  static START_DATE_SORTING_KEY = "start_date";
  static STATUS_SORTING_KEY = "status";
  static CREATED_SORTING_KEY = "created_on";
  static TASK_PROJECT = "TASK_PROJECT";
  static TASK_UPDATED = "TASK_UPDATED";
  static TASK_ERR_MSG = "TASK_ERR_MSG";
  static NO_STARTDATE = "NO_STARTDATE";
  static TASK_START_DATE = "TASK_START_DATE";
  static TASK_DUE_DATE = "TASK_DUE_DATE";
  static ASSIGN_USER = "ASSIGN_USER";
  static NO_DUEDATE = "NO_DUEDATE";
  static TASK_EVERY_DAY = "TASK_EVERY_DAY";
  static TASK_EVERY_WEEK = "TASK_EVERY_WEEK";
  static TASK_EVERY_MONTH = "TASK_EVERY_MONTH";
  static TASK_EVERY_YEAR = "TASK_EVERY_YEAR";
  static TASK_NO_REPEAT = "TASK_NO_REPEAT";
  static CHANGE_REPEAT_PATTERN = "CHANGE_REPEAT_PATTERN";
  static PROJECT_REQUIRED = "PROJECT_REQUIRED";
  static TASK_SUBJECT_REQUIRED = "TASK_SUBJECT_REQUIRED";
  static TASK_CREATED = "TASK_CREATED";
  static PROJECT_LINKED_TASK_REQUIRED = "PROJECT_LINKED_TASK_REQUIRED";
  static TASK_ESTIMATE_IS_INVALID = "TASK_ESTIMATE_IS_INVALID";
  static TASK_DUEDATE_IS_INVALID = "TASK_DUEDATE_IS_INVALID";
  static STATUS_NEW = "STATUS_NEW";
  static STATUS_INPROGRESS = "STATUS_INPROGRESS";
  static STATUS_COMPLETED = "STATUS_COMPLETED";
  static DUPLICATE_TASK_CREATED = "DUPLICATE_TASK_CREATED";
  static TASK_REMOVED_MSG = "TASK_REMOVED_MSG";
  static PERMISSION_DECLINED = "PERMISSION_DECLINED";
  static SOME_UNKNOWN_ERROR = "SOME_UNKNOWN_ERROR";
  static PICK_START_DATE = "PICK_START_DATE";
  static PICK_DUE_DATE = "PICK_DUE_DATE";
  static TASK_LANGUAGE = "taskLanguage";
  static TASK_DUE_DATE_UPDATE_FAIL = "TASK_DUE_DATE_UPDATE_FAIL";
  static TASK_START_DATE_UPDATE_FAIL = "TASK_START_DATE_UPDATE_FAIL";
  static SEARCH_STRING_ERR_MSG = "SEARCH_STRING_ERR_MSG";
  static ENTER_A_COMMENT = "ENTER_A_COMMENT";
  static POST_MESSAGE = "VNC_PORTAL_POST_MESSAGE";
  static TASK_SUBJECT_NOT_ALLOWED = "TASK_SUBJECT_NOT_ALLOWED";
  static ASC = "asc";
  static DESC = "desc";
  static STATUS_SELECTION_ERROR = "STATUS_SELECTION_ERROR";
  static GO_TO_SERVER_URL_PAGE = "GO_TO_SERVER_URL_PAGE";
  static TAG_MIN_LENGTH = "TAG_MIN_LENGTH";
  static DUPLICATE_TAG_NAME = "DUPLICATE_TAG_NAME";
  static PROFILE_UPDATED_MSG = "PROFILE_UPDATED_MSG";
  static CONTAINS = "CONTAINS";
  static NOT_CONTAIN = "NOT_CONTAIN";
  static NO_DUEDATE_SET = "NO_DUEDATE_SET";
  static SEARCH_SAVED_MSG = "SEARCH_SAVED_MSG";
  static PASSED_DUEDATE = "PASSED_DUEDATE";
  static PASSED_CURRENTTIME = "PASSED_CURRENTTIME";
  static SUNDAY = "SUNDAY";
  static MONDAY = "MONDAY";
  static TUESDAY = "TUESDAY";
  static WEDNESDAY = "WEDNESDAY";
  static THURSDAY = "THURSDAY";
  static FRIDAY = "FRIDAY";
  static SATURDAY = "SATURDAY";
  static DOWNLOADING_STARTED = "DOWNLOADING_STARTED";
  static DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";
  static RESET_ADVANCE_SEARCH_MESSAGE = "RESET_ADVANCE_SEARCH_MESSAGE";
  static DELETE_TASK_MESSAGE = "DELETE_TASK_MESSAGE";
  static CONFIRM_DELETE_ATTACHMENT = "CONFIRM_DELETE_ATTACHMENT";
  static FILE_UPLOAD_SIZE_ERROR = "FILE_UPLOAD_SIZE_ERROR";
  static FILE_UPLOAD_LIMIT = 29300000;
  static TAGS = "TAGS";
  static SAVE = "SAVE";
  static TASK_NOT_SAVED_MSG = "TASK_NOT_SAVED_MSG";
  static SAVE_TASK = "SAVE_TASK";
  static GO_TO_REGISTRATION_PAGE = "GO_TO_REGISTRATION_PAGE";
  static GO_TO_FORGOT_PASSWORD_PAGE = "GO_TO_FORGOT_PASSWORD_PAGE";
  static GO_TO_RESET_PASSWORD_PAGE = "GO_TO_RESET_PASSWORD_PAGE";
  static CHANGE_SERVER_URL = "CHANGE_SERVER_URL";
  static GO_TO_CONFIRM_EMAIL = "GO_TO_CONFIRM_EMAIL";
  static ACTIVATION_LINK_SEND_SUCCESS = "ACTIVATION_LINK_SEND_SUCCESS";
  static FORGOT_PASSWORD_SEND_SUCCESS = "FORGOT_PASSWORD_SEND_SUCCESS";
  static RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
  static CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
  static NEW_REGISTER_ERROR_MESSAGE = "NEW_REGISTER_ERROR_MESSAGE";
  static RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
  static CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
  static COMING_SOON = "COMING_SOON";
  static UNKNOWN_USER = "UNKNOWN_USER";
  static INTERNET_LOST_CONNECTION = "INTERNET_LOST_CONNECTION";
  static OFFLINE = "OFFLINE";
  static WRONG_CURRENT_PWD_ERROR = "WRONG_CURRENT_PWD_ERROR";
  static UNKNOWN = "Unknown";
  static DEACTIVATE_ACCOUNT_SUCCESS = "DEACTIVATE_ACCOUNT_SUCCESS";
  static CONFIRM_DEACTIVATION_MESSAGE = "CONFIRM_DEACTIVATION_MESSAGE";
  static CONFIRM_DEACTIVATION_NOTE = "CONFIRM_DEACTIVATION_NOTE";
  static LISTS = "LISTS";
  static LIST_CREATED = "LIST_CREATED";
  static LIST_UPDATED = "LIST_UPDATED";
  static LIST_DELETED = "LIST_DELETED";
  static DELETE = "DELETE";
  static RENAME = "RENAME";
  static EXPIRED = "expired";
  static ACTIVATION_LINK_EXPIRED_MESSAGE = "ACTIVATION_LINK_EXPIRED_MESSAGE";
  static OPEN_TASK_DETAIL_PAGE = "OPEN_TASK_DETAIL_PAGE";
  static GO_TO_TASK_INVITATION = "GO_TO_TASK_INVITATION";
  static TOKEN_EXPIRED = "TOKEN_EXPIRED";
  static ENTER_VALID_EMAIL_ADDRESS = "ENTER_VALID_EMAIL_ADDRESS";
  static DOWNLOAD_NOT_IMAGEVIDEO_ATTACHMENT_NOT_PERMITTED_IOS = "DOWNLOAD_NOT_IMAGEVIDEO_ATTACHMENT_NOT_PERMITTED_IOS";
  static ONLY_AUTHOR_CAN_INVITE = "ONLY_AUTHOR_CAN_INVITE";
  static EXCEED_LIMIT_FOR_INVITE = "EXCEED_LIMIT_FOR_INVITE";
  static HAVE_NOT_ACTIVATED = "haven't activated";
  static LOCKED = "locked";
  static HAVE_NOT_ACTIVATED_MSG = "HAVE_NOT_ACTIVATED_MSG";
  static ACCOUNT_LOCKED_MSG = "ACCOUNT_LOCKED_MSG";
  static ACCOUNT_NOT_FOUND_WITH_EMAIL = "ACCOUNT_NOT_FOUND_WITH_EMAIL";
  static NOT_ALLOWED_REGISTER = "NOT_ALLOWED_REGISTER";
  static START_DATE_CHANGE_ERROR_MESSAGE = "START_DATE_CHANGE_ERROR_MESSAGE";
  static MUST_BE_GREATER_THEN = "must be greater than";
  static PROFILE_REMOVED_MSG = "PROFILE_REMOVED_MSG";
  static TIME_CANCEL_LABEL = "TIME_CANCEL_LABEL";
  static TIME_SET_LABEL = "TIME_SET_LABEL";
  static CONFIRM_DUPLICATE_TASK = "CONFIRM_DUPLICATE_TASK";
  static CONFIRM_DUPLICATE_SELECTED_TASKS = "CONFIRM_DUPLICATE_SELECTED_TASKS";
  static CONFIRM_DELETE_TASK_MESSAGE = "CONFIRM_DELETE_TASK_MESSAGE";
  static USER_ARCHIVED = "USER_ARCHIVED";
  static USER_UNARCHIVED = "USER_UNARCHIVED";
  static INVITATION_SENT = "INVITATION_SENT";
  static REACHED_LIMIT_FOR_ACTIVE_USER = "REACHED_LIMIT_FOR_ACTIVE_USER";
  static SUBJECT_IS_TOO_LONG = "SUBJECT_IS_TOO_LONG";
  static DONE_EQUAL_ERROR = "DONE_EQUAL_ERROR";
  static SETTINGS_SAVE_SUCCESS = "SETTINGS_SAVE_SUCCESS";
  static LOCATIONS = "LOCATIONS";
  static LOCATION_CREATED = "LOCATION_CREATED";
  static LOCATION_UPDATED = "LOCATION_UPDATED";
  static LOCATION_DELETED = "LOCATION_DELETED";
  static LIST_IS_TOO_LONG = "LIST_IS_TOO_LONG";
  static LOCATION_IS_TOO_LONG = "LOCATION_IS_TOO_LONG";
  static LOG_TIME_SUCCESS = "LOG_TIME_SUCCESS";
  static SPENT_TIME_INVALID = "SPENT_TIME_INVALID";
  static HOURS_IS_INVALID = "Hours is invalid";
  static CONFIRM_DELETE_WATCHER = "CONFIRM_DELETE_WATCHER";
  static MORE = "MORE";
  static AND = "AND";
  static SAVE_FILTER_IS_TOO_LONG = "SAVE_FILTER_IS_TOO_LONG";
  static TAG_SPACE_NOT_ALLOWED = "TAG_SPACE_NOT_ALLOWED";
  static TASK_ESTIMATE_MINUTE_IS_INVALID = "TASK_ESTIMATE_MINUTE_IS_INVALID";
  static FOUND_UPDATE_TASK_CONFLICT = "Found update conflict";
  static FOUND_UPDATE_TASK_CONFLICT_ERROR = "FOUND_UPDATE_TASK_CONFLICT_ERROR";
  static RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND";
  static TASK_HAS_BEEN_DELETED = "TASK_HAS_BEEN_DELETED";
  static SHOW_DESCRIPTION_DIFFERENCE = "SHOW_DESCRIPTION_DIFFERENCE";
  static TASK_UPDATE_TITLE = "TASK_UPDATE_TITLE";
  static TASK_UPDATE_SUB_TITLE = "TASK_UPDATE_SUB_TITLE";
  static NEW_TASK_ASSIGNMENT_TITLE = "NEW_TASK_ASSIGNMENT_TITLE";
  static NEW_TASK_ASSIGNMENT_SUB_TITLE = "NEW_TASK_ASSIGNMENT_SUB_TITLE";
  static REMINDER_TASK_DUE_TODAY = "REMINDER_TASK_DUE_TODAY";
  static TASK_DUE_TODAY = "TASK_DUE_TODAY";
  static TFA_OTP_VERIFICATION = "TFA_OTP_VERIFICATION";
  static TFA_CONFIGURED_SUCCESS = "TFA_CONFIGURED_SUCCESS";
  static ENTER_WRONG_OTP = "ENTER_WRONG_OTP";
  static ERROR_SAVE_2FA_SETTING = "ERROR_SAVE_2FA_SETTING";
  static SPENT_TIME_SHOULD_BE_GREATER = "SPENT_TIME_SHOULD_BE_GREATER";
  static TASK_WATCHER_TITLE = "TASK_WATCHER_TITLE";
  static TASK_WATCHER_SUB_TITLE = "TASK_WATCHER_SUB_TITLE";
  static GO_TO_LOGIN_SCREEN = "GO_TO_LOGIN_SCREEN";
}
