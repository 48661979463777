
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  Input,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy
} from "@angular/core";
import { MdlLayoutComponent, MdlDialogService } from "@angular-mdl/core";
import { HeaderService } from "../../services/header.service";
import { TaskSettingsDialogComponent } from "../task-settings/task-settings-dialog/task-settings-dialog.component";
import { TasksRootState, getAuthUser } from "../../store/index";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { ConfigService } from "../../../common/providers/config.service";
import { TaskService } from "../../task.service";
import { environment } from "../../../../environments/environment";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { AppsDialogComponent } from "../../../shared/components/apps-dialog/apps.component.dialog";
import { Logout } from "../../../actions/app";
import { TaskGeneralSettingsDialogComponent } from "../task-settings/task-general-settings-dialog/task-general-settings.component";
import { TaskOptionListComponent } from "./task-option-list.component";
import { TaskLoginProfileDialogComponent } from "../task-login-user-profile/task-login-user-profile.component";
import { TagOptionListComponent } from "./tag-option-list.component";
import { TasksConstants } from "../../shared/task-constacts";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus, getLoginStatus } from "../../../reducers";
import { FolderOptionListComponent } from "./folder-option-list.component";
import { CommonUtil } from "../../../common/utils/common.utils";
import { UsersManagementComponent } from "./users-management.component";
import { SaveSearchDialogComponent } from "../save-search-dialog/save-search-dialog.component";
import { LocationOptionListComponent } from "./location-option-list.component";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-sidebar-drawer",
  template: `
    <div id="profileMenuMain" (click)="openProfileDialog()" class="profile__cover" [style.backgroundImage]="profileBackground">
      <vp-avatar [user]="{ name: user && user.fullname ? user.fullname : '', avatarURL: user.userAvatar }"> </vp-avatar>
      <h4 id="userEmail" class="profile__h4 disable-select">{{user.email}}</h4>
    </div>
    <div class="drawer__links">
      <ng-template ngFor [ngForOf]="links" let-link>
        <div *ngIf="(link.title !== 'USERS_MANAGEMENT') || (link.title === 'USERS_MANAGEMENT' && user.team_user === 'true')" id="{{ link.id }}" class="__row" (click)="onAction(link)">
          <div class="left_col">
            <mdl-icon>{{link.icon}}</mdl-icon>
            <span class="disable-select">{{link.title | translate}}</span>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarDrawerComponent implements OnDestroy{
  @Input() layout: MdlLayoutComponent;
  user: any = {};
  profileBackground = "url(" + CommonUtil.getFullUrl("/assets/img/profile-background.jpg") + ")";
  @Input() url: string;
  @ViewChild(TagOptionListComponent, { static: false }) tagOptionListDialog: TagOptionListComponent;
  switchApp: boolean = false;
  isAlive: boolean = true;
  isOnline: boolean = false;
  actions: any = {
    APP_SWITCHER: "APP_SWITCHER",
    TASK_LIST: "TASK_LIST",
    FOLDER_LIST: "FOLDER_LIST",
    TAG_LIST: "TAG_LIST",
    LOCATION_LIST: "LOCATION_LIST",
    TASK_GENERAL_SETTINGS: "TASK_GENERAL_SETTINGS",
    ABOUT: "ABOUT",
    TASK_SETTINGS: "TASK_SETTINGS",
    LOGOUT: "LOGOUT",
    PROFILE: "PROFILE",
    USERS_MANAGEMENT: "USERS_MANAGEMENT",
    SAVED_SEARCHES: "SAVED_SEARCHES"
  };
  links: Link[] = [
    {
      title: "APPS",
      action: "APP_SWITCHER",
      icon: "apps",
      id: "appSwitchBtn"
    },
    {
      title: "TASKS",
      action: "TASK_LIST",
      icon: "event_available",
      enableRoutes: ["task"],
      id: "tasksBtn"
    },
    {
      title: "LISTS",
      action: "FOLDER_LIST",
      icon: "assignment",
      enableRoutes: ["task"],
      id: "listsBtn"
    },
    {
      title: "TAGS",
      action: "TAG_LIST",
      icon: "local_offer",
      enableRoutes: ["task"],
      id: "tagsBtn"
    },
    {
      title: "LOCATIONS",
      action: "LOCATION_LIST",
      icon: "place",
      enableRoutes: ["task"],
      id: "locationsBtn"
    },
    {
      title: "USERS_MANAGEMENT",
      action: "USERS_MANAGEMENT",
      icon: "account_box",
      enableRoutes: ["task"],
      id: "userMenagementBtn"
    },
    {
      title: "SAVED_SEARCHES",
      action: "SAVED_SEARCHES",
      icon: "youtube_searched_for",
      enableRoutes: ["task"],
      id: "savedSearchesBtn"
    },
    {
      title: "TASK_GENERAL_SETTINGS",
      action: "TASK_GENERAL_SETTINGS",
      icon: "settings",
      enableRoutes: ["task"],
      id: "generalSettingMenu"
    },
    {
      title: "ABOUT",
      action: "ABOUT",
      icon: "info_outline",
      enableRoutes: ["task"],
      id: "aboutMenu"
    },
    {
      title: "LOGOUT",
      action: "LOGOUT",
      icon: "exit_to_app",
      id: "logoutBtn"
    }
  ];

  constructor(
    private headerService: HeaderService,
    private store: Store<TasksRootState>,
    private router: Router,
    private configService: ConfigService,
    private taskService: TaskService,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogService: MdlDialogService,
    private tasksRepo: TaskRepository,
    private matDialog: MatDialog
  ) {

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.user = user;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("profileAvatarUpdated").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      this.updateUserAvatar();
    });

    this.broadcaster.on<any>("closeLayoutDrawer").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.layout.closeDrawer();
      });
  }

  private updateUserAvatar() {
    this.store.select(getAuthUser).pipe(take(1)).subscribe(user => {
      if (user) {
        this.user = user;
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  onAction(link: any): void {
    switch (link.action) {
      case this.actions.APP_SWITCHER:
        const appDlg = this.matDialog.open(AppsDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vp-apps-dialog",
          data: { url: this.url }
        });
        break;

      case this.actions.TASK_GENERAL_SETTINGS:
        const gdlg = this.matDialog.open(TaskGeneralSettingsDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vnctaskgeneralsettingsdialog",
        });
        break;

      case this.actions.TASK_LIST:
        const dlg = this.matDialog.open(TaskOptionListComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vp-task-option-list-dialog",
        });
        dlg.afterClosed().pipe(take(1)).subscribe($event => {
          if ($event && $event.title) {
            this.navigateRoute($event);
          }
        });
        break;

      case this.actions.FOLDER_LIST:
        const fDialog = this.matDialog.open(FolderOptionListComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vp-folder-option-list-dialog",
        });
        fDialog.afterClosed().pipe(take(1)).subscribe($event => {
          if ($event && $event.title) {
            this.navigateRoute($event);
          }
        });
        break;

      case this.actions.TAG_LIST:
        const tDialog = this.matDialog.open(TagOptionListComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vp-tag-option-list-dialog",
        });
        tDialog.afterClosed().pipe(take(1)).subscribe($event => {
          if ($event && $event.title) {
            this.navigateRoute($event);
          }
        });
        break;

      case this.actions.LOCATION_LIST:
        const lDialog = this.matDialog.open(LocationOptionListComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vp-location-option-list-dialog",
        });
        lDialog.afterClosed().pipe(take(1)).subscribe($event => {
          if ($event && $event.title) {
            this.navigateRoute($event);
          }
        });
        break;

      case this.actions.USERS_MANAGEMENT:
        let uDialog = this.dialogService.showCustomDialog({
          component: UsersManagementComponent,
          providers: [],
          isModal: true,
          styles: { visibility: "visible" },
          animate: false,
          clickOutsideToClose: true,
          classes: ""
        });
        break;

      case this.actions.SAVED_SEARCHES:
        const sDialog = this.matDialog.open(SaveSearchDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vp-save-search-dialog",
        });
        sDialog.afterClosed().pipe(take(1)).subscribe($event => {
          if ($event && $event.query) {
            this.navigateSaveSearches($event.query);
          }
        });
        break;
      case this.actions.ABOUT:
        const aDialog = this.matDialog.open(TaskSettingsDialogComponent, {
          maxWidth: "100%",
          autoFocus: false,
          panelClass: "vnctasksettingsdialog",
        });
        break;

      case this.actions.LOGOUT:
        this.layout.closeDrawer();
        this.logout();
        break;
    }
  }

  navigateRoute($event) {
    console.log("SidebarDrawerComponent, navigateRoute: ", $event);

    this.layout.closeDrawer();
    if ( $event.type === TasksConstants.ROUTE_TYPE_STATUS ) {
      this.router.navigate(["/task", $event.title]);
    } else if ( $event.type === TasksConstants.ROUTE_TYPE_TAG ) {
      this.router.navigate(["/task/tags", $event.title]);
    } else if ( $event.type === TasksConstants.ROUTE_TYPE_LIST ){
      this.router.navigate(["/task/list", $event.title]);
    } else {
      this.router.navigate(["/task/location", $event.title]);
    }
  }

  navigateSaveSearches(query) {
    this.layout.closeDrawer();
    this.tasksRepo.setSearchParams(query);
    this.router.navigate(["/task", TasksConstants.ROUTE_SEARCH], { queryParams: {} });
  }

  logout(): void {
    if (environment.isCordova && typeof window.FirebasePlugin !== "undefined") {
      window.FirebasePlugin.getToken(token => {
        this.taskService.removeFirebaseToken(token)
          .pipe(take(1))
          .subscribe(res => {
            console.log("[removeFirebaseToken]", token, res);
            window.FirebasePlugin.unregister();
            this.processLogout();
          }, err => {
            console.error("[removeFirebaseToken - error]", err);
            this.processLogout();
          });
      });

      window.FirebasePlugin.clearAllNotifications( () => {
        console.log("[FirebasePlugin clearAllNotifications] success");
      }, error => {
        console.log("[FirebasePlugin clearAllNotifications] error", error);
      });
    } else {
      this.processLogout();
    }
  }

  private processLogout(): void {
    this.tasksRepo.logout();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  openProfileDialog() {
    const gdlg = this.matDialog.open(TaskLoginProfileDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnc_task_login_profile_dialog",
    });
  }
}

interface Link {
  title: string;
  action: string;
  icon: string;
  id?: string;
  enabled?: boolean;
  enableRoutes?: string[];
  enableBadge?: boolean;
  badgeValue?: string;
  isFontAwesome?: boolean;
  children?: any[];
  expanded?: boolean;
}
