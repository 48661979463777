
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Broadcaster } from "./broadcaster.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Http, Response, Headers } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";
import { AppConstants } from "../utils/app-constants";
import { LocaleService } from "./locale.service";
import { map } from "rxjs/operators";
import { VNCtaskConfig } from "src/app/task/models/vnctask-config";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class ConfigService {

  private lang = ["en", "de"];

  private config = {

    // Key used in local storage to store authentication token
    AUTH_TOKEN_KEY: "auth.jwt",
    "appsEnabled": [{
      "title": "VNCtask",
      "route": "/task",
      "enabled": true
    }],
    "lang": "en"
  };

  API_URL = "";
  selectedServer = false;
  currentLanguage = new Subject();
  two_factor_authentication: boolean = false;
  serverURL = "https://vnctask.com";
  avatarServiceUrl = "https://avatar.vnc.biz";
  // serverURL = "https://dev.vnctask.vnc.biz";
  private showLoginIframeKeyboardTimer;

  constructor(private http: Http,
    private localeService: LocaleService,
    private broadcaster: Broadcaster ) {
    if (localStorage.getItem("publicVncDirectoryUrl") !== null) {
      this.set("publicVncDirectoryUrl", localStorage.getItem("publicVncDirectoryUrl"));
    }
  }

  loadConfig() {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    if (!isCordovaOrElectron) {
      console.log("[loadConfig] !environment.isCordova");
      this.setAPIURL("");
      this.selectedServer = true;
    } else {
      if (localStorage.getItem("serverURL") === null) {
        localStorage.setItem("serverURL", this.serverURL);
      }
      this.selectedServer = true;
      this.setAPIURL(localStorage.getItem("serverURL").trim());
    }
  }

  set(key: string, value: any): any {

    let oldValue = this.config[key];

    if (oldValue !== value) {
      console.debug("[config.set(" + key + ")]", oldValue, " => ", value);
    }
    return this.config[key] = value;
  }

  get(key): any {
    let result = this.config[key];
    console.debug("[config.get(" + key + ")] => ", result);
    return result;
  }

  getOrThrow(key): any {

    if (!this.config.hasOwnProperty(key))
      throw "Missing '" + key + "' in config";

    return this.config[key];
  }

  setAPIURL(url: string): void {
    this.API_URL = url;
    console.log("[Set API URL]", url);
  }

  showError(msg) {
    console.log(`Invalid configuration: ${msg}`);
  }

  getSupportedLangs() {
    return this.lang.slice();
  }

  getDefaultLang() {
    return this.lang[0];
  }

  getConfiguredLang() {
    let lang = this.get("lang");
    if (this.lang.indexOf(lang) >= 0)
      return lang;
    return this.getDefaultLang();
  }

  isAppEnabled(appTitle: string) {
    let apps = this.config["appsEnabled"] || [];
    let app = apps.filter(app => app.title === appTitle)[0];
    return app && app.enabled;
  }

  clearCache() {
    localStorage.removeItem("firebaseToken");
    localStorage.removeItem("REDMINE_URL");
    localStorage.removeItem("redmineDefaultProject");
    localStorage.removeItem("tracker_id");
    localStorage.removeItem("status_new");
    localStorage.removeItem("status_inProgress");
    localStorage.removeItem("status_completed");
  }

  loginIframe() {
    if (!this.selectedServer) {
      console.log("[config.ts] return");
      return;
  }
  let iframe = document.createElement("iframe");
    if (iframe) {
      let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/task")[0];
      initialHref = environment.isCordova ? initialHref.split("/task")[0] : initialHref.split("/index.html")[0];
      let loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
      console.log("[config.service] launching login iframe with url", loginUrl);
      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("body").appendChild(iframe);
      }

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }


  logoutIframe() {
    let iframe = document.createElement("iframe");
    if (iframe) {
      if (localStorage.getItem("serverURL") !== null) {
        this.API_URL = localStorage.getItem("serverURL").trim();
      }
      iframe.id = "logoutIframe";
      iframe.setAttribute("src", this.API_URL + "/api/call-logout");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      if (document.querySelector("body") !== null && document.querySelector("#logoutIframe") === null) {
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        document.querySelector("body").appendChild(iframe);
      }
    }
  }

  hideLoginIframe() {
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();

      this.hideKeyboard();
    }
  }

  tfaOtpIframe() {
    console.log("[ConfigService][tfaOtpIframe] selectedServer: ", this.selectedServer);

    if (!this.selectedServer) {
      console.log("[ConfigService][tfaOtpIframe] return");
      return;
    }

    let iframe = document.createElement("iframe");
    if (iframe) {
      const initialHref = environment.isCordova ? window.location.href.split("/www")[0] : window.location.href.split("/task")[0];
      const loginUrl = `${ initialHref }${ environment.isCordova ? "/www" : "" }/assets/tfa-otp.html`;
      console.log("[config.service] launching tfa otp iframe with url", loginUrl);

      iframe.id = "tfaOtpIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      // iframe.onload = () => {
      //   console.log("[loginIframe] in onload");
      // };

      if (document.querySelector("body") !== null && document.querySelector("#tfaOtpIframe") === null) {
        document.querySelector("body").appendChild(iframe);
      }

      // by some reason, a standard '.focus()' does not work in login.html for Android
      this.showKeyboard();

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }

  hideTfaOtpIframe() {
    if (document.querySelector("#tfaOtpIframe") !== null) {
      document.querySelector("#tfaOtpIframe").remove();

      this.hideKeyboard();
    }
  }

  showKeyboard() {
    if (environment.isCordova && typeof Keyboard !== "undefined")  {
      this.showLoginIframeKeyboardTimer = setTimeout(() => {
        Keyboard.show();
        this.showLoginIframeKeyboardTimer = null;
      }, 100);
    }
  }

  hideKeyboard(){
    // by some reason, a standard '.focus()' does not work in login.html for Android,
    // so we show a keyboard in 'config.service.ts' using a Keyboard class.
    // So then we needd to hide it here once login is successful

    if  (!this.showLoginIframeKeyboardTimer) {
      clearTimeout(this.showLoginIframeKeyboardTimer);
      this.showLoginIframeKeyboardTimer = null;
    } else {
      if (environment.isCordova && typeof Keyboard !== "undefined")  {
        setTimeout(() => {
          Keyboard.hide();
        }, 100);
      }
    }
  }

  getConfig() { // FIXME: this at least has a bad name. also we have two configs: this /api/config and config.json from loadConfig()
    if (localStorage.getItem("serverURL") !== null) {
      this.API_URL = localStorage.getItem("serverURL").trim();
    }
    return this.http.get(this.API_URL + "/api/config");
  }

  getMyProducts() {
    if (localStorage.getItem("serverURL") !== null) {
      this.API_URL = localStorage.getItem("serverURL").trim();
    }
    let headers = new Headers({"Content-Type": "application/json"});
    if (environment.isCordova || environment.isElectron) {
      headers = new Headers({"Content-Type": "application/json", "Authorization": localStorage.getItem("token")});
    }
    return this.http.get(this.API_URL + "/api/my-products", { headers: headers }).pipe(map(response => response.json()));
  }

  set language(value) {
    this.currentLanguage.next(value);
    this.localeService.registerLocale(value);
    this.broadcaster.broadcast("CHANGE_CALENDER_LOCALE", value);
    localStorage.setItem(AppConstants.TASK_LANGUAGE, value);

  }

  get language() {
    if (localStorage.getItem(AppConstants.TASK_LANGUAGE) !== null) {
      return localStorage.getItem(AppConstants.TASK_LANGUAGE);
    }
    return "en";
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleErrorObservable(error: Response | any) {
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  changeServerUrl(serverURL: string): Observable<any> {
    let headers = new Headers({
      "Content-Type": "application/json",
      "Accept": "application/json"
    });

    if (serverURL.endsWith("/")) {
      serverURL = serverURL.substring(0, serverURL.lastIndexOf("/")).trim();
    }

    return this.http.get(serverURL + "/api/config", { headers: headers}).pipe(map(response => response.json()));
  }

  setConfigDetails(data: any) {
    if (data) {
      if (data.redmineURL) {
        if (!localStorage.getItem("REDMINE_URL")) {
          localStorage.setItem("REDMINE_URL", data.redmineURL);
        }
      }
      if (data.publicVncDirectoryUrl) {
        this.set("publicVncDirectoryUrl", data.publicVncDirectoryUrl);
        localStorage.setItem("publicVncDirectoryUrl", data.publicVncDirectoryUrl);
      }
      if (data.redmineDefaultProject) {
        if (!localStorage.getItem("redmineDefaultProject")) {
          localStorage.setItem("redmineDefaultProject", data.redmineDefaultProject);
        }
      }
      if (data.tracker_id) {
        if (!localStorage.getItem("tracker_id")) {
          localStorage.setItem("tracker_id", data.tracker_id);
        }
      }
      if (data.redmineStatus) {
        if (!localStorage.getItem("status_new")) {
          localStorage.setItem("status_new", data.redmineStatus.new);
        }
        if (!localStorage.getItem("status_inProgress")) {
          localStorage.setItem("status_inProgress", data.redmineStatus.inProgress);
        }
        if (!localStorage.getItem("status_completed")) {
          localStorage.setItem("status_completed", data.redmineStatus.completed);
        }
      }
      if (data.two_factor_authentication) {
        this.two_factor_authentication = data.two_factor_authentication;
        localStorage.setItem("twoFactorAuthentication", data.two_factor_authentication ? "true" : "false");
      } else {
        this.two_factor_authentication = false;
        localStorage.setItem("twoFactorAuthentication", "false");
      }
      if (data.isUserRegistrationViaVNCd) {
        this.set("isUserRegistrationViaVNCd", data.isUserRegistrationViaVNCd );
      } else {
        this.set("isUserRegistrationViaVNCd", false );
      }
      if (data.isForgotPasswordViaVNCd) {
        this.set("isForgotPasswordViaVNCd", data.isForgotPasswordViaVNCd );
      } else {
        this.set("isForgotPasswordViaVNCd", false );
      }
      if (data.disallowedDeleteAccount) {
        this.set("disallowedDeleteAccount", data.disallowedDeleteAccount );
      } else {
        this.set("disallowedDeleteAccount", false );
      }
      if (data.isChangePasswordViaVNCd) {
        this.set("isChangePasswordViaVNCd", data.isChangePasswordViaVNCd );
      } else {
        this.set("isChangePasswordViaVNCd", false );
      }
      if (data.redirectPasswordChangeUrl) {
        this.set("redirectPasswordChangeUrl", data.redirectPasswordChangeUrl );
      } else {
        this.set("redirectPasswordChangeUrl", "" );
      }
      if (data.avatarServiceUrl) {
        this.avatarServiceUrl = data.avatarServiceUrl;
        localStorage.setItem("avatarServiceUrl", data.avatarServiceUrl);
      } else {
        localStorage.setItem("avatarServiceUrl", "");
      }
    }
  }

  setVnctaskConfig(vnctaskConfig: VNCtaskConfig) {
    if (vnctaskConfig.default_project && vnctaskConfig.default_project.identifier ) {
      localStorage.setItem("redmineDefaultProject", vnctaskConfig.default_project.identifier);
    }
    if (vnctaskConfig.vnctask_tracker && vnctaskConfig.vnctask_tracker.id) {
      localStorage.setItem("tracker_id", vnctaskConfig.vnctask_tracker.id.toString());
    }
    if (vnctaskConfig.new_status && vnctaskConfig.new_status.id) {
      localStorage.setItem("status_new", vnctaskConfig.new_status.id.toString());
    }
    if (vnctaskConfig.in_progress_status && vnctaskConfig.in_progress_status.id) {
      localStorage.setItem("status_inProgress", vnctaskConfig.in_progress_status.id.toString());
    }
    if (vnctaskConfig.completed_status && vnctaskConfig.completed_status.id) {
      localStorage.setItem("status_completed", vnctaskConfig.completed_status.id.toString());
    }
  }

  forgotPasswordIframe() {
    let iframe = document.createElement("iframe");
    iframe.id = "forgotPasswordIframe";
    iframe.setAttribute("src", "https://signup.vncmeet.com/account/lost_password");
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.position = "fixed";
    iframe.style.zIndex = "999";
    iframe.style.border = "none";

    if (document.querySelector("body") !== null && document.querySelector("#forgotPasswordIframe") === null) {
        document.querySelector("body").appendChild(iframe);
    }
    iframe = null;
  }

  registrationIframe() {
    let iframe = document.createElement("iframe");
    iframe.id = "registrationIframe";
    iframe.setAttribute("src", "https://signup.vncmeet.com/account/register");
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.position = "fixed";
    iframe.style.zIndex = "999";
    iframe.style.border = "none";

    if (document.querySelector("body") !== null && document.querySelector("#registrationIframe") === null) {
        document.querySelector("body").appendChild(iframe);
    }
    iframe = null;
  }

  hideRegistrationIframe() {
    if (document.querySelector("#registrationIframe") !== null) {
      document.querySelector("#registrationIframe").remove();
    }
  }

  hideForgotPasswordIframe() {
    if (document.querySelector("#forgotPasswordIframe") !== null) {
      document.querySelector("#forgotPasswordIframe").remove();
    }
  }
}
