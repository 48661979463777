
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ConfigService } from "../../../common/providers/config.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy, Inject } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference, MdlDialogService, MdlSnackbarService } from "@angular-mdl/core";
import { Tag } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { TaskService } from "../../task.service";
import { TimeEntry } from "../../models/time-entry";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TaskUtils } from "../../shared/task-utils";
import { LogTimeComponent } from "../log-time/log-time-dialog.component";
import sort from "fast-sort";
import { takeWhile, finalize, take } from "rxjs/operators";
import { environment } from "src/environments/default.environment.prod";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-time-log-history",
  template: `
  <div class="task__dialog vp-timelog-history-dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
        {{'TIMELOG_HISTORY' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="openLogTimeDialog()">
        <mat-icon class="disable-select">add</mat-icon>
      </button>
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body">
    <mdl-list>
      <mdl-list-item *ngFor="let timeEntry of timeEntries" class="timelog-history">
        <div class="task-icon-avtar" *ngIf="!timeEntry.avatar">
          <mdl-icon>person</mdl-icon>
        </div>
        <div class="task-comment-avtar" *ngIf="timeEntry.avatar">
          <img img-cache [src]="timeEntry.avatar" />
        </div>
        <div class="timelog-history--body">
            <div class="timelog-history--body__meta">
                <span class="timelog-history--body__meta--username">{{ timeEntry.user.name }}</span>
                <span class="timelog-history--body__meta--date">. {{ getTimeAgo(timeEntry.created_on) }}</span>
            </div>
            <div class="timelog-history--body__hours">
                <span class="timelog-history--body__hours--label">{{ 'SPENT_TIME' | translate }}:</span>
                <span>{{ convertDecimalToTimeFormat(timeEntry.hours) }}</span>
            </div>
            <div class="timelog-history--body__comments">
                <p [innerHTML]="timeEntry.comments"></p>
            </div>
        </div>
      </mdl-list-item>
    </mdl-list>
  </div>
  </div>
  <button id="newListButton" (click)="openLogTimeDialog()" class="add_list-button brand-button-color mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
    <i class="material-icons disable-select">add</i>
  </button>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimelogHistoryComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  tagList: Tag[] = [];
  isOnline: boolean = false;
  searchText: string = "";
  issue_id: number;
  timeEntries: TimeEntry[] = [];
  isLoading: boolean = false;
  totalSpentTime: string;
  created_on: Date;
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private taskService: TaskService,
    private configService: ConfigService,
    private mdlSnackbarService: MdlSnackbarService,
    private dialogRef: MatDialogRef<TimelogHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog
  ) {
    this.issue_id = this.data.issue_id;
    this.created_on = this.data.created_on;
    this.setupStore();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.broadcaster.on<any>("hideTimelogHistoryDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  setupStore() {
    let timeStamp = new Date().getTime();
    this.isLoading = true;
    this.taskService.getTimeLogHistory(this.issue_id).pipe(take(1), finalize( () => {
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    })).subscribe( timeEntries => {
      timeEntries.forEach(timeEntries => {
          timeEntries.avatar = this.taskRepo.serverUrlAvatar(timeEntries.user.id, timeStamp);
      });
      this.timeEntries = timeEntries;
      this.broadcaster.broadcast("sortTimeEntries");
      this.changerDetectorRef.markForCheck();
    }, err => {
      this.mdlSnackbarService.showToast(err);
    });

    this.broadcaster.on<any>("sortTimeEntries").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      if (this.timeEntries) {
        if (this.timeEntries.length > 0) {
          sort(this.timeEntries).desc( timeEntry => timeEntry.created_on);
        }
      }
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    let spent_time = this.getTotalSpentTime();
    this.dialogRef.close( { "spent_time" : spent_time.toString()});
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  getTimeAgo(created_on): string {
    return CommonUtil.getTimeAgoWithLocale(created_on, this.configService.language);
  }

  convertDecimalToTimeFormat(hours: string) {
    return TaskUtils.convertDecimalToTimeFormat(hours);
  }

  openLogTimeDialog() {
    const dlg = this.matDialog.open(LogTimeComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-log-time-dialog",
      data: { issue_id: this.issue_id, created_on: this.created_on }
    });
    dlg.afterClosed().pipe(take(1)).subscribe($event => {
      if ($event) {
        if ($event.timeEntry) {
          let timeStamp = new Date().getTime();
          let timeEntry = $event.timeEntry;
          timeEntry.avatar = this.taskRepo.serverUrlAvatar(timeEntry.user.id, timeStamp);
          this.timeEntries.push(timeEntry);
          this.changerDetectorRef.markForCheck();
          this.broadcaster.broadcast("sortTimeEntries");
        }
      }
    });
  }

  getTotalSpentTime() {
    let total_count = 0;
    if (this.timeEntries) {
      if (this.timeEntries.length > 0) {
        this.timeEntries.forEach( timeEntry => {
          total_count += parseFloat(timeEntry.hours);
        });
      }
    }
    return total_count;
  }
}
