
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-select-projects-dialog" id="taskSelectProjectsDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
    <div class="header_lbl disable-select">
        {{'SELECT_PROJECTS' | translate}}
    </div>
    <div class="desktop-close-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
  </div>
  <div class="task__dialog-body task__dialog-body--pad">
    <mdl-list>
      <mdl-list-item>
        <mdl-list-item-primary-content>
          <mdl-radio [(ngModel)]="allSelected" [value]="true" (click)="selectAll()"></mdl-radio>
          <span class="option_fields_text">{{ 'ALL_PROJECTS' | translate }}</span>
        </mdl-list-item-primary-content>
      </mdl-list-item>
      <mdl-list-item>
        <mdl-list-item-primary-content>
          <mdl-radio [value]="false" [ngModel]="allSelected" (click)="selectNone()"></mdl-radio>
          <span class="option_fields_text">{{ 'CONTENT_SELECTED' | translate }}</span>
        </mdl-list-item-primary-content>
      </mdl-list-item>
    </mdl-list>
    <div class="selected-projects" *ngIf="!allSelected">
      <div id="select-projects-list-dialog" class="option-list">
        <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText" [label]="'FIND_THE_PROJECT' | translate" floating-label></mdl-textfield>
        <mdl-list [class.changed]="changed">
          <mdl-list-item class="project-list-item" *ngFor="let project of projectList  | vpTaskProjectSearch : searchText; let i = index;"
            [class.selected]="i == selectedIndex">
            <mdl-list-item-primary-content>
              <span>{{project.name}}</span>
            </mdl-list-item-primary-content>
            <mdl-list-item-secondary-action>
              <mdl-checkbox [value]="isProjectSelected(project)" (change)="projectSelected($event , project)"></mdl-checkbox>
            </mdl-list-item-secondary-action>
          </mdl-list-item>
        </mdl-list>
      </div>
    </div>
  </div>
  <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="close()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="!changed" (click)="saveProjects()">
              {{ 'SAVE' | translate }}
          </button>
      </div>
      <div class="mobile-footer" *ngIf="changed">
          <div class="title">
              {{ 'SETTINGS_UPDATED_APPLY_CHANGES' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button (click)="saveProjects()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
  </div>
</div>
