
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="repeat_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
    <mdl-icon>autorenew</mdl-icon>
    <p>{{ 'REPEAT' | translate }}</p>
  </div>

  <div id="repeat_option_list" class="option-list" *ngIf="active">
    <mdl-list>
      <mdl-list-item *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="onItemClick(item)">
          <mdl-icon *ngIf="item.value!=='never'">autorenew</mdl-icon>
          <span>{{item.name}}</span>
      </mdl-list-item>
    </mdl-list>
  </div>
