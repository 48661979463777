
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType, getFolderList } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { List, dialogType } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-folder-option-list-item",
  template: `
    <div class="vp-folder-option-list-item-dialog">
      <mdl-list [class.mat-list-disabled]="iscontextMenuActionsDisabled">
        <mdl-list-item (click)="$event.stopPropagation();deleteList()">
          <mdl-icon class="disable-select">delete</mdl-icon>
          <span class="disable-select">{{ 'DELETE' | translate }}</span>
        </mdl-list-item>
        <mdl-list-item (click)="$event.stopPropagation();renameList()">
          <mdl-icon class="disable-select">edit</mdl-icon>
          <span class="disable-select">{{ 'RENAME' | translate }}</span>
        </mdl-list-item>
        <mdl-list-item (click)="$event.stopPropagation();hide()">
          <mdl-icon class="disable-select">close</mdl-icon>
          <span class="disable-select">{{ 'CLOSE' | translate }}</span>
        </mdl-list-item>
      </mdl-list>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FolderOptionListItemComponent implements OnDestroy {
  isAlive: boolean = true;
  isOnline: boolean = false;
  dialogType = dialogType;
  iscontextMenuActionsDisabled: boolean = true;
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private dialogRef: MatDialogRef<FolderOptionListItemComponent>
  ) {
    if (CommonUtil.isOnIOS()) {
      setTimeout(() => {
          this.iscontextMenuActionsDisabled = false;
          this.changerDetectorRef.markForCheck();
      }, 1000);
    } else {
      this.iscontextMenuActionsDisabled = false;
    }

    this.setupStore();
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideFolderOptionListItemDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  deleteList() {
    this.dialogRef.close({ type: TasksConstants.DELETE });
  }

  renameList() {
    this.dialogRef.close({ type: TasksConstants.RENAME });
  }
}
