
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Component, OnDestroy, ViewChild, AfterViewInit, ElementRef } from "@angular/core";
import { TasksRootState, getAuthUser } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference, MdlSnackbarService, MdlTextFieldComponent } from "@angular-mdl/core";
import { dialogType, AuthUser } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { TasksConstants } from "../../shared/task-constacts";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-invite-users",
  template: `
    <div class="vp-invite-users-dialog">
    <form [formGroup]="form">
        <div class="mdl-dialog__title">{{ 'INVITE_USERS' | translate }}</div>
        <div class="mdl-dialog__header-body">{{ 'INVITE_USER_NOTES' | translate }}</div>
        <div class="mdl-dialog__content">
            <textarea  (input)="inputHandler($event)" (change)="changeValue($event)" id="emailAddress" type="text" #emailAddress formControlName="name" class="email_add_div mail-line-1"></textarea>
        </div>
        <div class="mdl-dialog__actions">
          <button mdl-button (click)="inviteTeamUsers(form.value)" [disabled]="!form.valid" mdl-colored="primary" mdl-ripple>{{ 'INVITE' | translate }}</button>
          <button mdl-button mdl-colored="primary" (click)="cancel()">{{ 'CANCEL' | translate }}</button>
        </div>
    </form>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InviteUsersComponent implements AfterViewInit, OnDestroy {
  isAlive: boolean = true;
  isOnline: boolean = false;
  dialogType = dialogType;
  form: UntypedFormGroup;
  name = new UntypedFormControl(null, [Validators.required]);
  isLoading: boolean = false;
  invitationCount: number;
  mailLines = 1;
  @ViewChild("emailAddress", { static: false }) emailAddress: ElementRef;
  authUser: AuthUser;
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private dialog: MdlDialogReference,
    private fb: UntypedFormBuilder,
    private mdlSnackbarService: MdlSnackbarService,
    private successService: SuccessService,
    private errorService: ErrorService,
    private messageTranslatorService: MessageTranslatorService
  ) {
    this.setupStore();
    this.handleInviteTeamUsersErrorMessages();
    this.handleInviteTeamUsersSuccessMessages();
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideInviteUsersDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.form = this.fb.group({
      "name": this.name
    });
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.emailAddress) {
      setTimeout( () => {
        this.emailAddress.nativeElement.focus();
      }, 200);
    }
  }

  handleInviteTeamUsersErrorMessages() {
    this.errorService.only(ErrorType.InviteTeamUsersError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleInviteTeamUsersSuccessMessages() {
    this.successService.only(SuccessType.InviteTeamUsersSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        this.invitationCount + " " + success.messages
      );
      this.isLoading = false;
      this.hide();
      this.changerDetectorRef.markForCheck();
    });
  }

  cancel(value?) {
    this.dialog.hide(value);
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialog.hide();
  }

  inviteTeamUsers(form) {
    if (this.isOnline) {
      if (form && this.authUser.can_invite_users === "true") {
        let email = form.name.replace(/,\s*$/, "");
        let emailArray = email.split(",");
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let isValid = true;
        emailArray.forEach(email => {
          if (!email.match(mailformat)) {
            isValid = false;
          }
        });
        if (!isValid) {
          this.mdlSnackbarService.showToast(
            this.messageTranslatorService.getMessage(TasksConstants.ENTER_VALID_EMAIL_ADDRESS)
          );
          return false;
        }
        this.invitationCount = emailArray.length;
        this.isLoading = true;
        this.taskRepo.inviteTeamUsers(emailArray);
      } else {
        this.mdlSnackbarService.showToast(
          this.messageTranslatorService.getMessage(TasksConstants.REACHED_LIMIT_FOR_ACTIVE_USER)
        );
      }
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  changeValue(event) {
    this.resizeTextBox();
  }

  inputHandler(event) {
    this.resizeTextBox();
  }

  resizeTextBox() {
    this.resetTextArea();
    let input = this.emailAddress.nativeElement;
    let emailLines = Math.floor(input.scrollHeight / 18);
    if (emailLines > 1) {
      if (emailLines > 3) {
        this.mailLines = 4;
      } else {
        this.mailLines = emailLines;
      }
    } else {
      this.mailLines = 1;
    }
    let element = document.querySelectorAll("#emailAddress")[0];
    element.classList.add("mail-line-" + this.mailLines);
    this.changerDetectorRef.markForCheck();
  }

  resetTextArea() {
    let element = document.querySelectorAll("#emailAddress")[0];
    let buttonEle;
    for (let i of Array.from(Array(this.mailLines).keys())) {
      if ( i !== 0) {
        element.classList.remove("mail-line-" + (i + 1));
      }
    }
    this.mailLines = 1;
    this.changerDetectorRef.markForCheck();
  }
}
