
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div id="new_task_dialog" class="vnctask-compose-dialog task__dialog open">
  <div id="new_task_dialog_header" class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="goBack()">
            <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
        {{'NEW_TASK' | translate}}
    </div>
    <div class="desktop-close-button">
        <button mat-button (click)="goBack()">
            <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
  </div>
  <div class="task__dialog-body add-task-options" [class.save_mode]="!submitting && !activeComponent">
    <vp-vnctask-compose-input [isVisible]="submitting" [optionComponents]="_optionComponents" (onValueChange)="onValueChange($event)" (onActiveComponentChange)="onActiveComponentChange($event)"
      (onAdd)="addTask()" (onRemoveTag)="removeTag($event)" (onRemoveWatcher)="removeWatcher($event)"></vp-vnctask-compose-input>
    <div id="options-container" class="vnctask-compose-dialog__options-container" [class.optionview]="activeComponent" [class.mainView]="activeComponent">
      <vp-vnctask-option-start-date [isVisible]="submitting" (onOpen)="openDialog($event)" (onClick)="onStartDateClick($event)" (onSelect)="onOptionClick($event)"
        [onMainView]="!activeComponent"></vp-vnctask-option-start-date>
      <vp-vnctask-option-due-date [isVisible]="submitting" (onOpen)="openDialog($event)" (onClick)="onCompletionDateClick($event)" (onSelect)="onOptionClick($event)"
        [onMainView]="!activeComponent"></vp-vnctask-option-due-date>
      <vp-vnctask-option-priority [isVisible]="submitting" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-priority>
      <vp-vnctask-option-link-project *ngIf="projectList.length > 1" [isVisible]="submitting" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-link-project>
      <vp-vnctask-option-add-user [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)" [onMainView]="!activeComponent" (onSelect)="onOptionClick($event)"
        [project]="selectedProject"></vp-vnctask-option-add-user>
      <vp-vnctask-option-link-list [isVisible]="submitting" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-link-list>
      <vp-vnctask-option-add-repeats [isVisible]="submitting" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-repeats>
      <vp-vnctask-option-add-estimate [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-estimate>
      <vp-vnctask-option-add-tags [isVisible]="submitting" (onOpen)="openDialog($event)" (onBack)="goBack()" (onSelect)="onTagOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-tags>
      <vp-vnctask-option-link-location [isVisible]="submitting" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-link-location>
      <vp-vnctask-option-add-description [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-description>
      <vp-vnctask-option-add-watchers [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)" [onMainView]="!activeComponent" (onSelect)="onWatcherOptionClick($event)"
        [project]="selectedProject"></vp-vnctask-option-add-watchers>
    </div>
  </div>
  <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="goBack()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="submitting || activeComponent || addTaskInProgress" (click)="addTask()">
              {{ 'CREATE' | translate }}
          </button>
      </div>
      <div class="mobile-footer" *ngIf="!submitting && !activeComponent">
          <div class="title">
              {{ 'CREATE_TASK' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button [disabled]="addTaskInProgress" (click)="addTask()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
  </div>
  <!-- <div *ngIf="!submitting && !activeComponent" [class.save-with-toolbar]="isDescOpen" class="task__dialog-footer">
    <span>{{'CREATE_TASK' | translate}} </span>
    <button mdl-ripple mdl-button mdl-button-type="fab" [disabled]="addTaskInProgress" (click)="addTask()" id="addTaskButton">
      <mdl-icon>done</mdl-icon>
    </button>
  </div> -->
</div>
<div class="vnctask-loading" *ngIf="isOnIOS && isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
