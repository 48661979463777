
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="task-item-div" class="task-item disable-select" (click)="onTaskClicked()" vpLongPress [timeout]="700" (onLongPress)="$event.stopPropagation();openContextMenu()" [ngClass]="{'selected-hightlight': task.isSelected, 'collected-task': task.isChecked }"
  *ngIf="task">
  <div class="task-item__check">
    <mdl-checkbox [(ngModel)]="task.isChecked" (click)="$event.stopPropagation();" (change)="onSelectTask(task.isChecked)"></mdl-checkbox>
    <!-- <vp-avatar [class.task-comment-avtar-device]="task.isChecked" [user]="{ name: task.assigned_to && task.assigned_to.name ? task.assigned_to.name : '', avatarURL: task.userAvatar }"> </vp-avatar> -->

    <vp-avatar [class.task-comment-avtar-device]="task.isChecked" [stopPropagation]="true" [jid]="task.assigned_to.jid" [menuDisabled]="getMenuDisabled(task.assigned_to.jid)" vncActionWheelMenu [type]="'detail-mobile-avatar'" [user]="{ name: task.assigned_to && task.assigned_to.name ? task.assigned_to.name : '', avatarURL: task.userAvatar }"></vp-avatar>

    <div class="task-item__avtar" *ngIf="task.isChecked">
      <div class="task-icon-avtar task-done-avtar disable-select">
        <mdl-icon>done</mdl-icon>
      </div>
   </div>
    <div class="summary-pane-detail">
      <div>
        <span class="header-text disable-select">{{task.subject}}</span>
        <span *ngIf="task.repeat && task.repeat !== 'n'" class="repeat-icon"><mdl-icon>autorenew</mdl-icon></span>
      </div>
      <span class="tag-list-item" *ngIf="task.tags && task.tags.length > 0">
          <span *ngFor="let tag of task.tags; let i = index">
            <ng-container *ngIf="i <= 4">
              <span class="tag-text">{{ tag.name }}</span>
            </ng-container>
            <ng-container *ngIf="i == 5 && !task.isMoreTags">
                <span class="view-all-text" (click)="$event.stopPropagation();task.isMoreTags = true;">{{ 'VIEW_ALL' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="i > 4 && task.isMoreTags">
                <span class="tag-text">{{ tag.name }}</span>
            </ng-container>
          </span>
      </span>
    </div>
  </div>
  <div class="task-item__more">
    <div class="task-item__more__due disable-select" [ngClass]="(task.status.name !== 'Closed' && task.status.name !== 'Completed' && task.due_date && task.due_date < currentDate) ? 'task-item__more__due_date' : ''">
      <span>{{ dueDateDisplayFormat() }}</span>
    </div>
    <div class="task-item__more__due disable-select" [ngClass]="(task.status.name !== 'Closed' && task.status.name !== 'Completed' && task.due_date && task.due_date < currentDate) ? 'task-item__more__due_date' : ''">
      <span>{{ dueDateDisplayYear() }}</span>
    </div>
    <!-- <div class="task-item__more__status disable-select">
      <mdl-icon *ngIf="task.status.name === 'New'">panorama_fish_eye</mdl-icon>
      <mdl-icon *ngIf="task.status.name === 'In Progress'">timelapse</mdl-icon>
      <mdl-icon *ngIf="task.status.name === 'Closed' || task.status.name === 'Completed'">check_circle</mdl-icon>
    </div> -->
    <div class="task-item__more__progress disable-select">
      <div [ngStyle]="{'width': task.done_ratio + '%' }"></div>
    </div>
  </div>
</div>
