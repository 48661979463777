
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Component, OnDestroy, ElementRef, ViewChild, Input } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { TasksRootState, getSaveSearches } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { AuthUser, SearchQuery } from "../../models";
import { getOnlineStatus } from "../../../reducers";
import { SearchRepository } from "../../repository/search.repository";
import sort from "fast-sort";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-save-search-dialog",
  template: `
  <div class="task__dialog vp-save-search-dialog" id="savedSearchDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'SAVED_SEARCHES' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body">
    <div class="option-list">
    <mdl-textfield id="savedSearchTextField" class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'SEARCH_FILTER' | translate"  floating-label></mdl-textfield>
      <mdl-list id="savedSearchList">
        <mdl-list-item
          *ngFor="let saveSearch of saveSearches  | vpTaskProjectSearch : searchText;" (click)="navigateRoute(saveSearch)">
          <div>
            <div>
              <span class="query-name disable-select">{{ saveSearch.name }}</span>
              <span class="query-date">{{ saveSearch.created_on | vpLocaleDate: 'dd.MM.yyyy'}}</span>
            </div>
            <div>
              <span class="query-result-count">{{ saveSearch.issues_count }} {{ 'SEARCH_RESULTS' | translate }}</span>
            </div>
          </div>
        </mdl-list-item>
      </mdl-list>
    </div>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SaveSearchDialogComponent implements OnDestroy {
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  isOnline: boolean = false;
  searchText: string = "";
  query: string = "";
  isLoading: boolean = false;
  saveSearches: SearchQuery[] = [];
  @ViewChild("input", { static: false }) input: ElementRef;
  authUser: AuthUser;
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private searchRepo: SearchRepository,
    private dialogRef: MatDialogRef<SaveSearchDialogComponent>
  ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.broadcaster.on<any>("hideSaveSearchDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.setupStore();
    this.focus();
    this.changerDetectorRef.markForCheck();
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getSaveSearches).pipe(takeWhile(() => this.isAlive)).subscribe((saveSearches) => {
      this.saveSearches = saveSearches;
      sort(this.saveSearches).desc( saveSearch => {
          if (saveSearch.created_on) {
            return saveSearch.created_on;
          } else {
            return null;
          }
      });
      this.changerDetectorRef.markForCheck();
    });
  }

  focus() {
    setTimeout(() => {
      if (this.input && this.input.nativeElement) {
        this.input.nativeElement.focus();
        this.changerDetectorRef.markForCheck();
      }
    }, 1000);
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close(query?) {
    this.dialogRef.close({ query: query});
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  clearText() {
    this.query = "";
    this.changerDetectorRef.markForCheck();
  }

  navigateRoute(saveSearch: SearchQuery) {
    if (this.isOnline) {
      let query = this.searchRepo.generateSearchQueryFromFilters(saveSearch.filters);
      this.close(query);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }
}
