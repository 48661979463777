
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { ConfigService } from "../../common/providers/config.service";
import { TranslateService } from "@ngx-translate/core";
import { TasksConstants } from "../shared/task-constacts";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MessageTranslatorService {
    private translationMessage: any = {};
    translatedMessagesd$: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        private configService: ConfigService,
        private translate: TranslateService,
    ) {
        this.updateTranslatedMessages();
        this.configService.currentLanguage.subscribe((lang: string) => {
          this.updateTranslatedMessages();
       });
    }

    private updateTranslatedMessages() {
      this.translate.get([
        TasksConstants.SEARCH_STRING_ERR_MSG,
        TasksConstants.TASK_PROJECT,
        TasksConstants.TASK_UPDATED,
        TasksConstants.TASK_ERR_MSG,
        TasksConstants.TASK_START_DATE,
        TasksConstants.TASK_DUE_DATE,
        TasksConstants.ASSIGN_USER,
        TasksConstants.CHANGE_REPEAT_PATTERN,
        TasksConstants.NO_STARTDATE,
        TasksConstants.NO_DUEDATE,
        TasksConstants.TODAY_DUE,
        TasksConstants.TOMORROW_DUE,
        TasksConstants.STATUS_SELECTION_ERROR,
        TasksConstants.DUE_DATE,
        TasksConstants.PRIORITY,
        TasksConstants.TASK_NAME,
        TasksConstants.START_DATE,
        TasksConstants.STATUS,
        TasksConstants.TASK_CREATED,
        TasksConstants.TASK_SUBJECT_REQUIRED,
        TasksConstants.TASK_REMOVED_MSG,
        TasksConstants.DUPLICATE_TASK_CREATED,
        TasksConstants.TODAY_DUE,
        TasksConstants.TOMORROW_DUE,
        TasksConstants.NO_DUEDATE,
        TasksConstants.NO_STARTDATE,
        TasksConstants.TASK_EVERY_DAY,
        TasksConstants.TASK_EVERY_WEEK,
        TasksConstants.TASK_EVERY_MONTH,
        TasksConstants.TASK_EVERY_YEAR,
        TasksConstants.TASK_NO_REPEAT,
        TasksConstants.STATUS_NEW,
        TasksConstants.STATUS_INPROGRESS,
        TasksConstants.STATUS_COMPLETED,
        TasksConstants.PROJECT_REQUIRED,
        TasksConstants.TASK_UPDATED,
        TasksConstants.PROJECT_LINKED_TASK_REQUIRED,
        TasksConstants.TASK_ESTIMATE_IS_INVALID,
        TasksConstants.TASK_DUEDATE_IS_INVALID,
        TasksConstants.TASK_START_DATE_UPDATE_FAIL,
        TasksConstants.TASK_DUE_DATE_UPDATE_FAIL,
        TasksConstants.ENTER_A_COMMENT,
        TasksConstants.LOW,
        TasksConstants.NORMAL,
        TasksConstants.HIGH,
        TasksConstants.URGENT,
        TasksConstants.IMMEDIATE,
        TasksConstants.TASK_SUBJECT_NOT_ALLOWED,
        TasksConstants.TAG_MIN_LENGTH,
        TasksConstants.DUPLICATE_TAG_NAME,
        TasksConstants.PROFILE_UPDATED_MSG,
        TasksConstants.CONTAINS,
        TasksConstants.NOT_CONTAIN,
        TasksConstants.NO_DUEDATE_SET,
        TasksConstants.SEARCH_SAVED_MSG,
        TasksConstants.PASSED_DUEDATE,
        TasksConstants.PASSED_CURRENTTIME,
        TasksConstants.SUNDAY,
        TasksConstants.MONDAY,
        TasksConstants.TUESDAY,
        TasksConstants.WEDNESDAY,
        TasksConstants.THURSDAY,
        TasksConstants.FRIDAY,
        TasksConstants.SATURDAY,
        TasksConstants.DOWNLOADING_STARTED,
        TasksConstants.DOWNLOAD_SUCCESS,
        TasksConstants.PICK_START_DATE,
        TasksConstants.PICK_DUE_DATE,
        TasksConstants.RESET_ADVANCE_SEARCH_MESSAGE,
        TasksConstants.DELETE_TASK_MESSAGE,
        TasksConstants.CONFIRM_DELETE_ATTACHMENT,
        TasksConstants.FILE_UPLOAD_SIZE_ERROR,
        TasksConstants.TAGS,
        TasksConstants.TASK_NOT_SAVED_MSG,
        TasksConstants.SAVE_TASK,
        TasksConstants.ACTIVATION_LINK_SEND_SUCCESS,
        TasksConstants.FORGOT_PASSWORD_SEND_SUCCESS,
        TasksConstants.RESET_PASSWORD_SUCCESS,
        TasksConstants.CHANGE_PASSWORD_SUCCESS,
        TasksConstants.NEW_REGISTER_ERROR_MESSAGE,
        TasksConstants.RESET_PASSWORD_ERROR,
        TasksConstants.CHANGE_PASSWORD_ERROR,
        TasksConstants.COMING_SOON,
        TasksConstants.UNKNOWN_USER,
        TasksConstants.INTERNET_LOST_CONNECTION,
        TasksConstants.OFFLINE,
        TasksConstants.WRONG_CURRENT_PWD_ERROR,
        TasksConstants.DEACTIVATE_ACCOUNT_SUCCESS,
        TasksConstants.CONFIRM_DEACTIVATION_MESSAGE,
        TasksConstants.CONFIRM_DEACTIVATION_NOTE,
        TasksConstants.LISTS,
        TasksConstants.LIST_CREATED,
        TasksConstants.LIST_DELETED,
        TasksConstants.LIST_UPDATED,
        TasksConstants.ACTIVATION_LINK_EXPIRED_MESSAGE,
        TasksConstants.TOKEN_EXPIRED,
        TasksConstants.ENTER_VALID_EMAIL_ADDRESS,
        TasksConstants.DOWNLOAD_NOT_IMAGEVIDEO_ATTACHMENT_NOT_PERMITTED_IOS,
        TasksConstants.ONLY_AUTHOR_CAN_INVITE,
        TasksConstants.EXCEED_LIMIT_FOR_INVITE,
        TasksConstants.HAVE_NOT_ACTIVATED_MSG,
        TasksConstants.ACCOUNT_LOCKED_MSG,
        TasksConstants.ACCOUNT_NOT_FOUND_WITH_EMAIL,
        TasksConstants.NOT_ALLOWED_REGISTER,
        TasksConstants.START_DATE_CHANGE_ERROR_MESSAGE,
        TasksConstants.PROFILE_REMOVED_MSG,
        TasksConstants.TIME_CANCEL_LABEL,
        TasksConstants.TIME_SET_LABEL,
        TasksConstants.CONFIRM_DUPLICATE_TASK,
        TasksConstants.CONFIRM_DUPLICATE_SELECTED_TASKS,
        TasksConstants.CONFIRM_DELETE_TASK_MESSAGE,
        TasksConstants.USER_ARCHIVED,
        TasksConstants.USER_UNARCHIVED,
        TasksConstants.INVITATION_SENT,
        TasksConstants.PERMISSION_DECLINED,
        TasksConstants.REACHED_LIMIT_FOR_ACTIVE_USER,
        TasksConstants.SUBJECT_IS_TOO_LONG,
        TasksConstants.DONE_EQUAL_ERROR,
        TasksConstants.SETTINGS_SAVE_SUCCESS,
        TasksConstants.LOCATIONS,
        TasksConstants.LOCATION_CREATED,
        TasksConstants.LOCATION_DELETED,
        TasksConstants.LOCATION_UPDATED,
        TasksConstants.LIST_IS_TOO_LONG,
        TasksConstants.LOCATION_IS_TOO_LONG,
        TasksConstants.LOG_TIME_SUCCESS,
        TasksConstants.SPENT_TIME_INVALID,
        TasksConstants.CONFIRM_DELETE_WATCHER,
        TasksConstants.AND,
        TasksConstants.MORE,
        TasksConstants.SAVE_FILTER_IS_TOO_LONG,
        TasksConstants.TAG_SPACE_NOT_ALLOWED,
        TasksConstants.TASK_ESTIMATE_MINUTE_IS_INVALID,
        TasksConstants.FOUND_UPDATE_TASK_CONFLICT_ERROR,
        TasksConstants.CREATED,
        TasksConstants.RESOURCE_NOT_FOUND,
        TasksConstants.TASK_HAS_BEEN_DELETED,
        TasksConstants.TASK_UPDATE_TITLE,
        TasksConstants.TASK_UPDATE_SUB_TITLE,
        TasksConstants.NEW_TASK_ASSIGNMENT_TITLE,
        TasksConstants.NEW_TASK_ASSIGNMENT_SUB_TITLE,
        TasksConstants.REMINDER_TASK_DUE_TODAY,
        TasksConstants.TASK_DUE_TODAY,
        TasksConstants.ENTER_WRONG_OTP,
        TasksConstants.TFA_CONFIGURED_SUCCESS,
        TasksConstants.ERROR_SAVE_2FA_SETTING,
        TasksConstants.SPENT_TIME_SHOULD_BE_GREATER,
        TasksConstants.TASK_WATCHER_TITLE,
        TasksConstants.TASK_WATCHER_SUB_TITLE
    ])
    .subscribe(res => {
        this.translationMessage = res;
        this.translatedMessagesd$.next(this.translationMessage);
      });
    }

    getMessage(key: string): string {
      // console.log("[MessageTranslatorService][getMessage] key", key);

      let result = this.translationMessage[key];
      // console.log("[MessageTranslatorService][getMessage] result", result);

      return result;
    }
}
