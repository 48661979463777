
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, EventEmitter, OnChanges, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "./app.routes";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TasksRootState } from "../../../task/store";
import { Store } from "@ngrx/store";
import { getFederatedApps } from "../../../reducers";
import { InstallAppDialogComponent, APP_DATA } from "../install-app/install-app.component";
import { MdlDialogService } from "@angular-mdl/core";
import { environment } from "../../../../environments/environment";
import { takeUntil } from "rxjs/operators";
import { ConfigService } from "src/app/common/providers/config.service";

@Component({
  selector: "vp-app-switcher",
  templateUrl: "app-switcher.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSwitcherComponent implements OnChanges, OnDestroy {
  @Input() switchApp: boolean;
  @Input() url: string;
  @Output() closeSwitch = new EventEmitter();
  subscriber: any = null;
  routes: AppRoutes[] = [];
  private isAlive$ = new Subject<boolean>();
  constructor(
    public dialogService: MdlDialogService,
    private router: Router,
    private store: Store<TasksRootState>,
    private configService: ConfigService) {
      this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
        let routes = [];
        console.log("[AppSwitcherComponent]", apps);
        if ( apps && apps.length === 0) {
          let fedApps = localStorage.getItem("federatedApps");
          if (fedApps) {
            apps = JSON.parse(fedApps);
          }
        }
        apps.forEach(app => {
          routes.push({
            title: app?.options?.title,
            icon: this.configService.get("publicVncDirectoryUrl") + app?.options?.icon,
            image: this.configService.get("publicVncDirectoryUrl") + app?.options?.image,
            nativeLink: `${app?.name?.toLowerCase()}://main`,
            path: app?.options?.url,
            position: app?.options?.position,
            active: true,
            enabled: true
          });
        });
        this.routes = routes;
      });
  }

  navigate(path: any) {
    this.router.navigate(path);
    this.closeSwitch.emit(false);
  }

  checkInstalledApp(uri: string, appName: string): Observable<boolean> {
    const subject = new BehaviorSubject<boolean>(false);
    if (environment.isCordova) {
      if (CommonUtil.isOnAndroid()) {
        uri = CommonUtil.getPackageName(appName);
      }
      uri = uri.replace("main", "");
      console.log("[checkInstalledApp]", uri);
      appAvailability.check(
        uri, // URI Scheme
        () => {
          console.log("[checkInstalledApp] installed", appName);
          subject.next(true);
        },
        () => {
          console.log("[checkInstalledApp] not installed", appName);
          subject.next(false);
          let dialogStyles = {
            "width": "80%",
            "min-width": "80%",
            "max-width": "80%",
            "height": "auto",
            "visibility": "visible"
          };

          this.dialogService.showCustomDialog({
            component: InstallAppDialogComponent,
            providers: [{ provide: APP_DATA , useValue: {appName: appName} }],
            isModal: true,
            styles: dialogStyles,
            animate: false,
            clickOutsideToClose: true
          });
        }
      );
    } else {
      subject.next(false);
    }

    return subject.asObservable();
  }

  openApp(item): void {
    if (!CommonUtil.isOnMobileDevice()) {
      console.log("APPSWITCH openApp: ", item);
      if (item.title === "VNCtask") {
        // no need to re-open current app!
        this.closeSwitch.emit(false);
        return;
      }

      const path: string = new URL(item.path).origin;
      if (["VNCproject", "VNCdirectory"].indexOf(item.title) > -1) item.path = `${path}/login`;
      if (["VNCtalk", "VNCmail", "VNCcalendar", "VNCtask"].indexOf(item.title) > -1) item.path = `${path}/api/login`;

      window.open(item.path, "_blank");
    } else {
      window.open(item.nativeLink, "_system");
    }
    this.closeSwitch.emit(false);
  }

  ngOnChanges(): void {
    (this.routes || []).forEach(route => {
      if (route.path === this.url && route.enabled) {
        route.active = true;
      } else {
        route.active = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.closeSwitch.unsubscribe();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

}
