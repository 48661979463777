
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="assign-user-option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <mdl-icon>person</mdl-icon>
  <p>{{ 'ASSIGN_USER' | translate }}</p>
</div>

<div id="assign-user-option-list" class="option-list add-assignee-user-dialog" *ngIf="active">
  <div *ngIf="authUser.team_user === 'false' && authUser.can_invite_users === 'true' && project.can_invite_users === 'true'">
      <div class="assignee-user-save-close">
        <span>{{ 'ASSIGN_USER' | translate }}:</span>
        <div class="icons-div">
          <mdl-icon class="icon-close" (click)="goBack()">clear</mdl-icon>
          <mdl-icon *ngIf="email.valid && email.value !== ''" class="icon-check" (click)="setExternal()">check_circle</mdl-icon>
        </div>
      </div>
      <div class="external-user-div">
        <mdl-icon class="user-icon">person</mdl-icon>
        <div class="external-textfield">
            <mat-form-field hintLabel="{{ 'HINTLABEL_FOR_INVITE_EXTERNAL' | translate }}">
              <input type="email" [formControl]="email" autocomplete="email" (input)="changeValue($event)" (change)="changeValue($event)" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput>
              <mat-error *ngIf="email.errors?.pattern">
                  {{ 'EMAIL_INVALID_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
        </div>
      </div>
  </div>
  <mdl-list [class.external-user-list]="authUser.team_user === 'false' && authUser.can_invite_users === 'true' && project.can_invite_users === 'true'">
    <mdl-list-item
      *ngFor="let member of filteredItems; let i = index;"
      [class.selected]="i == selectedIndex"
      (click)="onItemClick(member)">
      <vp-avatar [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
      <span class="username-place">{{(member.name !== "") ? member.name : ('NO_NAME' | translate)  }}</span>
    </mdl-list-item>
  </mdl-list>
</div>
