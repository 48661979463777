
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="media-preview-dialog-component media-preview-dialog-desktop" *ngIf="!isOnMobile">
  <div class="image-wrapper">
    <div class="image-header">
      <div class="filename">{{ getFileName() }}</div>
      <span></span>
      <button id="closePreviewDialog" mdl-button mdl-button-type="icon" (click)="close()">
        <mdl-icon>close</mdl-icon>
      </button>
    </div>
    <div class="media" [ngSwitch]="selectedMediaType">

      <div *ngSwitchCase="MediaType.VOICE_MESSAGE">
          <img class="audio-preview-image" [src]="CommonUtil.getFullUrl('/assets/media-icons/file-document-mic.svg')" alt="mic">
          <audio id="music" preload="true" controls controlsList="nodownload"
                 onended="this.currentTime = 0;this.pause();" [src]="url">
          </audio>
      </div>
      <img *ngSwitchDefault [style.background]="placeholder" [src]="url"/>

      <video *ngSwitchCase="MediaType.VIDEOS"  preload="true" controls [src]="url" controlsList="nodownload">
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <div class="media-information">
      <div class="title-and-time">
        <span class="media-title">{{ getSenderName() }}</span>
        <span class="timeago">{{ getTimeAgo() }}</span>
      </div>

      <span></span>
      <div class="image-actions">
        <button mdl-button (click)="underDevelopment()">
          <mdl-icon>share</mdl-icon>
        </button>
        <button mdl-button (click)="downloadAttachment()">
          <mdl-icon>file_download</mdl-icon>
        </button>
        <button *ngIf="isDeletable()" mdl-button (click)="deleteAttachment()">
          <mdl-icon>delete</mdl-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="media-preview-dialog-mobile" *ngIf="isOnMobile" [ngSwitch]="selectedMediaType">
<div class="media-image-preview" *ngSwitchCase="MediaType.IMAGE">
    <div class="media-image-preview__header">
      <button mdl-button class="back-to-media" (click)="close()">
        <mdl-icon>chevron_left</mdl-icon>
      </button>
      <div class="image-file-name">
        <p>{{getFileName()}}</p>
      </div>
      <span></span>
      <div class="more-options" id="media-menu">
          <button mdl-button (click)="downloadAttachment()">
            <mdl-icon>arrow_downward</mdl-icon>
          </button>
      </div>
      <div class="more-options" id="media-menu">
        <button mdl-button #previewMenuBtn="mdlButton" (click)="showDropdown($event, previewMenu, previewMenuBtn, 'call-actions');">
          <mdl-icon>more_vert</mdl-icon>
        </button>
      </div>
    </div>
    <div class="media-image-preview__body">
      <img [src]="url" alt="image">
    </div>
    <div class="media-image-preview__footer">
      <div class="sender-name">
        <p>{{ getSenderName() }}</p>
      </div>
      <div class="sent-time">
        <p>{{ getTimeAgo() }}</p>
      </div>
    </div>
  </div>
  <div class="media-audio-preview" *ngSwitchCase="MediaType.VOICE_MESSAGE">
      <div class="media-audio-preview__header">
        <button mdl-button class="back-to-media" (click)="close()">
          <mdl-icon>chevron_left</mdl-icon>
        </button>
        <div class="audio-file-name">
          <p>{{getFileName()}}</p>
        </div>
        <span></span>
        <div class="more-options" id="media-menu">
            <button mdl-button (click)="downloadAttachment()">
              <mdl-icon>arrow_downward</mdl-icon>
            </button>
        </div>
        <div class="more-options" id="media-menu">
          <button mdl-button #previewMenuBtn="mdlButton" (click)="showDropdown($event, previewMenu, previewMenuBtn, 'call-actions');">
            <mdl-icon>more_vert</mdl-icon>
          </button>
        </div>
      </div>
      <div class="media-audio-preview__body">
        <img [src]="CommonUtil.getMediaUrl(MediaType.VOICE_MESSAGE)" alt="mic">
      </div>
      <div class="media-audio-preview__audio">
        <audio id="music" preload="true" controls controlsList="nodownload"
               onended="this.currentTime = 0;this.pause();" [src]="url">
        </audio>
      </div>
    </div>

    <div class="media-video-preview" *ngSwitchCase="MediaType.VIDEOS">
      <div class="media-video-preview__header">
        <button mdl-button class="back-to-media" (click)="close()">
          <mdl-icon>chevron_left</mdl-icon>
        </button>
        <div class="video-file-name">
          <p>{{getFileName()}}</p>
        </div>
        <span></span>
        <div class="more-options" id="media-menu">
          <button mdl-button (click)="downloadAttachment()">
            <mdl-icon>arrow_downward</mdl-icon>
          </button>
        </div>
        <div class="more-options" id="media-menu">
          <button mdl-button #previewMenuBtn="mdlButton" (click)="showDropdown($event, previewMenu, previewMenuBtn, 'call-actions');">
            <mdl-icon>more_vert</mdl-icon>
          </button>
        </div>
      </div>
      <div class="media-video-preview__body">
        <video controls [src]="url"  preload="true" controlsList="nodownload">
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>

    <mdl-menu #previewMenu="mdlMenu" mdl-menu-position="bottom-right" class="options-menu">
      <mdl-menu-item mdl-ripple class="menu-item" (click)="shareAttachment()">
        <mdl-icon>share</mdl-icon>
        <p>{{'SHARE' | translate}}</p>
      </mdl-menu-item>
      <mdl-menu-item *ngIf="isDeletable()" class="menu-item" (click)="deleteAttachment()">
        <mdl-icon>delete</mdl-icon>
        <p>{{'DELETE' | translate}}</p>
      </mdl-menu-item>
    </mdl-menu>
  </div>
