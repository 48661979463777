
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { Project } from "../../../../models";
import { Store } from "@ngrx/store";
import { TasksRootState, getProjectsList } from "../../../../store/index";
import { MdlDialogService } from "@angular-mdl/core";
import { takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-option-link-project",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => TaskLinkProjectOptionComponent)
  }],
  templateUrl: "option-link-project.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskLinkProjectOptionComponent extends TaskOptionComponent implements OnInit, OnDestroy {
  id: string = "link-to-project";
  triggerKey = "=";
  isAlive = true;

  constructor(private store: Store<TasksRootState>,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog ) {
    super(changerDetectorRef, matDialog);
  }

  ngOnInit() {
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe( projects => {
      this.setItems(projects);
    });
  }

  getPayload() {
    let value: Project = this.getValue();
    if (!value) return { };
    return { project_id: value.id, project_name: value.name };
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

}
