
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="advance-search-dialog" class="advance-search-dialog task__dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
        {{'ADVANCED_SEARCH' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="clear()">
        <mat-icon class="disable-select">cached</mat-icon>
      </button>
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="mobile-back-button">
      <button mat-button (click)="clear()">
        <mat-icon class="disable-select">cached</mat-icon>
      </button>
    </div>
  </div>
  <div class="mdl-dialog__content task__dialog-body">
    <div class="advance-search-list">
      <mdl-list>
          <mdl-list-item class="app-container" (click)="redirectTo('apps')">
            <mdl-list-item-primary-content>
              <span class="heading">{{ 'SEARCH_IN_APPS' | translate }}</span>
              <mdl-list-item-sub-title *ngIf="advanceSearch.app_types.length === 6">{{ 'ALL_APPS' | translate }}</mdl-list-item-sub-title>
              <mdl-list-item-sub-title *ngIf="advanceSearch.app_types.length < 6">{{ getAppsTitle() }}</mdl-list-item-sub-title>
            </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('search-through')">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'SEARCH_THROUGH' | translate }}</span>
                <mdl-list-item-sub-title><ng-container
                  *ngIf="advanceSearch.search_through_type.taskname && advanceSearch.search_through_type.description && advanceSearch.search_through_type.comment; else selectedTypes">
                  {{ 'CONTENT_ALL_FIELDS' | translate}}
                </ng-container>
                <ng-template #selectedTypes>
                  <ng-container
                    *ngIf="advanceSearch.search_through_type.taskname">
                    {{ 'TASK_NAME' | translate}}
                    <ng-container
                      *ngIf="advanceSearch.search_through_type.description || advanceSearch.search_through_type.comment">,
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="advanceSearch.search_through_type.description">
                    {{ 'DESCRIPTION' | translate}}
                    <ng-container
                      *ngIf="advanceSearch.search_through_type.comment">,
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="advanceSearch.search_through_type.comment">
                    {{ 'COMMENT' | translate }}
                  </ng-container>
                </ng-template>
              </mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('create-date-range')">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'CREATE_DATE_RANGE' | translate }}</span>
                <mdl-list-item-sub-title>
                  <ng-container *ngIf="advanceSearch.time.type === TimeRangeType.NONE">
                    {{ getTimeRangeObject(TimeRangeType.NONE).translation_key | translate }}
                  </ng-container>
                  <ng-container *ngIf="advanceSearch.time.type === TimeRangeType.LAST_HOUR">
                    {{ getTimeRangeObject(TimeRangeType.LAST_HOUR).translation_key | translate }}
                  </ng-container>
                  <ng-container *ngIf="advanceSearch.time.type === TimeRangeType.LAST_24_HOURS">
                    {{ getTimeRangeObject(TimeRangeType.LAST_24_HOURS).translation_key | translate }}
                  </ng-container>
                  <ng-container *ngIf="advanceSearch.time.type === TimeRangeType.LAST_WEEK">
                    {{ getTimeRangeObject(TimeRangeType.LAST_WEEK).translation_key | translate }}
                  </ng-container>
                  <ng-container *ngIf="advanceSearch.time.type === TimeRangeType.LAST_MONTH">
                    {{ getTimeRangeObject(TimeRangeType.LAST_MONTH).translation_key | translate }}
                  </ng-container>
                  <ng-container *ngIf="advanceSearch.time.type === TimeRangeType.CUSTOM">
                    {{ getTimeRangeObject(TimeRangeType.CUSTOM).translation_key | translate }}
                    <span>{{'FROM'|translate}}: {{advanceSearch.time.from.date | vpLocaleDate:medium}}</span>
                    <span>{{'TO'|translate}}: {{advanceSearch.time.to.date | vpLocaleDate:medium}}</span>
                  </ng-container>
                </mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('due-date-range')">
            <mdl-list-item-primary-content>
              <span class="heading">{{ 'DUEDATE_RANGE' | translate }}</span>
              <mdl-list-item-sub-title>
                <ng-container *ngIf="advanceSearch.dueDateTime.type === DueDateTimeRangeType.NONE">
                  {{ getTimeRangeObject(DueDateTimeRangeType.NONE).translation_key | translate }}
                </ng-container>
                <ng-container *ngIf="advanceSearch.dueDateTime.type === DueDateTimeRangeType.LAST_HOUR">
                  {{ getTimeRangeObject(DueDateTimeRangeType.LAST_HOUR).translation_key | translate }}
                </ng-container>
                <ng-container *ngIf="advanceSearch.dueDateTime.type === DueDateTimeRangeType.LAST_24_HOURS">
                  {{ getTimeRangeObject(DueDateTimeRangeType.LAST_24_HOURS).translation_key | translate }}
                </ng-container>
                <ng-container *ngIf="advanceSearch.dueDateTime.type === DueDateTimeRangeType.LAST_WEEK">
                  {{ getTimeRangeObject(DueDateTimeRangeType.LAST_WEEK).translation_key | translate }}
                </ng-container>
                <ng-container *ngIf="advanceSearch.dueDateTime.type === DueDateTimeRangeType.LAST_MONTH">
                  {{ getTimeRangeObject(DueDateTimeRangeType.LAST_MONTH).translation_key | translate }}
                </ng-container>
                <ng-container *ngIf="advanceSearch.dueDateTime.type === DueDateTimeRangeType.CUSTOM">
                  {{ getTimeRangeObject(DueDateTimeRangeType.CUSTOM).translation_key | translate }}
                  <span>{{'FROM'|translate}}: {{advanceSearch.dueDateTime.from.date | vpLocaleDate:medium}}</span>
                  <span>{{'TO'|translate}}: {{advanceSearch.dueDateTime.to.date | vpLocaleDate:medium}}</span>
                </ng-container>
              </mdl-list-item-sub-title>
            </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('users')">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'ASSIGNED_TO' | translate }}</span>
                <mdl-list-item-sub-title *ngIf="advanceSearch.assignees.type === UserType.NONE">{{ 'NONE' | translate }}</mdl-list-item-sub-title>
                <mdl-list-item-sub-title *ngIf="advanceSearch.assignees.type === UserType.ALL_USERS">{{ 'ALL_USERS' | translate }}</mdl-list-item-sub-title>
                <mdl-list-item-sub-title *ngIf="advanceSearch.assignees.type === UserType.SELECTED">{{ getUsersTitle() }}</mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" *ngIf="projectList.length > 1" (click)="redirectTo('projects')">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'PROJECTS' | translate }}</span>
                <mdl-list-item-sub-title *ngIf="advanceSearch.projects.length === 0">{{ 'ALL_PROJECTS' | translate }}</mdl-list-item-sub-title>
                <mdl-list-item-sub-title *ngIf="advanceSearch.projects.length > 0">{{ getProjectsTitle() }}</mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('lists')">
            <mdl-list-item-primary-content>
              <span class="heading">{{ 'LISTS' | translate }}</span>
              <mdl-list-item-sub-title *ngIf="advanceSearch.lists.type === ListType.NONE">{{ 'NONE' | translate }}</mdl-list-item-sub-title>
              <mdl-list-item-sub-title *ngIf="advanceSearch.lists.type === ListType.ALL_LISTS">{{ 'ALL_LISTS' | translate }}</mdl-list-item-sub-title>
              <mdl-list-item-sub-title *ngIf="advanceSearch.lists.type === ListType.SELECTED">{{ getListsTitle() }}</mdl-list-item-sub-title>
            </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('tags')">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'TAGS' | translate }}</span>
                <mdl-list-item-sub-title *ngIf="advanceSearch.tags.type === TagType.NONE">{{ 'NONE' | translate }}</mdl-list-item-sub-title>
                <mdl-list-item-sub-title *ngIf="advanceSearch.tags.type === TagType.ALL_TAGS">{{ 'ALL_TAGS' | translate }}</mdl-list-item-sub-title>
                <mdl-list-item-sub-title *ngIf="advanceSearch.tags.type === TagType.SELECTED">{{ getTagsTitle() }}</mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container" (click)="redirectTo('locations')">
            <mdl-list-item-primary-content>
              <span class="heading">{{ 'LOCATIONS' | translate }}</span>
              <mdl-list-item-sub-title *ngIf="advanceSearch.locations.type === LocationType.NONE">{{ 'NONE' | translate }}</mdl-list-item-sub-title>
              <mdl-list-item-sub-title *ngIf="advanceSearch.locations.type === LocationType.ALL_LOCATIONS">{{ 'ALL_LOCATIONS' | translate }}</mdl-list-item-sub-title>
              <mdl-list-item-sub-title *ngIf="advanceSearch.locations.type === LocationType.SELECTED">{{ getLocationsTitle() }}</mdl-list-item-sub-title>
            </mdl-list-item-primary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'INCLUDE_ATTACHEMENTS' | translate }}</span>
                <mdl-list-item-sub-title>{{ 'INCLUDE_ATTACHMENTS_SUB_TITLE' | translate }}</mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
              <mdl-list-item-secondary-content>
                  <mdl-switch [(ngModel)]="advanceSearch.attachments" (click)="updateSearchOptions()"></mdl-switch>
              </mdl-list-item-secondary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'INCLUDE_CLOSED_TASKS' | translate }}</span>
                <mdl-list-item-sub-title>{{ 'INCLUDE_CLOSED_TASKS_SUB_TITLE' | translate }}</mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
              <mdl-list-item-secondary-content>
                  <mdl-switch [(ngModel)]="advanceSearch.closed_tasks" (click)="updateSearchOptions()"></mdl-switch>
              </mdl-list-item-secondary-content>
          </mdl-list-item>
          <mdl-list-item class="app-container">
              <mdl-list-item-primary-content>
                <span class="heading">{{ 'SAVE_SEARCHES' | translate }}</span>
                <mdl-list-item-sub-title>{{ 'SAVE_SEARCHES_SUB_TITLE' | translate }}</mdl-list-item-sub-title>
              </mdl-list-item-primary-content>
              <mdl-list-item-secondary-content>
                  <mdl-switch [(ngModel)]="advanceSearch.save_searches" (click)="updateSearchOptions()"></mdl-switch>
              </mdl-list-item-secondary-content>
          </mdl-list-item>
      </mdl-list>
    </div>
  </div>
</div>
