
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { MdlDialogReference, MdlSnackbarService } from "@angular-mdl/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ConfirmPasswordValidator } from "./confirm-password.validator";
import { CommonUtil } from "../../../../common/utils/common.utils";
import { Router } from "@angular/router";
import { TaskRepository } from "../../../repository/task.repository";
import { ErrorService } from "../../../../common/providers/error-service";
import { SuccessService } from "../../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../../shared/task-enum";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { TasksRootState } from "../../../store/reducers";
import { Store } from "@ngrx/store";
import { getOnlineStatus } from "../../../../reducers";
import { TasksConstants } from "../../../shared/task-constacts";
import { TranslateService } from "@ngx-translate/core";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-registration-form",
  templateUrl: "./registration-form.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationFormComponent implements OnDestroy {
    backgroundImage = (CommonUtil.isOnMobileDevice()) ? "url(" + CommonUtil.getFullUrl("/assets/img/profile-background.jpg") + " )" : "none";
    registerForm: UntypedFormGroup;
    firstName: string = "";
    lastName: string = "";
    userName: string = "";
    email: string = "";
    password: string = "";
    confirmPassword: string = "";
    isAlive: boolean = true;
    isLoading: boolean = false;
    isOnline: boolean = false;
    storedLanguage: string = "en";
    isPolicyAccepted: boolean = true;

    constructor(
      private changerDetectorRef: ChangeDetectorRef,
      private fb: UntypedFormBuilder,
      private router: Router,
      private tasksRepo: TaskRepository,
      private errorService: ErrorService,
      private successService: SuccessService,
      private mdlSnackbarService: MdlSnackbarService,
      private store: Store<TasksRootState>,
      private broadcaster: Broadcaster,
      private translate: TranslateService
    ) {
      this.handleRegisterSuccessMessages();
      this.handleRegisterErrorMessages();

      this.registerForm = fb.group({
        "firstName" : [null, Validators.required],
        "lastName" : [null, Validators.required],
        "userName" : [null, Validators.required],
        "email": [null, Validators.compose([Validators.required])],
        "password": [null, Validators.compose([Validators.required, Validators.minLength(8)])],
        "confirmPassword": [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      }, {
        validator: ConfirmPasswordValidator.MatchPassword
      });

      this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
        this.isOnline = isOnline;
        this.changerDetectorRef.markForCheck();
      });
      this.storedLanguage = localStorage.getItem(TasksConstants.TASK_LANGUAGE);

      this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
        .subscribe(presence => {
          this.returnToLoginPage();
        });
        
      this.broadcaster.on<any>("hideRegistrationFormPage").pipe(takeWhile(() => this.isAlive))
        .subscribe(presence => {
          this.returnToLoginPage();
        });

      this.tasksRepo.removeLoginIframe();
    }

    handleRegisterErrorMessages() {
      this.errorService.only(ErrorType.UserRegisteredError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
        this.mdlSnackbarService.showToast(
          error.messages
        );
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
      });
    }

    handleRegisterSuccessMessages() {
      this.successService.only(SuccessType.UserRegistered).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
        localStorage.removeItem("invitation_token");
        localStorage.setItem("registration_email", this.registerForm.value.email);
        this.router.navigate(["/registration/activation"], { queryParams: {} });
      });
    }

    onFormSubmit(form) {
      if (this.isOnline) {
        let element: any = document.getElementById("privacy-policy");
        if (element && element.checked) {
          this.isLoading = true;
          let browserLang = this.translate.getBrowserLang();
          let body = {
            "user": {
              "firstname": form.firstName,
              "lastname": form.lastName,
              "login": form.userName,
              "mail": form.email,
              "password": form.password,
              "password_confirmation": form.confirmPassword,
              "language": browserLang.match(/en|de/) ? browserLang : "en"
            }
          };
          let invitation_token = localStorage.getItem("invitation_token");
          if (invitation_token) {
            body["token"] = invitation_token;
          }
          this.tasksRepo.userRegistration(body);
        } else {
          this.isPolicyAccepted = false;
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    returnToLoginPage() {
      this.router.navigate(["/task"], { queryParams: {} });
    }

    ngOnDestroy(): void {
      this.isAlive = false;
      this.changerDetectorRef.markForCheck();
    }

    clearForm(form) {
      if (form) {
        form.reset();
        let element: any = document.getElementById("privacy-policy");
        element.checked = false;
        this.changerDetectorRef.markForCheck();
      }
    }

    onChange() {
      let element: any = document.getElementById("privacy-policy");
      if (element) {
        if (element.checked) {
          this.isPolicyAccepted = true;
          this.changerDetectorRef.markForCheck();
        }
      }
    }
}
