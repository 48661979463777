
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";
import { ContactActionsTypes } from "../actions/contact.action";
import { VNCContact } from "../shared/models/contact.model";


export interface VNCContactState extends EntityState<VNCContact> {
  isLoaded: boolean;
  isLoading: boolean;
}

export const vncContactAdapter: EntityAdapter<VNCContact> = createEntityAdapter<VNCContact>({
  selectId: (contact: VNCContact) => {
    if (contact.jid) {
      return contact.jid;
    } if (contact.email) {
      return contact.email;
    }  else if (contact.emails && contact.emails.length > 0) {
      return contact.emails[0].email;
    } else {
      return contact.id.toString();
    }
  },
});

export const initialVNCContactState: VNCContactState = vncContactAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function vncContactReducer(
  state = initialVNCContactState,
  action: Action
): VNCContactState {
  switch (action.type) {
    case ContactActionsTypes.BULK_LOAD_VNC_CONTACTS: {
      return vncContactAdapter.upsertMany(action.payload, {
        ...state
      });
    }

    case ContactActionsTypes.UPDATE_VNC_CONTACTS: {
      return vncContactAdapter.upsertOne(action.payload, {
        ...state
      });
    }

    case ContactActionsTypes.ADD_VNC_CONTACTS: {
      return vncContactAdapter.upsertOne(action.payload, {
        ...state
      });
    }

    case ContactActionsTypes.REMOVE_VNC_CONTACTS: {
      return vncContactAdapter.removeOne(action.payload, {
        ...state
      });
    }

    case ContactActionsTypes.RESTORE_VNC_CONTACTS: {
      return vncContactAdapter.upsertMany(action.payload, {
        ...state
      });
    }

  }
  return state;
}
