
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { SearchRepository } from "../../../repository/search.repository";
import { Project } from "../../../models";
import { Store } from "@ngrx/store";
import { TasksRootState, getProjectsList } from "../../../store/index";
import { isNullOrUndefined } from "util";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { takeWhile, take } from "rxjs/operators";

@Component({
  selector: "vp-select-projects",
  templateUrl: "./select-projects-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  changed = false;
  isAlive = true;
  allSelected: boolean;
  projectList: Project[] = [];
  selectedProjects: Project[] = [];
  mdlFixDone = false;
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(
    private broadcaster: Broadcaster,
    private searchRepo: SearchRepository,
    private store: Store<TasksRootState>,
    private changeDetectionRef: ChangeDetectorRef) {

    this.store.select(getProjectsList).pipe(take(1)).subscribe( projects => {
      if (projects) {
        this.projectList = projects;
        this.changeDetectionRef.markForCheck();
      }
    });

    this.searchRepo.selectSavedProjects().pipe(take(1)).subscribe((selectedProjects) => {
      this.selectedProjects = selectedProjects;
      this.allSelected = this.selectedProjects.length === 0;
      this.changeDetectionRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskSelectProjectsDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  ngOnInit() {
  }

  close() {
    this.closeDialog.emit(true);
  }

  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      } , 100);
    }
  }

  selectAll() {
    this.allSelected = true;
    this.selectedProjects = [];
    this.checkForUpdate();
  }

  selectNone() {
    if (!this.allSelected) {
      return;
    }
    this.allSelected = false;
    this.changed = true;
  }

  checkForUpdate() {
    this.changed = true;
  }

  enableSubmit() {}

  projectSelected(event , project: Project) {
    if (event === true) {
      // Removing if already selected. This is sometimes caused by double click.
      this.selectedProjects = this.selectedProjects.filter(c => c.id !== project.id);
      this.selectedProjects.push(project);
    } else {
      this.selectedProjects = this.selectedProjects.filter(c => c.id !== project.id);
    }

    this.changed = true;
    this.allSelected = false;
  }

  isProjectSelected(project: Project) {
    return !isNullOrUndefined(this.selectedProjects.find((c) => c.id === project.id));
  }

  saveProjects() {
    this.searchRepo.saveProjectList(this.selectedProjects);
    this.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }
}
