
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter, Injectable } from "@angular/core";
import { Subject, BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { ConfigService } from "../../common/providers/config.service";

@Injectable()
export class HeaderService {
  private searchBox: EventEmitter<HeaderEvent>;
  currentHighlightedSearchIndex = -1;
  highlightedSearchResults: NodeListOf<Element> = <any>[];

  searchText$ = new BehaviorSubject<string>("");
  toggleDrawer$ = new Subject<boolean>();

  subscription: Subscription;
  timeoutInterval;
  titleHeaderShow$: Subject<boolean> = new Subject();
  showAdvancedTaskFilters$: Subject<boolean> = new Subject<boolean>();
  showSearchDialog$: Subject<boolean> = new Subject<boolean>();

  static ACTIONS = {
    TASK: {
      TASK_SEARCH: "task-search"
      }
  };

  constructor(private config: ConfigService) {
    this.searchBox = new EventEmitter<HeaderEvent>();
  }

  getSearchEmitter(): EventEmitter<HeaderEvent> {
    return this.searchBox;
  }

  emit(action: number, text: string): void {
    this.searchBox.emit({
      action: action === 13 ? HeaderEvent.ENTER : HeaderEvent.TYPING,
      text: text
    });
    if (action === 13)
      this.searchText$.next(text);
  }

  destroy() {
    if (this.timeoutInterval)
      clearTimeout(this.timeoutInterval);
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  getDrawerSource(): Observable<any> {
    return this.toggleDrawer$.asObservable();
  }

  toggleDrawer(): void {
    this.toggleDrawer$.next(true);
  }

  showTaskAdvancedFilters(show: boolean): void {
    this.showAdvancedTaskFilters$.next(show);
  }

  showSearchDialog(show: boolean): void {
    this.showSearchDialog$.next(show);
  }

  getAPIUrl() {
    return this.config.API_URL;
  }

  titleHeaderShow(show: boolean) {
    this.titleHeaderShow$.next(show);
  }
}

export class HeaderEvent {
  action: number;
  text: string;
  static TYPING?: number = 0;
  static ENTER?: number = 1;
}
