
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="description_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
    <mdl-icon>edit</mdl-icon>
    <p>{{ 'DESCRIPTION' | translate }}</p>
  </div>

  <div id="description_option_list" class="option-list add-description-dialog" *ngIf="active">
    <div class="description-save-close">
      <span>{{ 'DESCRIPTION' | translate }}:</span>
      <div class="icons-div">
        <mdl-icon id="description_cancel" class="icon-close" (click)="goBack()">clear</mdl-icon>
        <mdl-icon id="description_save" class="icon-check" (click)="setDescription()">check_circle</mdl-icon>
      </div>
    </div>
    <div class="detail__toolbar">
        <vp-vnctask-toolbar #taskToolbar [content]="content"></vp-vnctask-toolbar>
    </div>
  </div>
