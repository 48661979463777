
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, OnDestroy } from "@angular/core";
import { MdlSnackbarService } from "@angular-mdl/core";
import { TasksRootState, getAuthUser, getTasksIsLoading, SetIsLoading, StoreAuthUser } from "../../store/index";
import { Store } from "@ngrx/store";
import { environment } from "../../../../environments/environment";
import { TaskRepository } from "../../repository/task.repository";
import { SuccessService } from "../../../common/providers/success-service";
import { SuccessType } from "../../shared/task-enum";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { TaskImageCropperDialogComponent } from "../task-image-cropper-dialog/task-image-cropper-dialog.component";
import { getOnlineStatus } from "../../../reducers";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile } from "rxjs/operators";
import { LocalStorageService } from "../../services/local-storage.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CommandLine } from "electron";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-task-login-profile-dialog",
  templateUrl: "task-login-user-profile.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskLoginProfileDialogComponent implements OnInit, OnDestroy {
  user: any = {};
  isAlive: boolean = true;
  isLoading = false;
  isOnline: boolean = false;
  isIOS = CommonUtil.isOnIOS();

  constructor(
    private store: Store<TasksRootState>,
    private tasksRepo: TaskRepository,
    private successService: SuccessService,
    private mdlSnackbarService: MdlSnackbarService,
    private changerDetectorRef: ChangeDetectorRef,
    private broadcaster: Broadcaster,
    private dialogRef: MatDialogRef<TaskLoginProfileDialogComponent>,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService
  ) {

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskLoginProfileDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.user = user;
        setTimeout( () => { this.changerDetectorRef.markForCheck(); }, 100);
      }
    });

    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isLoading = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
    });

    this.handleUpdateUserAvatarSuccessMessages();
  }

  ngOnInit(): void {
  }

  handleUpdateUserAvatarSuccessMessages() {
    this.successService.only(SuccessType.UserAvatarUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {

      // reload user avatar
      this.tasksRepo.poppulateAuthUserAvatar(this.user, true).subscribe(avSuccess => {
        if (avSuccess) {
          this.localStorageService.storeUser(this.user);
          if (environment.isElectron) {
            if (this.user) {
                if (this.user.userAvatar) {
                  if (CommonUtil.isFileSystemUrl(this.user.userAvatar.toString())) {
                    this.user.userAvatar = this.sanitizer.bypassSecurityTrustUrl(this.user.userAvatar);
                  }
                }
            }
          }
          this.store.dispatch(new StoreAuthUser(this.user));
          this.mdlSnackbarService.showToast(success.messages);

          this.broadcaster.broadcast("profileAvatarUpdated");
          this.store.dispatch(new SetIsLoading(false));

          this.changerDetectorRef.markForCheck();
        }
      });
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  openCropperDialog() {
    if (this.isOnline) {
      const gdlg = this.matDialog.open(TaskImageCropperDialogComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "vp-task-image-cropper-dialog",
      });
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }
}
