
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="assign_location_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <mdl-icon>place</mdl-icon>
  <p>{{ 'LOCATIONS' | translate }}</p>
</div>

<div id="assign_location_option_list" class="option-list add-assignee-list-dialog" *ngIf="active">
  <div class="add-assignee-list-header">
    <span>{{ 'LOCATIONS' | translate }}</span>
    <mdl-icon class="add-list-icon" (click)="openCreateLocationDialog()">add_location</mdl-icon>
  </div>
  <mdl-list class="folder-list" *ngIf="filteredItems && filteredItems.length > 0">
    <mdl-list-item
      *ngFor="let location of filteredItems; let i = index;"
      [class.selected]="i == selectedIndex"
      (click)="onItemClick(location)">
        <mdl-icon>place</mdl-icon>
        <span>{{location.name}}</span>
    </mdl-list-item>
  </mdl-list>
  <div *ngIf="filteredItems && filteredItems.length === 0" class="no-available-lists">
    <span>{{ 'NO_AVAILABLE_LOCATIONS' | translate }}</span>
  </div>
</div>
