
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="registration" [style.backgroundImage]="backgroundImage">
    <div class="registration-form">
        <div class="title">{{ 'REGISTER' | translate }}</div>
        <div style="margin-top:30px;">
            <form [formGroup]="registerForm" (ngSubmit)="onFormSubmit(registerForm.value)">
                <mat-form-field>
                    <input type="text" autocomplete="firstName" formControlName="firstName" matInput placeholder="{{ 'FIRST_NAME' | translate }}" required>
                </mat-form-field>
                <mat-form-field>
                    <input type="text" autocomplete="lastName" formControlName="lastName" matInput placeholder="{{ 'LAST_NAME' | translate }}" required>
                </mat-form-field>
                <mat-form-field>
                    <input type="text" autocomplete="email" formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput placeholder="{{ 'EMAIL' | translate }}" required>
                    <mat-error *ngIf="registerForm.get('email').errors?.pattern">
                        {{ 'EMAIL_INVALID_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input type="text" autocomplete="userName" formControlName="userName" matInput placeholder="{{ 'USERNAME' | translate }}" required>
                </mat-form-field>
                <mat-form-field hintLabel="{{ 'PASSWORD_HINT' | translate }}">
                    <input type="password" autocomplete="password" formControlName="password" matInput placeholder="{{ 'PASSWORD' | translate }}" required>
                </mat-form-field>
                <mat-form-field>
                    <input type="password" autocomplete="confirmPassword" formControlName="confirmPassword" matInput placeholder="{{ 'PASSWORD_REPEAT' | translate }}" required>
                    <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
                        {{ 'PASSWORD_MISMATCH_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <div *ngIf="storedLanguage === 'en'">
                    <input type="checkbox" id="privacy-policy" (change)="onChange()">I have read and agree to the
                    <a class="policy-privacy-link open-new-window" href="https://vnclagoon.com/data-privacy-policy" target="_blank">VNC Privacy Policy</a>
                </div>
                <div *ngIf="storedLanguage === 'de'">
                    <input type="checkbox" id="privacy-policy" (change)="onChange()"> Ich habe die
                    <a class="policy-privacy-link open-new-window" href="https://vnclagoon.com/de/data-privacy-policy" target="_blank">Datenschutzbestimmungen</a>
                    von VNC gelesen und stimme ihnen zu.
                </div>
                <mat-error *ngIf="!isPolicyAccepted">
                    {{ 'PRIVACY_POLICY_ERROR' | translate }}
                </mat-error>
                <div class="clear-form">
                    <span (click)="clearForm(registerForm)">{{ 'CLEAR_FORM' | translate }}</span>
                </div>
                <div class="login-button">
                    <button type="submit" [disabled]="!registerForm.valid">{{ 'REGISTER' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="app_footer-title">
        <span class="disable-select" (click)="returnToLoginPage()">{{ 'RETURN_TO_LOGIN_PAGE' | translate }}</span>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
