
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-task-login-profile-dialog task__dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
           <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
           </button>
        </div>
        <div class="header_lbl disable-select">
           {{'PROFILE' | translate}}
        </div>
        <div class="desktop-close-button">
           <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
           </button>
        </div>
    </div>
    <div class="login_user_profile_dialog-body">
        <div class="detail_body_size">
            <div class="task-profile">
                <div layout="column" *ngIf="user && user.userAvatar" layout-align="center center" class="layout-align-center-center">
                    <vp-avatar [user]="{ name: user && user.fullname ? user.fullname : '', avatarURL: user.userAvatar }"> </vp-avatar>
                </div>
                <mdl-icon *ngIf="user && !user.userAvatar" (click)="fileUpload.click()" class="material-icons" style="cursor: pointer;">account_circle</mdl-icon>
                <span class="uploadlbl" *ngIf="user" (click)="openCropperDialog()">{{'ADD_PHOTO' | translate }}</span>
                <br/>
                <span class="span-header" *ngIf="user">{{ user.fullname }}</span>
            </div>
            <div class="task_details">
                <div class="details_heading">{{'PERSONAL_INFORMATION' | translate}}</div>
                <div class="details_info">
                    <div>
                        <mdl-icon>mail</mdl-icon>
                    </div>
                    <div>
                        <div class="text_info" *ngIf="user && user.email">
                            {{ user.email }}
                        </div>
                    </div>
                </div>
               <div class="separator-last-div"></div>
            </div>
        </div>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
