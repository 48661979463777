
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="(task && !isEditMode) || (taskCpy && isEditMode)" vpScrollList (onUserScroll)="setHeader($event);closeOverlay()" class="task-detail-info vp-vnctask-details" [class.edit-mode-padding]="isEditMode && isMobileDevice">
    <div id="task-detail-header" class="header" [style.background-color]="getPriorityColor(task.priority)">
        <div *ngIf="!isEditMode && isMobileDevice" class="detail-header">
            <div class="buttons-header">
                <div class="back-buttons">
                    <button class="brand-color mdl-button" id="task-detail-back-btn" (click)="backFromDetail()">
                        <mdl-icon class="material-icons">chevron_left</mdl-icon>
                    </button>
                </div>
                <div *ngIf="!isFixedHeader" class="opts-buttons">
                    <button class="brand-color mdl-button" id="task-detail-done-btn" (click)="onCompleteTask()">
                        <mdl-icon class="material-icons">check_circle</mdl-icon>
                    </button>
                    <button class="brand-color mdl-button" id="task-detail-done-btn" (click)="onDuplicateTask()">
                        <mdl-icon class="material-icons">content_copy</mdl-icon>
                    </button>
                    <button class="brand-color mdl-button" id="task-detail-delete-btn" (click)="onDeleteTask()">
                        <mdl-icon class="material-icons">delete</mdl-icon>
                    </button>
                </div>
                <div class="fixed-subject-info" *ngIf="isFixedHeader">
                    <div class="title" [class.highlight-header]="taskHighlightFields && taskHighlightFields.subject">{{ task.subject }}</div>
                </div>
                <div class="more-button" *ngIf="isFixedHeader">
                    <button class="brand-color mdl-button" id="task-detail-more-btn" #bottomRightButton (click)="taskDetailHeaderPopover.toggle($event,bottomRightButton)">
                        <mdl-icon class="material-icons">more_vert</mdl-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="!isFixedHeader" class="subject-header">
                <div class="assigned-icon">
                    <vp-avatar [user]="{ name: task.assigned_to && task.assigned_to.name ? task.assigned_to.name : '', avatarURL: task.userAvatar }"> </vp-avatar>
                </div>
                <div class="text-header">
                    <div><span [class.highlight-header]="taskHighlightFields && taskHighlightFields.subject">{{ task.subject }}</span></div>
                    <div class="flex">
                        <div class="property-text-header">
                            <div class="property-text">
                                <mdl-icon class="material-icons">assignment_late</mdl-icon>
                                <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.priority">{{ task.priority?.name | uppercase | translate }}</span>
                            </div>
                            <div class="property-text">
                                <mdl-icon class="material-icons">person</mdl-icon>
                                <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.assigned_to">{{ task.assigned_to ? task.assigned_to.name : 'NONE' | translate }}</span>
                            </div>
                        </div>
                        <div class="edit-button-header" *ngIf="!isEditMode">
                            <button id="task_edit_btn" class="edit-button" [style.background-color]="getEditIconColor(task.priority)" mdl-ripple mdl-button mdl-button-type="fab"
                                (click)="onEdit(task)">
                                <mdl-icon>edit</mdl-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditMode && !isMobileDevice" class="detail-header">
            <div class="desktop-detail-header">
                <div class="subject-title-header">
                    <mdl-icon class="material-icons">event_available</mdl-icon>
                    <span class="title" [class.highlight-header]="taskHighlightFields && taskHighlightFields.subject">{{ task.subject }}</span>
                </div>
                <div class="property-detail-header">
                    <div class="property-header">
                        <div class="assignto-header">
                            <vp-avatar [user]="{ name: task.assigned_to && task.assigned_to.name ? task.assigned_to.name : '', avatarURL: task.userAvatar }"> </vp-avatar>
                            <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.assigned_to">{{ task.assigned_to ? task.assigned_to.name : 'NONE' | translate }}</span>
                        </div>
                        <div class="priority-header">
                            <mdl-icon class="material-icons">assignment_late</mdl-icon>
                            <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.priority">{{ task.priority?.name | uppercase | translate }}</span>
                        </div>
                    </div>
                    <div class="edit-button-header" *ngIf="!isEditMode">
                        <button class="edit-button" [style.background-color]="getEditIconColor(task.priority)" mdl-ripple mdl-button mdl-button-type="fab"
                            (click)="onEdit(task)">
                            <mdl-icon>edit</mdl-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isEditMode" class="detail-header">
            <div class="buttons-header">
                <div class="back-buttons">
                    <button id="task-edit-close-btn" class="brand-color mdl-button" (click)="backToDetail()">
                        <mdl-icon>chevron_left</mdl-icon>
                    </button>
                </div>
                <div class="edit-task-header">{{ 'EDIT_TASK' | translate }}</div>
            </div>
            <div class="subject-header">
                <div class="subject-textfield">
                    <mat-form-field>
                        <textarea matInput rows="2" maxrows="3" [(ngModel)]="subject" #editTaskSubject></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div id="task-detail-body" class="detail-body" [class.task-detail-line-1]="!isEditMode && commentListExpanded" [class.comment-collapsed]="!commentListExpanded">
        <div class="detail-info-table" *ngIf="!isEditMode">
            <div class="task-completed-icon" *ngIf="task.status && (task.status.name === 'Closed' || task.status.name === 'Completed')">
                <img src="assets/images/Task_completed.svg" />
            </div>
            <!-- Description -->
            <div *ngIf="(task.description ? task.description.length > 0: false)">
                <div class="table-row">
                    <div id="description" [innerHTML]="descriptionHTML" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.description" [class.desc-read-more]="descLines > 8 && !descExpanded"></div>
                    <div *ngIf="!task.description" class="padding-10"><span>{{ 'NO_DESCRIPTION' | translate }}</span></div>
                </div>
                <div class="table-row" *ngIf="descLines > 8">
                  <div class="desc-expand-div" (click)="descExpanded ? !descExpanded = false: descExpanded = true">
                    <span *ngIf="!descExpanded">{{ 'READ_MORE' | translate }}</span>
                    <span *ngIf="descExpanded">{{ 'READ_LESS' | translate }}</span>
                  </div>
                </div>
            </div>
            <!-- Separater Div-->
            <div class="separater-expand-div" (click)="propertyListExpanded ? !propertyListExpanded = false: propertyListExpanded = true">
                <div class="item-icon">
                    <mdl-icon *ngIf="!propertyListExpanded">expand_more</mdl-icon>
                    <mdl-icon *ngIf="propertyListExpanded">expand_less</mdl-icon>
                </div>
                <div class="item-title">{{ 'PROPERTIES' | translate }}:</div>
            </div>
            <!-- Start Date -->
            <div *ngIf="propertyListExpanded" class="padding-14">
                <div class="table-row" *ngIf="task.start_date">
                    <div class="label-table-cell">
                        <mdl-icon>today</mdl-icon>
                        <span class="detail-label-text">{{ 'TASK_START_DATE' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.start_date">{{ getDateWithFormat(task.start_date, "dd MMM. y") }}</span>
                    </div>
                </div>
                <!-- Due Date -->
                <div class="table-row" *ngIf="task.due_date">
                    <div class="label-table-cell">
                        <mdl-icon>event</mdl-icon>
                        <span class="detail-label-text">{{ 'TASK_DUE_DATE' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span *ngIf="task.due_date" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.due_date">{{ getDateWithFormat(task.due_date, "dd MMM. y") }}</span>
                    </div>
                </div>
                <!-- Status -->
                <div class="table-row" *ngIf="task.status">
                    <div class="label-table-cell">
                        <mdl-icon>assignment_turned_in</mdl-icon>
                        <span class="detail-label-text">{{ 'STATUS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span *ngIf="task.status && (task.status.name === 'Closed' || task.status.name === 'Completed')" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.status">{{'STATUS_COMPLETED' | translate}}</span>
                        <span *ngIf="task.status && (task.status.name === 'In Progress')" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.status">{{'STATUS_INPROGRESS' | translate}}</span>
                        <span *ngIf="task.status && (task.status.name === 'New')" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.status">{{'STATUS_NEW' | translate }}</span>
                    </div>
                </div>
                <!-- Repeat -->
                <div class="table-row" *ngIf="task.repeat">
                    <div class="label-table-cell">
                        <mdl-icon>autorenew</mdl-icon>
                        <span class="detail-label-text">{{ 'REPEATS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span *ngIf="task.repeat==='w'">{{ 'TASK_EVERY_WEEK' | translate }}</span>
                        <span *ngIf="task.repeat==='m'">{{ 'TASK_EVERY_MONTH' | translate }}</span>
                        <span *ngIf="task.repeat==='d'">{{ 'TASK_EVERY_DAY' | translate }}</span>
                        <span *ngIf="task.repeat==='y'">{{ 'TASK_EVERY_YEAR' | translate }}</span>
                        <span *ngIf="task.repeat==='' || task.repeat==='n' || (!task.repeat)">{{ 'TASK_NO_REPEAT' | translate }}</span>
                    </div>
                </div>
                <!-- Project -->
                <div class="table-row" *ngIf="task.project && projectList.length > 1">
                    <div class="label-table-cell">
                        <mdl-icon>work</mdl-icon>
                        <span class="detail-label-text">{{ 'TASK_PROJECT' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.project">{{task.project.name}}</span>
                    </div>
                </div>
                <!-- Created by -->
                <div class="table-row" *ngIf="task.author">
                  <div class="label-table-cell">
                      <mdl-icon>person</mdl-icon>
                      <span class="detail-label-text">{{ 'CREATED_BY' | translate }}:</span>
                  </div>
                  <div class="value-table-cell">
                      <span>{{ task.author.name }}</span>
                  </div>
                </div>
                <!-- List -->
                <div class="table-row" *ngIf="task.list">
                    <div class="label-table-cell">
                        <mdl-icon>assignment</mdl-icon>
                        <span class="detail-label-text">{{ 'LIST' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span class="list-span">{{task.list.name}}</span>
                    </div>
                </div>
                <!-- Location -->
                <div class="table-row" *ngIf="task.location">
                  <div class="label-table-cell">
                      <mdl-icon>place</mdl-icon>
                      <span class="detail-label-text">{{ 'LOCATION' | translate }}:</span>
                  </div>
                  <div class="value-table-cell">
                      <span class="list-span">{{task.location.name}}</span>
                  </div>
                </div>
                <!-- Tags -->
                <div class="table-row" *ngIf="( isMobileDevice && task.tags && task.tags.length > 0)">
                    <div class="label-table-cell tag-icon-div">
                        <mdl-icon>local_offer</mdl-icon>
                    </div>
                    <div class="value-table-cell tag-value-div">
                        <div class="tags-list-view">
                            <span *ngFor="let tag of task.tags;  let i = index">
                                <ng-container *ngIf="i <= 4">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="i == 5 && !showMoreTags">
                                    <span class="view-all-text" (click)="showMoreTags = true;">
                                        <mdl-icon>more_horiz</mdl-icon>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="i > 4 && showMoreTags">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="(i === task.tags.length - 1) && showMoreTags">
                                    <span class="view-less-text" (click)="showMoreTags = false;">{{ 'SHOW_LESS' | translate }}</span>
                                </ng-container>
                             </span>
                        </div>
                    </div>
                </div>
                <div class="table-row" *ngIf="(!isMobileDevice && task.tags && task.tags.length > 0)">
                    <div class="label-table-cell">
                        <mdl-icon>local_offer</mdl-icon>
                        <span class="detail-label-text">{{ 'TAGS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <div class="tags-list-view">
                            <span *ngFor="let tag of task.tags;  let i = index">
                                <ng-container *ngIf="i <= 4">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="i == 5 && !showMoreTags">
                                    <span class="view-all-text" (click)="showMoreTags = true;">
                                        <mdl-icon>more_horiz</mdl-icon>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="i > 4 && showMoreTags">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="(i === task.tags.length - 1) && showMoreTags">
                                    <span class="view-less-text" (click)="showMoreTags = false;">{{ 'SHOW_LESS' | translate }}</span>
                                </ng-container>
                            </span>
                         </div>
                    </div>
                </div>
                <div class="separate-bar margin-top-bottom-10"></div>
                <div class="table-row" *ngIf="!isShowMore">
                    <div class="show-more-div" (click)="isShowMore = true;">
                        <span>{{ 'SHOW_MORE' | translate }}</span>
                        <mdl-icon>arrow_downward</mdl-icon>
                    </div>
                </div>

                <div *ngIf="isShowMore" style="padding-top:20px;">
                    <!-- Reminder -->
                    <div class="table-row" *ngIf="task.remind_on">
                        <div class="label-table-cell">
                            <mdl-icon>notifications_active</mdl-icon>
                            <span class="detail-label-text">{{ 'REMINDER' | translate }}:</span>
                        </div>
                        <div class="value-table-cell">
                            <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.remind_on">{{ getDateWithFormat(task.remind_on, "MMMM dd, yyyy. HH:mm") }}</span>
                        </div>
                    </div>
                    <!-- URL -->
                    <div class="table-row" *ngIf="task.external_url">
                        <div class="label-table-cell">
                            <mdl-icon>insert_link</mdl-icon>
                            <span class="detail-label-text">{{ 'URL' | translate }}:</span>
                        </div>
                        <div class="value-table-cell">
                            <span class="word-break" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.external_url" [innerHTML]="linkify(task.external_url)"></span>
                        </div>
                    </div>
                    <!-- Attachment -->
                    <div class="table-row" *ngIf="task.attachments && task.attachments.length > 0">
                        <div class="label-table-cell-row">
                            <mdl-icon>attach_file</mdl-icon>
                            <span class="detail-label-text">{{ 'ATTACHMENTS' | translate }}:</span>
                        </div>
                    </div>
                    <div *ngIf="task.attachments && task.attachments.length > 0">
                        <div *ngFor="let attachment of task.attachments" class="table-row">
                            <div class="attachmentListView">
                                <div class="thumbnail" (click)="openAttachment(attachment)">
                                    <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(attachment.filename))">
                                </div>
                                <div class="filename-detail" (click)="openAttachment(attachment)">
                                    <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="checkAttachmentHighLight(attachment)">{{attachment.filename}}</span>
                                </div>
                                <div class="thumbnail" (click)="downloadFile(attachment)">
                                    <img [src]="CommonUtil.getFullUrl('/assets/media-icons/download.svg')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- watchers -->
                    <div class="table-row" *ngIf="task.watchers && task.watchers.length > 0">
                      <div class="label-table-cell-row">
                          <mdl-icon>visibility</mdl-icon>
                          <span class="detail-label-text">{{ 'WATCHERS' | translate }}:</span>
                      </div>
                    </div>
                    <div *ngIf="task.watchers && task.watchers.length > 0">
                      <div *ngFor="let watcher of task.watchers" class="table-row">
                          <div class="watcherListView">
                            <vp-avatar [user]="{ name: watcher && watcher.name ? watcher.name : '', avatarURL: watcher.avatar }"> </vp-avatar>
                            <div class="name-detail">
                              <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="checkWatcherHighLight(watcher)">{{ watcher.name }}</span>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="table-row" *ngIf="isShowMore">
                    <div class="show-more-div" (click)="isShowMore = false;">
                        <span>{{ 'SHOW_LESS' | translate }}</span>
                        <mdl-icon>arrow_upward</mdl-icon>
                    </div>
                </div>
            </div>
            <!-- Separater Div for Efforts And Progress -->
            <div class="separater-expand-div" (click)="effortsAndProgress ? !effortsAndProgress = false: effortsAndProgress = true">
                <div class="item-icon">
                    <mdl-icon *ngIf="!effortsAndProgress">expand_more</mdl-icon>
                    <mdl-icon *ngIf="effortsAndProgress">expand_less</mdl-icon>
                </div>
                <div class="item-title">{{ 'EFFORTS_AND_PROGRESS' | translate }}:</div>
            </div>
            <div *ngIf="effortsAndProgress" class="padding-14">
                <!-- Estimated time -->
                <div class="table-row" *ngIf="task.estimated_hours">
                    <div class="label-table-cell">
                        <mdl-icon>access_time</mdl-icon>
                        <span class="detail-label-text">{{ 'ESTIMATED_TIME' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.estimated_hours">{{ convertDecimalToTimeFormat(task.estimated_hours) }}</span>
                    </div>
                </div>
                <!-- Spent time -->
                <div class="table-row" *ngIf="task.spent_hours && task.estimated_hours">
                    <div class="label-table-cell">
                        <mdl-icon>timer</mdl-icon>
                        <span class="detail-label-text">{{ 'TOTAL_SPENT_TIME' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span>{{ convertDecimalToTimeFormat(task.spent_hours) }}</span>
                        <span class="timelog-history-label" (click)="openTimelogHistoryDialog()">{{ 'TIMELOG_HISTORY' | translate }}</span>
                    </div>
                </div>
                <!-- Done -->
                <div class="table-row">
                    <div class="label-table-cell">
                        <mdl-icon>timelapse</mdl-icon>
                        <span class="detail-label-text">{{ 'COMPLETED' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.done_ratio">{{ task.done_ratio }}%</span>
                    </div>
                </div>
            </div>
            <div class="separater-expand-div" (click)="commentListExpanded ? !commentListExpanded = false: commentListExpanded = true">
                <div class="item-icon">
                    <mdl-icon *ngIf="!commentListExpanded">expand_more</mdl-icon>
                    <mdl-icon *ngIf="commentListExpanded">expand_less</mdl-icon>
                </div>
                <div class="item-title">{{ 'COMMENTS' | translate }}: <span class="item-count" *ngIf="!commentListExpanded && task.comments">{{ task.comments.length }}</span></div>
            </div>
            <div id="task-edit-comment-section" class="comment_section" *ngIf="commentListExpanded && task.comments">
                <div *ngFor="let comment of task.comments; let i = index" class="comment_detail">
                    <vp-avatar [user]="{ name: comment.user && comment.user.name ? comment.user.name : '', avatarURL: comment.userAvatar }"> </vp-avatar>
                    <div class="comment_detail--body" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="checkCommentHighLight(comment)">
                        <div class="comment_detail--body__meta">
                            <span class="comment_detail--body__meta--username">{{comment.user.name}}</span>
                            <span class="comment_detail--body__meta--date align-right">{{ getDateWithFormat(comment.created_on, "MMM dd, yyyy") }}</span>
                        </div>
                        <div class="comment_detail--body__text">
                            <p *ngIf="!comment.isEditMode" [innerHTML]="comment.notes"></p>
                            <div class="edit-comment" *ngIf="comment.isEditMode">
                                <textarea [vpMention]="userMentionList" [mentionConfig]="{ triggerChar:'@', maxItems:100, labelKey:'label', mentionSelect: onMentionSelect }" #editCommentInput id="edit-comment-{{i}}" class="edit-comment-input ng-dirty" (input)="applySizeEditComment(editCommentInput, i)"
                                    (change)="applySizeEditComment(editCommentInput,i)"></textarea>
                                <div class="edit-comment-buttons">
                                    <span (click)="resetComment(comment)" class="cancel-btn">{{ 'CANCEL' | translate }}</span>
                                    <span (click)="updateComment(comment, editCommentInput.value)" class="save-btn">{{ 'SAVE' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="comment_detail--body__edited">
                            <span *ngIf="!comment.isEditMode && comment.isEdited">{{ 'EDITED' | translate }}</span>
                        </div>
                    </div>
                    <div class="comment_detail--edit__button">
                        <mdl-icon *ngIf="authUser?.id === comment?.user.id" (click)="editComment(comment, i)">edit</mdl-icon>
                    </div>
                </div>
                <div *ngIf="!task.comments.length" class="no-comments">{{ 'NO_COMMENTS' | translate }}!</div>
            </div>
        </div>
        <div class="detail-info-table" *ngIf="isEditMode">
            <!-- Priority -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'PRIORITY' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                    <mat-select
                        class="mdl-selection-option-only-background"
                        [(ngModel)]="selectedPriorityOption"
                        (selectionChange)="onPrioritySelect($event)" 
                        disableOptionCentering
                        panelClass="mat-select-dropdown-below"
                    >
                        <mat-option *ngFor="let priority of priorityList" [value]="priority.id" [style.color]="getPriorityColor(priority)">
                            {{ priority.name | uppercase | translate }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Assign to -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'ASSIGNED_TO' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field" >
                        <input type="text" #assigneeTrigger matInput [formControl]="assigneeCtrl" [matAutocomplete]="assignee">
                        <mat-autocomplete class="assignee-list" #assignee="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let user of filteredUsers | async" [value]="user" class="option-list-item">
                                <vp-avatar [user]="{ name: user && user.name ? user.name : '', avatarURL: user.avatar }"> </vp-avatar>
                                <span class="assignee-text">{{user.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="margin-top-bottom-10"></div>
            <!-- Description -->
            <div class="table-row">
                <div class="detail__toolbar">
                    <vp-vnctask-toolbar #taskToolbar (isToolbarOpen)="toolbarIsOpen($event)" [content]="taskCpy.description"></vp-vnctask-toolbar>
                </div>
            </div>
            <div class="margin-top-bottom-10"></div>
            <!-- Start Date -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TASK_START_DATE' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <div *ngIf="!taskCpy.start_date || taskCpy.start_date == null" #startDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="startDateMenu">
                        {{ 'NONE' | translate }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <div *ngIf="taskCpy.start_date || taskCpy.start_date != null" #startDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="startDateMenu">{{ getDateWithFormat(taskCpy.start_date, "dd MMM. y") }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #startDateMenu="matMenu" class="edit-task-date-matmenu">
                        <button mat-menu-item class="edit-task-date-matmenu-item" *ngFor="let startdate of startDateItems" (click)="startDateSelectItem(startdate)">
                            {{startdate.name}}
                            <span *ngIf="startdate.date!== null">{{startdate.date | vpLocaleDate: 'dd MMM.'}}</span>
                            <mdl-icon *ngIf="startdate.date==null">event_busy</mdl-icon>
                        </button>
                        <button mat-menu-item class="pick-date-icon" (click)="editDateClick('startDateSet')">
                            <span>{{ 'PICK_START_DATE' | translate }}</span>
                            <mdl-icon>today</mdl-icon>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <!-- Due Date -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TASK_DUE_DATE' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <div *ngIf="!taskCpy.due_date || taskCpy.due_date == null" #dueDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="dueDateMenu">
                        {{ 'NONE' | translate }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <div *ngIf="taskCpy.due_date || taskCpy.due_date != null" #dueDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="dueDateMenu">{{ getDateWithFormat(taskCpy.due_date, "dd MMM. y") }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #dueDateMenu="matMenu" class="edit-task-date-matmenu">
                        <button mat-menu-item class="edit-task-date-matmenu-item" *ngFor="let duedate of dueDateItems" (click)="dueDateSelectItem(duedate)">
                            {{duedate.name}}
                            <span *ngIf="duedate.date!== null">{{duedate.date | vpLocaleDate: 'dd MMM.'}}</span>
                            <mdl-icon *ngIf="duedate.date==null">event_busy</mdl-icon>
                        </button>
                        <button mat-menu-item class="pick-date-icon" (click)="editDateClick('dueDateSet')">
                            <span>{{ 'PICK_DUE_DATE' | translate }}</span>
                            <mdl-icon>event</mdl-icon>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <!-- Status -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'STATUS' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                    <mat-select class="mdl-selection-option" [(ngModel)]="selectedStatusOption" (selectionChange)="onStatusSelect($event)">
                        <mat-option *ngFor="let status of required_statuses" [value]="status.id">
                            {{status.displayName}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Repeats -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'REPEATS' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                    <mat-select class="mdl-selection-option" [(ngModel)]="selectedRepeatOption" (selectionChange)="onRepeatsSelect($event)">
                        <mat-option *ngFor="let repeat of repeatItems" [value]="repeat.value">
                            {{repeat.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Project -->
            <div class="table-row padding-left-right-15" *ngIf="projectList.length > 1">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TASK_PROJECT' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                        <input type="text" #projectTrigger matInput [formControl]="projectCtrl" [matAutocomplete]="project">
                        <mat-autocomplete class="project-list" #project="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onProjectSelect($event)">
                            <mat-option *ngFor="let project of filteredProjects | async" [value]="project" class="option-list-item">
                                <span>{{project.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <!-- List -->
            <div class="table-row padding-left-right-15">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'LIST' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                        <input type="text" #listTrigger matInput [formControl]="listCtrl" [matAutocomplete]="list">
                        <mat-autocomplete class="project-list" #list="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let list of filteredLists | async" [value]="list" class="option-list-item">
                                <span class="list-span">{{list.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <!-- Location -->
            <div class="table-row padding-left-right-15">
              <div class="label-table-cell">
                  <span class="detail-label-text">{{ 'LOCATION' | translate }}:</span>
              </div>
              <div class="value-table-cell">
                  <mat-form-field class="form-field">
                      <input type="text" #locationTrigger matInput [formControl]="locationCtrl" [matAutocomplete]="location">
                      <mat-autocomplete class="project-list" #location="matAutocomplete" [displayWith]="displayFn">
                          <mat-option *ngFor="let location of filteredLocations | async" [value]="location" class="option-list-item">
                              <span class="list-span">{{location.name}}</span>
                          </mat-option>
                      </mat-autocomplete>
                  </mat-form-field>
              </div>
            </div>
            <!-- Estimate Time-->
            <div class="table-row padding-left-right-15">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'ESTIMATED_TIME' | translate }}:</span>
                </div>
                <div class="value-table-cell" *ngIf="spentHours == '00' && spentMinutes == '00'">
                    <input class="task-time-input" [(ngModel)]="estimatedHours" (keydown)="validateNumber($event, estimatedHours)" type="text" maxlength="2" inputmode="numeric" pattern="[0-9]*">
                    <span>:</span>
                    <input class="task-time-input" [(ngModel)]="estimatedMinutes" (keydown)="validateNumber($event, estimatedMinutes)" type="text" maxlength="2" inputmode="numeric" pattern="[0-9]*">
                    <span class="detail-label-text">(hh:mm)</span>
                </div>
                <div class="value-table-cell" *ngIf="spentHours != '00' || spentMinutes != '00'">
                    <span>{{ convertDecimalToTimeFormat(task.estimated_hours) }}</span>
                </div>
            </div>
            <!-- Spent Time-->
            <div class="table-row padding-left-right-15" *ngIf="taskCpy?.estimated_hours">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TOTAL_TIME' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <span> {{ spentHours }}</span>
                    <span>:</span>
                    <span> {{ spentMinutes }}</span>
                    <span class="log-time-label" (click)="openLogTimeDialog()">{{ 'LOG_TIME' | translate }}</span>
                </div>
            </div>
            <!-- Done -->
            <div class="table-row padding-left-right-15">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'DONE' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                   <mat-form-field class="form-field">
                    <mat-select class="mdl-selection-option" [(ngModel)]="selectedDoneOption" (selectionChange)="onDoneSelect($event)">
                        <mat-option *ngFor="let percentage of done_percentages" [value]="percentage">
                            {{ percentage }}%
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="separate-bar margin-top-bottom-10"></div>
            <!-- Reminder -->
            <div class="table-row  table-row-with-padding" *ngIf="taskCpy?.remind_on">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'REMINDER' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <div>
                        <span class="cursor-pointer" (click)="openReminderDialog()">{{ getDateWithFormat(taskCpy.remind_on, "MMMM dd, yyyy. HH:mm") }}</span>
                        <mdl-icon class="cursor-pointer align-right" (click)="removeReminder()">clear</mdl-icon>
                    </div>
                </div>
            </div>
            <!-- URL -->
            <div class="table-row  table-row-with-padding" *ngIf="taskCpy?.external_url || showExternalUrl">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'URL' | translate }}:</span>
                </div>
                <div class="value-table-cell" [formGroup]="externalURLForm">
                    <mat-form-field class="external-form-field">
                        <input type="text" autocomplete="external_url" formControlName="external_url" matInput pattern="^(?:(h|H)(t|T)(t|T)(p|P)(s|S)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+%;=.]+$">
                        <mat-error *ngIf="externalURLForm.get('external_url').errors?.pattern">
                            {{ 'URL_INVALID_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- Attachment -->
            <div class="table-row-with-padding">
                <div class="table-row" *ngIf="taskCpy.attachments && taskCpy.attachments.length > 0 || fileUploads.length > 0">
                    <div class="label-table-cell">
                        <span class="detail-label-text">{{ 'ATTACHMENTS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell add-attachment-info" (click)="openAttachDialog()">
                      <mdl-icon>add</mdl-icon>
                      <span class="label-text">{{ 'ADD_ATTACHMENTS' | translate }}</span>
                  </div>
                </div>
                <div *ngFor="let attachment of taskCpy.attachments" class="table-row">
                    <div class="attachmentListView isEditMode">
                        <div class="thumbnail">
                            <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(attachment.filename))">
                        </div>
                        <div class="filename">{{attachment.filename}}</div>
                        <div class="clearBtn">
                            <mdl-icon *ngIf="attachment.deletable" (click)="removeAttachments(attachment, true)">clear</mdl-icon>
                        </div>
                    </div>
                </div>
                <div *ngFor="let attachment of fileUploads" class="table-row">
                    <div class="attachmentListView isEditMode">
                        <div class="thumbnail">
                            <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(attachment.filename))">
                        </div>
                        <div class="filename">{{attachment.filename}}</div>
                        <div class="clearBtn">
                            <mdl-icon (click)="removeAttachments(attachment, false)">clear</mdl-icon>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tags -->
            <div class="table-row-with-padding" *ngIf="!isMobileDevice">
                <div class="table-row" *ngIf="!isMobileDevice">
                    <div class="label-table-cell">
                        <span class="detail-label-text">{{ 'TAGS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <div class="tags-list-view">
                            <span *ngFor="let tag of taskCpy.tags" class="edit-input-tag">
                                <span class="edit-input-tag-name">{{tag.name}}</span>
                                <i class="material-icons" (click)="removeTag(tag)">close</i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-row table-row-with-padding">
                <div class="tags-list-view">
                    <div class="add-tag-div">
                        <textarea class="filter-search-textfield add-tag-input ng-dirty" [(ngModel)]="tagText" placeholder="{{ 'ADD_TAG' | translate }}"
                            (keydown.enter)="addTag($event);false" (keydown.space)="false" id="task-edit-add-tag-textfield" rows="1" minlength="2" (input)="searchTags($event)" #tagInput [matAutocomplete]="tag">
                        </textarea>
                        <button mdl-ripple mdl-button mdl-button-type="fab" *ngIf="(tagText ? tagText.length >= 2 : false)" class="add-tag-check" (click)="addTag($event)">
                            <mdl-icon>done</mdl-icon>
                        </button>
                    </div>
                    <span class="tag-msg-span">{{ 'ADD_TAG_MSG' | translate }}</span>
                    <mat-autocomplete class="tag-list" #tag="matAutocomplete" [displayWith]="displayValue.bind(this)">
                        <mat-option *ngFor="let tag of searchTagsList" [value]="tag.name">
                            <span>{{ (tag) ? tag.name : ('NO_NAME' | translate) }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="table-row-with-padding" *ngIf="isMobileDevice">
                <div class="table-row" *ngIf="(taskCpy.tags && taskCpy.tags.length > 0)">
                    <div class="tags-list-view margin-left-35">
                        <span *ngFor="let tag of taskCpy.tags" class="edit-input-tag">
                            <span class="edit-input-tag-name">{{tag.name}}</span>
                            <i class="material-icons" (click)="removeTag(tag)">close</i>
                        </span>
                    </div>
                </div>
            </div>
            <!-- watchers -->
            <div class="table-row-with-padding">
              <div class="table-row" *ngIf="taskCpy.watchers && taskCpy.watchers.length > 0 || selectedWatcherList.length > 0">
                  <div class="label-table-cell">
                      <span class="detail-label-text">{{ 'WATCHERS' | translate }}:</span>
                  </div>
                  <div class="value-table-cell add-attachment-info" (click)="openWatcherDialog()">
                    <mdl-icon>add</mdl-icon>
                    <span class="label-text">{{ 'ADD_WATCHERS' | translate }}</span>
                </div>
              </div>
              <div *ngFor="let watcher of taskCpy.watchers" class="table-row">
                  <div class="watcherListView isEditMode">
                      <vp-avatar [user]="{ name: watcher && watcher.name ? watcher.name : '', avatarURL: watcher.avatar }"> </vp-avatar>
                      <div class="name">{{ watcher.name }}</div>
                      <div class="clearBtn">
                          <mdl-icon (click)="removeWatchers(watcher, true)">clear</mdl-icon>
                      </div>
                  </div>
              </div>
              <div *ngFor="let watcher of selectedWatcherList" class="table-row">
                  <div class="watcherListView isEditMode">
                      <vp-avatar [user]="{ name: watcher && watcher.name ? watcher.name : '', avatarURL: watcher.avatar }"> </vp-avatar>
                      <div class="name">{{ watcher.name }}</div>
                      <div class="clearBtn">
                          <mdl-icon (click)="removeWatchers(watcher, false)">clear</mdl-icon>
                      </div>
                  </div>
              </div>
            </div>
            <div class="separate-bar margin-top-bottom-10"></div>
            <div class="detail-add-fields">
                <span (click)="openAddFieldDialog()">{{ 'ADD_FIELDS' | uppercase | translate }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!isEditMode && commentListExpanded" class="add-comment-wrapper">
        <div class="add-comment">
            <div class="add-comment--textbox">
                <textarea [vpMention]="userMentionList" [mentionConfig]="{ triggerChar:'@', maxItems:100, labelKey:'label', mentionSelect: onMentionSelect }" id="task-edit-comment-textfield" class="comment-input ng-dirty" #commentInput [(ngModel)]="commentText" (input)="inputHandler($event)"
                    (change)="changeValue($event)" (click)="setAddCommentFocus()" placeholder="{{ 'ENTER_A_COMMENT' | translate }}"></textarea>
            </div>
            <i id="task-edit-comment-send" class="add-comment--btn material-icons" [class.disabled]="!commentText" (click)="addComment()">send</i>
        </div>
    </div>
    <div *ngIf="isEditMode" [class.with-toolbar]="isToolbarOpen" class="task__detail-footer">
        <button class="negative" (click)="backToDetail()"> {{ 'CANCEL' | translate }} </button>
        <button class="primary" (click)="applyChanges()" [disabled]="applying || !externalURLForm.valid"> {{ 'SAVE' | translate }} </button>
    </div>
</div>
<mdl-popover id="task-detail-header-menubar-popover" #taskDetailHeaderPopover mdl-popover-position="bottom-right" [hide-on-click]="true">
    <div class="task-detail-header-menu-popover">
        <mdl-list>
            <mdl-list-item mdl-ripple class="menu-item" (click)="onEdit(task)">
              <mdl-icon>edit</mdl-icon>
              {{'EDIT_TASK' | translate}}
            </mdl-list-item>
            <mdl-list-item class="menu-item" (click)="onCompleteTask()">
              <mdl-icon>check_circle</mdl-icon>
              {{'COMPLETE' | translate}}
            </mdl-list-item>
            <mdl-list-item class="menu-item" (click)="onDuplicateTask()">
              <mdl-icon>content_copy</mdl-icon>
              {{'CLONE_TASK' | translate}}
            </mdl-list-item>
            <mdl-list-item class="menu-item" (click)="onDeleteTask()">
              <mdl-icon>delete</mdl-icon>
              {{'DELETE_TASK' | translate}}
            </mdl-list-item>
        </mdl-list>
    </div>
</mdl-popover>
