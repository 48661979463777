
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ICompact } from "./../../../models/task";
import { ViewChild, Component, Input, Output, EventEmitter, HostBinding, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { DatePipe } from "@angular/common";
import { TaskDatePickerComponent } from "../../task-date-picker/task-date-picker.component";
import { MdlDialogService } from "@angular-mdl/core";
import { DateType, User } from "../../../models";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({ template: ``, changeDetection: ChangeDetectionStrategy.OnPush })
export class TaskOptionComponent implements OnDestroy {
  @Input() onMainView: boolean = true;
  @Input() isVisible: boolean = false;
  @Output() onOpen = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @HostBinding("class") classes = "option";
  @HostBinding("class.active") active: boolean = false;
  id: string = "base-option";
  triggerKey: string;
  private value: any;
  private items: any[] = [];
  protected filteredItems: any[] = [];
  private selectedIndex: number = 0;
  private startDate: any;
  private completeDate: any;
  private datePipe: DatePipe = new DatePipe("en");
  rangeStart = new Date();
  private TagValue: ICompact[] = [];
  private watcherValue: User[] = [];
  content: any = "";

  constructor(
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog
  ) {
    this.rangeStart.setDate(this.rangeStart.getDate() - 1);
  }

  setItems(items: any[]) {
    this.items = items;
    this.filteredItems = (items || []).slice();
    this.changerDetectorRef.markForCheck();
  }

  setValue() {
    this.value = this.selectedIndex >= 0 ? this.filteredItems[this.selectedIndex] : null;
    this.selectedIndex = 0;
    this.filteredItems = this.items.slice();
    this.changerDetectorRef.markForCheck();
  }

  clearValue() {
    this.value = null;
    this.changerDetectorRef.markForCheck();
  }

  getValue() {
    return this.value;
  }

  getPayload() {
    return {};
  }

  filter(q: string) {
    this.filteredItems =
      this.items.filter(item => (item.name || "").toLowerCase().includes(q.trim().toLowerCase()));
    this.selectedIndex = 0;
    this.changerDetectorRef.markForCheck();
  }

  userSearchFilter(q: string) {
    this.filteredItems =
      this.items.filter(item => (item.name || "").toLowerCase().indexOf(q.trim().toLowerCase()) === 0 ||
        (item.mail || "").toLowerCase().indexOf(q.trim().toLowerCase()) === 0
      );
    this.selectedIndex = 0;
    this.changerDetectorRef.markForCheck();
  }

  navigate(direction: string) {
    if (direction === "up") {
      this.selectedIndex += 1;
    } else if (direction === "down") {
      this.selectedIndex -= 1;
    }
    if (this.selectedIndex > this.filteredItems.length - 1) {
      this.selectedIndex = 0;
    }
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.filteredItems.length - 1;
    }
    this.changerDetectorRef.markForCheck();
  }

  show() {
    this.active = true;
  }

  hide() {
    this.active = false;
  }

  isActive() {
    return this.active;
  }

  validate() { }

  isValid() {
    return true;
  }

  onItemClick(item: any) {
    this.selectedIndex = this.filteredItems.indexOf(item);
    this.setValue();
    this.onSelect.emit();
    this.changerDetectorRef.markForCheck();
  }

  onDescriptionClick(item: any) {
    this.value = item;
    this.onSelect.emit();
    this.changerDetectorRef.markForCheck();
  }

  onTagClick(items: ICompact[]) {
    items.forEach( item => {
      this.TagValue.push(item);
    });
    this.onSelect.emit(items);
    this.changerDetectorRef.markForCheck();
  }

  onWatcherClick(items: User[]) {
    items.forEach( item => {
      this.watcherValue.push(item);
    });
    this.onSelect.emit(items);
    this.changerDetectorRef.markForCheck();
  }

  onRemoveTag(tagName) {
    console.log("[TaskOptionComponent][onRemoveTag] tagName", tagName, this.TagValue);

    this.TagValue = this.TagValue.filter( tag => tag.name !== tagName);

    console.log("[TaskOptionComponent][onRemoveTag] this.TagValue", this.TagValue);
  }

  getTagValue() {
    return this.TagValue;
  }


  onRemoveWatcher(userName) {
    console.log("[TaskOptionComponent][onRemoveWatcher] userName", userName, this.TagValue);

    this.watcherValue = this.watcherValue.filter( user => user.name !== userName);

    console.log("[TaskOptionComponent][onRemoveWatcher] this.watcherValue", this.watcherValue);
  }

  getWatcherValue() {
    return this.watcherValue;
  }

  onExternalUserClick(item: any) {
    this.value = item;
    this.onSelect.emit();
    this.changerDetectorRef.markForCheck();
  }

  openDatePicker(type) {
    const dlg = this.matDialog.open(TaskDatePickerComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-datepicker-dialog",
      data: { header: "", rangeStart: this.rangeStart}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        if ( type === DateType.START_DATE) {
          this.startDateSelect(res);
        } else {
          this.completeDateSelect(res);
        }
      }
    });
  }

  startDateSelect(event) {
    this.startDate = { date: event, name: this.datePipe.transform(event, "MMM dd") };
    this.value = this.startDate;
    this.onClick.emit(this.startDate);
    this.changerDetectorRef.markForCheck();
  }

  completeDateSelect(event) {
    this.completeDate = { date: event, name: this.datePipe.transform(event, "MMM dd") };
    this.value = this.completeDate;
    this.onClick.emit(this.completeDate);
    this.changerDetectorRef.markForCheck();
  }

  private onPushBugHack() {
    setTimeout(() => {
    this.changerDetectorRef.markForCheck();
    }, 100);
  }

  ngOnDestroy(): void {
    this.onOpen.unsubscribe();
    this.onSelect.unsubscribe();
    this.onClick.unsubscribe();
  }

  goBack() {
    this.onBack.emit();
  }
}
