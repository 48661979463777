
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ViewChild, Input, OnDestroy, ChangeDetectionStrategy, Inject, Optional } from "@angular/core";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { dialogType } from "../../models";
import { takeWhile } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-confirm-dialog",
  template: `
  <div class="task__dialog">
    <div *ngIf="header" class="task__dialog-header">
        <div class="header_lbl disable-select">
            {{ header | translate }}
        </div>
    </div>
    <div class="task__dialog-body">
      <div class="content">
        <span>{{ message | translate}}</span>
      </div>
    </div>
    <div class="task__dialog-footer">
      <div class="buttons-div">
        <button mat-button id="task-confirm-cancel-btn" (click)="no()">{{ 'TASK_CANCEL_LABEL' | translate }}</button>
        <button mat-button *ngIf="dialogType.DELETE === type" id="task-confirm-delete-btn" (click)="confirm()">{{ 'TASK_DELETE_LABEL' | translate }}</button>
        <button mat-button *ngIf="dialogType.RESET === type" id="task-confirm-delete-btn" (click)="confirm()">{{ 'TASK_RESET_LABEL' | translate }}</button>
        <button mat-button *ngIf="dialogType.UNSAVE === type" id="task-confirm-delete-btn" (click)="confirm()">{{ 'SAVE' | translate }}</button>
        <button mat-button *ngIf="dialogType.DEACTIVATE === type" id="task-confirm-delete-btn" (click)="confirm()">{{ 'DEACTIVATE' | translate }}</button>
        <button mat-button *ngIf="dialogType.OK === type" id="task-confirm-delete-btn" (click)="confirm()">{{ 'OK' | translate }}</button>
      </div>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskConfirmDialogComponent implements OnDestroy {
  header: string;
  message: string;
  type: dialogType;
  dialogType = dialogType;
  isAlive = true;

  constructor( private broadcaster: Broadcaster,
    private dialogRef: MatDialogRef<TaskConfirmDialogComponent>,
    private dialogService: MdlDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
     ) {
    this.header = (this.data && this.data.header) ? this.data.header : null;
    this.message = this.data.message;
    this.type = this.data.type;
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hideDialog();
      });
    this.broadcaster.on<any>("hideTaskConfirmDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hideDialog();
      });
  }

  public confirm() {
    this.hideDialog(true);
  }

  public no(): void {
    this.hideDialog(false);
  }

  @HostListener("document:keydown.esc", ["$event"])
  public onEsc(): void {
    this.dialogRef.close();
  }

  public hideDialog(value?): void {
    this.dialogRef.close(value);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
