
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="passwordreset" [style.backgroundImage]="backgroundImage">
    <div class="passwordreset-form">
        <div class="title">{{ 'RESET_PASSWORD_TITLE' | translate }}</div>
        <div style="margin-top:30px;">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onFormSubmit(resetPasswordForm.value)">
                <mat-form-field hintLabel="{{ 'PASSWORD_HINT' | translate: {'minLength': minPasswordLenth} }}">
                    <input type="password" autocomplete="password" formControlName="password" matInput placeholder="{{ 'PASSWORD' | translate }}"
                        required>
                </mat-form-field>
                <mat-form-field>
                    <input type="password" autocomplete="confirmPassword" formControlName="confirmPassword" matInput
                        placeholder="{{ 'PASSWORD_REPEAT' | translate }}" required>
                    <mat-error *ngIf="resetPasswordForm.get('confirmPassword').errors && resetPasswordForm.get('confirmPassword').errors.ConfirmPassword">
                        {{ 'PASSWORD_MISMATCH_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <div class="login-button">
                    <button type="submit" [disabled]="!resetPasswordForm.valid">{{ 'RESET' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="app_footer-title">
        <span class="disable-select" (click)="returnToLoginPage()">{{ 'RETURN_TO_LOGIN_PAGE' | translate }}</span>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
