
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTaskStatisticsInfo } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { TaskStatistics } from "../../models";
import { MdlDialogReference } from "@angular-mdl/core";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile, take } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-option-list",
  template: `
  <div class="task__dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'TASKS' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body task-option-list_dialog" id="taskListsScreen">
    <mdl-list>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_ALL_TASK)" id="allTasksMenu">
        <mdl-icon>event_available</mdl-icon>
        <span class="list-item">{{ taskConstants.ALL_TASK | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_ALL_TASK) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_NEW)" id="newMenu">
        <mdl-icon>panorama_fish_eye</mdl-icon>
        <span class="list-item">{{ taskConstants.NEW_TASK | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_NEW) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_OPEN)" id="incompleteMenu">
        <mdl-icon>timelapse</mdl-icon>
        <span class="list-item">{{ taskConstants.OPEN | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_OPEN) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_COMPLETED)" id="completedMenu">
        <mdl-icon>check_circle</mdl-icon>
        <span class="list-item">{{ taskConstants.COMPLETED | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_COMPLETED) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_ASSIGNEDTOME)" id="assignToMeMenu">
        <mdl-icon>assignment</mdl-icon>
        <span class="list-item">{{ taskConstants.ASSIGNEDTOME | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_ASSIGNEDTOME) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_CREATEDBYME)" id="createdByMeMenu">
        <mdl-icon>assignment</mdl-icon>
        <span class="list-item">{{ taskConstants.CREATEDBYME | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_CREATEDBYME) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_TODAY_DUE)" id="todayMenu">
        <mdl-icon>access_time</mdl-icon>
        <span class="list-item">{{ taskConstants.TODAY_DUE | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_TODAY_DUE) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_TOMORROW_DUE)" id="tomorrowMenu">
        <mdl-icon>watch_later</mdl-icon>
        <span class="list-item">{{ taskConstants.TOMORROW_DUE | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_TOMORROW_DUE) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_THIS_WEEK_DUE)" id="thisWeekMenu">
        <mdl-icon>date_range</mdl-icon>
        <span class="list-item">{{ taskConstants.THIS_WEEK_DUE | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_THIS_WEEK_DUE) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_TASKS_I_WATCH)" id="taskIWatchMenu">
        <mdl-icon>visibility</mdl-icon>
        <span class="list-item">{{ taskConstants.TASKS_I_WATCH | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_TASKS_I_WATCH) }}</span>
        </div>
      </mdl-list-item>
      <mdl-list-item (click)="navigateRoute(taskConstants.ROUTE_MY_OVERDUE_TASKS)" id="myoverduetasks">
        <mdl-icon>assignment_late</mdl-icon>
        <span class="list-item">{{ taskConstants.MY_OVERDUE_TASKS | translate }}</span>
        <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_MY_OVERDUE_TASKS) }}</span>
        </div>
      </mdl-list-item>
    </mdl-list>
  </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskOptionListComponent implements OnDestroy {
  selectedFilter: string;
  taskConstants = TasksConstants;
  total_count: number = 0;
  taskStatistics: TaskStatistics[];
  isAlive = true;
  isOnline: boolean = false;

  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaskOptionListComponent>
  ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideTaskOptionListDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.setupStore();
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(take(1))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });
    this.store
      .select(getTaskStatisticsInfo)
      .pipe(take(1))
      .subscribe(info => {
        this.taskStatistics = info;
        this.changerDetectorRef.markForCheck();
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  navigateRoute(title: any) {
    this.hide(title);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(title?) {
    this.dialogRef.close({ type: this.taskConstants.ROUTE_TYPE_STATUS, title: title});
  }

  getTotalCount(routeName) {
    if (this.taskStatistics) {
      let taskStatistic: TaskStatistics = this.taskStatistics.find( statisticInfo =>
        statisticInfo.type === routeName );
      return taskStatistic ? taskStatistic.info.total_count : 0;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
