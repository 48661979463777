
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { TaskUtils } from "../../../../shared/task-utils";
import { TaskRepository } from "../../../../repository/task.repository";
import { DateFilterType } from "../../../../shared/task-enum";
import { MdlDialogService } from "@angular-mdl/core";
import { DateType } from "../../../../models";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-option-start-date",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => TaskStartDateOptionComponent)
  }],
  templateUrl: "option-start-date.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStartDateOptionComponent extends TaskOptionComponent implements OnInit {
  id: string = "add-start-date";
  triggerKey = "~";
  dateType = DateType.START_DATE;

  constructor(
    private taskRepo: TaskRepository,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog
  ) {
    super(changerDetectorRef, matDialog);
  }

  ngOnInit() {
    let items = this.taskRepo.getDateFilterItems(DateFilterType.StartDate);
    this.setItems(items);
  }

  getPayload() {
    let value = this.getValue();
    if (!value || (value && !value.date)) return { start_date: ""};
    return { start_date: TaskUtils.getFormattedDate(value.date) };
  }

}
