
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable , NgZone } from "@angular/core";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import * as RecordRTC from "recordrtc";

import * as moment from "moment";
import { TasksConstants } from "../shared/task-constacts";
import { Broadcaster } from "../../common/providers/broadcaster.service";


interface RecordedAudioOutput {
  content: any;
  title: string;
  type: string;
}

@Injectable()
export class AudioService {

  private stream;
  private recorder;
  private _onAudioRecorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _onAudioRecordingFailed = new Subject<string>();
  private recordTimeLimitSeconds = 5 * 60;
  private recordedTime;
  private recordingTimeInterval;
  private startTime;
  private totalSeconds = 0;
  constructor(private zone: NgZone, private broadcaster: Broadcaster) {

  }


  startRecording() {

    console.log("[Audio Service] starting recorder");

    if ((typeof device !== "undefined" && device.platform && device.platform.toLowerCase() === "ios")) {
      this.mobileAudioRecording();
      return;
    }
    else {

      if (this.startTime) {
        return;
      }

      this.recordedTime = "00:00:00";
      this.totalSeconds = 0;
      this._recordingTime.next(this.recordedTime);

      navigator.mediaDevices.getUserMedia({ audio: true }).then(s => {
        this.stream = s;
        this.record();

      }).catch(error => {
        console.log("error in startMedia" , error);
        this._onAudioRecordingFailed.next();
      });
    }

  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {

    console.log("[Audio Service] recording started");

    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream , {
      type: "audio" ,
      mimeType: "audio/webm"
    });
    this.recorder.record();
    this.recordTimer();

  }

  recordTimer() {
    this.startTime = moment();
    this.recordingTimeInterval = setInterval(
      () => {
        this.totalSeconds += 1;
        const hours = Math.floor(this.totalSeconds / 3600);
        const minutes = Math.floor(this.totalSeconds / 60 % 60);
        const seconds = this.totalSeconds % 60;
        this.recordedTime = this.twoDigits(hours) + ":" + this.twoDigits(minutes) + ":" + this.twoDigits(seconds);
        this._recordingTime.next(this.recordedTime);
      } ,
      1000
    );
  }

  stopRecording() {
    if (this.startTime) {
      console.log("[Audio Service] recording stopped");
      this.recorder.stop((blob) => {
        if (this.startTime) {
          let mp3Name = encodeURIComponent("audio_recording_" + new Date().getTime() + ".mp3");
          console.log("[Audio Service] stop recording success");
          this.stopMedia();
          let reader = new FileReader();
          let self = this;
          if ( blob.size > TasksConstants.FILE_UPLOAD_LIMIT) {
            this._onAudioRecordingFailed.next();
            this.broadcaster.broadcast("OnFileLimitExcededError");
          } else {
            reader.onloadend = function (e) {
              self._onAudioRecorded.next({ content: reader.result, title: mp3Name, type: "audio/mp3" });
            };
            reader.readAsArrayBuffer(blob);
          }
        }
      } , () => {
        this.stopMedia();
        this._onAudioRecordingFailed.next();
      });
    }

  }

  pauseRecording() {
    this.recorder.pause();
    clearInterval(this.recordingTimeInterval);
  }

  resumeRecording() {
    this.recorder.resume();
    this.recordTimer();
  }

  private stopMedia() {
    if (this.startTime) {
      console.log("[Audio Service] media stopped");
      this.recorder = null;
      this.recordedTime = null;
      clearInterval(this.recordingTimeInterval);
      this.startTime = null;
      this.stopStream(this.stream);
      this.stream = null;
    }
  }

  private stopStream(stream: MediaStream) {
    if (stream) {
      stream.getAudioTracks().forEach(track => track.stop());
    }
  }

  getRecordedAudio(): Observable<RecordedAudioOutput> {
    return this._onAudioRecorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  isRecordingFailed(): Observable<string> {
    return this._onAudioRecordingFailed.asObservable();
  }


  private twoDigits(num) {
    if (isNaN(num)) {
      return "00";
    }
    if (num < 10) {
      return "0" + num;
    }
    return num;
  }

  private mobileAudioRecording() {
    console.log("[Audio] mobile audio recording");

    let captureError = (e) => {
      console.error("[mobileAudioRecording.captureError]" , e);
      this._onAudioRecordingFailed.next();
    };

    let captureSuccess = (e) => {
      console.log("[mobileAudioRecording.captureOK]" , e);
      let filePath = e[0].localURL;
      window.resolveLocalFileSystemURL(filePath , (fileEntry: any) => {
        fileEntry.file((file) => {
          let reader = new FileReader();
          let self = this;
          if ( file.size > TasksConstants.FILE_UPLOAD_LIMIT) {
            this._onAudioRecordingFailed.next();
            this.broadcaster.broadcast("OnFileLimitExcededError");
          } else {
            reader.onloadend = function (e) {
              let mp3Name = encodeURIComponent("audio_recording_" + file.name);
              self._onAudioRecorded.next({ content: reader.result, title: mp3Name, type: "audio/wav"});
            };
            reader.readAsArrayBuffer(file);
          }
        } , (e) => {
          console.error("[window.resolveLocalFileSystemURL.accessFile]" , e);
          this._onAudioRecordingFailed.next();
        });
      } , (e) => {
        console.error("[window.resolveLocalFileSystemURL]" , e);
        this._onAudioRecordingFailed.next();
      });
    };
    navigator.device.capture.captureAudio(
      captureSuccess , captureError , { duration: this.recordTimeLimitSeconds }
    );
  }

}
