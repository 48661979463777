
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Component, OnDestroy, ElementRef, ViewChild, Input, Inject } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { TasksRootState } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile, take, finalize } from "rxjs/operators";
import { TaskService } from "../../task.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-description-diff-dialog",
  template: `
  <div class="task__dialog vp-description-diff-dialog">
    <div class="task__dialog-header">
      <div class="mobile-back-button">
        <button mat-button (click)="hide()">
            <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'DESCRIPTION' | translate}}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="hide()">
            <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body">
      <div class="diff-label">{{ 'DIFF' | translate }}</div>
      <div id="descDiff"></div>
    </div>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DescriptionDiffDialogComponent implements OnDestroy {
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  isOnline: boolean = false;
  isLoading: boolean = false;
  journal_id: any;
  detail_id: any;

  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskService: TaskService,
    private dialogRef: MatDialogRef<DescriptionDiffDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.setupStore();
    this.broadcaster.on<any>("hideDiscriptionDiffDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.journal_id = this.data.journal_id;
    this.detail_id = this.data.detail_id;
    this.getDescriptionDiff(this.journal_id, this.detail_id);
    this.changerDetectorRef.markForCheck();
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  getDescriptionDiff(journal_id, detail_id) {
    if (this.isOnline) {
        this.isLoading = true;
        this.taskService.getDescriptionDiff(journal_id, detail_id).pipe(take(1), finalize( () => {
            this.isLoading = false;
        })).subscribe( res => {
            let newDesc = "";
            let oldDesc = "";

            if ( res && res.new_value ) {
              newDesc = CommonUtil.escapeColonURLs(res.new_value);
              newDesc = textile(newDesc);
              newDesc = newDesc.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
              newDesc = CommonUtil.linkify(newDesc);
            }

            if ( res && res.old_value) {
              oldDesc = CommonUtil.escapeColonURLs(res.old_value);
              oldDesc = textile(oldDesc);
              oldDesc = oldDesc.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");
              oldDesc = CommonUtil.linkify(oldDesc);
            }

            let output = htmldiff(oldDesc, newDesc);

            document.querySelector("#descDiff").innerHTML = output;
            this.changerDetectorRef.markForCheck();
        }, err => {
        });
    }
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
