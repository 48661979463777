
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskGeneralSettingsDialog" class="task__dialog vp-task-general-settings-dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'TASK_GENERAL_SETTINGS' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body task__dialog-body--pad" [class.save-mode]="isUpdated">
    <div class="row">
      <label>{{'LANGUAGE_LABEL' | translate}}</label>
      <mdl-select id="selectLanguage" class="mdl-selection-option" [(ngModel)]="selectedLanguage" (change)="updateOptions()">
        <mdl-option *ngFor="let language of supportedLanguages" [value]="language.value">{{language.display | translate}}</mdl-option>
      </mdl-select>
    </div>
    <div class="row" *ngIf="showServerSettings">
      <label>{{'SERVER_URL' | translate}}</label>
      <mdl-textfield [(ngModel)]="serverURL" (change)="updateOptions()"></mdl-textfield>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="notification-header">{{ 'SYSTEM_NOTIFICATIONS' | translate }}</div>
      <mdl-list>
        <mdl-list-item>
          <mdl-list-item-primary-content>
            <span class="heading">{{ 'GLOBAL_MUTE' | translate }}</span>
            <mdl-list-item-sub-title>{{ 'GLOBAL_MUTE_SUB_TITLE' | translate }}</mdl-list-item-sub-title>
          </mdl-list-item-primary-content>
          <mdl-list-item-secondary-content>
            <mdl-switch [(ngModel)]="global_mute" (click)="updateOptions(); onGlobalMuteChange()" id="switchGlobalMute"></mdl-switch>
          </mdl-list-item-secondary-content>
        </mdl-list-item>
        <mdl-list-item [class.disable-event]="global_mute">
          <mdl-list-item-primary-content>
            <span class="heading">{{ 'SOUND' | translate }}</span>
            <mdl-list-item-sub-title></mdl-list-item-sub-title>
          </mdl-list-item-primary-content>
          <mdl-list-item-secondary-content>
            <mdl-switch [(ngModel)]="sound" (click)="updateOptions()" id="switchSound"></mdl-switch>
          </mdl-list-item-secondary-content>
        </mdl-list-item>
        <mdl-list-item [class.disable-event]="global_mute">
          <mdl-list-item-primary-content>
            <span class="heading">{{ 'NOTIFICATIONS' | translate }}</span>
            <mdl-list-item-sub-title></mdl-list-item-sub-title>
          </mdl-list-item-primary-content>
          <mdl-list-item-secondary-content>
            <mdl-switch [(ngModel)]="notification" (click)="updateOptions()" id="switchNotification"></mdl-switch>
          </mdl-list-item-secondary-content>
        </mdl-list-item>
      </mdl-list>
    </div>
    <div class="divider"></div>
    <div class="row" *ngIf="!isCordova && isSupport2FA">
      <div class="notification-header">{{ 'SECURITY' | translate }}</div>
      <mdl-list>
        <mdl-list-item>
          <mdl-list-item-primary-content>
            <span class="heading">{{ 'ENABLE_TWO_FACTOR_AUTHENTICATION' | translate }}</span>
            <mdl-list-item-sub-title></mdl-list-item-sub-title>
          </mdl-list-item-primary-content>
          <mdl-list-item-secondary-content>
            <mdl-switch [(ngModel)]="use2FA" id="2faSetting" (click)="update2FAOptions()"></mdl-switch>
          </mdl-list-item-secondary-content>
        </mdl-list-item>
        <mdl-list-item *ngIf="!isCordova && use2FA && !is2FAEnabled">
          <div class="start-authenticator-app" (click)="setAuthenticationApp()">
            {{'START_AUTHENTICATOR_APP' | translate }}
          </div>
        </mdl-list-item>
      </mdl-list>
    </div>
    <div class="divider" *ngIf="!isCordova && isSupport2FA"></div>
    <div class="row">
      <div class="notification-header">{{ 'EMAIL_NOTIFCATIONS' | translate }}</div>
      <mdl-list>
        <mdl-list-item>
          <mdl-list-item-primary-content>
            <span class="heading">{{ 'EMAIL_NOTIFCATIONS' | translate }}</span>
            <mdl-list-item-sub-title>{{ 'EMAIL_NOTIFCATIONS_SUB_TITLE' | translate }}</mdl-list-item-sub-title>
          </mdl-list-item-primary-content>
          <mdl-list-item-secondary-content>
            <mdl-switch [(ngModel)]="mail_notification" (click)="updateOptions()" id="switchEmailNotification"></mdl-switch>
          </mdl-list-item-secondary-content>
        </mdl-list-item>
        <mdl-list-item>
          <mdl-list-item-primary-content>
            <span class="heading">{{ 'DISABLE_NOTIFICATIONS_OF_MY_TASKS' | translate }}</span>
            <mdl-list-item-sub-title>{{ 'DISABLE_NOTIFICATIONS_OF_MY_TASKS_SUB_TITLE' | translate }}</mdl-list-item-sub-title>
          </mdl-list-item-primary-content>
          <mdl-list-item-secondary-content>
            <mdl-switch [(ngModel)]="no_self_notified" (click)="updateOptions()"></mdl-switch>
          </mdl-list-item-secondary-content>
        </mdl-list-item>
      </mdl-list>
      <div class="deactivate-div" id="deactivateAccount" *ngIf="!disallowedDeleteAccount">
        <span (click)="feedback()">{{ 'DEACTIVATE_ACCOUNT' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="close()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="!isUpdated" (click)="onSaveSetting()">
              {{ 'SAVE' | translate }}
          </button>
      </div>
      <div class="mobile-footer" *ngIf="isUpdated">
          <div class="title">
              {{ 'SETTINGS_UPDATED_APPLY_CHANGES' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button (click)="onSaveSetting()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
</div>
