
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HostListener, Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from "@angular/core";
import { MdlDialogReference, MdlSnackbarService, MdlDialogService } from "@angular-mdl/core";
import { TaskOptionComponent } from "./task-options/task-option.component";
import { TaskComposeInputComponent } from "./task-compose-input/task-compose-input.component";
import { Project } from "../../models";
import { TaskService } from "../../task.service";
import { ConfigService } from "../../../common/providers/config.service";
import { TranslateService } from "@ngx-translate/core";
import { TaskRepository } from "../../repository/task.repository";
import { TasksConstants } from "../../shared/task-constacts";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { Store } from "@ngrx/store";
import { TasksRootState, getAuthUser, getProjectsList, getTasksIsLoading, getMemberList } from "../../store/index";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { AuthUser } from "../../models/user";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { dialogType } from "../../models/dialog-type";
import { CommonUtil } from "../../../common/utils/common.utils";
import { getOnlineStatus } from "../../../reducers";
import { TaskAddTagsOptionComponent, TaskAddWatchersOptionComponent, TaskAddUserOptionComponent } from "./task-options";
import { takeWhile, take, filter } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-task-compose",
  templateUrl: "task-compose.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskComposeComponent implements AfterViewInit, OnDestroy {
  @ViewChildren(TaskOptionComponent) optionComponents: QueryList<TaskOptionComponent>;
  @ViewChild(TaskComposeInputComponent, { static: false }) inputComponent: TaskComposeInputComponent;
  @ViewChild(TaskAddTagsOptionComponent, { static: false }) tagComponent: TaskAddTagsOptionComponent;
  @ViewChild(TaskAddWatchersOptionComponent, { static: false }) watcherComponent: TaskAddWatchersOptionComponent;
  @ViewChild(TaskOptionComponent, { static: false }) optionComponent: TaskAddWatchersOptionComponent;
  _optionComponents: TaskOptionComponent[] = [];
  activeComponent: TaskOptionComponent;
  selectedProject: Project;
  submitting: boolean = true;
  addTaskInProgress: boolean = false;
  configProject: any;
  defaultConfigProject: String;
  currentUser: AuthUser;
  isAlive = true;
  isDescOpen: boolean = false;
  dialogType = dialogType;
  projectList: Project[] = [];
  isOnline: boolean = false;
  pDialog: any;
  isLoading: boolean = false;
  isOnIOS: boolean = CommonUtil.isOnIOS();
  assignee: any;
  constructor(
    public dialogRef: MatDialogRef<TaskComposeComponent>,
    private mdlSnackbarService: MdlSnackbarService,
    private service: TaskService,
    private configService: ConfigService,
    private translate: TranslateService,
    private tasksRepo: TaskRepository,
    private errorService: ErrorService,
    private successService: SuccessService,
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogService: MdlDialogService,
    private messageTranslatorService: MessageTranslatorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog
  ) {
    if (this.data && this.data.assignee) {
      this.assignee = this.data.assignee;
      this.changerDetectorRef.markForCheck();
    }
    this.setupStore();
    this.handleTaskCreateSuccess();
    this.handleTaskCreateFail();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.goBack();
      });

    this.broadcaster.on<any>("hideTaskComposeDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.goBack();
      });
  }

  ngAfterViewInit() {
    if ( this.data && (this.data.description !== undefined)) {
      let component = this.optionComponents.find(component => component.id === "add-description");
      this.inputComponent.setActiveComponent(component);
      component.content = this.data.description;
    }

    setTimeout(() => {
      this._optionComponents = this.optionComponents.toArray();

      this.store.select(getMemberList).pipe(filter(members => members.length > 0), take(1)).subscribe( members => {
        if (this.assignee) {
          let member = members.find( member =>  member.username === this.assignee);
          if (member) {
            let component = this.optionComponents.find(component => component.id === "add-user");
            this.inputComponent.setActiveComponent(component);
            component.onItemClick(member);
            if (this.inputComponent && this.inputComponent.composeInput) {
              this.inputComponent.composeInput.nativeElement.focus();
            }
          }
        }
      });
    });
  }

  handleTaskCreateSuccess() {
    this.successService.only(SuccessType.TaskCreated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.dialogRef.close();
      this.submitting = false;
      this.mdlSnackbarService.showToast(
        success.messages
      );

      this.tasksRepo.getTasksStats();
      this.tasksRepo.getFolderListWithCounters();
      this.tasksRepo.getTagsListWithCounters();
      this.tasksRepo.getLocationsWithCounters();

      this.addTaskInProgress = false;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 100);
    });
  }

  handleTaskCreateFail() {
    this.errorService.only(ErrorType.TaskCreateFail).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.submitting = false;
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.addTaskInProgress = false;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 100);
    });
  }

  setupStore() {
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe(projectList => {
      if (projectList.length > 0) {
        this.projectList = projectList;
        this.changerDetectorRef.markForCheck();

        this.configProject = projectList.find( project => project.identifier === localStorage.getItem("redmineDefaultProject"));

        if (!this.configProject) {
          console.error("[TaskComposeComponent][setupStore][getProjectsList] no default project returned from server, ask an administrator to fix it");
        }

        this.selectedProject = this.configProject;
        this.tasksRepo.getMemberList(this.selectedProject.id);
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("[task-compose.component] isLoading: " + value);
      this.isLoading = value;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 100);
    });
  }

  openDialog(id: string) {
    let component = this.optionComponents.find(component => component.id === id);
    if (!component.isValid() && !this.submitting) {
      return component.validate();
    }
    if (!component.getValue() && !this.submitting) {
      if (id === "add-description") {
        this.isDescOpen = true;
      } else {
        this.isDescOpen = false;
      }
      this.changerDetectorRef.markForCheck();
      this.inputComponent.setActiveComponent(component);
    }
  }

  goBack() {
    if (this.inputComponent.getText().trim() === "" || this.activeComponent) {
      if (!this.activeComponent) return this.dialogRef.close();
      this.inputComponent.clearTriggerKey();
      return false;
    }

    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.UNSAVE, message: this.messageTranslatorService.getMessage(TasksConstants.TASK_NOT_SAVED_MSG), header: this.messageTranslatorService.getMessage(TasksConstants.SAVE_TASK) }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.addTask();
      } else {
        if (!this.activeComponent) return this.dialogRef.close();
        this.inputComponent.clearTriggerKey();
      }
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.goBack();
  }

  onOptionClick(event) {
    this.isDescOpen = false;
    let component = this.inputComponent.getActiveComponent();
    this.inputComponent.insertTag(component.getValue());
  }

  onTagOptionClick(items) {
    let component = this.inputComponent.getActiveComponent();
    for (let i = 0; i < items.length; i++) {
      this.inputComponent.activeComponent = component;
      this.inputComponent.insertTag(items[i]);
    }
  }

  onWatcherOptionClick(items) {
    let component = this.inputComponent.getActiveComponent();
    for (let i = 0; i < items.length; i++) {
      this.inputComponent.activeComponent = component;
      this.inputComponent.insertTag(items[i]);
    }
  }

  onStartDateClick(startSelectedDate) {
    let component = this.inputComponent.getActiveComponent();
    this.inputComponent.insertTag(startSelectedDate);
  }

  onCompletionDateClick(composeTaskCompleteDate) {
    let component = this.inputComponent.getActiveComponent();
    this.inputComponent.insertTag(composeTaskCompleteDate);
  }

  onValueChange(data: any) {
    let existProject = false;
    let subject: string = this.inputComponent.getText();
    if (data.id !== undefined && data.id === "link-to-project") {
      let component = this._optionComponents.find(component => component.id === data.id);
      this.selectedProject = component.getValue();
      if (!this.selectedProject) {
        this.selectedProject = this.configProject;
      }
      if (!this.selectedProject && !this.configProject) {
        this.submitting = true;
      }
    } else if (data.id !== undefined && data.id === "vp-vnctask-compose-input") {
      if (subject !== null && subject.length > 0 && (this.selectedProject || this.configProject)) {
        this.submitting = false;
      } else {
        let components = this._optionComponents.filter(component => component.getValue() );
        if (components.length > 0) {
          this.submitting = false;
        } else {
          this.submitting = true;
        }
      }
    }
    this.changerDetectorRef.markForCheck();
  }

  onActiveComponentChange(component: TaskOptionComponent) {
    this.activeComponent = component;
    if (this.activeComponent && this.activeComponent.id === "add-description") {
      this.isDescOpen = true;
    } else {
      this.isDescOpen = false;
    }
  }

  addTask() {
    this.addTaskInProgress = true;
    if (this.inputComponent.getText().trim() === "") {
      let components = this._optionComponents.filter(component => component.getValue() );
      if (components.length === 0) {
        this.addTaskInProgress = false;
        this.goBack();
        return;
      }
    }
    if (this.inputComponent.getText().length > 255 ) {
      this.submitting = false;
      this.tasksRepo.subjectTooLong();
      this.addTaskInProgress = false;
      return;
    }

    let subjectFirstCharacter = this.inputComponent.getText().trim().charAt(0);
    if (subjectFirstCharacter === "~" || subjectFirstCharacter === "^" || subjectFirstCharacter === "*" || subjectFirstCharacter === "=" || subjectFirstCharacter === "+" || subjectFirstCharacter === "!") {
      this.submitting = false;
      this.tasksRepo.subjectNotAllowed();
      this.addTaskInProgress = false;
      return;
    }

    let payload: any = { subject: this.inputComponent.getText().trim() };

    this._optionComponents.forEach(component => {
      Object.assign(payload, component.getPayload());
    });

    // validate estimated_hours
    if (payload.estimated_hours) {
      const estimatedH = payload.estimated_hours.split(":")[0];
      const estimatedM = payload.estimated_hours.split(":")[1];
      if ((estimatedH > 8) || (estimatedH >= 8 && estimatedM > 0)){
        this.submitting = false;
        this.tasksRepo.errorEstimateIsInvalid();
        this.addTaskInProgress = false;
        return;
      }
      if (estimatedM > 60) {
        this.submitting = false;
        this.tasksRepo.errorEstimateMinuteIsInvalid();
        this.addTaskInProgress = false;
        return;
      }
    }

    // validate due_date
    if (payload.due_date && payload.start_date) {
      const sd = new Date(payload.start_date);
      const dd = new Date(payload.due_date);
      if (dd < sd) {
        this.submitting = false;
        this.tasksRepo.errorDueDateInvalid();
        this.addTaskInProgress = false;
        return;
      }
    }

    // set default project
    if (payload.project_id === undefined || payload.project_id === null) {
      Object.assign(payload, { "project_id": this.configProject.id, "project_name": this.configProject.name });
    }

    if (!payload.assigned_to_id && !payload.invitee_email) {
      if (this.currentUser) {
        Object.assign(payload, { "assigned_to_id": this.currentUser.id });
        Object.assign(payload, { "assigned_to_name": this.currentUser.fullname });
      }
    }

    Object.assign(payload, { "author_id": this.currentUser.id });
    Object.assign(payload, { "author_name": this.currentUser.fullname });

    console.log("[task-compose] add task", payload);

    if (this.isOnline || CommonUtil.isSQLSupported()) {
      this.tasksRepo.addNewTask(payload);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      this.addTaskInProgress = false;
    }
  }

  removeTag(tagName) {
    console.log("[TaskComposeComponent][removeTag] tagName", tagName);

    this.tagComponent.removeTag(tagName);
  }

  removeWatcher(userName) {
    console.log("[TaskComposeComponent][removeWatcher] userName", userName);

    this.watcherComponent.removeWatcher(userName);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
