
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { MdlDialogReference } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { ConfigService } from "../../../common/providers/config.service";
import { TaskService } from "../../task.service";
import { TranslateService } from "@ngx-translate/core";
import { TaskRepository } from "../../repository/task.repository";
import { takeUntil } from "rxjs/operators";

const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";

@Component({
    selector: "vp-change-server-dialog",
    templateUrl: "change-server-dialog.html"
})
export class ChangeServerDialogComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    serverUrl = new UntypedFormControl(localStorage.getItem("serverURL") || null, [Validators.required, Validators.pattern(reg)]);
    private isAlive$ = new Subject<boolean>();
    constructor(
        private dialog: MdlDialogReference,
        private broadcaster: Broadcaster,
        private configService: ConfigService,
        private taskRepo: TaskRepository,
        private translate: TranslateService,
        private fb: UntypedFormBuilder) {
        this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
                this.dialog.hide();
            });
        this.broadcaster.on<any>("hideRegistrationFormPage").pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
                this.dialog.hide();
            });
    }

    ngOnInit() {
        this.form = this.fb.group({
            "serverUrl": this.serverUrl
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    cancel() {
        this.dialog.hide();
    }

    save() {
        const url = this.form.value.serverUrl.trim();
        this.configService.changeServerUrl(url).subscribe(data => {
            if (data) {
                // this.configService.clearCache();
                // this.taskService.clearHeaders();
                this.taskRepo.cleanCacheData();
                this.configService.API_URL = url;
                this.configService.selectedServer = true;
                localStorage.setItem("serverURL", url);
                this.configService.setConfigDetails(data);
                this.taskRepo.createTableIfNotExists();
                this.dialog.hide();
            } else {
                this.translate.get("CHANGE_SERVER_ERROR").subscribe(val => {
                    alert(val);
                });
            }
        }, err => {
            this.translate.get("CHANGE_SERVER_ERROR").subscribe(val => {
                alert(val);
            });
        });

    }
}
