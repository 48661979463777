
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskLegalNoticeDialog" class="task__dialog vp-task-legal-notice-dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{'LEGAL_NOTICE' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <div class="legalnotice-action-list" *ngIf="localLang === 'de'">
            <div class="terms-action-div" id="termsOfUseMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/de/Nutzungsbedingungen/">
                    <mdl-icon>assignment</mdl-icon>
                    <span>{{'TERMS_OF_USE' | translate}}</span>
                </a>
            </div>
            <div class="terms-action-div" id="dataPrivacyMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/de/datenschutzerklaerung/">
                    <mdl-icon>lock</mdl-icon>
                    <span>{{'DATA_PRIVACY' | translate}}</span>
                </a>
            </div>
        </div>
        <div class="legalnotice-action-list" *ngIf="localLang === 'en'">
            <div class="terms-action-div" id="termsOfUseMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/terms">
                    <mdl-icon>assignment</mdl-icon>
                    {{'TERMS_OF_USE' | translate}}
                </a>
            </div>
            <div class="terms-action-div" id="dataPrivacyMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/data-privacy-policy/">
                    <mdl-icon>lock</mdl-icon>
                    {{'DATA_PRIVACY' | translate}}
                </a>
            </div>
        </div>
    </div>
</div>
