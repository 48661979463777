
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { TasksConstants } from "./../../shared/task-constacts";
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, ElementRef, AfterViewInit, HostListener } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { MdlDialogReference, MdlSnackbarService, MdlTextFieldComponent } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { TasksRootState } from "../../store/reducers";
import { Store } from "@ngrx/store";
import { getOnlineStatus } from "../../../reducers";
import { TaskRepository } from "../../repository/task.repository";
import { getSelectedFilterOption } from "../../store/selectors/tasks.selectors";
import { Router } from "@angular/router";
import { Location } from "../../models/location";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { takeUntil, takeWhile } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-create-location-dialog",
    templateUrl: "create-location-dialog.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateLocationDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    form: UntypedFormGroup;
    name = new UntypedFormControl(null, [Validators.required]);
    private isAlive$ = new Subject<boolean>();
    isAlive: boolean = true;
    isLoading: boolean = false;
    isOnline: boolean = false;
    isCreate: boolean = true;
    selectedLocation: Location;
    oldLocationName: string;
    selectedFilterOption: string;
    @ViewChild("locationName", { static: false }) locationName: ElementRef;
    constructor(
      private dialogRef: MatDialogRef<CreateLocationDialogComponent>,
      private broadcaster: Broadcaster,
      private errorService: ErrorService,
      private successService: SuccessService,
      private mdlSnackbarService: MdlSnackbarService,
      private store: Store<TasksRootState>,
      private tasksRepo: TaskRepository,
      private changerDetectorRef: ChangeDetectorRef,
      private fb: UntypedFormBuilder,
      private router: Router,
      private messageTranslatorService: MessageTranslatorService,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
     this.isCreate = this.data.isCreate;
      this.selectedLocation = this.data.selectedLocation;
      this.handleCreateLocationSuccessMessages();
      this.handleLocationErrorMessages();
      this.handleUpdateLocationSuccessMessages();
      this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.dialogRef.close();
        });
      this.broadcaster.on<any>("hideCreateLocationDialogPage").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.dialogRef.close();
        });

      this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
        this.isOnline = isOnline;
        this.changerDetectorRef.markForCheck();
      });

      this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilterOption = value;
      });

      this.oldLocationName = (this.selectedLocation) ? this.selectedLocation.name : "";

      if (!this.isCreate) {
        this.name.setValue(this.selectedLocation.name);
      }
    }

    ngOnInit() {
      this.form = this.fb.group({
        "name": this.name
      });
    }

    ngAfterViewInit(): void {
      if (this.locationName) {
        setTimeout( () => {
          this.locationName.nativeElement.focus();
        }, 200);
      }
    }

    handleLocationErrorMessages() {
      this.errorService.only(ErrorType.LocationError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
        this.mdlSnackbarService.showToast(
          error.messages
        );
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
      });
    }

    handleCreateLocationSuccessMessages() {
      this.successService.only(SuccessType.LocationCreated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
        this.mdlSnackbarService.showToast(
            success.messages
          );
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
        this.tasksRepo.getLocationsWithCounters();
        this.cancel(true);
      });
    }

    handleUpdateLocationSuccessMessages() {
      this.successService.only(SuccessType.LocationUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
        this.mdlSnackbarService.showToast(
            success.messages
          );
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
        this.tasksRepo.getLocationsWithCounters();
        if (this.oldLocationName === this.selectedFilterOption) {
          this.router.navigate(["/task/location", this.selectedLocation.name]);
        }
        this.cancel(false);
      });
    }

    ngOnDestroy(): void {
      this.isAlive$.next(false);
      this.isAlive$.unsubscribe();
    }

    @HostListener("document:keydown.esc", ["$event"])
    hide() {
      this.dialogRef.close();
    }

    cancel(value?) {
      this.dialogRef.close(value);
    }

    save() {
      if (this.isOnline) {
        let locationName = this.name.value;
        if (locationName) {
          if (locationName.length > 255 ) {
            this.mdlSnackbarService.showToast(
              this.messageTranslatorService.getMessage(TasksConstants.LOCATION_IS_TOO_LONG)
            );
          } else {
            this.isLoading = true;
            this.changerDetectorRef.markForCheck();
            this.tasksRepo.createLocation(locationName);
          }
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    update() {
      if (this.isOnline) {
        let locationName = this.name.value;
        if (locationName) {
          if (locationName.length > 255 ) {
            this.mdlSnackbarService.showToast(
              this.messageTranslatorService.getMessage(TasksConstants.LOCATION_IS_TOO_LONG)
            );
          } else {
            this.isLoading = true;
            this.selectedLocation.name = locationName;
            this.changerDetectorRef.markForCheck();
            this.tasksRepo.updateLocation(this.selectedLocation);
          }
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
}
