
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, HostListener, ChangeDetectionStrategy, EventEmitter, Output, Input, OnDestroy, ChangeDetectorRef, Inject, InjectionToken } from "@angular/core";
import { MdlDialogComponent, MdlDialogReference } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { OwlDateTimeIntl } from "ng-pick-datetime-ex";
import { MessageTranslatorService } from "../../services";
import { TasksConstants } from "../../shared/task-constacts";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-task-add-reminder-dialog",
  templateUrl: "task-add-reminder-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskAddReminderDialogComponent implements OnDestroy {
  static INJECT_DATA = new InjectionToken("reminderDateTime");
  customDate: any;
  customTime: any;
  dueDateTime: any;
  selectedReminder: any;
  minDate = new Date(new Date().setDate( new Date().getDate() - 1 ));
  reminderOption: any = {
    ON_DUE_DATE: 1,
    ON_CUSTOM_DATE: 2
  };
  isAlive = true;
  reminderDateTime: {
    isDueDate: boolean;
    dateTime: Date;
  };
  constructor( private broadcaster: Broadcaster,
    private owlDateTimeIntl: OwlDateTimeIntl,
    private changerDetectorRef: ChangeDetectorRef,
    private messageTranslatorService: MessageTranslatorService,
    private dialogRef: MatDialogRef<TaskAddReminderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reminderDateTime = this.data.reminderDateTime;
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskAddReminderDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.owlDateTimeIntl.cancelBtnLabel = this.messageTranslatorService.getMessage(TasksConstants.TIME_CANCEL_LABEL);
    this.owlDateTimeIntl.setBtnLabel = this.messageTranslatorService.getMessage(TasksConstants.TIME_SET_LABEL);

    this.customDate = null;
    this.customTime = null;
    this.dueDateTime = null;
    if (this.reminderDateTime.isDueDate) {
      this.selectedReminder = this.reminderOption.ON_DUE_DATE;
      if (this.reminderDateTime.dateTime) {
        this.dueDateTime = this.reminderDateTime.dateTime;
      }
    } else {
      this.selectedReminder = this.reminderOption.ON_CUSTOM_DATE;
      if (this.reminderDateTime.dateTime) {
        this.customDate = this.reminderDateTime.dateTime;
        this.customTime = this.reminderDateTime.dateTime;
      }
    }
    this.changerDetectorRef.markForCheck();
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.close();
  }

  close(value?) {
    this.dialogRef.close(value);
  }

  customTypeSelect() {
    if ( !this.customDate && !this.customTime) {
      let currentDate = new Date();
      this.customDate = currentDate;
      this.customTime = currentDate;
      this.changerDetectorRef.markForCheck();
    }
  }

  addReminder($event) {
    let payload = {};
    switch (this.selectedReminder) {
      case this.reminderOption.ON_DUE_DATE:
        this.reminderDateTime = {
          isDueDate: true,
          dateTime: this.dueDateTime
        };
        break;
      case this.reminderOption.ON_CUSTOM_DATE:
        this.reminderDateTime = {
          isDueDate: false,
          dateTime: new Date(
            this.customDate.getFullYear(),
            this.customDate.getMonth(),
            this.customDate.getDate(),
            this.customTime.getHours(),
            this.customTime.getMinutes()
          )
        };
        break;
    }
    this.changerDetectorRef.markForCheck();
    this.close(this.reminderDateTime);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

}
