
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-create-list-dialog vp-save-query-dialog">
    <form [formGroup]="form">
        <div class="mdl-dialog__title">{{ 'SAVE_FILTER' | translate }}</div>
        <div class="mdl-dialog__content">
            <mat-form-field>
               <input matInput #queryName type="text" formControlName="name">
            </mat-form-field>
        </div>

        <div class="mdl-dialog__actions">
            <button mdl-button (click)="save()" [disabled]="!form.valid" mdl-colored="primary" mdl-ripple>{{ 'SAVE' | translate }}</button>
            <button mdl-button mdl-colored="primary" (click)="cancel()">{{ 'CANCEL' | translate }}</button>

        </div>
    </form>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
        <mdl-spinner single-color active="true"></mdl-spinner>
    </div>
</div>
