
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, AfterViewInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from "@angular/core";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { FileUpload } from "../../models";
import { TaskService } from "../../task.service";
import { ErrorService } from "../../../common/providers/error-service";
import { ErrorType } from "../../shared/task-enum";
import { TasksRootState, SetIsLoading } from "../../store";
import { Store } from "@ngrx/store";
import { TaskAttachmentOptionDialogComponent } from "../task-attachment-option-dialog/task-attachment-option-dialog.component";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TaskConfirmDialogComponent } from "./../task-dialogs/confirm-dialog.component";
import { dialogType } from "../../models/dialog-type";
import { TasksConstants } from "../../shared/task-constacts";
import { MessageTranslatorService } from "../../services";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-add-attachment-dialog",
  templateUrl: "task-add-attachment-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskAddAttachmentDialogComponent implements AfterViewInit, OnDestroy {
  uploads: FileUpload[] = [];
  @Output() onAttach = new EventEmitter();
  isLoading = false;
  CommonUtil = CommonUtil;
  isIOS = CommonUtil.isOnIOS();
  dialogType = dialogType;
  isAlive = true;
  isOnline: boolean = false;

  constructor( private broadcaster: Broadcaster,
    private taskService: TaskService,
    private store: Store<TasksRootState>,
    private errorService: ErrorService,
    private dialogService: MdlDialogService,
    private changerDetectorRef: ChangeDetectorRef,
    private messageTranslatorService: MessageTranslatorService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<TaskAddAttachmentDialogComponent>,
  ){
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskAddAttachmentDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("OnAddAttachItem").pipe(takeWhile(() => this.isAlive))
    .subscribe( $event => {
      this.onAddItem($event);
    });

    this.broadcaster.on<any>("OnFileLimitExcededError").pipe(takeWhile(() => this.isAlive))
    .subscribe( $event => {
      this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.messageTranslatorService.getMessage("FILE_UPLOAD_SIZE_ERROR")});
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.openOptionDialog();
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.close();
  }

  close(uploads?) {
    this.dialogRef.close(uploads);
  }

  onAddItem($event) {
    if (this.isOnline) {
      if ($event.data) {
        this.store.dispatch(new SetIsLoading(true));
        this.isLoading = true;
        this.changerDetectorRef.markForCheck();
        this.taskService.uploadFile($event.data.content).subscribe( res => {
          $event.data.token = res.upload.token;
          this.uploads.push($event.data);
          this.store.dispatch(new SetIsLoading(false));
          this.isLoading = false;
          this.changerDetectorRef.markForCheck();
        }, err => {
          this.store.dispatch(new SetIsLoading(false));
          this.isLoading = false;
          this.changerDetectorRef.markForCheck();
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        });
      }
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  removeItem(item: FileUpload) {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + item.filename + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.uploads.splice(this.uploads.indexOf(item), 1);
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  attachItems($event) {
    this.close(this.uploads);
  }

  openOptionDialog() {
    const dlg = this.matDialog.open(TaskAttachmentOptionDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskattachmentoptiondialog"
    });
  }

  getConentTypeIcon(content_type) {
    let icon = "insert_drive_file";
    switch (content_type) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        icon = "image";
        break;
      case "video/mp4":
      case "video/webm":
      case "video/ogg":
        icon = "video_library";
        break;
      case "audio/mp3":
      case "audio/wav":
        icon = "mic";
        break;
    }
    return icon;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
