
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

 <div id="mainLayout" class= "task-container">
    <div class="task-list-view" [class.task-detail]="isDetailView" [class.task-list]="tasks.length > 0 && !isDetailView"  [class.is-right-sidebar-expanded]="rightSidebarExpanded">
      <div *ngIf="isSearchMode">
        <div class="task-search-result-panel">
          <div class="search-result">
            <span>{{ searchStatistic.total_count }} {{ 'RESULT_FOUND_IN_TASK' | translate}}</span>
          </div>
          <div class="search-header-options">
            <div class="search-header-options-task">
              <img class="vnc-icon" src="assets/images/VNCtask.svg"><span>{{ 'TASKS' | translate}}</span>
            </div>
            <div *ngIf="isSaveSearchEnable" class="search-header-options-save" (click)="saveSearchQuery()">
              <mdl-icon>save</mdl-icon><span>{{ 'SAVE_SEARCH' | translate}}</span>
            </div>
            <button id="main-layout-search-clear" class="mdl-button mdl-js-button mdl-button--icon" (click)="onClearSearch()">
              <mdl-icon>clear</mdl-icon>
            </button>
          </div>
        </div>
      </div>
      <vp-vnctask-filter *ngIf="( screen === 'desktop' && !isSearchMode ) || !isSearchDialogActive" (onClick)="onClickReloadTasks()" (onCloseCheckClick)="onCheckShowOnlyCloseTasks($event)" (onOpenCheckClick)="onCheckShowOnlyOpenTasks($event)" (onShowMyTaskClick)="onCheckShowOnlyMyTasks($event)" (onSelect)="onFilterSelect($event)"></vp-vnctask-filter>
      <vp-vnctask-header-menubar #filter [isChangeUserOptionVisible]="isChangeUserOptionVisible" [selectAll]= "selectAll" [isSearchMode]= "isSearchMode" [selectedTasksIds]="selectedTasksIds" [totalCount]="tasks.length" (menuItemClick)="clickEventManage($event)"></vp-vnctask-header-menubar>
      <div vpScrollList (onUserScroll)="loadMoreTasks($event)" id="taskListLayout" class="scrollable-content" [class.searchmode]="isSearchMode && tasks.length > 0" [class.search-dialog-active]="screen !== 'desktop' && isSearchDialogActive && tasks.length > 0 && !isSearchMode">
        <div>
          <vp-vnctask-list id="tasksList" (openAddTaskDialog)="openAddTaskDialog()" (onSelectTask)="onSelectTask($event)" [tasks]="tasks" (onTaskClick)="onTaskClick($event);"></vp-vnctask-list>
        </div>
      </div>
      <button id="newTaskButton" *ngIf="isMobileDevice && !isSearchMode" (click)="openAddTaskDialog()" class="task__add-button brand-button-color mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored" [class.detailview] = "isDetailView">
        <i class="material-icons">add</i>
      </button>
    </div>
    <div class="task-detail-view" [class.hide-detail-view]="(!isDetailView && isMobileDevice) || tasks.length === 0" [class.is-right-sidebar-expanded]="rightSidebarExpanded">
      <router-outlet></router-outlet>
    </div>
    <vp-right-sidebar *ngIf="rightSidebarExpanded"></vp-right-sidebar>
</div>
<div class="vnctask-loading" *ngIf="isLoading" [class.pulltorefresh]="isRefreshing" id="loadingSpinner">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
