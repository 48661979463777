
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskAttachmentDialog" class="task__dialog">
    <div class="vp-task-add-attachment-dialog task__dialog-header">
      <div class="mobile-back-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'ATTACHMENT' | translate}}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="openOptionDialog()">
          <mat-icon class="disable-select">add</mat-icon>
        </button>
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="mobile-back-button">
        <button mat-button (click)="openOptionDialog()">
          <mat-icon class="disable-select">add</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
      <div class="task-attachment-list">
        <div class="task-attachment-item" *ngFor="let item of uploads">
          <div class="thumbnail">
              <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(item.filename))">
          </div>
          <div class="filename">{{ item.filename }}</div>
          <div class="delete"><mdl-icon (click)="removeItem(item)">delete</mdl-icon></div>
        </div>
      </div>
    </div>
    <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="close()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button (click)="attachItems($event)">
              {{ 'SAVE' | translate }}
          </button>
      </div>
      <div class="mobile-footer">
          <div class="title">
              {{ 'ADD_ATTACHMENTS' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button (click)="attachItems($event)">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
