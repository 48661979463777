
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-task-notification"
    [@enterLeave]="item.state"
    (click)="onClick($event)"
    [class]="theClass"
    (mouseenter)="onEnter()"
    (mouseleave)="onLeave()">
    <div *ngIf="item.type !== 'html'" class="vp-task-notification-default vp-task-notification-content-wrapper {{ item.type }}">
      <div *ngIf="item.type === taskNotificationType.REMINDER" class="vp-task-notification-title"><mdl-icon>notifications_active</mdl-icon><span>{{ 'REMINDER_TASK_DUE_TODAY' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.ASSIGNMENT" class="vp-task-notification-title"><mdl-icon>assignment_turned_in</mdl-icon><span>{{ 'NEW_TASK_ASSIGNMENT_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.TASK_UPDATE" class="vp-task-notification-title"><mdl-icon>assignment_turned_in</mdl-icon><span>{{ 'TASK_UPDATE_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.MENTIONING" class="vp-task-notification-title"><mdl-icon>assignment_turned_in</mdl-icon><span>{{ 'TASK_MENTION_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.OVERDUE_TASKS" class="vp-task-notification-title"><mdl-icon>assignment_late</mdl-icon><span>{{ 'TASK_OVERDUE_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.TODAY_DUE_TASKS" class="vp-task-notification-title"><mdl-icon>access_time</mdl-icon><span>{{ 'TASK_DUE_TODAY_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.DELETION" class="vp-task-notification-title"><mdl-icon>event_busy</mdl-icon><span>{{ 'TASK_DELETE_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.ASSIGNMENT_REMOVED" class="vp-task-notification-title"><mdl-icon>event_busy</mdl-icon><span>{{ 'TASK_REMOVE_TITLE' | translate }}</span></div>
      <div *ngIf="item.type === taskNotificationType.TASK_WATCHER" class="vp-task-notification-title"><mdl-icon>visibility</mdl-icon><span>{{ 'TASK_WATCHER_TITLE' | translate }}</span></div>
      <div class="vp-task-notification-content" *ngIf="!item?.override?.isMultiple">
        <span *ngIf="item.type === taskNotificationType.REMINDER">{{ 'TASK_DUE_TODAY' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.ASSIGNMENT">{{ 'NEW_TASK_ASSIGNMENT_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.TASK_UPDATE">{{ 'TASK_UPDATE_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.MENTIONING">{{ 'TASK_MENTION_SUB_TITTLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.DELETION">{{ 'TASK_DELETE_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.ASSIGNMENT_REMOVED">{{ 'TASK_REMOVE_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.TASK_WATCHER">{{ 'TASK_WATCHER_SUB_TITLE' | translate }}</span>
        <br>
        <h6>{{ item.title }}</h6>
      </div>
      <div class="vp-task-notification-content" *ngIf="item?.override?.isMultiple">
        <span *ngIf="item.type === taskNotificationType.REMINDER">{{ 'TASK_DUE_TODAY' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.ASSIGNMENT">{{ 'NEW_TASK_ASSIGNMENT_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.TASK_UPDATE">{{ 'TASK_UPDATE_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.MENTIONING">{{ 'TASK_MENTION_SUB_TITTLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.DELETION">{{ 'TASK_DELETE_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.ASSIGNMENT_REMOVED">{{ 'TASK_REMOVE_SUB_TITLE' | translate }}</span>
        <span *ngIf="item.type === taskNotificationType.TASK_WATCHER">{{ 'TASK_WATCHER_SUB_TITLE' | translate }}</span>
        <br>
        <ul class="reminder-tasks-list-notification">
          <li (click)="$event.stopPropagation();$event.preventDefault();openTask(task)" *ngFor="let task of item.override.tasks">{{ task.subject }}</li>
        </ul>
      </div>
      <div class="vp-task-notification-actions">
        <button (click)="cancel()" class="notification-button mdl-button">
            {{ 'CANCEL' | translate }}
        </button>
        <button (click)="openTask()" class="notification-button mdl-button" *ngIf="!item?.override?.isMultiple && ( item.type !== taskNotificationType.OVERDUE_TASKS && item.type !== taskNotificationType.TODAY_DUE_TASKS && item.type !== taskNotificationType.DELETION && item.type !== taskNotificationType.ASSIGNMENT_REMOVED )">
            {{ 'OPEN_TASK' | translate }}
        </button>
        <button (click)="showOverdueTasks()" class="notification-button mdl-button" *ngIf="item.type === taskNotificationType.OVERDUE_TASKS">
          {{ 'SHOW_OVERDUE_TASKS' | translate }}
        </button>
        <button (click)="showDueTodayTasks()" class="notification-button mdl-button" *ngIf="item.type === taskNotificationType.TODAY_DUE_TASKS">
          {{ 'SHOW_DUE_TODAY_TASKS' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="item.type === 'html'" class="vp-task-notification-html vp-task-notification-content-wrapper {{ item.type }}">
      <div class="vp-task-notification-content" [innerHTML]="item.html"></div>
    </div>
</div>
