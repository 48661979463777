/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges, OnInit } from "@angular/core";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Component({
  selector: "vp-avatar",
  templateUrl: "./user-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges, OnDestroy, OnInit {
  @Input() type;
  @Input() user;
  avatarURL: string;
  firstLastCharacters: string;
  photoLastUpdate: any;
  photoLastUpdateSubscription$: any;
  email: string;
  constructor(
    private changeDetectionRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (this.user.avatarURL && this.user.avatarURL !== "") {
      this.avatarURL = this.user.avatarURL;
      this.changeDetectionRef.markForCheck();
    }
    if (this.user.name && this.user.name !== "") {
      this.firstLastCharacters = CommonUtil.getAvatarText(this.user.name);
      this.changeDetectionRef.markForCheck();
    }
  }

  getBackgroundColor() {
    let color = "#888888";
    if (!this.avatarURL && this.user && this.user.bgcolor) {
      color = this.user.bgcolor;
    } else if (this.avatarURL) {
      color = "transparent";
    } else if (!this.avatarURL && this.firstLastCharacters) {
      color = CommonUtil.getBgColor(this.firstLastCharacters.substr(0, 1));
    }
    return color;
  }

  ngOnChanges(data) {
   if (data.user.currentValue.avatarURL && data.user.currentValue.avatarURL !== "") {
    this.avatarURL = data.user.currentValue.avatarURL;
    this.changeDetectionRef.markForCheck();
   } else {
    this.imgLoadOnError("");
   }
   if (data.user.currentValue.name && data.user.currentValue.name !== "") {
    this.firstLastCharacters = CommonUtil.getAvatarText(data.user.currentValue.name);
    this.changeDetectionRef.markForCheck();
   } else {
    this.firstLastCharacters = null;
    this.changeDetectionRef.markForCheck();
   }
  }

  ngOnDestroy() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
  }

  imgLoadOnError(event) {
    this.avatarURL = null;
    this.changeDetectionRef.markForCheck();
  }

}
