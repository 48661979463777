
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="task-filter">
  <div *ngIf="screen !== 'desktop' && selectedFilterType" id="task-filter-option-label-mobile" [matMenuTriggerFor]="getMenuTrigger()" class="filter-option-label disable-select">
    <span class="text-label">{{selectedFilterKey | translate}}</span>
    <mdl-icon>expand_more</mdl-icon>
  </div>
  <div *ngIf="screen === 'desktop'" id="task-filter-option-label" class="filter-option-label disable-select">
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_STATUS">{{ 'TASKS' | translate }}: </span>
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_TAG">{{ 'TAGS' | translate }}: </span>
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_LIST">{{ 'LISTS' | translate }}: </span>
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION">{{ 'LOCATIONS' | translate }}: </span>
    <span class="text-label">{{selectedFilterKey | translate}}</span>
    <span>{{ "(" + total_count + ")" }}</span>
  </div>
  <!-- <div *ngIf="screen === 'desktop'" class="filter-task-div">
    <label *ngIf="selectedFilterOption == taskConstants.ROUTE_ALL_TASK ||
    selectedFilterOption == taskConstants.ROUTE_TODAY_DUE ||
    selectedFilterOption == taskConstants.ROUTE_ASSIGNEDTOME ||
    selectedFilterOption == taskConstants.ROUTE_CREATEDBYME ||
    selectedFilterOption == taskConstants.ROUTE_TOMORROW_DUE ||
    selectedFilterOption == taskConstants.ROUTE_TASKS_I_WATCH ||
    selectedFilterOption == taskConstants.ROUTE_THIS_WEEK_DUE ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LIST ||
    selectedFilterType === taskConstants.ROUTE_TYPE_TAG ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION" class="mdl-checkbox open-tasks-checkbox" for="checkbox-2">
      <mdl-checkbox id="checkbox-2" [(ngModel)]="openTask" (click)="onOpenTaskCheck(openTask)" [disabled]="total_count === 0 || closeTask">{{ 'OPEN_TASKS' | translate }}</mdl-checkbox>
    </label>
    <label *ngIf="selectedFilterOption == taskConstants.ROUTE_ALL_TASK ||
    selectedFilterOption == taskConstants.ROUTE_TODAY_DUE ||
    selectedFilterOption == taskConstants.ROUTE_ASSIGNEDTOME ||
    selectedFilterOption == taskConstants.ROUTE_CREATEDBYME ||
    selectedFilterOption == taskConstants.ROUTE_TOMORROW_DUE ||
    selectedFilterOption == taskConstants.ROUTE_TASKS_I_WATCH ||
    selectedFilterOption == taskConstants.ROUTE_THIS_WEEK_DUE ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LIST ||
    selectedFilterType === taskConstants.ROUTE_TYPE_TAG ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION" class="mdl-checkbox open-tasks-checkbox my-tasks-checkbox" for="checkbox-2">
      <mdl-checkbox id="checkbox-2" [(ngModel)]="closeTask" (click)="onCloseTaskCheck(closeTask)" [disabled]="total_count === 0 || openTask">{{ 'CLOSE_TASKS' | translate }}</mdl-checkbox>
    </label>
    <label *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_TAG || selectedFilterOption == taskConstants.ROUTE_TASKS_I_WATCH" class="mdl-checkbox open-tasks-checkbox my-tasks-checkbox" for="checkbox-2">
      <mdl-checkbox id="checkbox-2" [(ngModel)]="showMyTask" (click)="onShowMyTaskCheck(showMyTask)" [disabled]="total_count===0">{{ 'MY_TASKS' | translate }}</mdl-checkbox>
    </label>
  </div>
  <div *ngIf="screen === 'desktop'" class="filter-sort-div" [class.align-popover]="!isLangEnglish">
    <span class="sort-by">
      {{ 'SORT_BY' | translate }}
    </span>
    <mdl-select id="task-filter-option-select" class="disable-select mdl-selection-option" [(ngModel)]="selectedSortValue" (change)="onSelectSortItem()" [disabled]="total_count===0 ">
        <mdl-option *ngFor="let sortOption of sortingOption" [value]="sortOption.value">{{sortOption.viewValue}}</mdl-option>
    </mdl-select>
  </div> -->
  <div class="filter-sort-div-mobile">
    <span [matMenuTriggerFor]="viewMoreOptions" #menuTrigger="matMenuTrigger">{{ 'ORDER' | translate }}/{{ 'FILTER' | translate }}</span>
    <mat-icon [matMenuTriggerFor]="viewMoreOptions" #menuTrigger="matMenuTrigger">arrow_drop_down</mat-icon>
  </div>
</div>
<mat-menu #statusMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_ALL_TASK)">
    <mdl-icon>event_available</mdl-icon>
    <span>{{ taskConstants.ALL_TASK | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_ALL_TASK) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_NEW)">
    <mdl-icon>panorama_fish_eye</mdl-icon>
    <span>{{ taskConstants.NEW_TASK | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_NEW) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_OPEN)">
    <mdl-icon>timelapse</mdl-icon>
    <span>{{ taskConstants.OPEN | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_OPEN) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_COMPLETED)">
    <mdl-icon>check_circle</mdl-icon>
    <span>{{ taskConstants.COMPLETED | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_COMPLETED) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_ASSIGNEDTOME)">
    <mdl-icon>assignment_ind</mdl-icon>
    <span>{{ taskConstants.ASSIGNEDTOME | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_ASSIGNEDTOME) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_CREATEDBYME)">
    <mdl-icon>assignment</mdl-icon>
    <span>{{ taskConstants.CREATEDBYME | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_CREATEDBYME) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_TODAY_DUE)">
    <mdl-icon>access_time</mdl-icon>
    <span>{{ taskConstants.TODAY_DUE | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_TODAY_DUE) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_TOMORROW_DUE)">
    <mdl-icon>watch_later</mdl-icon>
    <span>{{ taskConstants.TOMORROW_DUE | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_TOMORROW_DUE) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_THIS_WEEK_DUE)">
    <mdl-icon>date_range</mdl-icon>
    <span>{{ taskConstants.THIS_WEEK_DUE | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_THIS_WEEK_DUE) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_TASKS_I_WATCH)">
    <mdl-icon>date_range</mdl-icon>
    <span>{{ taskConstants.TASKS_I_WATCH | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_TASKS_I_WATCH) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_MY_OVERDUE_TASKS)">
    <mdl-icon>assignment_late</mdl-icon>
    <span>{{ taskConstants.MY_OVERDUE_TASKS | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_MY_OVERDUE_TASKS) }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #tagMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item *ngFor="let tag of tagList" (click)="navigateRoute(tag.name)">
    <mdl-icon>local_offer</mdl-icon>
    <span>{{ tag.name }}</span>
    <div class="rounded-rectangle">
      <span>{{ tag.count }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #listMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item *ngFor="let list of folderList" (click)="navigateRoute(list.name)">
    <mdl-icon>assignment</mdl-icon>
    <span>{{ list.name }}</span>
    <div class="rounded-rectangle">
      <span>{{ list.tasks_count }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #locationMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item *ngFor="let location of locationList" (click)="navigateRoute(location.name)">
    <mdl-icon>assignment</mdl-icon>
    <span>{{ location.name }}</span>
    <div class="rounded-rectangle">
      <span>{{ location.tasks_count }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #viewMoreOptions="matMenu" class="viewMoreOptions" xPosition="before">
  <div class="title">{{ 'ORDER_BY' | translate }}</div>
  <button mat-menu-item (click)="setSortBy(taskConstants.DUE_DATE_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.DUE_DATE_SORTING_KEY }" >
    <span>{{ 'DUE_DATE' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.DUE_DATE_SORTING_KEY" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>    
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.PRIORITY_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.PRIORITY_SORTING_KEY }">
    <span>{{ 'PRIORITY' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.PRIORITY_SORTING_KEY" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>    
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.TASK_NAME_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.TASK_NAME_SORTING_KEY }">
    <span>{{ 'TASK_NAME' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.TASK_NAME_SORTING_KEY" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>    
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.START_DATE_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.START_DATE_SORTING_KEY }">
    <span>{{ 'START_DATE' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.START_DATE_SORTING_KEY" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>    
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.STATUS_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.STATUS_SORTING_KEY }">
    <span>{{ 'STATUS' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.STATUS_SORTING_KEY" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>    
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.CREATED_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.CREATED_SORTING_KEY }">
    <span>{{ 'CREATED' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.CREATED_SORTING_KEY" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>    
  </button>
  <mat-divider></mat-divider>
  <div class="title">{{ 'FILTER' | translate }}</div>
  <button mat-menu-item (click)="setOpenTaskBy(openTask)" [ngClass]="{'selected': openTask === true }" [disabled]="(total_count === 0) || closeTask === true || (selectedFilterOption === taskConstants.ROUTE_NEW || selectedFilterOption === taskConstants.ROUTE_OPEN || selectedFilterOption === taskConstants.ROUTE_COMPLETED || selectedFilterOption === taskConstants.ROUTE_MY_OVERDUE_TASKS )">
      <span>{{ 'OPEN_TASKS' | translate }}</span>
      <mat-icon *ngIf="( openTask === true && ((total_count !== 0) && (selectedFilterOption !== taskConstants.ROUTE_NEW && selectedFilterOption !== taskConstants.ROUTE_OPEN && selectedFilterOption !== taskConstants.ROUTE_COMPLETED && selectedFilterOption !== taskConstants.ROUTE_MY_OVERDUE_TASKS)))" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>
  </button>
  <button mat-menu-item (click)="setCloseTaskBy(closeTask)" [ngClass]="{'selected': closeTask === true }" [disabled]="(total_count === 0) || openTask === true || (selectedFilterOption === taskConstants.ROUTE_NEW || selectedFilterOption === taskConstants.ROUTE_OPEN || selectedFilterOption === taskConstants.ROUTE_COMPLETED || selectedFilterOption === taskConstants.ROUTE_MY_OVERDUE_TASKS )">
    <span>{{ 'CLOSE_TASKS' | translate }}</span>
    <mat-icon *ngIf="( closeTask === true && ((total_count !== 0) && (selectedFilterOption !== taskConstants.ROUTE_NEW && selectedFilterOption !== taskConstants.ROUTE_OPEN && selectedFilterOption !== taskConstants.ROUTE_COMPLETED && selectedFilterOption !== taskConstants.ROUTE_MY_OVERDUE_TASKS)))" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>
  </button>
  <button mat-menu-item (click)="setMyTaskBy(showMyTask)" [ngClass]="{'selected': showMyTask === true }" [disabled]="(total_count === 0) || ( selectedFilterType !== taskConstants.ROUTE_TYPE_TAG && selectedFilterOption !== taskConstants.ROUTE_TASKS_I_WATCH)">
    <span>{{ 'MY_TASKS' | translate }}</span>
    <mat-icon *ngIf="( showMyTask === true && ((total_count !== 0) && ( selectedFilterType === taskConstants.ROUTE_TYPE_TAG || selectedFilterOption === taskConstants.ROUTE_TASKS_I_WATCH)))" class="mdi-20px active-view" fontSet="mdi" fontIcon="mdi-check"></mat-icon>
  </button>
</mat-menu>