
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { TaskState } from "../store/reducers/tasks.reducers";
import { Store } from "@ngrx/store";
import { getAuthUser } from "../store/selectors";
import { AuthService } from "../../common/providers/auth.service";
import { Observable, Subject, combineLatest, forkJoin } from "rxjs";
import { map, take } from "rxjs/operators";
import { CommonUtil, mobileonly } from "../../common/utils/common.utils";
import { Task, TaskComment, ICompact, Project, User, List, PendingOperation, Tag, SearchQuery, Location } from "../models";
import { AppConstants } from "../../common/utils/app-constants";
import { DatabaseReady } from "../../actions/app";
import { TaskUtils } from "../shared/task-utils";
import { LocalStorageService } from "./local-storage.service";
import { Broadcaster } from "../../common/providers/broadcaster.service";
import { FilesStorageService } from "./files-storage.service";
import { environment } from "../../../environments/environment";


@Injectable()
export class DatabaseService {

  private KEY_SQLITE_DB = "vnc_task.db";


  // Tasks SQL
  //
  private CREATE_TASK_TABLE_SQL = "CREATE TABLE IF NOT EXISTS TaskTable (id INTEGER not null, status_id integer not null, status_name text, author integer not null, assigned_to integer, start_date text, due_date text, subject text, priority integer, description text, project_id integer, comments text, list_id integer, location_id integer, tags_ids text, watcher_ids text, repeat text, external_url text, task text not null, created_on text)";
  private CREATE_TASK_SQL = "INSERT INTO TaskTable(id, status_id, status_name, author, assigned_to, start_date, due_date, subject, priority, description, project_id, comments, list_id, location_id, tags_ids, watcher_ids, repeat, external_url, task, created_on) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)";
  private UPDATE_TASK_SQL = "UPDATE TaskTable SET status_id = ?, status_name = ? , author = ?, assigned_to = ?, start_date = ?, due_date = ?, subject = ?, priority = ?, description = ?, project_id = ?, comments = ?, list_id = ?, location_id = ?, tags_ids = ?, watcher_ids = ?, repeat = ?, external_url = ?, task = ?, created_on = ? WHERE id = ?";
  private UPDATE_TASK_TAGS_IDS_SQL = "UPDATE TaskTable SET tags_ids = ? WHERE id = ?";
  private REPLACE_TASK_SQL = "UPDATE TaskTable SET id = ?, status_id = ?, status_name = ? , author = ?, assigned_to = ?, start_date = ?, due_date = ?, subject = ?, priority = ?, description = ?, project_id = ?, comments = ?, list_id = ?, location_id = ?, tags_ids = ?, watcher_ids = ?, repeat = ?, external_url = ?, task = ?, created_on = ? WHERE id = ?";
  private DROP_TASK_TABLE_SQL = "DROP TABLE IF EXISTS TaskTable";
  private DELETE_TASKS = "DELETE FROM TaskTable";
  //
  //
  private FIND_TASK_BY_ID_SQL = "SELECT t.*, l.name AS list_name, o.name AS location_name from TaskTable t LEFT JOIN ListTable AS l ON t.list_id = l.id LEFT JOIN LocationTable as o ON t.location_id = o.id WHERE t.id = ?";
  private FIND_TASKS_BY_ID_SQL  = "SELECT id FROM TaskTable where id IN ";
  private FIND_TASKS_SQL = "SELECT due_date, task FROM TaskTable WHERE 1=1";
  //
  private COUNT_TOTAL_TASKS_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE 1=1";
  private COUNT_TOTAL_COMPLETED_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE 1=1 AND trim(lower(status_name)) = 'completed'";
  private COUNT_TOTAL_OVERDUE_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE 1=1 AND date(due_date) BETWEEN date('1970-01-01') AND date('now','-1 day')";
  private COUNT_TOTAL_DUE_DATE_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE 1=1 AND date(due_date) = ?";
  private COUNT_ALL_TASKS_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?)";
  private COUNT_TASKS_BY_STATUS_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND trim(lower(status_name)) = ?";
  private COUNT_TASKS_OPEN_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND trim(lower(status_name)) IN ('in progress', 'new')";
  private COUNT_TASKS_NEW_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND trim(lower(status_name)) = 'new'";
  private COUNT_TASKS_COMPLETED_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND trim(lower(status_name)) = 'completed'";
  private COUNT_TASKS_BY_ASSIGNED_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE author != ? AND assigned_to = ?";
  private COUNT_TASKS_BY_AUTHOR_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE author = ?";
  private COUNT_TASKS_BY_DUE_DATE_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND date(due_date) = ?";
  private COUNT_TASKS_BY_DUE_DATE_RANGE_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND date(due_date) BETWEEN ? AND ?";
  private COUNT_TASKS_OVERDUE_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND date(due_date) BETWEEN date('1970-01-01') AND date('now','-1 day')";
  private COUNT_TASKS_WATCH_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE watcher_ids LIKE";
  private COUNT_TASKS_MY_OVERDUE_SQL = "SELECT count(id) AS cnt FROM TaskTable WHERE (author = ? OR assigned_to = ?) AND trim(lower(status_name)) IN ('in progress', 'new') AND date(due_date) <= ?";
  //
  private UPDATE_TASK_STATUS_SQL = "UPDATE TaskTable SET status_id = ?, status_name = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_COMMENTS_SQL = "UPDATE TaskTable SET comments = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_PRIORITY_SQL = "UPDATE TaskTable SET priority = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_REPEAT_SQL = "UPDATE TaskTable SET repeat = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_STARTDATE_SQL = "UPDATE TaskTable SET start_date = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_DUEDATE_SQL = "UPDATE TaskTable SET due_date = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_PROJECTID_SQL = "UPDATE TaskTable SET project_id = ?, task = ? WHERE id = ?";
  private UPDATE_TASK_REMOVE_LIST_SQL = "UPDATE TaskTable SET list_id = null WHERE list_id = ?";
  private UPDATE_TASKS_LIST_SQL = "UPDATE TaskTable SET list_id = ? WHERE id IN ";
  private UPDATE_TASKS_LOCATION_SQL = "UPDATE TaskTable SET location_id = ? WHERE id IN ";
  private UPDATE_TASK_REMOVE_LOCATION_SQL = "UPDATE TaskTable SET location_id = null WHERE location_id = ?";
  private UPDATE_TASK_WATCHER_SQL = "UPDATE TaskTable SET watcher_ids = ?, task = ? WHERE id = ?";
  // private UPDATE_TASKS_ASSIGNEDTO_SQL = "UPDATE TaskTable SET assigned_to = ? WHERE id IN ";
  private UPDATE_TASK_ASSIGNEDTO_SQL = "UPDATE TaskTable SET assigned_to = ?, task = ? WHERE id = ?";
  //
  private DELETE_TASKS_SQL = "DELETE from TaskTable where id IN ";

  // Avatars SQL
  //
  private CREATE_AVATAR_TABLE_SQL = "CREATE TABLE IF NOT EXISTS AvatarTable (user_id INTEGER not null, local_file_name text not null, last_update_ts INTEGER not null)";
  private FIND_AVATAR_SQL = "SELECT local_file_name FROM AvatarTable where user_id = ?";
  private FIND_AVATARS_BY_USER_ID_SQL = "SELECT user_id, local_file_name FROM AvatarTable where user_id IN ";
  private CREATE_AVATAR_IF_NOT_EXIST_SQL = "INSERT INTO AvatarTable(user_id, local_file_name, last_update_ts) SELECT ?, ?, ?  WHERE NOT EXISTS(SELECT 1 FROM AvatarTable WHERE user_id = ?)";
  private UPDATE_AVATAR_SQL = "UPDATE AvatarTable SET local_file_name = ?, last_update_ts = ? WHERE user_id = ?";
  private DROP_AVATAR_TABLE_SQL = "DROP TABLE IF EXISTS AvatarTable";
  private DELETE_AVATARS = "DELETE FROM AvatarTable";
  private DELETE_AVATAR = "DELETE FROM AvatarTable where user_id = ?";

  // Projects SQL
  //
  private CREATE_PROJECT_TABLE_SQL = "CREATE TABLE IF NOT EXISTS ProjectTable (id INTEGER not null, project text not null, members text)";
  private FIND_PROJECT_SQL = "SELECT project FROM ProjectTable where id = ?";
  private FIND_PROJECT_MEMBERS_SQL = "SELECT members FROM ProjectTable where id = ?";
  private SELECT_ALL_PROJECT_SQL = "SELECT id, project FROM ProjectTable";
  private FIND_PROJECTS_IDS_BY_IDS_SQL = "SELECT id from ProjectTable WHERE id IN ";
  private CREATE_PROJECT_SQL = "INSERT INTO ProjectTable(id, project, members) VALUES (?, ?, ?)";
  private UPDATE_PROJECT_SQL = "UPDATE ProjectTable SET project = ?, members = ? WHERE id = ?";
  private DROP_PROJECT_TABLE_SQL = "DROP TABLE IF EXISTS ProjectTable";
  private DELETE_PROJECTS = "DELETE FROM ProjectTable";

  // Lists SQL
  //
  private CREATE_LISTS_TABLE_SQL = "CREATE TABLE IF NOT EXISTS ListTable (id INTEGER not null, name text not null)";
  private FIND_LISTS_IDS_BY_IDS_SQL = "SELECT id from ListTable WHERE id IN ";
  private CREATE_LIST_SQL = "INSERT INTO ListTable(id, name) VALUES (?, ?)";
  private UPDATE_LIST_SQL = "UPDATE ListTable SET name = ? WHERE id = ?";
  private DELETE_LIST_SQL = "DELETE from ListTable WHERE id = ?";
  private SELECT_ALL_LISTS_WITH_TASKS_COUNT_SQL = "SELECT l.name AS name, l.id AS id, COUNT(t.list_id) AS tasks_count FROM ListTable l LEFT JOIN TaskTable AS t on l.id = t.list_id GROUP BY l.name";
  private DROP_LISTS_TABLE_SQL = "DROP TABLE IF EXISTS ListTable";
  private DELETE_LISTS = "DELETE FROM ListTable";

   // Lists SQL
  //
  private CREATE_LOCATIONS_TABLE_SQL = "CREATE TABLE IF NOT EXISTS LocationTable (id INTEGER not null, name text not null)";
  private FIND_LOCATIONS_IDS_BY_IDS_SQL = "SELECT id from LocationTable WHERE id IN ";
  private CREATE_LOCATION_SQL = "INSERT INTO LocationTable(id, name) VALUES (?, ?)";
  private UPDATE_LOCATION_SQL = "UPDATE LocationTable SET name = ? WHERE id = ?";
  private DELETE_LOCATION_SQL = "DELETE from LocationTable WHERE id = ?";
  private SELECT_ALL_LOCATIONS_WITH_TASKS_COUNT_SQL = "SELECT l.name AS name, l.id AS id, COUNT(t.location_id) AS tasks_count FROM LocationTable l LEFT JOIN TaskTable AS t on l.id = t.location_id GROUP BY l.name";
  private DROP_LOCATIONS_TABLE_SQL = "DROP TABLE IF EXISTS LocationTable";
  private DELETE_LOCATIONS = "DELETE FROM LocationTable";

  // Tags SQL
  //
  private CREATE_TASK_TAG_TABLE_SQL = "CREATE TABLE IF NOT EXISTS TaskTagTable (task_id INTEGER, tag_id INTEGER)";
  private CREATE_TASK_TAG_IF_NOT_EXIST_SQL = "INSERT INTO TaskTagTable(task_id, tag_id) SELECT ?, ? WHERE NOT EXISTS(SELECT 1 FROM TaskTagTable WHERE task_id = ? AND tag_id = ?)";
  private DELETE_TASK_TAG_BY_TAG_SQL = "DELETE from TaskTagTable WHERE tag_id = ?";
  private DELETE_TASK_TAG_BY_TAKS_AND_TAG_SQL = "DELETE from TaskTagTable WHERE task_id = ? AND tag_id = ?";
  private DELETE_TASK_TAG_BY_TASK_SQL = "DELETE from TaskTagTable WHERE task_id IN ";
  private REPLACE_TASK_TAG_ID_SQL = "UPDATE TaskTagTable SET tag_id = ? WHERE tag_id = ?";
  private SELECT_ALL_TAGS_WITH_TASKS_COUNT_SQL = "SELECT t.id,t.name, COUNT(tt.task_id) AS tasks_count FROM TaskTagTable tt JOIN TagTable AS t ON tt.tag_id=t.id GROUP BY t.id";
  private DELETE_TAGS_TASKS = "DELETE FROM TaskTagTable";
  //
  private CREATE_TAGS_TABLE_SQL = "CREATE TABLE IF NOT EXISTS TagTable (id INTEGER PRIMARY KEY, name text not null)";
  private CREATE_TAG_IF_NOT_EXIST_SQL = "INSERT INTO TagTable(id, name) SELECT ?, ? WHERE NOT EXISTS(SELECT 1 FROM TagTable WHERE id = ?)";
  private DELETE_TAG_SQL = "DELETE from TagTable WHERE id = ?";
  private REPLACE_TAG_ID_SQL = "UPDATE TagTable SET id = ? WHERE id = ?";
  private SELECT_TAGS_FOR_TASK = "SELECT id,name FROM TaskTagTable JOIN TagTable ON TaskTagTable.tag_id=TagTable.id WHERE task_id = ?";
  private SELECT_TAGS_FOR_TASKS = "SELECT task_id,id,name FROM TaskTagTable JOIN TagTable ON TaskTagTable.tag_id=TagTable.id WHERE task_id IN ";
  private SEARCH_TAGS_FOR_TASKS = "SELECT * FROM TagTable WHERE name LIKE ";
  private SELECT_ALL_TAGS = "SELECT * FROM TagTable";
  private DELETE_TAGS = "DELETE FROM TagTable";

  // Pending operations SQL
  //
  private CREATE_PENDING_OPERATIONS_TABLE_SQL = "CREATE TABLE IF NOT EXISTS PendingOperationsTable (task_id INTEGER not null, type text not null, additional_data text)";
  private ADD_PENDING_OPERATION_SQL = "INSERT INTO PendingOperationsTable(task_id, type, additional_data) VALUES (?, ?, ?)";
  private UPDATE_PENDING_OPERATION_ADDITIONALDATA_SQL = "UPDATE PendingOperationsTable SET additional_data = ? WHERE task_id = ?";
  private FIND_PENDING_OPERATION_BY_ID_SQL = "SELECT task_id, type, additional_data FROM PendingOperationsTable where task_id = ?";
  private FIND_PENDING_OPERATION_ID_BY_ID_SQL = "SELECT task_id FROM PendingOperationsTable where task_id = ?";
  private SELECT_ALL_PENDING_OPERATIONS_SQL = "SELECT task_id, type, additional_data FROM PendingOperationsTable";
  private DELETE_PENDING_OPERATION_SQL = "DELETE from PendingOperationsTable where task_id = ?";
  private DELETE_PENDING_OPERATIONS_SQL = "DELETE from PendingOperationsTable where task_id IN (?)";
  private DROP_PENDING_OPERATIONS_TABLE_SQL = "DROP TABLE IF EXISTS PendingOperationsTable";
  private DELETE_PENDING_OPS = "DELETE FROM PendingOperationsTable";


  // Save Search SQL
  //
  private CREATE_SAVE_SEARCHES_TABLE_SQL = "CREATE TABLE IF NOT EXISTS SaveSearchTable (id INTEGER not null, name text not null, created_on text, issues_count integer, filters text)";
  private CREATE_SAVE_SEARCHES_SQL = "INSERT INTO SaveSearchTable(id, name, created_on, issues_count, filters) VALUES (?, ?, ?, ?, ?)";
  private FIND_SAVE_SEARCHES_IDS_BY_IDS_SQL = "SELECT id from SaveSearchTable WHERE id IN ";
  private UPDATE_SAVE_SEARCHES_SQL = "UPDATE SaveSearchTable SET name = ?, created_on = ?, issues_count = ?, filters = ? WHERE id = ?";
  private DROP_SAVE_SEARCHES_TABLE_SQL = "DROP TABLE IF EXISTS SaveSearchTable";
  private DELETE_SAVE_SEARCHES = "DELETE FROM SaveSearchTable";
  private SELECT_SAVE_SEARCHES = "SELECT * FROM SaveSearchTable";

  // Migration Sql
  private MIGRATION_TASK_TABLE_ADD_WATCHER_IDS_FIELD_SQL = "ALTER TABLE TaskTable ADD COLUMN watcher_ids TEXT default null";
  private MIGRATION_TASK_TABLE_ADD_LOCATION_ID_FIELD_SQL = "ALTER TABLE TaskTable ADD COLUMN location_id integer default null";
  private MIGRATION_TASK_TABLE_ADD_EXTERNAL_URL_FIELD_SQL = "ALTER TABLE TaskTable ADD COLUMN external_url TEXT default null";
  private MIGRATION_TASK_TABLE_ADD_CREATED_ON_FIELD_SQL = "ALTER TABLE TaskTable ADD COLUMN created_on TEXT default null";
  private CREATE_MIGRATION_TABLE_SQL = "CREATE TABLE IF NOT EXISTS MigrationTable ( migration_id text, sql text not null, table_name text, migration_date text)";
  private CREATE_MIGRATION_SQL = "INSERT INTO MigrationTable(migration_id, sql, table_name, migration_date) VALUES (?, ?, ?, ?)";
  private SELECT_MIGRATION_IDS_SQL = "SELECT migration_id FROM MigrationTable";
  private MIGRATION_IDS = [
    { id : "201904151350", sql: this.MIGRATION_TASK_TABLE_ADD_LOCATION_ID_FIELD_SQL, table: "TaskTable" },
    { id : "201904151351", sql: this.MIGRATION_TASK_TABLE_ADD_WATCHER_IDS_FIELD_SQL, table: "TaskTable" },
    { id : "201904151352", sql: this.MIGRATION_TASK_TABLE_ADD_CREATED_ON_FIELD_SQL, table: "TaskTable" }
  ];

  private sqliteDB;
  private isDbInitialized: boolean = false;
  private storageLocation;

  constructor(
    private store: Store<TaskState>,
    private localStorageService: LocalStorageService,
    private broadcaster: Broadcaster,
    private filesStorageService: FilesStorageService) {

    console.log("[SQLITE][DatabaseService] constructor, isElectron = " + environment.isElectron);


    if (environment.isElectron) {
      this.setup().subscribe(() => {
        console.log("[SQLITE][DBElectronWrapper] database ready");
        this.store.dispatch(new DatabaseReady());
      });
    } else {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }

    this.broadcaster.on<any>("appComponentOnDestroy").subscribe($event => {
      if (CommonUtil.isSQLSupported()) {
        this.close().subscribe(() => {
          console.log("[SQLITE] database closed");
        });
      }
    });
  }

  private deviceReady() {
    console.log("[SQLITE] deviceReady");

    // for iOS it's Library/NoCloud
    if (environment.isCordova) {
      this.storageLocation = cordova.file.dataDirectory;
    }

    this.setup().subscribe(() => {
      console.log("[SQLITE] database ready");
      this.store.dispatch(new DatabaseReady());
    });
  }

  
  private setup(): Observable<any> {
    console.log("[SQLITE] setup");

    if (!CommonUtil.isOnNativeMobileDevice() && !environment.isElectron) {
      console.error("[SQLITE] init DB for unsupported platform");
      return;
    }
    const response = new Subject<any>();
    if (CommonUtil.isOnNativeMobileDevice()){
      try {
        this.sqliteDB = window.sqlitePlugin.openDatabase({
          name: this.KEY_SQLITE_DB,
          location: "default",
          androidDatabaseProvider: "system"
        }, (db) => {
          this.createTableIfNotExist().subscribe(() => {
            response.next();
          });
        }, (error) => {
          console.log("[SQLITE] Open database ERROR: " + JSON.stringify(error));
          response.error(error);
        });
      } catch (e) {
        console.log(`[SQLITE] Error has occured - ${e}`);
        response.error(e);
      }
    } else if (environment.isElectron) {
      const DBElectronWrapper = window.DBElectronWrapper;
      console.log("[SQLITE] setup, DBElectronWrapper: ", DBElectronWrapper);

      if (DBElectronWrapper) {
        try {
          this.sqliteDB = new DBElectronWrapper(this.KEY_SQLITE_DB, (db) => {
            let token = localStorage.getItem("token");
            if (token) {
              this.isDbInitialized = true;
              response.next();
            } else {
              this.createTableIfNotExist().subscribe(() => {
                response.next();
              });
            }
          }, (error) => {
            console.log("[SQLITE] Open database ERROR: " + JSON.stringify(error));
            response.error(error);
          });
        } catch (e) {
          console.log(`[SQLITE] Error has occured - ${e}`);
          response.error(e);
        }
      } else {
        console.error(`[SQLITE] Error has occured, DBElectronWrapper is empty`);
      }
    }
    return response.asObservable().pipe(take(1));
  }

  public fireDatabaseReadyIfInitialized(){
    if (this.sqliteDB && this.isDbInitialized) {
      this.store.dispatch(new DatabaseReady());
    }
  }

  createTableIfNotExist(): Observable<any> {
    console.log("[SQLITE] createTableIfNotExist");

    const response = new Subject();

    this.sqliteDB.sqlBatch([
      // this.DROP_TASK_TABLE_SQL,
      // this.DROP_PROJECT_TABLE_SQL,
      // this.DROP_PENDING_OPERATIONS_TABLE_SQL,
      this.CREATE_AVATAR_TABLE_SQL,
      this.CREATE_TASK_TABLE_SQL,
      this.CREATE_PROJECT_TABLE_SQL,
      this.CREATE_LISTS_TABLE_SQL,
      this.CREATE_LOCATIONS_TABLE_SQL,
      this.CREATE_TASK_TAG_TABLE_SQL,
      this.CREATE_TAGS_TABLE_SQL,
      this.CREATE_PENDING_OPERATIONS_TABLE_SQL,
      this.CREATE_SAVE_SEARCHES_TABLE_SQL,
      this.CREATE_MIGRATION_TABLE_SQL
    ], () => {
      console.log(`[SQLITE] Tables created`);
      this.isDbInitialized = true;
      response.next();
    }, (error) => {
      console.log(`[SQLITE] Error has occured while creating tables`, error);
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }

  /*private applyMigrations(): Observable<any> {

    const response = new Subject();

    this.getMigrationIds().subscribe( migrationIds => {
      let count = 0;
      this.MIGRATION_IDS.filter ( item => {
        let id = migrationIds.find( id => id === item.id );
        if (!id) {
          this.alterTableDetails(item).subscribe( value => {
            if (value) {
              count++;
            }
          });
        }
      });
      if (count > 0) {
        this.cleanTasksData();
        response.next(true);
      } else {
        response.next(false);
      }
    });

    console.log("[SQLITE] applyMigrations");
    return response.asObservable().pipe(take(1));
  }*/

  public alterTableDetails(item: any ): Observable<any> {
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] alterTableDetails skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }
    const response = new Subject();
    this.sqliteDB.transaction((tx) => {
      // write to DB
      tx.executeSql(item.sql, [], (tx, result) => {
        tx.executeSql(this.CREATE_MIGRATION_SQL, [item.id, item.sql, item.table, new Date().toString()], (tx, result) => {
          console.log(`[SQLITE] Create Migration: success`);
        }, (tx, error) => {
          console.error(`[SQLITE] Create Migration: Error has occured while saving entry`, error);
          response.next(false);
        });
      }, (tx, error) => {
        console.error(`[SQLITE] alter table error: `, error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] alter table: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] alter table: Transaction success.`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  public getTaskStatistics(params: any): Observable<any> {
    console.log("[SQLITE] getTaskStatistics, params: ", params);

    const response = new Subject();

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getTaskStatistics skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }
    let currentUserId;
    if (this.localStorageService.getUser()) {
      currentUserId = this.localStorageService.getUser().id;
    }
    if (!currentUserId) {
      return;
    }
    let resp = {};

    let time1;
    let time2;
    let time3;

    time1 = performance.now();
    console.log("[SQLITE] getTaskStatistics: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] getTaskStatistics: Transaction occured");
      time2 = performance.now();

      tx.executeSql(this.buildQueryForCounters(this.COUNT_ALL_TASKS_SQL, params), [currentUserId, currentUserId], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["all_tasks"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_ALL_TASKS_SQL error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_OPEN_SQL, params), [currentUserId, currentUserId], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["open"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_OPEN_SQL error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_NEW_SQL, params), [currentUserId, currentUserId], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["new"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_NEW_SQL error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_COMPLETED_SQL, params), [currentUserId, currentUserId], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["completed"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_COMPLETED_SQL error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_BY_ASSIGNED_SQL, params), [currentUserId, currentUserId], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["assigned_to_me"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_BY_ASSIGNED_SQL error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_BY_DUE_DATE_SQL, params), [currentUserId, currentUserId, TaskUtils.dateToday()], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["due_today"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_BY_DUE_DATE_SQL today error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_BY_DUE_DATE_SQL, params), [currentUserId, currentUserId, TaskUtils.dateTomorrow()], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["due_tomorrow"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_BY_DUE_DATE_SQL tomorrow error", error);
      });

      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_BY_DUE_DATE_RANGE_SQL, params), [currentUserId, currentUserId, TaskUtils.dateThisWeekStarts(), TaskUtils.dateThisWeekEnds()], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["due_this_week_count"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_BY_DUE_DATE_RANGE_SQL week error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_BY_AUTHOR_SQL, params), [currentUserId], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["created_by_me"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_BY_AUTHOR_SQL tomorrow error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_WATCH_SQL, params) + " '%" + currentUserId + "%'", [], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["watched_by_me"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_WATCH_SQL watch error", error);
      });
      tx.executeSql(this.buildQueryForCounters(this.COUNT_TASKS_MY_OVERDUE_SQL, params), [currentUserId, currentUserId, TaskUtils.dateYesterday()], (tx, result) => {
        const total_count = result.rows.item(0).cnt;
        resp["overdue"] = { total_count: total_count, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0 };
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatistics COUNT_TASKS_MY_OVERDUE_SQL watch error", error);
      });
      if (environment.isElectron) {
        setTimeout(() => {
          console.log("[SQLITE] getTaskStatistics: Transaction success.", resp);
          response.next(resp);
        }, 1000);
      }
    }, (error) => {
      console.error("[SQLITE] getTaskStatistics: Transaction error", error);
      response.next(null);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] getTaskStatistics: Transaction success. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(resp);
    });

    return response.asObservable().pipe(take(1));
  }

  public getTaskStatisticsForTaskFilter(searchParams): Observable<any> {
    console.log("[SQLITE] getTaskStatisticsForTaskFilter, searchParams:", searchParams);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getTaskStatisticsForTaskFilter skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject();

    let resp = {};

    let time1;
    let time2;
    let time3;

    time1 = performance.now();
    console.log("[SQLITE] getTaskStatisticsForTaskFilter: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] getTaskStatisticsForTaskFilter: Transaction occured");
      time2 = performance.now();

      const query = this.buildQuery(this.COUNT_TOTAL_TASKS_SQL, 0, 0, searchParams);
      console.log("[SQLITE] getTaskStatisticsForTaskFilter, COUNT_TOTAL_TASKS_SQL query: ", query);
      tx.executeSql(query, [], (tx, result) => {
        resp["total_count"] = result.rows.item(0).cnt;
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatisticsForTaskFilter COUNT_TOTAL_TASKS_SQL error", error);
      });
      const completed_query = this.buildQuery(this.COUNT_TOTAL_COMPLETED_SQL, 0, 0, searchParams);
      console.log("[SQLITE] getTaskStatisticsForTaskFilter, COUNT_TOTAL_COMPLETED_SQL query: ", completed_query);
      tx.executeSql(completed_query, [], (tx, result) => {
        resp["completed_count"] = result.rows.item(0).cnt;
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatisticsForTaskFilter COUNT_TOTAL_COMPLETED_SQL error", error);
      });
      let overdueSearchParams = searchParams;
      if (overdueSearchParams["op[status_id]"] !== "c") {
        if (overdueSearchParams["v[status_id][]"] !== "1") {
          overdueSearchParams["op[status_id]"] = "o";
        }
        const overdue_query = this.buildQuery(this.COUNT_TOTAL_OVERDUE_SQL, 0, 0, overdueSearchParams);
        console.log("[SQLITE] getTaskStatisticsForTaskFilter, COUNT_TOTAL_OVERDUE_SQL query: ", overdue_query);
        tx.executeSql(overdue_query, [], (tx, result) => {
          resp["overdue_count"] = result.rows.item(0).cnt;
        }, (tx, error) => {
          console.error("[SQLITE] getTaskStatisticsForTaskFilter COUNT_TOTAL_OVERDUE_SQL error", error);
        });
      } else {
        resp["overdue_count"] = 0;
      }
      const due_tomorrow_query = this.buildQuery(this.COUNT_TOTAL_DUE_DATE_SQL, 0, 0, searchParams);
      console.log("[SQLITE] getTaskStatisticsForTaskFilter, COUNT_TOTAL_DUE_DATE_SQL query: ", due_tomorrow_query);
      tx.executeSql(due_tomorrow_query, [TaskUtils.dateTomorrow()], (tx, result) => {
        resp["due_tomorrow_count"] = result.rows.item(0).cnt;
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatisticsForTaskFilter COUNT_TOTAL_DUE_DATE_SQL error", error);
      });
      if (environment.isElectron) {
        setTimeout(() => {
          console.log("[SQLITE] getTaskStatisticsForTaskFilter: Transaction success.", resp);
          response.next(resp);
        }, 1000);
      }
    }, (error) => {
      console.error("[SQLITE] getTaskStatisticsForTaskFilter: Transaction error", error);
      response.next(null);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] getTaskStatisticsForTaskFilter: Transaction success. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`, resp);
      response.next(resp);
    });

    return response.asObservable().pipe(take(1));
  }

  public getTaskStatisticsForSearch(searchParams): Observable<any> {
    console.log("[SQLITE] getTaskStatisticsForSearch, searchParams:", searchParams);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getTaskStatisticsForSearch skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject();

    const currentUserId = this.localStorageService.getUser().id;

    let resp = {};

    let time1;
    let time2;
    let time3;

    time1 = performance.now();
    console.log("[SQLITE] getTaskStatisticsForSearch: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] getTaskStatisticsForSearch: Transaction occured");
      time2 = performance.now();

      const query = this.buildQueryForCountersInSearch(this.COUNT_ALL_TASKS_SQL, searchParams);
      console.log("[SQLITE] getTaskStatisticsForSearch, COUNT_ALL_TASKS_SQL query: ", query);
      tx.executeSql(query, [currentUserId, currentUserId], (tx, result) => {
        resp["total_count"] = result.rows.item(0).cnt;
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatisticsForSearch COUNT_ALL_TASKS_SQL error", error);
      });
      tx.executeSql(this.buildQueryForCountersInSearch(this.COUNT_TASKS_COMPLETED_SQL, searchParams), [currentUserId, currentUserId], (tx, result) => {
        resp["completed_count"] = result.rows.item(0).cnt;
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatisticsForSearch COUNT_TASKS_COMPLETED_SQL error", error);
      });
      let overdueSearchParams = searchParams;
      if (overdueSearchParams["op[status_id]"] !== "c") {
        overdueSearchParams["op[status_id]"] = "o";
        tx.executeSql(this.buildQueryForCountersInSearch(this.COUNT_TASKS_OVERDUE_SQL, overdueSearchParams), [currentUserId, currentUserId], (tx, result) => {
          resp["overdue_count"] = result.rows.item(0).cnt;
        }, (tx, error) => {
          console.error("[SQLITE] getTaskStatisticsForSearch COUNT_TASKS_OVERDUE_SQL error", error);
        });
      } else {
        resp["overdue_count"] = 0;
      }
      tx.executeSql(this.buildQueryForCountersInSearch(this.COUNT_TASKS_BY_DUE_DATE_SQL, searchParams), [currentUserId, currentUserId, TaskUtils.dateTomorrow()], (tx, result) => {
        resp["due_tomorrow_count"] = result.rows.item(0).cnt;
      }, (tx, error) => {
        console.error("[SQLITE] getTaskStatisticsForSearch COUNT_TASKS_BY_DUE_DATE_SQL error", error);
      });
      if (environment.isElectron) {
        setTimeout(() => {
          console.log("[SQLITE] getTaskStatisticsForSearch: Transaction success.", resp);
          response.next(resp);
        }, 1000);
      }
    }, (error) => {
      console.error("[SQLITE] getTaskStatisticsForSearch: Transaction error", error);
      response.next(null);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] getTaskStatisticsForSearch: Transaction success. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`, resp);
      response.next(resp);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public saveOrUpdateTasks(tasks: Task[]): Observable<boolean>  {
    console.log("[SQLITE] saveOrUpdateTasks, count: " + tasks.length);

    const response = new Subject<boolean>();

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] saveOrUpdateTasks skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    if (!tasks || tasks.length === 0){
      return;
    }

    const maybeExistentIds = tasks.map(task => task.id);

    let toCreateCounter = 0;
    let toUpdateCounter = 0;

    let time1;
    let time2;
    let time3;

    // Step 1:
    // find existing tasks
    time1 = performance.now();
    console.log("[SQLITE] saveOrUpdateTasks: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] saveOrUpdateTasks: Transaction occured");
      time2 = performance.now();
      const query = this.FIND_TASKS_BY_ID_SQL + "('" + maybeExistentIds.join("','") + "')";

      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] saveOrUpdateTasks found tasks in DB: ", result.rows.length);

          let existentIds = [];
          for (let i = 0; i < result.rows.length; i++) {
            let item = result.rows.item(i);
            existentIds.push(item.id);
          }

          // Step 2: insert/update tasks in DB
          //
          maybeExistentIds.forEach((id, index) => {
            let query;
            let params;

            const task = tasks[index];

            if (existentIds.includes(id)) {
              params = this.paramsForCreateOrUpdateTask(task, false);
              query = this.UPDATE_TASK_SQL;
              ++toUpdateCounter;
            } else {
              params = this.paramsForCreateOrUpdateTask(task, true);
              query = this.CREATE_TASK_SQL;
              ++toCreateCounter;
            }

            // write to DB
            tx.executeSql(query, params, (tx, result) => {
              // console.log(`[SQLITE] saveOrUpdateTasks: Add or Update task`, result);
            }, (tx, error) => {
              console.error(`[SQLITE] saveOrUpdateTasks: Error has occured while saving task`, error);
            });
            task.tags.forEach(tag => {
              tx.executeSql(this.CREATE_TAG_IF_NOT_EXIST_SQL, [tag.id, tag.name, tag.id], (tx, result) => {
                // console.log(`[SQLITE] saveOrUpdateTasks: Add or Update tag`, result);
              }, (tx, error) => {
                console.error(`[SQLITE] saveOrUpdateTasks: Error has occured while saving task`, error);
              });
              tx.executeSql(this.CREATE_TASK_TAG_IF_NOT_EXIST_SQL, [task.id, tag.id, task.id, tag.id], (tx, result) => {
                // console.log(`[SQLITE] saveOrUpdateTasks: Add or Update task`, result);
              }, (tx, error) => {
                console.error(`[SQLITE] saveOrUpdateTasks: Error has occured while saving task`, error);
              });
            });
            if (environment.isElectron) {
              response.next(true);
            }
          });
        }, (tx, error) => {
          console.error("[SQLITE] saveOrUpdateTasks: Error has occured while finding tasks", error);
        }
      );
    }, (error) => {
      console.error("[SQLITE] saveOrUpdateTasks: Transaction error", error);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] saveOrUpdateTasks: Transaction success. CreatedTasks: ${toCreateCounter}, UpdatedTasks: ${toUpdateCounter}. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public updateTask(task: Task, removedTagsIds: number[]): Observable<boolean>{
    console.log("[SQLITE] updateTask, task & removedTagsIds: ", task, removedTagsIds);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateTask skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    const params = this.paramsForCreateOrUpdateTask(task, false);
    const query = this.UPDATE_TASK_SQL;

    this.sqliteDB.transaction((tx) => {
      // write to DB
      tx.executeSql(query, params, (tx, result) => {
        console.log(`[SQLITE] updateTask: update task success`, result);
      }, (tx, error) => {
        console.error(`[SQLITE] updateTask: Error has occured while updating task`, error);
      });
      removedTagsIds.forEach(removedTagId => {
        tx.executeSql(this.DELETE_TASK_TAG_BY_TAKS_AND_TAG_SQL, [task.id, removedTagId], (tx, result) => {
          console.log(`[SQLITE] updateTask: Delete task`, result);
        }, (tx, error) => {
          console.error(`[SQLITE] updateTask: Error has occured while updating task`, error);
        });
      });
      task.tags.forEach(tag => {
        tx.executeSql(this.CREATE_TAG_IF_NOT_EXIST_SQL, [tag.id, tag.name, tag.id], (tx, result) => {
          console.log(`[SQLITE] updateTask: Add or Update tag`, result);
        }, (tx, error) => {
          console.error(`[SQLITE] updateTask: Error has occured while updating task`, error);
        });
        tx.executeSql(this.CREATE_TASK_TAG_IF_NOT_EXIST_SQL, [task.id, tag.id, task.id, tag.id], (tx, result) => {
          console.log(`[SQLITE] updateTask: Add or Update task`, result);
        }, (tx, error) => {
          console.error(`[SQLITE] updateTask: Error has occured while updating task`, error);
        });
      });
      if (environment.isElectron) {
        setTimeout(() => {
          response.next(true);
        }, 1000);
      }
    }, (error) => {
      console.error("[SQLITE] updateTask: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] updateTask: Transaction success.`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public addNewTask(task: Task): Observable<boolean>{
    console.log("[SQLITE] addNewTask, task: ", task);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addNewTask skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    let time1;
    let time2;
    let time3;

    const params = this.paramsForCreateOrUpdateTask(task, true);

    console.log("[SQLITE] addNewTask, params & tags: ", params, task.tags);

    time1 = performance.now();
    this.sqliteDB.transaction((tx) => {
      time2 = performance.now();

      tx.executeSql(this.CREATE_TASK_SQL, params, (tx, result) => {
        console.log(`[SQLITE] addTask: Add task success`, result);
      }, (tx, error) => {
        console.error(`[SQLITE] addTask: Error has occured while adding task`, error);
      });
      task.tags.forEach(tag => {
        tx.executeSql(this.CREATE_TAG_IF_NOT_EXIST_SQL, [tag.id, tag.name, tag.id], (tx, result) => {
          console.log(`[SQLITE] addTask: Add or Update tag`, result);
        }, (tx, error) => {
          console.error(`[SQLITE] addTask: Error has occured while adding task`, error);
        });
        tx.executeSql(this.CREATE_TASK_TAG_IF_NOT_EXIST_SQL, [task.id, tag.id, task.id, tag.id], (tx, result) => {
          console.log(`[SQLITE] addTask: Add or Update task`, result);
        }, (tx, error) => {
          console.error(`[SQLITE] addTask: Error has occured while adding task`, error);
        });
      });
      if (environment.isElectron) {
        response.next(true);
      }
    }, (error) => {
      console.error("[SQLITE] addNewTask: Transaction error", error);
      response.next(false);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] addNewTask: Transaction success. TTAT ${(time2 - time1).toFixed()}  TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public replaceTask(previousTaskId: number, task: Task): Observable<boolean>  {
    console.log("[SQLITE] replaceTask, previousTaskId & task: ", previousTaskId, task);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] replaceTask skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    const params = this.paramsForCreateOrUpdateTask(task, true);
    params.push(previousTaskId);
    console.log("[SQLITE] replaceTask, params: ", params);

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.REPLACE_TASK_SQL, params, (tx, result) => {
        console.log(`[SQLITE] replaceTask: replace task success`, result);
        response.next(true);
      }, (tx, error) => {
        console.error(`[SQLITE] replaceTask: Error has occured while replace task`, error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] replaceTask: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] replaceTask: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public getTask(id: number): Observable<Task>{
    console.log("[SQLITE] getTask, id: " + id);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getTask skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<Task>();

    let time1;
    let time2;
    let time3;

    let task;

    time1 = performance.now();
    this.sqliteDB.transaction((tx) => {
      time2 = performance.now();

      tx.executeSql(this.FIND_TASK_BY_ID_SQL, [id],
        (tx, result) => {
          if (result.rows.length > 0) {
            let item = result.rows.item(0);
            task = this.buildTaskModelFromDBRecord(item);
            console.log("[SQLITE] getTask, task found: ", task);

            tx.executeSql(this.SELECT_TAGS_FOR_TASK, [id],
              (tx, result) => {
                if (result.rows.length > 0) {
                  for (let i = 0; i < result.rows.length; i++) {
                    let item = result.rows.item(i);
                    task.tags.push(item);
                  }
                  console.log("[SQLITE] getTask, tags found: ", task.tags);
                }
                if (environment.isElectron) {
                  setTimeout(() => {
                    console.log("[SQLITE] getTask, found: ", task);
                    response.next(task);
                  }, 1000);
                }
              }, (tx, error) => {
                task = null;
                console.error("[SQLITE] getTask Error has occured while finding task's tags", error);
              }
            );
          } else {
            console.log("[SQLITE] getTask, nothing found");
            response.next(undefined);
          }
        }, (error) => {
          console.error("[SQLITE] getTask Error has occured while finding task", error);
          response.next(undefined);
        });
    }, (error) => {
      console.error("[SQLITE] getTask: Transaction error", error);
      response.next(undefined);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] getTask: Transaction success. TTAT ${(time2 - time1).toFixed()}  TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(task);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public getTasks(offset: number, limit: number, params: any): Observable<Task[]>{
    console.log("[SQLITE] getTasks, offset: " + offset + ", limit: " + limit + ", params: ", params);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getTasks skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    if (!params) {
      console.log("[SQLITE] getTasks skip: params is null.");
      return;
    }

    const response = new Subject<Task[]>();

    let time1;
    let time2;
    let time3;

    let tasks: Task[] = [];
    let tasksHash = {};
    let errMessage;

    time1 = performance.now();
    this.sqliteDB.transaction((tx) => {
      time2 = performance.now();

      const query = this.buildQuery(this.FIND_TASKS_SQL, offset, limit, params);
      console.log("[SQLITE] getTasks, query: ", query);

      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] getTasks, results: ", result);
          if (result.rows) {
            console.log("[SQLITE] getTasks, results rows: ", result.rows);
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              let task = this.buildTaskModelFromDBRecord(item);
              tasks.push(task);
              tasksHash[task.id] = task;
            }
            console.log("[SQLITE] getTasks tasks array: ", tasks);
            const query = this.SELECT_TAGS_FOR_TASKS + "('" + Object.keys(tasksHash).join("','") + "')";
            console.log("[SQLITE] getTasks tags, query: ", query);
            tx.executeSql(query, [],
              (tx, result) => {
                console.log("[SQLITE] getTasks tags, results: ", result);
                if (result.rows.length > 0) {
                  console.log("[SQLITE] getTasks tags, results rows: ", result.rows);
                  for (let i = 0; i < result.rows.length; i++) {
                    const item = result.rows.item(i);
                    const task = tasksHash[item.task_id];
                    task.tags.push({id: item.id, name: item.name});
                    console.log("[SQLITE] getTasks tasks tags array: ", task.tags);
                  }
                  console.log("[SQLITE] getTasks tasks with tags array: ", tasks);
                }
              }, (tx, error) => {
                console.error("[SQLITE] getTasks Error has occured while finding tasks tags", error);
                response.error(error);
              }
            );
          }
          console.log("[SQLITE] getTasks fetched tasks: ", tasks.length);
          if (environment.isElectron) {
            response.next(tasks);
          }
        }, (tx, error) => {
          console.error("[SQLITE] getTasks Error has occured while searching tasks:", error);
          response.error(error);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getTasks: Transaction error", error);
      response.error(error);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] getTasks: Transaction success. TTAT ${(time2 - time1).toFixed()}  TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`, tasks);
      response.next(tasks);
    });

    return response.asObservable().pipe(take(1));
  }
 
  public getTagsWithTasksCount(): Observable<any>{
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getTagsWithTasksCount skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    console.log("[SQLITE] getTagsWithTasksCount");

    const response = new Subject<any>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_ALL_TAGS_WITH_TASKS_COUNT_SQL, [],
        (tx, result) => {
          let tagsStats: Tag[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              tagsStats.push(new Tag(item));
            }
          }
          console.log("[SQLITE] getTagsWithTasksCount tagsStats", tagsStats);
          response.next(tagsStats);
        }, (tx, error) => {
          console.error("[SQLITE] getTagsWithTasksCount Error has occured while groupping tags", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getTagsWithTasksCount: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getTagsWithTasksCount: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public getAllTags(): Observable<ICompact[]>{
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getAllTags skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    console.log("[SQLITE] getAllTags");

    const response = new Subject<ICompact[]>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_ALL_TAGS, [],
        (tx, result) => {
          let allTags: ICompact[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              allTags.push(item);
            }
          }
          console.log("[SQLITE] getAllTags allTags", allTags);
          response.next(allTags);
        }, (tx, error) => {
          console.error("[SQLITE] getAllTags Error has occured", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getAllTags: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getAllTags: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public getSearchTags(searchtext: String): Observable<Tag[]>{
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getSearchTags skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    console.log("[SQLITE] getSearchTags searchtext", searchtext);

    const response = new Subject<Tag[]>();

    this.sqliteDB.readTransaction((tx) => {
      const query = this.SEARCH_TAGS_FOR_TASKS + `'%${searchtext}%'`;
      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] getSearchTags found", result.rows.length);
          let tags: Tag[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              tags.push(new Tag(item));
            }
          }
          console.log("[SQLITE] getSearchTags tags found", tags);
          response.next(tags);
        }, (tx, error) => {
          console.error("[SQLITE] getSearchTags Error has occured", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getSearchTags: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getSearchTags: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }
  
  public replaceTagId(oldId: number, newId: number): Observable<boolean>{
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] replaceTagId skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    console.log("[SQLITE] replaceTagId oldId & newId", oldId, newId);

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.REPLACE_TAG_ID_SQL, [newId, oldId],
        (tx, result) => {
          console.log("[SQLITE] replaceTagId Tags done");
        }, (tx, error) => {
          console.error("[SQLITE] replaceTagId Tags Error has occured", error);
        }
      );
      tx.executeSql(this.REPLACE_TASK_TAG_ID_SQL, [newId, oldId],
        (tx, result) => {
          console.log("[SQLITE] replaceTagId TagsTask done");
        }, (tx, error) => {
          console.error("[SQLITE] replaceTagId TagsTask Error has occured", error);
        }
      );
    }, (error) => {
      console.error("[SQLITE] replaceTagId: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] replaceTagId: Transaction success.`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  private buildTaskModelFromDBRecord(item){
    let task = new Task(JSON.parse(item.task));

    // if (item.assigned_to) {
    //   task.assigned_to
    // }

    if (task.due_date && task.due_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime()) {
      task.due_date = null;
    }
    if (task.start_date && task.start_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime()) {
      task.start_date = null;
    }

    if (item.list_id) {
      task.list = {id: item.list_id, name: item.list_name};
    } else {
      task.list = null;
    }
    if (item.location_id) {
      task.location = {id: item.location_id, name: item.location_name};
    } else {
      task.location = null;
    }
    task.tags = []; // will be filled on next query

    return task;
  }

  private buildTaskJson(task: Task){
    return JSON.stringify(task, (replacerKey, replacerValue) => {
      if (replacerKey === "tags" || replacerKey === "list" || replacerKey === "location") {
        // 'tags' & 'list' & 'location' are stored in separate tables
        return undefined;
      }
      return replacerValue;
    });
  }

  private paramsForCreateOrUpdateTask(task: Task, isCreate: boolean){
    if (!task.due_date) {
      task.due_date = TaskUtils.dueDateOrStartDateNull();
    }
    if (!task.start_date) {
      task.start_date = TaskUtils.dueDateOrStartDateNull();
    }

    if (task.estimated_hours && !task.spent_hours) {
      task.spent_hours = "00:00";
    }

    const taskJson = this.buildTaskJson(task);
    const unifiedParams = [task.status.id,
                          task.status.name,
                          task.author.id,
                          task.assigned_to ? task.assigned_to.id : 0,
                          TaskUtils.dateToSearchableDBDate(task.start_date),
                          TaskUtils.dateToSearchableDBDate(task.due_date),
                          task.subject,
                          task.priority.id,
                          task.description,
                          task.project.id,
                          JSON.stringify(task.comments),
                          task.list ? task.list.id : null,
                          task.location ? task.location.id : null,
                          task.tags && task.tags.length > 0 ? JSON.stringify(task.tags.map(t  => t.id.toString())) : null, // tags_ids
                          task.watchers && task.watchers.length > 0 ? JSON.stringify(task.watchers.map(t  => t.id.toString())) : null,
                          task.repeat,
                          task.external_url ? task.external_url : null,
                          taskJson,
                          TaskUtils.dateToSearchableDBDateTime(task.created_on)];
    if (isCreate) {
      return [task.id, ...unifiedParams];
    }
    return [...unifiedParams, task.id];
  }

  // for get tasks
  private buildQuery(baseQuery: string, offset: number, limit: number, params: any){
    let query = baseQuery;

    if (params) {
      query = this.buildAssigneeAndAuthorQuery(query, params);

      // Search text
      if (params["op[fulltext][]"] && params["v[fulltext][]"]) {
        query = this.buildSearchQuery(query, params);
      }

      // Status
      const statusId = params["op[status_id]"];
      if (statusId) {
        if (statusId === "c") {
          query += ` AND trim(lower(status_name)) = 'completed'`;
        } else if (statusId === "o") {
          query += ` AND trim(lower(status_name)) IN ('in progress', 'new')`;
        } else if ( statusId === "=" && params["v[status_id][]"] === "1") {
          query += ` AND trim(lower(status_name)) = 'new'`;
        }
      }

      // Due date
      const opDueDate = params["op[due_date]"];
      if (opDueDate) {
        if (opDueDate === "w") { // this week
          query += ` AND date(due_date) BETWEEN '${TaskUtils.dateThisWeekStarts()}' AND '${TaskUtils.dateThisWeekEnds()}'`;
        } else if (opDueDate === "=") { // today or tomorrow
          const vDueDate = params["v[due_date][]"];
          query += ` AND date(due_date) = '${vDueDate}'`;
        } else if (opDueDate === "<=") {
          const vDueDate = params["v[due_date][]"];
          query += ` AND date(due_date) <= '${vDueDate}'`;
        }
      }

      // Tags
      const opTags = params["op[tags]"];
      if (opTags) {
        if (opTags === "=") {
          const vTags = params["v[tags][]"];  // tag id
          query += ` AND tags_ids LIKE '%"${vTags}"%'`;
        }
      }

      // Lists
      const opList = params["op[list]"];
      if (opList) {
        if (opList === "=") {
            const vList = params["v[list][]"];  // list id
            query += ` AND list_id = ${vList}`;
        }
      }

      // Lists
      const opLocation = params["op[location]"];
      if (opLocation) {
        if (opLocation === "=") {
            const vList = params["v[location][]"];  // list id
            query += ` AND location_id = ${vList}`;
        }
      }

      // Sort
      const sortBy = params["sort"]; // due_date:asc, priority:desc, subject:asc
      if (sortBy) {
        const sortByParts = sortBy.split(":");
        const sortByField = sortByParts[0];
        const sortByDirection = sortByParts[1].toUpperCase();
        if (sortByField === "due_date") {
          query += " ORDER BY date(due_date)";
        } else if (sortByField === "start_date") {
          query += " ORDER BY date(start_date)";
        } else if (sortByField === "created_on") {
          query += " ORDER BY date(created_on)";
        } else if (sortByField === "subject") {
          query += " ORDER BY subject COLLATE NOCASE";
        } else if (sortByField === "status") {
          query += " ORDER BY status_id";
        } else {
          query += ` ORDER BY ${sortByField}`;
        }
        query += ` ${sortByDirection}`;
      } else {
        // default sort
        query += " ORDER BY date(due_date) ASC";
      }
    }

    if (limit > 0) {
      query += ` LIMIT ${limit}`;
    }
    if (offset > 0) {
      query += ` OFFSET ${offset}`;
    }

    return query;
  }

  // for counts
  private buildQueryForCounters(baseQuery: string, params: any){
    let query = baseQuery;
    return query;
  }

  // for counts in search
  private buildQueryForCountersInSearch(baseQuery: string, params: any){
    let query = baseQuery;

    if (params) {
      // Search text
      if (params["op[fulltext][]"] && params["v[fulltext][]"]) {
        query = this.buildSearchQuery(query, params);
      }

      // Status
      const statusId = params["op[status_id]"];
      if (statusId) {
        if (statusId === "c") {
          query += ` AND trim(lower(status_name)) = 'completed'`;
        } else if (statusId === "o") {
          query += ` AND trim(lower(status_name)) IN ('in progress', 'new')`;
        } else if ( statusId === "=" && params["v[status_id][]"] === "1") {
          query += ` AND trim(lower(status_name)) = 'new'`;
        }
      }

       // Tags
       const opTags = params["op[tags]"];
       if (opTags) {
         if (opTags === "=") {
           const vTags = params["v[tags][]"];  // tag id
           query += ` AND tags_ids LIKE '%"${vTags}"%'`;
         }
       }

       // Lists
       const opList = params["op[list]"];
       if (opList) {
         if (opList === "=") {
             const vList = params["v[list][]"];  // list id
             query += ` AND list_id = ${vList}`;
         }
       }

       // Locations
       const opLocation = params["op[location]"];
       if (opLocation) {
         if (opLocation === "=") {
             const vList = params["v[location][]"];  // list id
             query += ` AND location_id = ${vList}`;
         }
       }
    }

    return query;
  }

  private buildAssigneeAndAuthorQuery(baseQuery: string, params: any){
    let query = baseQuery;

    if (params["v[assigned_to_id][]"]) {
      const assignedToId = params["v[assigned_to_id][]"];
      const assignedToID = assignedToId === "me" ? this.localStorageService.getUser().id : assignedToId;
      query += ` AND assigned_to = ${assignedToID}`;
    } else if (params["v[author_or_assigned][]"]) {
      const authorOrAssigned = params["v[author_or_assigned][]"];
      const authorIDOrAssignedToID = authorOrAssigned === "me" ? this.localStorageService.getUser().id : authorOrAssigned;
      query += ` AND (author = ${authorIDOrAssignedToID} OR assigned_to = ${authorIDOrAssignedToID})`;
    } else if (params["v[author_assigned_or_watcher][]"]) {
      const authorOrAssigned = params["v[author_assigned_or_watcher][]"];
      const authorIDOrAssignedToID = authorOrAssigned === "me" ? this.localStorageService.getUser().id : authorOrAssigned;
      query += ` AND (author = ${authorIDOrAssignedToID} OR assigned_to = ${authorIDOrAssignedToID} OR watcher_ids LIKE '%${authorIDOrAssignedToID}%')`;
    }

    if (params["v[author_id][]"]) {
      const author = params["v[author_id][]"];
      const authorID = author === "me" ? this.localStorageService.getUser().id : author;
      if (params["op[author_id]"] === "!") {
        query += ` AND author != ${authorID}`;
      } else {
        query += ` AND author = ${authorID}`;
      }
    }

    if (params["v[watcher_id][]"]) {
      const watcher = params["v[watcher_id][]"];
      const watcherID = watcher === "me" ? this.localStorageService.getUser().id : watcher;
      query += ` AND watcher_ids LIKE '%${watcherID}%'`;
    }
    return query;
  }

  private buildSearchQuery(baseQuery: string, params: any){
    let query = baseQuery;

    const paramsToSearchBy = params["op[fulltext][]"];
    query += " AND ";
    paramsToSearchBy.forEach((sOp, sOpIndex) => {
      let formalizedOp;
      if (sOp === "subj") {
        formalizedOp = "subject";
      } else if (sOp === "desc") {
        formalizedOp = "description";
      } else if (sOp === "comm") {
        formalizedOp = "comments";
      } else {
        formalizedOp = sOp;
      }

      // 1st element
      if (sOpIndex === 0 && paramsToSearchBy.length > 1) {
        query += "(";
      }
      //
      if (sOpIndex === 0) {
        query += `${formalizedOp} like '%${params["v[fulltext][]"][0]}%'`;
      } else {
        query += ` OR ${formalizedOp} like '%${params["v[fulltext][]"][0]}%'`;
      }
      // last elemement
      if (sOpIndex === paramsToSearchBy.length - 1 && paramsToSearchBy.length > 1) {
        query += ")";
      }
    });

    return query;
  }

  
  public removeTasks(tasksIds: number[]): Observable<any>{
    console.log("[SQLITE] removeTasks, tasksIds: ", tasksIds);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] removeTasks skip: sqliteDB or isDbInitialized is not initialized.");
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      let query = this.DELETE_TASKS_SQL + "('" + tasksIds.join("','") + "')";
      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] removeTasks success", result);
        }, (tx, error) => {
          console.error("[SQLITE] removeTasks Error has occured", error);
        }
      );
      query = this.DELETE_TASK_TAG_BY_TASK_SQL + "('" + tasksIds.join("','") + "')";
      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] removeTasks tags success", result);
        }, (tx, error) => {
          console.error("[SQLITE] removeTasks tags Error has occured", error);
        }
      );
      if (environment.isElectron) {
        response.next(true);
      }
    }, (error) => {
      console.error("[SQLITE] removeTasks: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] removeTasks: Transaction success`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public completeTask(task: Task): Observable<any>{
    console.log("[SQLITE] completeTask, task: ", task);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] completeTask skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_STATUS_SQL, [TaskUtils.statusCompletedId(), "completed", taskJson, task.id], (tx, result) => {
        console.log("[SQLITE] completeTask success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] completeTask Error has occured", error);
        response.next(false);
      }
    );
    }, (error) => {
      console.error("[SQLITE] completeTask: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] completeTask: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updatePriority(task: Task, priority: number): Observable<any>{
    console.log("[SQLITE] updatePriority, task & priority: ", task, priority);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updatePriority skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_PRIORITY_SQL, [priority, taskJson, task.id], (tx, result) => {
        console.log("[SQLITE] updatePriority: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updatePriority: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updatePriority: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updatePriority: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateRepeat(task: Task, repeat: string): Observable<any>{
    console.log("[SQLITE] updateRepeat, task & repeat: ", task, repeat);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateRepeat skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_REPEAT_SQL, [repeat, taskJson, task.id], (tx, result) => {
        console.log("[SQLITE] updateRepeat: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updateRepeat: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updateRepeat: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateRepeat: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateStartDate(task: Task, startDate: Date): Observable<any>{
    console.log("[SQLITE] updateStartDate, task & startDate: ", task, startDate);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateStartDate skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_STARTDATE_SQL, [startDate ? TaskUtils.dateToSearchableDBDate(startDate) : null, taskJson, task.id], (tx, result) => {
        console.log("[SQLITE] updateStartDate: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updateStartDate: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updateStartDate: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateStartDate: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateDueDate(task: Task, dueDate: Date): Observable<any>{
    console.log("[SQLITE] updateDueDate, task & dueDate: ", task, dueDate);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateDueDate skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_DUEDATE_SQL, [dueDate ? TaskUtils.dateToSearchableDBDate(dueDate) : null, taskJson, task.id], (tx, result) => {
        console.log("[SQLITE] updateDueDate: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updateDueDate: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updateDueDate: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateDueDate: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateProjectId(task: Task, projectId: number): Observable<any>{
    console.log("[SQLITE] updateProjectId, task & projectId: ", task, projectId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateProjectId skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_PROJECTID_SQL, [projectId, taskJson, task.id], (tx, result) => {
        console.log("[SQLITE] updateProjectId: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updateProjectId: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updateProjectId: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateProjectId: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateList(tasks: Task[], listId: number): Observable<any>{
    console.log("[SQLITE] updateList, task & listId: ", tasks, listId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateList skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    // override tasks tags completely
    this.sqliteDB.transaction((tx) => {
      const query = this.UPDATE_TASKS_LIST_SQL + "('" + tasks.map(t => t.id).join("','") + "')";
      tx.executeSql(query, [listId], (tx, result) => {
        console.log("[SQLITE] updateList: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updateList: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updateList: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateList: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateLocation(tasks: Task[], locationId: number): Observable<any>{
    console.log("[SQLITE] updateList, task & locationId: ", tasks, locationId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateList skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    // override tasks tags completely
    this.sqliteDB.transaction((tx) => {
      const query = this.UPDATE_TASKS_LOCATION_SQL + "('" + tasks.map(t => t.id).join("','") + "')";
      tx.executeSql(query, [locationId], (tx, result) => {
        console.log("[SQLITE] updateLocation: success", result);
        response.next(true);
      }, (tx, error) => {
        console.error("[SQLITE] updateLocation: Error has occured", error);
        response.next(false);
      });
    }, (error) => {
      console.error("[SQLITE] updateLocation: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateLocation: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateAssignedTo(task: Task, assignedToId: number): Observable<any>{
    console.log("[SQLITE] updateAssignedTo, task & assignedToId: ", task, assignedToId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateAssignedTo skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    // override tasks tags completely
    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_ASSIGNEDTO_SQL, [assignedToId, taskJson, task.id],
        (tx, result) => {
          console.log("[SQLITE] updateAssignedTo, result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] updateAssignedTo, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] updateAssignedTo: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateAssignedTo: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateWatchers(task: Task, watcher_ids: number[]): Observable<any>{
    console.log("[SQLITE] updateWatchers, task & watcher_ids: ", task, watcher_ids);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateWatchers skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    // override tasks tags completely
    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      tx.executeSql(this.UPDATE_TASK_WATCHER_SQL, [ JSON.stringify(watcher_ids), taskJson, task.id],
        (tx, result) => {
          console.log("[SQLITE] updateWatchers, result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] updateWatchers, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] updateWatchers: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateWatchers: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateTags(task: Task, tags: ICompact[]): Observable<any>{
    console.log("[SQLITE] updateTags, task & tags: ", task, tags);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateTags skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    // override tasks tags completely
    this.sqliteDB.transaction((tx) => {
      const query = this.DELETE_TASK_TAG_BY_TASK_SQL + "('" + [task.id].join("','") + "')";
      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] updateTags, delete task result: ", result);
        }, (tx, error) => {
          console.error("[SQLITE] updateTags, error: ", error);
        }
      );
      setTimeout( () => {
        task.tags.forEach(tag => {
          tx.executeSql(this.CREATE_TAG_IF_NOT_EXIST_SQL, [tag.id, tag.name, tag.id], (tx, result) => {
            console.log(`[SQLITE] updateTags: Add or Update tag`, result);
          }, (tx, error) => {
            console.error(`[SQLITE] updateTags: Error has occured while updating task`, error);
          });
          tx.executeSql(this.CREATE_TASK_TAG_IF_NOT_EXIST_SQL, [task.id, tag.id, task.id, tag.id], (tx, result) => {
            console.log(`[SQLITE] updateTags: Add or Update task`, result);
          }, (tx, error) => {
            console.error(`[SQLITE] updateTags: Error has occured while updating task`, error);
          });
        });
      }, 200),

      tx.executeSql(this.UPDATE_TASK_TAGS_IDS_SQL, [JSON.stringify(task.tags.map(t  => t.id.toString())), task.id],
        (tx, result) => {
          console.log("[SQLITE] updateTags, update task result: ", result);
        }, (tx, error) => {
          console.error("[SQLITE] updateTags, error: ", error);
        }
      );
      if (environment.isElectron) {
         setTimeout(() => {
          response.next(true);
         }, 1000);
      }
    }, (error) => {
      console.error("[SQLITE] updateTags: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] updateTags: Transaction success`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public updateComments(task: Task): Observable<boolean>{
    console.log("[SQLITE] updateComments, task: ", task);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] updateComments skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      const taskJson = this.buildTaskJson(task);
      const params = [JSON.stringify(task.comments), taskJson, task.id];
      tx.executeSql(this.UPDATE_TASK_COMMENTS_SQL, params,
        (tx, result) => {
          console.log("[SQLITE] updateComments, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] updateComments, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] updateComments: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] updateComments: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public removeListFromTasks(listId: number): Observable<boolean>{
    console.log("[SQLITE] removeListFromTasks, listId: ", listId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] removeListFromTasks skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.UPDATE_TASK_REMOVE_LIST_SQL, [listId],
        (tx, result) => {
          console.log("[SQLITE] removeListFromTasks, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] removeListFromTasks, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] removeListFromTasks: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] removeListFromTasks: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public removeLocationFromTasks(locationId: number): Observable<boolean>{
    console.log("[SQLITE] removeLocationFromTasks, locationId: ", locationId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] removeLocationFromTasks skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.UPDATE_TASK_REMOVE_LOCATION_SQL, [locationId],
        (tx, result) => {
          console.log("[SQLITE] removeLocationFromTasks, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] removeLocationFromTasks, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] removeLocationFromTasks: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] removeLocationFromTasks: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }
  /// Lists

  
  public createOrUpdateLists(lists: List[]): Observable<boolean>{
    console.log("[SQLITE] createOrUpdateLists, lists: ", lists);

    const response = new Subject<boolean>();

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] createOrUpdateLists skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const maybeExistentIds = lists.map(list => list.id);

    let toCreateCounter = 0;
    let toUpdateCounter = 0;

    let time1;
    let time2;
    let time3;


    // Step 1:
    // find existing lists
    time1 = performance.now();
    console.log("[SQLITE] createOrUpdateLists: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] createOrUpdateLists: Transaction occured");
      time2 = performance.now();
      const query = this.FIND_LISTS_IDS_BY_IDS_SQL + "('" + maybeExistentIds.join("','") + "')";

      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] createOrUpdateLists found lists in DB: ", result.rows.length);

          let existentIds = [];
          for (let i = 0; i < result.rows.length; i++) {
            let item = result.rows.item(i);
            existentIds.push(item.id);
          }

          // Step 2: insert/update tasks in DB
          //
          maybeExistentIds.forEach((id, index) => {
            let query;
            let params;

            const list = lists[index];

            if (existentIds.includes(id)) {
              params = [list.name, list.id];
              query = this.UPDATE_LIST_SQL;
              ++toUpdateCounter;
            } else {
              params = [list.id, list.name];
              query = this.CREATE_LIST_SQL;
              ++toCreateCounter;
            }

            // write to DB
            tx.executeSql(query, params, (tx, result) => {
              //
            }, (tx, error) => {
              console.error(`[SQLITE] createOrUpdateLists: Error has occured while saving task`, error);
            });
          });
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] createOrUpdateLists: Error has occured while finding tasks", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] createOrUpdateLists: Transaction error", error);
      response.next(false);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] createOrUpdateLists: Transaction success. Created: ${toCreateCounter}, Updated: ${toUpdateCounter}. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public getListsWithTasksCount(): Observable<any>{
    console.log("[SQLITE] getListsWithTasksCount");

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getListsWithTasksCount skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_ALL_LISTS_WITH_TASKS_COUNT_SQL, [],
        (tx, result) => {
          console.log("[SQLITE] getListsWithTasksCount result", result.rows);
          let lists: List[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              lists.push(new List(item));
            }
          }
          console.log("[SQLITE] getListsWithTasksCount: success", lists);
          response.next(lists);
        }, (tx, error) => {
          console.error("[SQLITE] getListsWithTasksCount: error has occured while groupping lists", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getListsWithTasksCount: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getListsWithTasksCount: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public deleteList(id: number): Observable<boolean>{
    console.log("[SQLITE] deleteList, id: " + id);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] deleteList skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.DELETE_LIST_SQL, [id],
        (tx, result) => {
          console.log("[SQLITE] deleteList, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] deleteList, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] deleteList: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] deleteList: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  /// Locations

  
  public createOrUpdateLocations(locations: Location[]): Observable<boolean>{
    console.log("[SQLITE] createOrUpdateLocations, lists: ", locations);

    const response = new Subject<boolean>();

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] createOrUpdateLocations skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const maybeExistentIds = locations.map(location => location.id);

    let toCreateCounter = 0;
    let toUpdateCounter = 0;

    let time1;
    let time2;
    let time3;


    // Step 1:
    // find existing locations
    time1 = performance.now();
    console.log("[SQLITE] createOrUpdateLocations: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] createOrUpdateLocations: Transaction occured");
      time2 = performance.now();
      const query = this.FIND_LOCATIONS_IDS_BY_IDS_SQL + "('" + maybeExistentIds.join("','") + "')";

      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] createOrUpdateLocations found lists in DB: ", result.rows.length);

          let existentIds = [];
          for (let i = 0; i < result.rows.length; i++) {
            let item = result.rows.item(i);
            existentIds.push(item.id);
          }

          // Step 2: insert/update tasks in DB
          //
          maybeExistentIds.forEach((id, index) => {
            let query;
            let params;

            const location = locations[index];

            if (existentIds.includes(id)) {
              params = [location.name, location.id];
              query = this.UPDATE_LOCATION_SQL;
              ++toUpdateCounter;
            } else {
              params = [location.id, location.name];
              query = this.CREATE_LOCATION_SQL;
              ++toCreateCounter;
            }

            // write to DB
            tx.executeSql(query, params, (tx, result) => {
              //
            }, (tx, error) => {
              console.error(`[SQLITE] createOrUpdateLocations: Error has occured while saving task`, error);
            });
          });
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] createOrUpdateLocations: Error has occured while finding tasks", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] createOrUpdateLocations: Transaction error", error);
      response.next(false);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] createOrUpdateLocations: Transaction success. Created: ${toCreateCounter}, Updated: ${toUpdateCounter}. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public getLocationsWithTasksCount(): Observable<any>{
    console.log("[SQLITE] getLocationsWithTasksCount");

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getLocationsWithTasksCount skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_ALL_LOCATIONS_WITH_TASKS_COUNT_SQL, [],
        (tx, result) => {
          console.log("[SQLITE] getLocationsWithTasksCount result", result.rows);
          let locations: Location[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              locations.push(new Location(item));
            }
          }
          console.log("[SQLITE] getLocationsWithTasksCount: success", locations);
          response.next(locations);
        }, (tx, error) => {
          console.error("[SQLITE] getLocationsWithTasksCount: error has occured while groupping lists", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getLocationsWithTasksCount: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getLocationsWithTasksCount: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public deleteLocation(id: number): Observable<boolean>{
    console.log("[SQLITE] deleteLocation, id: " + id);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] deleteLocation skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.DELETE_LOCATION_SQL, [id],
        (tx, result) => {
          console.log("[SQLITE] deleteLocation, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] deleteLocation, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] deleteLocation: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] deleteLocation: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  /// Projects & members

  
  public getAllProjects(): Observable<Project[]>{
    console.log("[SQLITE] getAllProjects");

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getAllProjects skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<Project[]>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_ALL_PROJECT_SQL, [],
        (tx, result) => {
          let projects: Project[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              const item = result.rows.item(i);
              projects.push(new Project(JSON.parse(item.project)));
            }
          }
          console.log("[SQLITE] getAllProjects fetched projects: ", projects);

          response.next(projects);
        }, (tx, error) => {
          console.log("[SQLITE] getAllProjects Error has occured while finding projects", error);
          response.next(undefined);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getAllProjects: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getAllProjects: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public createOrUpdateProjects(projects: Project[], membersHash: any): Observable<boolean>{
    console.log("[SQLITE] createOrUpdateProjects, projects: ", projects.length);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] createOrUpdateProjects skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    if (!projects || projects.length === 0) {
      return;
    }

    const maybeExistentIds = projects.map(list => list.id);

    let toCreateCounter = 0;
    let toUpdateCounter = 0;

    let time1;
    let time2;
    let time3;

    const response = new Subject<boolean>();

    // Step 1:
    // find existing projects
    time1 = performance.now();
    console.log("[SQLITE] createOrUpdateProjects: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] createOrUpdateProjects: Transaction occured");
      time2 = performance.now();
      const query = this.FIND_PROJECTS_IDS_BY_IDS_SQL + "('" + maybeExistentIds.join("','") + "')";

      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] createOrUpdateProjects found projects in DB: ", result.rows.length);

          let existentIds = [];
          for (let i = 0; i < result.rows.length; i++) {
            let item = result.rows.item(i);
            existentIds.push(item.id);
          }

          // Step 2: insert/update tasks in DB
          //
          maybeExistentIds.forEach((id, index) => {
            let query;
            let params;

            const project = projects[index];
            const members = membersHash[project.id];

            const unifiedParams = [JSON.stringify(project), JSON.stringify(members)];

            if (existentIds.includes(id)) {
              params = [...unifiedParams, project.id];
              query = this.UPDATE_PROJECT_SQL;
              ++toUpdateCounter;
            } else {
              params = [project.id, ...unifiedParams];
              query = this.CREATE_PROJECT_SQL;
              ++toCreateCounter;
            }

            // write to DB
            tx.executeSql(query, params, (tx, result) => {
              //
            }, (tx, error) => {
              console.error(`[SQLITE] createOrUpdateProjects: Error has occured while saving project`, error);
            });
          });
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] createOrUpdateProjects: Error has occured while finding projects", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] createOrUpdateProjects: Transaction error", error);
      response.next(false);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] createOrUpdateProjects: Transaction success. Created: ${toCreateCounter}, Updated: ${toUpdateCounter}. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public getProjectMembers(projectId: number): Observable<User[]>{
    console.log("[SQLITE] getProjectMembers, id: " + projectId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getProjectMembers skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<User[]>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.FIND_PROJECT_MEMBERS_SQL, [projectId],
        (tx, result) => {
          if (result.rows.length === 1) {
            let item = result.rows.item(0);
            let jsonMembers = JSON.parse(item.members);
            let members: User[] = [];
            for (let i = 0; i < jsonMembers.length; i++) {
              members.push(new User(jsonMembers[i]));
            }
            console.log("[SQLITE] getProjectMembers fetched members: ", members);
            response.next(members);
          } else {
            console.log("[SQLITE] getProjectMembers, nothing found");
            response.next(undefined);
          }
        }, (tx, error) => {
          console.error("[SQLITE] getProjectMembers: Error1 has occured while executing sql", error);
          response.next(undefined);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getProjectMembers: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getProjectMembers: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  /// Pendings

  
  public addPendingTaskDelete(taskId: number): Observable<boolean>{
    console.log("[SQLITE] addPendingTaskDelete", taskId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addPendingTaskDelete skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    // need to check maybe we already have a pending task create operation
    // if yes - remove it only

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.FIND_PENDING_OPERATION_ID_BY_ID_SQL, [taskId],
        (tx, result) => {
          if (result.rows.length > 0) {
            for (let i = 0; i < result.rows.length; i++) {
              const item = result.rows.item(i);
              const pendingTaskId = item.task_id;

              console.log("[SQLITE] addPendingTaskDelete: found pending op, so removing it. Id:", pendingTaskId);
              tx.executeSql(this.DELETE_PENDING_OPERATION_SQL, [pendingTaskId],
                (tx, result) => {
                  console.log("[SQLITE] addPendingTaskDelete, success result: ", result);
                }, (tx, error) => {
                  console.error("[SQLITE] addPendingTaskDelete, error: ", error);
                }
              );
            }
          } else {
            console.log("[SQLITE] addPendingTaskDelete: not found pending create op");

            // not exist then create a pending delete operation
            tx.executeSql(this.ADD_PENDING_OPERATION_SQL, [taskId, PendingOperation.PENDING_OPERATION_TYPE_TASK_DELETE, null],
              (tx, result) => {
                console.log("[SQLITE] addPendingTaskDelete success", result);
              }, (tx, error) => {
                console.error("[SQLITE] addPendingTaskDelete: Error2 has occured while executing sql", error);
              }
            );
          }
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] addPendingTaskDelete: Error1 has occured while executing sql", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] addPendingTaskDelete: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] addPendingTaskDelete: Transaction success.`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public addPendingTaskCreate(taskId: number): Observable<boolean>{
    console.log("[SQLITE] addPendingTaskCreate", taskId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addPendingTaskCreate skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.ADD_PENDING_OPERATION_SQL, [taskId, PendingOperation.PENDING_OPERATION_TYPE_TASK_CREATE, null],
        (tx, result) => {
          console.log("[SQLITE] addPendingTaskCreate, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] addPendingTaskCreate, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] addPendingTaskCreate: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] addPendingTaskCreate: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public addPendingTaskUpdate(taskId: number, fileUploads: any = null, repeat: String = null, watcherMembers: any = null): Observable<boolean>{
    console.log("[SQLITE] addPendingTaskUpdate", taskId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addPendingTaskUpdate skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    let additionalData = null;
    if (fileUploads || repeat || watcherMembers) {
      additionalData = {};
      if (fileUploads) {
        additionalData["fileUploads"] = fileUploads;
      }
      if (repeat) {
        additionalData["repeat"] = repeat;
      }
      if (watcherMembers) {
        additionalData["watcherMembers"] = watcherMembers;
      }
      additionalData = JSON.stringify(additionalData);
    }

    const response = new Subject<boolean>();

    // need to check maybe we already have a pending task create operation
    // if yes - then ignore current one, do not create new

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.FIND_PENDING_OPERATION_ID_BY_ID_SQL, [taskId],
        (tx, result) => {
          if (result.rows.length > 0) {
            const item = result.rows.item(0);
            const pendingTaskId = item.task_id;

            console.log("[SQLITE] addPendingTaskUpdate: found pending create op, so ignore current. Id:", pendingTaskId);

            if (additionalData) {
              console.log("[SQLITE] addPendingTaskUpdate: add additionalData to op Id:", pendingTaskId);
              console.log("[SQLITE] addPendingTaskUpdate: additionalData", additionalData);

              tx.executeSql(this.UPDATE_PENDING_OPERATION_ADDITIONALDATA_SQL, [additionalData, taskId],
                (tx, result) => {
                  console.log("[SQLITE] addPendingTaskUpdate add fileUploads to op Id success", result);
                }, (tx, error) => {
                  console.error("[SQLITE] addPendingTaskUpdate: Error3 has occured while executing sql", error);
                }
              );
            }
          } else {
            console.log("[SQLITE] addPendingTaskUpdate: not found pending create op");

            // not exist then create a pending update operation
            tx.executeSql(this.ADD_PENDING_OPERATION_SQL, [taskId, PendingOperation.PENDING_OPERATION_TYPE_TASK_UPDATE, additionalData],
              (tx, result) => {
                console.log("[SQLITE] addPendingTaskUpdate success", result);
              }, (tx, error) => {
                console.error("[SQLITE] addPendingTaskUpdate: Error2 has occured while executing sql", error);
              }
            );
          }
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] addPendingTaskUpdate: Error1 has occured while executing sql", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] addPendingTaskUpdate: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] addPendingTaskUpdate: Transaction success.`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public addPendingTaskCommentCreate(taskId: number, taskComment: TaskComment): Observable<boolean>{
    console.log("[SQLITE] addPendingTaskCommentCreate", taskId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addPendingTaskCommentCreate skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }
    let commentPaylod: any = taskComment;
    commentPaylod.task_id = taskId;

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.ADD_PENDING_OPERATION_SQL, [taskComment.id, PendingOperation.PENDING_OPERATION_TYPE_COMMENT_ADD, JSON.stringify(commentPaylod)],
        (tx, result) => {
          console.log("[SQLITE] AddPendingTaskCommentCreate, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] AddPendingTaskCommentCreate, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] addPendingTaskCommentCreate: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] addPendingTaskCommentCreate: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public addPendingTaskCommentUpdate(taskId: number, taskComment: TaskComment): Observable<boolean>{
    console.log("[SQLITE] addPendingTaskCommentUpdate", taskId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addPendingTaskCommentUpdate skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    let commentPaylod: any = taskComment;
    commentPaylod.task_id = taskId;

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.FIND_PENDING_OPERATION_ID_BY_ID_SQL, [commentPaylod.id],
        (tx, result) => {
          if (result.rows.length > 0) {
            const item = result.rows.item(0);
            const pendingTaskId = item.task_id;

            console.log("[SQLITE] addPendingTaskCommentUpdate: found pending create op, so ignore current. Id:", pendingTaskId);

            if (commentPaylod) {
              console.log("[SQLITE] addPendingTaskCommentUpdate: add additionalData to op Id:", pendingTaskId);

              tx.executeSql(this.UPDATE_PENDING_OPERATION_ADDITIONALDATA_SQL, [commentPaylod.id, JSON.stringify(commentPaylod)],
                (tx, result) => {
                  console.log("[SQLITE] addPendingTaskCommentUpdate add fileUploads to op Id success", result);
                }, (tx, error) => {
                  console.error("[SQLITE] addPendingTaskCommentUpdate: Error3 has occured while executing sql", error);
                }
              );
            }
          } else {
            console.log("[SQLITE] addPendingTaskCommentUpdate: not found pending create op");

            // not exist then create a pending update operation
            tx.executeSql(this.ADD_PENDING_OPERATION_SQL, [commentPaylod.id, PendingOperation.PENDING_OPERATION_TYPE_COMMENT_EDIT, JSON.stringify(commentPaylod)],
              (tx, result) => {
                console.log("[SQLITE] addPendingTaskCommentUpdate success", result);
              }, (tx, error) => {
                console.error("[SQLITE] addPendingTaskCommentUpdate: Error2 has occured while executing sql", error);
              }
            );
          }
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] addPendingTaskCommentUpdate: Error1 has occured while executing sql", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] addPendingTaskCommentUpdate: Transaction error", error);
      response.next(false);
    }, () => {
      console.log(`[SQLITE] addPendingTaskCommentUpdate: Transaction success.`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public addPendingAttachmentsBulkDelete(taskId: number, attachmentsIds: number[]): Observable<boolean>{
    console.log("[SQLITE] addPendingAttachmentsBulkDelete", taskId, attachmentsIds);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] addPendingAttachmentsBulkDelete skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      const additionalData = {"attachmentsIds": attachmentsIds};
      tx.executeSql(this.ADD_PENDING_OPERATION_SQL, [taskId, PendingOperation.PENDING_OPERATION_TYPE_ATTACHMENTS_BULK_DELETE, JSON.stringify(additionalData)],
        (tx, result) => {
          console.log("[SQLITE] addPendingAttachmentBulkDelete, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] addPendingAttachmentBulkDelete, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] addPendingAttachmentsBulkDelete: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] addPendingAttachmentsBulkDelete: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public getAllPendingOperations(): Observable<PendingOperation[]>{
    console.log("[SQLITE] getAllPendingOperations");

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getAllPendingOperations skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<PendingOperation[]>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_ALL_PENDING_OPERATIONS_SQL, [],
        (tx, result) => {
          let ops: PendingOperation[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              ops.push(new PendingOperation(item));
            }
          }
          console.log("[SQLITE] getAllPendingOperations fetched ops: ", ops);

          response.next(ops);
        }, (tx, error) => {
          console.error("[SQLITE] getAllPendingOperations: Error has occured while executing sql", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getAllPendingOperations: Transaction error", error);
      response.next(null);
    }, () => {
      console.log(`[SQLITE] getAllPendingOperations: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public deletePendingOperation(taskId: number): Observable<boolean>{
    console.log("[SQLITE] deletePendingOperation, taskId: " + taskId);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] deletePendingOperation skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.DELETE_PENDING_OPERATION_SQL, [taskId],
        (tx, result) => {
          console.log("[SQLITE] deletePendingOperation, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] deletePendingOperation, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] deletePendingOperation: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] deletePendingOperation: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  ///

  
  cleanAllData(): Observable<any> {
    console.log("[SQLITE] cleanAllData");

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] cleanAllData skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.sqlBatch([
      this.DELETE_TASKS,
      this.DELETE_AVATARS,
      this.DELETE_PROJECTS,
      this.DELETE_LISTS,
      this.DELETE_LOCATIONS,
      this.DELETE_TAGS,
      this.DELETE_TAGS_TASKS,
      this.DELETE_PENDING_OPS,
      this.DELETE_SAVE_SEARCHES
    ], () => {
      console.log(`[SQLITE] cleanAllData: Transaction success`);
      response.next(true);
    }, (error) => {
      console.error("[SQLITE] cleanAllData: Transaction error", error);
      response.next(false);
    });

    return response.asObservable().pipe(take(1));
  }

  
  cleanTasksData(): Observable<any> {
    console.log("[SQLITE] cleanTasksData");

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] cleanTasksData skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.DELETE_TASKS, [],
        (tx, result) => {
          console.log("[SQLITE] cleanTasksData, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] cleanTasksData, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] cleanTasksData: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] cleanTasksData: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  close(): Observable<any> {
    console.log("[SQLITE] close");

    const response = new Subject();

    try {
      if (this.sqliteDB) {
        this.sqliteDB.close(() => {
          console.log("[SQLITE] SQLite DB is closed!");
          response.next();
        }, (error) => {
          console.log("[SQLITE] Error closing DB:" + error.message);
          response.next();
        });
      }
    } catch (e) {
      console.log(`[SQLITE] close, error has occured - ${e}`);
      response.next();
    }

    return response.asObservable().pipe(take(1));
  }

  
  deleteDatabase(): Observable<any> {
    console.log("[SQLITE] deleteDatabase");

    const response = new Subject();

    try {
      this.close().subscribe(() => {
        if (CommonUtil.isOnNativeMobileDevice()) {
          window.sqlitePlugin.deleteDatabase({
            name: this.KEY_SQLITE_DB,
            location: "default",
            androidDatabaseImplementation: 2
          }, () => {
            console.log("[SQLITE] Database deleted");
            response.next();
          }, (error) => {
            console.log(" [SQLITE] Delete database ERROR: " + JSON.stringify(error));
            response.next();
          });
        } else if (environment.isElectron) {
          try {
            this.sqliteDB.deleteDatabase(this.KEY_SQLITE_DB, (err) => {
              if (err){
                console.error("[SQLITE] Delete database ERROR: " + JSON.stringify(err));
                response.error(err);
              } else {
                console.log("[SQLITE] sqliteDB deleted");
                response.next();
              }
            });
          } catch (e) {
            console.log(`[SQLITE] Error has occured when delete database - ${e}`);
            response.error(e);
          }
        }
      });
    } catch (e) {
      console.log(`[SQLITE] Error has occured - ${e}`);
      response.next();
    }
    return response.asObservable().pipe(take(1));
  }

  
  assignAvatars(uniqueUsersIds: any[], serverUrls: string[], forceResync: boolean, avatarPropertyName: string): Observable<any> {
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE][assignAvatars] skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<any>();

    console.log("[SQLITE][assignAvatars] uniqueUserIds", uniqueUsersIds.length, serverUrls);

    // 1. check if users avs are already cached
    this.findLocalAvatarsNames(uniqueUsersIds).subscribe((localAvatarsNamesHash) => {
      console.log("[SQLITE][assignAvatars] findLocalAvatarsNames localAvatarsNamesHash", localAvatarsNamesHash);

      let opsDone = 0;
      let resultsHash = {};

      if (uniqueUsersIds.length > 0) {
        uniqueUsersIds.forEach((userId, idx) => {

          let localFileName = localAvatarsNamesHash[userId];
          console.log("[SQLITE][assignAvatars] userId & localFileName", userId, localFileName);

          // exists, so assign local url
          if (localFileName && !forceResync) {
            if (CommonUtil.isOnIOS()) {
              this.buildReadableIOSLocalAvatarUrl(this.filesStorageService.filePathOnDisc(localFileName)).subscribe((readableLocalFileUrl) => {
                if (readableLocalFileUrl) {
                  resultsHash[userId] = readableLocalFileUrl;
                }

                ++opsDone;
                if (opsDone === uniqueUsersIds.length) {
                  console.log("[SQLITE][assignAvatars] DONE", opsDone, resultsHash);
                  response.next(resultsHash);
                }
              });
            } else {
              const readableLocalFileUrl = this.filesStorageService.filePathOnDisc(localFileName);
              resultsHash[userId] = readableLocalFileUrl;

              ++opsDone;
              if (opsDone === uniqueUsersIds.length) {
                console.log("[SQLITE][assignAvatars] DONE", opsDone, resultsHash);
                response.next(resultsHash);
              }
            }

          // not exists, so download, save & assign
          } else {
            const recordAlreadyExists = localFileName ? true : false;

            const currentTimestamp = new Date().getTime();
            localFileName = `user_avatar_${userId}_${currentTimestamp}.png`;

            this.downloadAvatarToDisk(serverUrls[idx], localFileName).subscribe(localFileUrl => {
              if (localFileUrl) {
                this.saveAvatar(recordAlreadyExists, localFileName, userId, currentTimestamp).subscribe(success => {

                });
                resultsHash[userId] = localFileUrl;
              } else {
                resultsHash[userId] = serverUrls[idx];
                if (recordAlreadyExists) {
                  this.deleteAvatar(userId).subscribe( success => {
                  });
                }
              }

              ++opsDone;
              if (opsDone === uniqueUsersIds.length) {
                console.log("[SQLITE][assignAvatars] DONE", opsDone, resultsHash);
                response.next(resultsHash);
              }

            });
          }
        });
      } else {
        console.log("[SQLITE][assignAvatars] DONE", opsDone, resultsHash);
        response.next(resultsHash);
      }

    });
    return response.asObservable().pipe(take(1));
  }

  private buildReadableIOSLocalAvatarUrl(localFileUrl: String): Observable<any> {
    console.log("[SQLITE][buildReadableIOSLocalAvatarUrl]", localFileUrl);

    const response = new Subject<any>();

    window.resolveLocalFileSystemURL(localFileUrl, (fileEntry) => {
      fileEntry.file((file) => {
        const reader = new FileReader();
        reader.onloadend = function (e) {
          localFileUrl = this.result as string;
          response.next(localFileUrl);
        };
        reader.readAsDataURL(file);
      }, (err) => {
        console.error("[SQLIte][buildReadableLocalAvatarUrl] FileReader error", err);
        response.next(null);
        // TODO: maybe re-load from server?
      });
    }, (err) => {
      console.error("[SQLIte][buildReadableLocalAvatarUrl] resolveLocalFileSystemURL error", err);
      response.next(null);
      // TODO: maybe re-load from server?
    });

    return response.asObservable().pipe(take(1));
  }

  private downloadAvatarToDisk(serverUrl: String, localFileName: String): Observable<any> {
    const response = new Subject<any>();

    this.filesStorageService.downloadImageToDevice(serverUrl, localFileName)
      .subscribe(localUrl => {
        response.next(localUrl);
      }, err => {
        console.error("[SQLITE] downloadAvatarToDisk error", err);
        response.next(null);
      }
    );

    return response.asObservable().pipe(take(1));
  }

  private findLocalAvatarsNames(userIds: number[]): Observable<any> {
    console.log("[SQLITE] findLocalAvatarsNames", userIds);

    const response = new Subject<any>();

    this.sqliteDB.readTransaction((tFun) => {
      const query = this.FIND_AVATARS_BY_USER_ID_SQL + "('" + userIds.join("','") + "')";
      console.log("[SQLITE] findLocalAvatarsNames query", query);
      tFun.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] findLocalAvatarsNames found", result.rows.length);
          let avatars = {};
          if (result.rows.length > 0) {
            for (let i = 0; i < result.rows.length; i++) {
              const item = result.rows.item(i);
              avatars[item.user_id] = item.local_file_name;
            }
          }
          response.next(avatars);
        }, (tx, error) => {
          console.error("[SQLITE] findLocalAvatarsNames: error", error);
        }
      );
    });

    return response.asObservable().pipe(take(1));
  }

  private saveAvatar(recordExists: boolean, fileName: String, userId: number, currentTimestamp: number): Observable<any> {
    console.log("[SQLITE] saveAvatar", userId, fileName, recordExists);

    const response = new Subject<any>();

    this.sqliteDB.transaction((tFun) => {
      const query = recordExists ? this.UPDATE_AVATAR_SQL : this.CREATE_AVATAR_IF_NOT_EXIST_SQL;
      const params = recordExists ? [fileName, currentTimestamp, userId] : [userId, fileName, currentTimestamp, userId];

      tFun.executeSql(query, params,
        (tx, result) => {
          console.log("[SQLITE] saveAvatar success");
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] saveAvatar: error", error);
          response.next(false);
        }
      );
    });

    return response.asObservable().pipe(take(1));
  }

  public deleteAvatar(id: number): Observable<boolean>{
    console.log("[SQLITE] deleteAvatar, id: " + id);

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] deleteAvatar skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const response = new Subject<boolean>();

    this.sqliteDB.transaction((tx) => {
      tx.executeSql(this.DELETE_AVATAR, [id],
        (tx, result) => {
          console.log("[SQLITE] deleteAvatar, success result: ", result);
          response.next(true);
        }, (tx, error) => {
          console.error("[SQLITE] deleteAvatar, error: ", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] deleteAvatar: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] deleteAvatar: Transaction success`);
    });

    return response.asObservable().pipe(take(1));
  }

  /// Save Query

  
  public createOrUpdateSaveQuery(saveSearches: SearchQuery[]): Observable<boolean>{
    console.log("[SQLITE] createOrUpdateSaveQuery, lists: ", saveSearches);

    const response = new Subject<boolean>();

    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] createOrUpdateSaveQuery skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    const maybeExistentIds = saveSearches.map(query => query.id);

    let toCreateCounter = 0;
    let toUpdateCounter = 0;

    let time1;
    let time2;
    let time3;


    // Step 1:
    // find existing save searches
    time1 = performance.now();
    console.log("[SQLITE] createOrUpdateSaveQuery: Trying to accure transaction");
    this.sqliteDB.transaction((tx) => {
      console.log("[SQLITE] createOrUpdateSaveQuery: Transaction occured");
      time2 = performance.now();
      const query = this.FIND_SAVE_SEARCHES_IDS_BY_IDS_SQL + "('" + maybeExistentIds.join("','") + "')";

      tx.executeSql(query, [],
        (tx, result) => {
          console.log("[SQLITE] createOrUpdateSaveQuery found save searches in DB: ", result.rows.length);

          let existentIds = [];
          for (let i = 0; i < result.rows.length; i++) {
            let item = result.rows.item(i);
            existentIds.push(item.id);
          }

          // Step 2: insert/update tasks in DB
          //
          maybeExistentIds.forEach((id, index) => {
            let query;
            let params;

            const list = saveSearches[index];

            if (existentIds.includes(id)) {
              params = [list.name, list.created_on, list.issues_count, JSON.stringify(list.filters), list.id];
              query = this.UPDATE_SAVE_SEARCHES_SQL;
              ++toUpdateCounter;
            } else {
              params = [list.id, list.name, list.created_on, list.issues_count, JSON.stringify(list.filters)];
              query = this.CREATE_SAVE_SEARCHES_SQL;
              ++toCreateCounter;
            }

            // write to DB
            tx.executeSql(query, params, (tx, result) => {
              //
            }, (tx, error) => {
              console.error(`[SQLITE] createOrUpdateSaveQuery: Error has occured while saving task`, error);
            });
          });
          if (environment.isElectron) {
            response.next(true);
          }
        }, (tx, error) => {
          console.error("[SQLITE] createOrUpdateSaveQuery: Error has occured while finding tasks", error);
          response.next(false);
        }
      );
    }, (error) => {
      console.error("[SQLITE] createOrUpdateSaveQuery: Transaction error", error);
      response.next(false);
    }, () => {
      time3 = performance.now();
      console.log(`[SQLITE] createOrUpdateSaveQuery: Transaction success. Created: ${toCreateCounter}, Updated: ${toUpdateCounter}. TTAT ${(time2 - time1).toFixed()} TTFT ${(time3 - time2).toFixed()} Total ${(time3 - time1).toFixed()}`);
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public getSaveSearches(): Observable<SearchQuery[]>{
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getSaveSearches skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    console.log("[SQLITE] getSaveSearches");

    const response = new Subject<SearchQuery[]>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_SAVE_SEARCHES, [],
        (tx, result) => {
          let saveSearches: SearchQuery[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              let filters = JSON.parse(item.filters);
              item.filters = filters;
              saveSearches.push(new SearchQuery(item));
            }
          }
          console.log("[SQLITE] getSaveSearches allTags", saveSearches);
          response.next(saveSearches);
        }, (tx, error) => {
          console.error("[SQLITE] getSaveSearches Error has occured", error);
          response.next(null);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getSaveSearches: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getSaveSearches: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }

  
  public getMigrationIds(): Observable<any[]>{
    if (!this.sqliteDB || !this.isDbInitialized) {
      console.log("[SQLITE] getMigrationIds skip: sqliteDB or isDbInitialized is not initialized.");
      return;
    }

    console.log("[SQLITE] getMigrationIds");

    const response = new Subject<any[]>();

    this.sqliteDB.readTransaction((tx) => {
      tx.executeSql(this.SELECT_MIGRATION_IDS_SQL, [],
        (tx, result) => {
          let migrationIds: any[] = [];
          if (result.rows) {
            for (let i = 0; i < result.rows.length; i++) {
              let item = result.rows.item(i);
              migrationIds.push(item.migration_id);
            }
          }
          console.log("[SQLITE] getMigrationIds", migrationIds);
          response.next(migrationIds);
        }, (tx, error) => {
          console.error("[SQLITE] getMigrationIds Error has occured", error);
          response.next([]);
        }
      );
    }, (error) => {
      console.error("[SQLITE] getMigrationIds: Transaction error", error);
    }, () => {
      console.log(`[SQLITE] getMigrationIds: Transaction success.`);
    });

    return response.asObservable().pipe(take(1));
  }
}
