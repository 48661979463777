
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-sidebar">
  <div class="sidebar-block-header">
    <div class="sidebar-item" matTooltip="{{ 'NEW_TASK' | translate }}">
      <button class="new-task-btn" (click)="openAddTaskDialog()">
        <mdl-icon>event_available</mdl-icon>
      </button>
      <div class="item-title" (click)="openAddTaskDialog()">
        {{ 'NEW_TASK' | translate }}
      </div>
      <button id="sidebar-collapse-button" *ngIf="isExpanded" mdl-button (click)="toggleExpand()">
        <mdl-icon class="sidebar-collapse-icon">keyboard_arrow_left</mdl-icon>
      </button>
      <button id="sidebar-collapse-button" class="sidebar-collapse-btn" *ngIf="!isExpanded" mdl-button (click)="toggleExpand()">
        <mdl-icon class="sidebar-collapse-icon">keyboard_arrow_right</mdl-icon>
      </button>
    </div>
  </div>

  <div class="task-sidebar-block">
    <div>
      <div id="sidebar-alltask-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_ALL_TASK)" (click)="navigateStatusRoute(taskConstants.ROUTE_ALL_TASK)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.ALL_TASK | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>event_available</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.ALL_TASK | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_ALL_TASK)">{{ getTotalCount(taskConstants.ROUTE_ALL_TASK) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-new-option">
        <div class="sidebar-item" [class.active] = "isSelectedFilter(taskConstants.ROUTE_NEW)" (click) = "navigateStatusRoute(taskConstants.ROUTE_NEW)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.NEW_TASK | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>panorama_fish_eye</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.NEW_TASK | translate }} </div>
            <div class ="rounded-rectangle" [class.active] = "isSelectedFilter(taskConstants.ROUTE_NEW)">{{ getTotalCount(taskConstants.ROUTE_NEW) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-open-option">
        <div class="sidebar-item" [class.active] = "isSelectedFilter(taskConstants.ROUTE_OPEN)" (click) = "navigateStatusRoute(taskConstants.ROUTE_OPEN)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.OPEN | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>timelapse</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.OPEN | translate }} </div>
            <div class ="rounded-rectangle" [class.active] = "isSelectedFilter(taskConstants.ROUTE_OPEN)">{{ getTotalCount(taskConstants.ROUTE_OPEN) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-completed-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_COMPLETED)" (click)="navigateStatusRoute(taskConstants.ROUTE_COMPLETED)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.COMPLETED | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>check_circle</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.COMPLETED | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_COMPLETED)">{{ getTotalCount(taskConstants.ROUTE_COMPLETED) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-assignedtome-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_ASSIGNEDTOME)" (click)="navigateStatusRoute(taskConstants.ROUTE_ASSIGNEDTOME)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.ASSIGNEDTOME | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>assignment_ind</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.ASSIGNEDTOME | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_ASSIGNEDTOME)">{{ getTotalCount(taskConstants.ROUTE_ASSIGNEDTOME) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-createdbyme-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_CREATEDBYME)" (click)="navigateStatusRoute(taskConstants.ROUTE_CREATEDBYME)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.CREATEDBYME | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>assignment</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.CREATEDBYME | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_CREATEDBYME)">{{ getTotalCount(taskConstants.ROUTE_CREATEDBYME) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-todaydue-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_TODAY_DUE)" (click)="navigateStatusRoute(taskConstants.ROUTE_TODAY_DUE)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.TODAY_DUE | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>access_time</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.TODAY_DUE | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_TODAY_DUE)">{{ getTotalCount(taskConstants.ROUTE_TODAY_DUE) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-tomorrow-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_TOMORROW_DUE)" (click)="navigateStatusRoute(taskConstants.ROUTE_TOMORROW_DUE)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.TOMORROW_DUE | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>watch_later</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.TOMORROW_DUE | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_TOMORROW_DUE)">{{ getTotalCount(taskConstants.ROUTE_TOMORROW_DUE) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-thisweek-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_THIS_WEEK_DUE)" (click)="navigateStatusRoute(taskConstants.ROUTE_THIS_WEEK_DUE)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.THIS_WEEK_DUE | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>date_range</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.THIS_WEEK_DUE | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_THIS_WEEK_DUE)">{{ getTotalCount(taskConstants.ROUTE_THIS_WEEK_DUE) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-tasksiwatch-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_TASKS_I_WATCH)" (click)="navigateStatusRoute(taskConstants.ROUTE_TASKS_I_WATCH)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.TASKS_I_WATCH | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>visibility</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.TASKS_I_WATCH | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_TASKS_I_WATCH)">{{ getTotalCount(taskConstants.ROUTE_TASKS_I_WATCH) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-overdue-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_MY_OVERDUE_TASKS)" (click)="navigateStatusRoute(taskConstants.ROUTE_MY_OVERDUE_TASKS)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.MY_OVERDUE_TASKS | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mdl-icon>assignment_late</mdl-icon>
            </div>
            <div class="item-title"> {{ taskConstants.MY_OVERDUE_TASKS | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_MY_OVERDUE_TASKS)">{{ getTotalCount(taskConstants.ROUTE_MY_OVERDUE_TASKS) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="separate-bar"></div>
    <div class="header-title-div" [class.collapsed]="!isExpanded" (click)="folderListExpanded ? !folderListExpanded = false: folderListExpanded = true">
      <div *ngIf="!isExpanded" [matMenuTriggerFor]="collapseListmenu" class="item-icon" matTooltip="{{ 'LISTS' | translate }}" matTooltipPosition="after"><mdl-icon>assignment</mdl-icon></div>
      <div *ngIf="isExpanded" class="header-title" matTooltip="{{ 'LISTS' | translate }}" matTooltipPosition="above">{{ 'LISTS' | translate }}</div>
      <div class="icon-div">
        <mat-icon class="add-list-icon" (click)="$event.stopPropagation();openAddListDialog()">playlist_add</mat-icon>
        <mdl-icon *ngIf="!folderListExpanded">expand_more</mdl-icon>
        <mdl-icon *ngIf="folderListExpanded">expand_less</mdl-icon>
      </div>
    </div>

    <mat-menu class="collapseMenu" #collapseListmenu="matMenu" xPosition="after">
      <div class="header-menu-item" mat-menu-item>
        <span class="item-title header-menu-item">{{ 'LISTS' | translate }}</span>
        <mat-icon class="" (click)="$event.stopPropagation();openAddListDialog()">playlist_add</mat-icon>
      </div>
      <div class="separate-bar"></div>
      <div mat-menu-item *ngFor="let list of folderList" (click)="navigateListRoute(list.name)">
          <span class="item-title" matTooltip="{{ list.name }}" matTooltipPosition="above">{{ list.name }}</span>
          <div class="rounded-rectangle task-count">
            <span>{{ list.tasks_count }}</span>
          </div>
      </div>
    </mat-menu>

    <div *ngIf="folderListExpanded && isExpanded">
      <div *ngFor="let list of folderList" class="sidebar-item" [class.active] = "isSelectedFilter(list.name)" (click) = "navigateListRoute(list.name)">
        <div class="sidebar-item-div" matTooltip="{{ list.name }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
          <div class="item-icon">
            <mdl-icon>assignment</mdl-icon>
          </div>
          <div class="item-title">{{ list.name }}</div>
          <div class="more-count-div">
            <div class="dropbox">
              <mdl-icon (click)="$event.stopPropagation();" [matMenuTriggerData]="{name: tag}" [matMenuTriggerFor]="folderMenu">more_vert</mdl-icon>
            </div>
            <mat-menu #folderMenu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <div class="context-item" (click)="deleteList(list)">
                  <mdl-icon>delete</mdl-icon>
                  <span>{{ 'DELETE' | translate }}</span>
                </div>
                <div class="context-item" (click)="renameList(list)">
                  <mdl-icon>edit</mdl-icon>
                  <span>{{ 'RENAME' | translate }}</span>
                </div>
              </ng-template>
            </mat-menu>
            <div class="rounded-rectangle task-count" [class.active] = "isSelectedFilter(list.name)">{{ list.tasks_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="separate-bar"></div>
    <div class="header-title-div" [class.collapsed]="!isExpanded" (click)="tagListExpanded ? !tagListExpanded = false: tagListExpanded = true">
      <div *ngIf="!isExpanded" [matMenuTriggerFor]="collapseTagmenu" class="item-icon" matTooltip="{{ 'TAGS' | translate }}" matTooltipPosition="after"><mdl-icon>local_offer</mdl-icon></div>
      <div *ngIf="isExpanded" class="header-title" matTooltip="{{ 'TAGS' | translate }}" matTooltipPosition="above">{{ 'TAGS' | translate }}</div>
      <div class="icon-div">
        <mdl-icon *ngIf="!tagListExpanded">expand_more</mdl-icon>
        <mdl-icon *ngIf="tagListExpanded">expand_less</mdl-icon>
      </div>
    </div>

    <mat-menu class="collapseMenu" #collapseTagmenu="matMenu" xPosition="after">
        <div class="header-menu-item" mat-menu-item>
          <span class="item-title header-menu-item">{{ 'TAGS' | translate }}</span>
        </div>
        <div class="separate-bar"></div>
        <div mat-menu-item *ngFor="let tag of tagList" (click)="navigateTagRoute(tag.name)">
            <span class="item-title" matTooltip="{{ tag.name }}" matTooltipPosition="above">{{ tag.name }}</span>
            <div class="rounded-rectangle task-count">
              <span>{{ tag.count }}</span>
            </div>
        </div>
    </mat-menu>

    <div *ngIf="tagListExpanded && isExpanded">
      <div *ngFor="let tag of tagList" class="sidebar-item" [class.active]="isSelectedFilter(tag.name)" (click)="navigateTagRoute(tag.name)">
        <div class="sidebar-item-div" matTooltip="{{ tag.name }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
          <div class="item-icon">
            <mdl-icon>local_offer</mdl-icon>
          </div>
          <div class="item-title">{{ tag.name }}</div>
          <div class="rounded-rectangle" [class.active]="isSelectedFilter(tag.name)">{{ tag.count }}</div>
        </div>
      </div>
    </div>
    <div class="separate-bar"></div>
    <div class="header-title-div" [class.collapsed]="!isExpanded" (click)="locationListExpanded ? !locationListExpanded = false: locationListExpanded = true">
      <div *ngIf="!isExpanded" [matMenuTriggerFor]="collapseLocationmenu" class="item-icon" matTooltip="{{ 'LOCATIONS' | translate }}" matTooltipPosition="after"><mdl-icon>place</mdl-icon></div>
      <div *ngIf="isExpanded" class="header-title" matTooltip="{{ 'LOCATIONS' | translate }}" matTooltipPosition="above">{{ 'LOCATIONS' | translate }}</div>
      <div class="icon-div">
        <mat-icon class="add-list-icon" (click)="$event.stopPropagation();openAddLocationDialog()">add_location</mat-icon>
        <mdl-icon *ngIf="!locationListExpanded">expand_more</mdl-icon>
        <mdl-icon *ngIf="locationListExpanded">expand_less</mdl-icon>
      </div>
    </div>

    <mat-menu class="collapseMenu" #collapseLocationmenu="matMenu" xPosition="after">
        <div class="header-menu-item" mat-menu-item>
          <span class="item-title header-menu-item">{{ 'LOCATIONS' | translate }}</span>
          <mat-icon (click)="$event.stopPropagation();openAddLocationDialog()">add_location</mat-icon>
        </div>
        <div class="separate-bar"></div>
        <div mat-menu-item *ngFor="let location of locationList" (click)="navigateLocationRoute(location.name)">
            <span class="item-title" matTooltip="{{ location.name }}" matTooltipPosition="above">{{ location.name }}</span>
            <div class="rounded-rectangle task-count">
              <span>{{ location.tasks_count }}</span>
            </div>
        </div>
    </mat-menu>

    <div *ngIf="locationListExpanded && isExpanded">
      <div *ngFor="let location of locationList" class="sidebar-item" [class.active] = "isSelectedFilter(location.name)" (click) = "navigateLocationRoute(location.name)">
        <div class="sidebar-item-div" matTooltip="{{ location.name }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
          <div class="item-icon">
            <mdl-icon>place</mdl-icon>
          </div>
          <div class="item-title">{{ location.name }}</div>
          <div class="more-count-div">
            <div class="dropbox">
              <mdl-icon (click)="$event.stopPropagation();" [matMenuTriggerData]="{name: tag}" [matMenuTriggerFor]="locationMenu">more_vert</mdl-icon>
            </div>
            <mat-menu #locationMenu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <div class="context-item" (click)="deleteLocation(location)">
                  <mdl-icon>delete</mdl-icon>
                  <span>{{ 'DELETE' | translate }}</span>
                </div>
                <div class="context-item" (click)="renameLocation(location)">
                  <mdl-icon>edit</mdl-icon>
                  <span>{{ 'RENAME' | translate }}</span>
                </div>
              </ng-template>
            </mat-menu>
            <div class="rounded-rectangle task-count" [class.active] = "isSelectedFilter(location.name)">{{ location.tasks_count }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <mdl-spinner single-color active="true"></mdl-spinner>
  </div>
</div>
